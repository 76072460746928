<template>
  <validation-observer
    ref="refFormObserver"
    #default="{invalid,pristine}"
  >
    <b-modal
      id="agency-change-note-modal"
      ref="modalEdit"
      header-bg-variant="light-info"
      centered
      size="md"
    >
      <template #modal-header>
        <h4 class="text-heading-3 m-0">
          {{ $t('reservation.editNote') }}
        </h4>
      </template>

      <template #modal-footer>
        <b-button
          variant="outline-danger"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleCancelEdit"
        >
          {{ $t('customer.cancel') }}
        </b-button>
        <b-button
          variant="gradient"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid||pristine"
          @click="handleEdit"
        >
          {{ $t('customer.save') }}
        </b-button>
      </template>

      <b-form ref="form">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Last Name"
            >
              <b-form-group>
                <b-form-textarea
                  v-model="dataToEdit.note"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimInput"
                  rows="4"
                  :placeholder="$t('reservation.notePlaceholder')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInvalidFeedback,
  BFormGroup,
  BButton,
  BModal,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  ref,
  watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import { required, dateFormat } from '@validations'

import useAgenciesHandle from '../useAgenciesHandle'

export default {
  components: {
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BModal,
    BButton,
    BForm,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    agencyToEdit: {
      type: Object,
      required: true,
    },
    agenciesData: {
      type: Object,
      required: true,
    },
    note: {
      type: [String, null],
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const dataToEdit = ref(cloneDeep(props.agencyToEdit))

    const resetContactDataToEdit = () => {
      dataToEdit.value = cloneDeep(props.agencyToEdit)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetContactDataToEdit)

    const { changeNote } = useAgenciesHandle()

    watch(() => props.agencyToEdit, val => {
      dataToEdit.value = cloneDeep(val)
    }, { deep: true })

    // Cancel Edit Contact
    function handleCancelEdit() {
      this.$refs.modalEdit.hide()
      resetForm()
    }

    // handle edit contact
    function handleEdit() {
      const dataToUpdate = getDifference(dataToEdit.value, props.agenciesData)
      store.dispatch('app/setLoading', true)

      changeNote(props.agenciesData.id, dataToUpdate)
        .then(res => {
          emit('update:note', res.raw[0].note)

          this.$refs.modalEdit.hide()
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    return {
      dataToEdit,
      // refContactListTable,
      handleCancelEdit,
      handleEdit,
      required,
      dateFormat,

      refFormObserver,
      getValidationState,
      resetForm,

      trimInput,
    }
  },
}
</script>
