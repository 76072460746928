export default {
  packageConfigsList: 'Danh sách gói dịch vụ bán lẻ',
  packageConfigsCreate: 'Tạo gói dịch vụ bán lẻ',
  agencyPackageConfigsList: 'Các gói đang hoạt động',
  agencyPackageConfigsCreate: 'Đăng ký dùng thử',
  agencyPackageConfigsIncrease: 'Gia hạn',
  agencyPackageConfigsBuy: 'Mua',
  agencyPackageConfigsEdit: 'Sửa',
  agencyPackageConfigsGift: 'Tặng gói',

  agencyPackageOrderList: 'Quản lý đơn hàng',
  agCreate: {
    title: 'Thêm đại lý mua gói dịch vụ bán lẻ',
  },
  columns: {
    name: 'Tên gói',
    type: 'Loại gói',
    price: 'Giá bán',
    searchLimit: 'Giới hạn tìm kiếm',
    specialNote: 'Đã bao gồm gói duy trì Website',
    bookingLimit: 'Giới hạn đặt vé',
    issueLimit: 'Giới hạn xuất vé',
    paxLimit: 'Giới hạn số lượng khách đặt vé',
    enableQR: 'Cho phép QR',
    unit: 'Đơn vị',
    enableFilter: 'Cho phép lọc',
    feeTransaction: 'Phí tự động xuất vé',
    createdAt: 'Ngày tạo',
    updatedAt: 'Ngày sửa',
    isActive: 'Trạng thái',
    agency: 'Đại lý bán lẻ',
    parentAgency: 'Đại lý F2',
    packageConfig: 'Gói dịch vụ',
    monthsUse: 'Số tháng sử dụng',
    monthsExtra: 'Số tháng được khuyến mãi',
    expiredAt: 'Thời gian hết hạn',
    enable: 'Trạng thái',
    action: 'Thao tác',
    paymentMethod: 'Phương thức thanh toán',
    payment: 'Thanh toán',
    buyer: 'Đại lý mua',
    agencyRetail: 'Đại lý áp dụng',
    orderCode: 'Mã đơn hàng',
    totalAmountToBePaid: 'Tổng tiền sau khi giảm giá',
    totalPrice: 'Tổng tiền',
    searchCount: 'Số lượt đã tìm kiếm',
    issueCount: 'Số vé đã xuất',
    bookCount: 'Số vé đã đặt',
    periodStart: 'Từ ngày',
    periodEnd: 'Đến ngày',
    percentage: 'Giảm giá (%)',
    amountMax: 'Giá trị mã giảm giá',
    code: 'Mã giảm giá',
    isUsed: 'Đã sử dụng',
    totalDiscount: 'Tổng giảm giá',
    autoIssueTicket: 'Tự động xuất vé khi thanh toán',
    enableDraft: 'Hỗ trợ quản lý booking ảo',
    domain: 'domain',
    isExpired: 'Trạng thái',
    dateCreate: 'Ngày',
    paymentMethods: 'Phương thức thanh toán',
  },
  placeholder: {
    searchText: 'Tìm kiếm gói dịch vụ',
    agency: 'Đại lý F2',
  },
  buy: {
    title: 'Mua gói dịch vụ bán lẻ',
  },
  increase: {
    title: 'Gia hạn gói dịch vụ bán lẻ',
  },
  payment: {
    title: 'Thanh toán',
  },
  gift: {
    title: 'Tặng gói dịch vụ bán lẻ',
  },
  CREDIT: 'Công nợ',
  MONEY: 'Chuyển khoản',
  PENDING: 'Chưa thanh toán',
  DONE: 'Đã thanh toán',
  CANCELED: 'Đã huỷ',
  cancel: 'Huỷ đơn hàng',
  coupon: {
    titleList: 'Danh sách mã giảm giá gói dịch vụ bản lẻ',
    create: 'Tạo',
    createCoupon: 'Tạo mã giảm giá',
  },
  addDomain: 'Thêm domain',
  expired: 'Đã hết hạn',
  unexpired: 'Chưa hết hạn',
  over: 'Quá hạn mức',
  notover: 'Còn hạn mức',
  all: 'Tất cả',
  history: {
    title: 'Số liệu sử dụng',
  },
  form: {
    create: 'Tạo gói bán lẻ',
    update: 'Sửa gói bán lẻ',
    name: 'Tên gói',
    price: 'Giá',
    unit: 'Đơn vị',
    searchLimit: 'Giới hạn tìm kiếm',
    bookingLimit: 'Giới hạn đặt vé',
    issueLimit: 'Giới hạn xuất vé',
    paxLimit: 'Giới hạn số lượng khách',
    enableQR: 'Bật QR',
    enableFilter: 'Bật bộ lọc',
    feeTransaction: 'Phí tự động xuất vé',
    isGift: 'Là quà tặng',
    type: 'Loại gói',
    exclusiveAgencies: 'Đại lý độc quyền',
    descriptions: 'Mô tả',
    paymentMethods: 'Phương thức thanh toán',
    placeholder: {
      name: 'Nhập tên',
      price: 'Nhập giá',
      unit: 'Nhập đơn vị',
      searchLimit: 'Nhập giới hạn tìm kiếm',
      bookingLimit: 'Nhập giới hạn đặt chỗ',
      issueLimit: 'Nhập giới hạn cấp phát',
      paxLimit: 'Nhập giới hạn khách',
      enableQR: 'Bật QR',
      enableFilter: 'Bật bộ lọc',
      feeTransaction: 'Nhập phí giao dịch',
      isGift: 'Chọn nếu là quà tặng',
      type: 'Chọn gói',
      exclusiveAgencies: 'Nhập đại lý độc quyền',
      descriptions: 'Nhập mô tả',
    },

    key: 'Key',
    description: 'Mô tả',
    value: 'Giá trị',
  },
  units: {
    MONTH: 'Tháng',
    YEAR: 'Năm',
  },
  btn: {
    addDescription: 'Thêm mô tả',
  },
}
