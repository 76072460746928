<template lang="">
  <BCard
    id="agent-requests-list"
    body-class="p-1"
  >
    <!-- ANCHOR Filters -->
    <AgentRequestStatsFilters
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :agency-code.sync="agencyCode"
      :searchText.sync="searchText"
      :requestType.sync="requestType"
      @refetchData="refetchData"
      @resetFilters="
        clearFilter()
        refetchData()
      "
    />

    <h4 class="fw-700 py-50 mb-0">
      {{ $t('agentRequests.title') }}
    </h4>

    <!-- ANCHOR Table List -->
    <b-table
      ref="refAgentRequestsListTable"
      :style="maxHeightStyle"
      :sticky-header="true"
      class="position-relative"
      :items="fetchAgentRequests"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
      bordered
      :busy="loading"
      :sort-desc.sync="isSortDirDesc"
      :sort-by.sync="sortBy"
      :fields="tableColumns"
    >
      <template
        v-for="column in tableColumns"
        #[`head(${column.key})`]="data"
      >
        <span
          :key="column.key"
          class="text-nowrap"
        >
          <div>{{ $t(`agentRequests.columns.${data.column}`) }}</div>
        </span>
      </template>

      <template #table-busy>
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>

      <template #cell(no)="data">
        <span class="font-weight-bold">{{ data.index + 1 }}</span>
      </template>

      <template #cell(agentCode)="{ item }">
        <span class="fw-700 text-uppercase">{{ item?.agentCode }}</span>
      </template>

      <template #cell(routes)="{ item }">
        {{ item.routes }}
      </template>

      <template #cell(flightDates)="{ item }">
        <div
          v-for="(flightDate, flightDateIndex) in item.flightDates.split(' - ')"
          :key="flightDateIndex"
          class="text-nowrap"
        >
          {{ flightDate }}
        </div>
      </template>

      <template #cell(source)="{ item }">
        {{ (item.request?.sources || []).join(', ') }}
      </template>

      <template #cell(trips)="{ item }">
        <HStack>
          <div class="fw-700">
            {{ item.request.type }}
          </div>:
          {{ item.trips }}
        </HStack>
        <HStack class="gap-3">
          <HStack end>
            <small class="font-italic">Người lớn:</small>
            <span class="fw-700">{{ item.request.adult }}</span>
          </HStack>
          <HStack end>
            <small class="font-italic">Trẻ em:</small>
            <span class="fw-700">{{ item.request.child }}</span>
          </HStack>
          <HStack end>
            <small class="font-italic">Em bé:</small>
            <span class="fw-700">{{ item.request.infant }}</span>
          </HStack>
        </HStack>
        <HStack class="gap-3">
          <div>Giá {{ item.request.isGroupedItineraryResponse ? 'kết hợp' : 'tách chặng' }}</div>
          <div>{{ item.request.numberOfStop === 0 ? 'Bay thẳng' : 'Nối chuyến' }}</div>
        </HStack>
      </template>

      <template #cell(requestType)="{ item }">
        <BBadge
          :variant="resolveRequestTypeVariant(item.requestType)"
          pill
          class="px-75"
        >
          {{ $te(`agentRequests.requestType.${item.requestType}`)
            ? $t(`agentRequests.requestType.${item.requestType}`)
            : item.requestType }}
        </BBadge>
      </template>

      <template #cell(createdAt)="{ item }">
        <div class="">
          {{ convertISODateTime(item.createdAt).dateTime }}
        </div>
      </template>
    </b-table>

    <!-- ANCHOR: Table Footer -->
    <b-row ref="refTableFooter">
      <b-col
        cols="6"
        md="4"
        class="d-flex align-items-center"
        order="1"
      >
        <v-select
          v-model="sizePerPage"
          :options="sizePerPageLgOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
          append-to-body
          :calculate-position="withPopper"
        >
          <template #option="data">
            <span>
              {{ data.label }} / {{ $t('page') }}
            </span>
          </template>

          <template #selected-option="data">
            <span>
              {{ data.label }} / {{ $t('page') }}
            </span>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="d-flex-center"
        order="3"
        order-md="2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalAgentRequests"
          :per-page="sizePerPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>

      <b-col
        cols="6"
        md="4"
        order="2"
        order-md="3"
        class="d-flex-center justify-content-end"
      >
        <span class="text-muted">
          {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
          {{ dataMeta.of }} {{ $t('paginationText.items') }}
        </span>
      </b-col>
    </b-row>
  </BCard>
</template>
<script>
import {
  BCard, BTable, BSpinner, BPagination, BCol, BRow,
  BBadge,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import { computed, ref } from '@vue/composition-api'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import { convertISODateTime } from '@/@core/utils/filter'

import useAgentRequestStatsList, { resolveRequestTypeVariant } from './useAgentRequestStatsList'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BPagination,
    BCol,
    BRow,
    BBadge,
    AgentRequestStatsFilters: () => import('./AgentRequestStatsFilters.vue'),
    vSelect: () => import('vue-select'),
  },
  setup() {
    const {
      refAgentRequestsListTable,
      fetchAgentRequests,
      loading,
      refetchData,
      clearFilter,
      dataMeta,
      sizePerPage,
      currentPage,
      totalAgentRequests,

      // Filter
      isSortDirDesc,
      sortBy,
      startDate,
      endDate,
      agencyCode,
      searchText,
      requestType,
      tableColumns,
    } = useAgentRequestStatsList()

    const refTableFooter = ref()
    const maxHeightStyle = computed(() => {
      const offsetTop = refAgentRequestsListTable.value?.$el?.offsetTop || 0
      const paginationHeight = refTableFooter.value?.offsetHeight || 0
      const rs = (offsetTop + paginationHeight) > 0 ? { maxHeight: `calc(80vh - ${offsetTop + paginationHeight + 20}px)` } : { maxHeight: '70vh' }
      return rs
    })

    return {
      refAgentRequestsListTable,
      fetchAgentRequests,
      loading,
      refetchData,
      clearFilter,
      dataMeta,
      sizePerPage,
      currentPage,
      totalAgentRequests,

      // Filter
      isSortDirDesc,
      sortBy,
      startDate,
      endDate,
      agencyCode,
      searchText,
      requestType,
      tableColumns,
      sizePerPageLgOptions,
      convertISODateTime,
      resolveRequestTypeVariant,
      maxHeightStyle,
      refTableFooter,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.maxWidth = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
  @import '@core/scss/vue/libs/vue-context.scss';

  #agent-requests-list ::v-deep {
    .per-page-selector {
      width: 135px;
    }
  }
</style>

<style lang="scss">
  #agent-requests-list {
    .table {
      thead th {
        vertical-align: middle;
      }
    }
  }

</style>
