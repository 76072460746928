var render = function () {
  var _vm$invoiceData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "dollar",
      "size": "25"
    },
    nativeOn: {
      "dblclick": function dblclick($event) {
        $event.preventDefault();
        return _vm.$emit('toggle-button');
      }
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.payment.title')) + " ")])], 1), _c('div', {
    staticClass: "d-flex-between gap-1"
  }, [_c('BRow', {
    staticClass: "px-75 flex-1"
  }, [_c('b-col', {
    staticClass: "px-25 flex-1",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.payment.totalAmount'),
      "label-for": "totalAmount"
    }
  }, [_c('IAmInputMoney', {
    attrs: {
      "id": "totalAmount",
      "value-money": _vm.getTotalAmount,
      "placeholder": _vm.$t('invoice.create.payment.totalAmount'),
      "disabled": true
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25 flex-1",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.payment.totalAmountNeedToPay'),
      "label-for": "totalAmountNeedToPay"
    }
  }, [_c('IAmInputMoney', {
    attrs: {
      "id": "totalAmountNeedToPay",
      "value-money": _vm.needToPayAmount,
      "placeholder": _vm.$t('invoice.create.payment.phTotalAmountNeedToPay'),
      "disabled": !_vm.isEditing,
      "on-blur": function onBlur(val) {
        return _vm.needToPayAmount = Number(val);
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25 flex-1",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.payment.agencyReceive'),
      "rules": "",
      "vid": "agencyReceive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$invoiceData;
        var errors = _ref.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "agencyReceive"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.payment.agencyReceive')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "agencyReceive",
            "value": (_vm$invoiceData = _vm.invoiceData) === null || _vm$invoiceData === void 0 ? void 0 : _vm$invoiceData.agencyReceive,
            "name": _vm.$t('invoice.create.payment.agencyReceive'),
            "options": _vm.agencyReceiveOptions,
            "reduce": function reduce(val) {
              return val;
            },
            "append-to-body": "",
            "calculate-position": _vm.withPopper,
            "label": "agencyCode",
            "filterable": "",
            "disabled": !_vm.isEditing,
            "clearable": true,
            "placeholder": _vm.$t('invoice.create.payment.agencyReceive'),
            "loading": _vm.loadingAgencyReceive
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                agencyReceive: val
              }));
            },
            "open": _vm.onOpenAgencyReceive
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "d-block font-weight-bold text-truncate text-uppercase"
              }, [_c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "d-block font-weight-bold text-truncate text-uppercase"
              }, [_c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "agencyReceive",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25 flex-1",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.payment.agencyIssue')
    }
  }, [_c('v-select', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "agencyIssue",
      "value": (_vm$invoiceData2 = _vm.invoiceData) === null || _vm$invoiceData2 === void 0 ? void 0 : _vm$invoiceData2.agencyIssue,
      "options": _vm.agencyIssueOptions,
      "label": "agencyCode",
      "append-to-body": "",
      "calculate-position": _vm.withPopper,
      "disabled": !_vm.isEditing,
      "clearable": true,
      "placeholder": _vm.$t('invoice.create.payment.agencyIssue'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          agencyIssue: val
        }));
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('BButton', {
    staticClass: "p-50 text-nowrap",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "pill": "",
      "disabled": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.payment.addDocuments')) + " ")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }