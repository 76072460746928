import service from './service'

export default {
  fetchPromotions(params) {
    return service.get('/promotions', params)
  },
  fetchPromotionsAvailable(params) {
    return service.get('/promotions/available', params)
  },
  createPromotion(body) {
    return service.post('/promotions', body)
  },
  getPromotionById(id) {
    return service.get(`/promotions/${id}`)
  },
  updatePromotion({ id, ...body }) {
    return service.patch(`/promotions/${id}`, body)
  },
  deletePromotion({ id }) {
    return service.delete(`/promotions/${id}`)
  },
}
