<!-- eslint-disable vue/no-mutating-props -->
<template lang="">
  <!-- SECTION - passengers -->
  <div class="d-flex-center gap-2">
    <div class="flex-1">
      <div
        class="section-table pax-table-template-columns"
        :class="[
          { 'border-theme': !config.showBackgroundColor || isBasicType},
          { 'show-ticket-number-grid': config.showTicketNumber}
        ]"
        :style="`
          ${isBasicType ? 'border-width: 2px;' : ''}
          ${config.showBackgroundColor && isBasicType ? `background-color: ${currentBgColor} !important` : ''}
        `"
      >
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="`
            ${config.showBackgroundColor && !isBasicType ? `background-color: ${currentBgColor} !important` : ''}
          `"
          :class="`${isBasicType ? 'bg-theme-dark text-white' : ''}`"
          contenteditable="true"
        >
          {{ $t('ticket.paxTable.no', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor && !isBasicType ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable="true"
          :class="`${isBasicType ? 'bg-theme-dark text-white' : ''}`"
        >
          {{ $t('ticket.paxTable.passenger', config.locale) }}
        </div>

        <div
          v-if="config.showTicketNumber"
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor && !isBasicType ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable="true"
          :class="`${isBasicType ? 'bg-theme-dark text-white' : ''}`"
        >
          {{ $t('ticket.paxTable.ticketNumber', config.locale) }}
        </div>

        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor && !isBasicType ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable="true"
          :class="`${isBasicType ? 'bg-theme-dark text-white' : ''}`"
        >
          {{ $t('ticket.paxTable.additionalServices', config.locale) }}
        </div>

        <template v-for="(item, index) in newPaxs">
          <div
            :key="`no-${index}`"
            class="text-20px p-4px-16px d-flex align-items-center w-100 border-t"
          >
            <div contenteditable="true">
              {{ String(index + 1).padStart(2, "0") }}
            </div>
          </div>
          <div
            :key="`pax-${index}`"
            class="p-4px-16px d-flex align-items-center w-100 border-t"
          >
            <div
              contenteditable="true"
              class="text-20px fw-700 w-100"
            >
              <template v-if="!frontendI18n && item?.passenger?.paxType && item?.passenger?.title">
                <!-- title INFANT đổi thành bé trai/gái https://discord.com/channels/1054696448110903327/1329715495997280318 -->
                ({{ item.passenger.title && $te(`reservation.titleOptions.${item.passenger.paxType === 'INFANT' ? 'CHILD' : item.passenger.paxType}.${item.passenger.title}`, config.locale)
                  ? $t(`reservation.titleOptions.${item.passenger.paxType === 'INFANT' ? 'CHILD' : item.passenger.paxType}.${item.passenger.title}`, config.locale)
                  : item.passenger.title }})
              </template>
              <span class="fw-700">
                {{
                  frontendI18n && !isEmpty(item.passenger)
                    ? $t(item.passenger)
                    : getLocaleFullName(item.passenger)
                }}
              </span>
              <span
                v-if="item.passenger?.travelFlyer"
                class="font-italic"
              >
                ({{ item.passenger?.travelFlyer }})
              </span>
            </div>
          </div>

          <div
            v-if="config.showTicketNumber"
            :key="`ticketNumber-${index}`"
            class="text-20px p-4px-16px d-flex flex-column align-items-center w-100 border-t"
          >
            <div
              v-for="(ticketNumber, ticketNumberIndex) in item.passenger?.ticketNumber"
              :key="`ticketNumber-${ticketNumberIndex}`"
              contenteditable="true"
              style="width: 100%;"
            >
              <span v-if="isCombinate && ticketNumber?.source">({{ ticketNumber?.source }})</span> {{ ticketNumber?.number }}
            </div>
            <div
              v-if="!(item.passenger && item.passenger?.ticketNumber?.length)"
              contenteditable="true"
              style="width: 100%;"
            />
          </div>

          <div
            :key="`bag-${index}`"
            class="text-20px p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
          >
            <div
              contenteditable="true"
              class="flex-1"
            >
              <template v-if="frontendI18n">
                {{ $t(item.bags, config.locale) }}
              </template>

              <template v-else-if="item.bags && item.bags.length">
                <template v-for="(bag, bagIndex) in item.bags">
                  <div
                    v-if="bag.xBagName && bag.trip"
                    :key="`BAG-${bagIndex}-${bag.segmentIds[0]}-${bag.paxId}-${bag.id}`"
                  >
                    {{ bag.trip }}:
                    {{ bag.xBagName }}
                  </div>
                </template>
              </template>

              <template v-if="item.others && item.others.length">
                <template v-for="(other, otherIndex) in item.others">
                  <div
                    v-if="other.trip && other.otherName"
                    :key="`OTHER-${otherIndex}-${other.segmentIds[0]}-${other.paxId}-${other.serviceSubCode}-${other.id}`"
                  >
                    {{ other.trip }}:
                    {{ other.otherName }}
                  </div>
                </template>
              </template>

              <template v-if="item.seat && item.seat.length">
                <template v-for="(seat, seatIndex) in item.seat">
                  <div
                    v-if="seat.seatName"
                    :key="`seat-${seatIndex}-${seat.segmentIds[0]}-${seat.paxId}-${seat.id}-${seat.pdcSeat}`"
                  >
                    {{ seat.trip }}:
                    {{ seat.seatName }}
                  </div>
                </template>
              </template>
            </div>
            <b-button
              class="pax-button"
              variant="outline-danger"
              style="padding: 4px;"
              @click="removePax(index)"
            >
              <feather-icon
                icon="MinusIcon"
                size="16"
              />
            </b-button>
            <b-button
              class="pax-button"
              variant="outline-success"
              style="padding: 4px;"
              @click="addPax(index)"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
            </b-button>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="qrBookingInfo"
      class="d-flex-center flex-column"
    >
      <div class="font-weight-bolder color-theme text-nowrap mb-50">
        {{ $t('ticket.qrBookingInfo', config.locale) }}
      </div>
      <QrcodeVue
        :value="qrBookingInfo"
        size="100"
        level="M"
      />
    </div>
  </div>
  <!-- !SECTION - passengers -->
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'

export default {
  components: {
    BButton,
    QrcodeVue: () => import('qrcode.vue'),
  },
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    qrBookingInfo: {
      type: [String, Boolean],
      default: '',
    },
    newPaxs: {
      type: Array,
      default: () => [],
    },
    currentBgColor: {
      type: String,
      default: '',
    },
    addPax: {
      type: Function,
      default: () => {},
    },
    removePax: {
      type: Function,
      default: () => {},
    },
    eticketTemplate: {
      type: String,
      default: 'modern',
    },
    isCombinate: {
      type: Boolean,
      default: false,
    },

  },
  setup(props) {
    const isBasicType = computed(() => props.eticketTemplate === 'basic')
    return {
      getLocaleFullName,
      isBasicType,
      isEmpty,
    }
  },
}
</script>
<style lang="">

</style>
