export const SALE_REPORT_TABLE_COLUMNS = [
  { label: 'no', key: 'no' /* , viValue: 'STT' */ },
  { label: 'airline', key: 'airline' /* , viValue: 'Hãng hàng không' */ },
  { label: 'source', key: 'source' /* , viValue: 'Hệ thống' */ },
  { label: 'bookingCode', key: 'bookingCode' /* , viValue: 'Mã đặt chỗ' */ },
  { label: 'paxName', key: 'paxName' /* , viValue: '' */ },
  { label: 'paxType', key: 'paxType' /* , viValue: '' */ },
  // { label: 'paxId', key: 'paxId' },
  { label: 'ticketNumber', key: 'ticketNumber' /* , viValue: '' */ },
  { label: 'trips', key: 'trips' /* , viValue: '' */ },
  { label: 'flightDates', key: 'flightDates' },
  { label: 'bookingClass', key: 'bookingClass' /* , viValue: 'Hạng đặt chỗ' */ },
  { label: 'paymentCode', key: 'paymentCode' /* , viValue: '' */ },
  { label: 'ticketType', key: 'ticketType' /* , viValue: '' */ },
  { label: 'flightType', key: 'flightType' /* , viValue: '' */ },
  { label: 'fareBasisCode', key: 'fareBasisCode' /* , viValue: '' */ },
  { label: 'bookingDate', key: 'bookingDate' /* , viValue: '' */ },
  { label: 'issueDate', key: 'issueDate', sortable: true /* , viValue: '' */ },
  { label: 'dob', key: 'dob' /* , viValue: '' */ },
  { label: 'booker', key: 'booker' /* , viValue: '' */ },
  { label: 'issuer', key: 'issuer' /* , viValue: '' */ },
  { label: 'agency', key: 'agency' /* , viValue: 'Mã đại lí' */ },
  { label: 'signBook', key: 'signBook' /* , viValue: '' */ },
  { label: 'currency', key: 'currency' /* , viValue: 'VND' */ },
  { label: 'priceTicket', key: 'priceTicket' /* , viValue: '' */ },
  { label: 'vat', key: 'tax' /* , viValue: 'Thuế VAT' */ },
  { label: 'otherTax', key: 'otherTax' },
  { label: 'tax', key: 'collectionFee' /* , viValue: '' */ },
  // { label: 'fee', key: 'fee' /* , viValue: 'Phí' */ },
  { label: 'feeIn', key: 'feeIn' /* , viValue: 'Phí' */ },
  { label: 'feeOut', key: 'feeOut' /* , viValue: 'Phí' */ },
  { label: 'feeService', key: 'feeService' /* , viValue: 'Phí dịch vụ' */ },
  // { label: 'commissionPrepaid', key: 'commissionPrepaid' /* , viValue: '' */ },
  {
    label: 'commissionPrepaidIn',
    key: 'commissionPrepaidIn' /* , viValue: '' */,
  },
  {
    label: 'commissionPrepaidOut',
    key: 'commissionPrepaidOut' /* , viValue: '' */,
  },
  // { label: 'unpaidCommission', key: 'unpaidCommission' /* , viValue: '' */ },
  { label: 'unpaidCommissionIn', key: 'unpaidCommissionIn' /* , viValue: '' */ },
  { label: 'unpaidCommissionOut', key: 'unpaidCommissionOut' /* , viValue: '' */ },
  { label: 'commission', key: 'commission' /* , viValue: 'hoa hong hang~' */ },
  // { label: 'totalPayment', key: 'totalPayment' /* , viValue: '' */ },
  { label: 'tradeCreditors', key: 'tradeCreditors' /* , viValue: '' */ },
  { label: 'receivables', key: 'receivables' /* , viValue: '' */ },
  { label: 'balanceAdjustment', key: 'balanceAdjustment' /* , viValue: '' */ },
  { label: 'profit', key: 'profit' /* , viValue: '' */ },
  // { label: 'accountsPayable', key: 'accountsPayable' /* , viValue: '' */ },
  { label: 'created', key: 'createdAt', sortable: true /* viValue: '', */ },
  { label: 'updated', key: 'updatedAt', sortable: true /* viValue: '', */ },
  { label: 'actions', key: 'actions' /* viValue: '', */ },
]

export const SALE_REPORT_REFUND_TICKET_TABLE_COLUMNS = [
  { label: 'no', key: 'no' /* , viValue: 'STT' */ },
  // { label: 'airline', key: 'airline' /* , viValue: 'Hãng hàng không' */ },
  // { label: 'source', key: 'source' /* , viValue: 'Hệ thống' */ },
  { label: 'bookingCode', key: 'bookingCode' /* , viValue: 'Mã đặt chỗ' */ },
  { label: 'paxName', key: 'paxName' /* , viValue: '' */ },
  { label: 'paxType', key: 'paxType' /* , viValue: '' */ },
  { label: 'flightDates', key: 'flightDates' },
  // { label: 'paxId', key: 'paxId' },
  { label: 'ticketNumber', key: 'ticketNumber' /* , viValue: '' */ },
  // { label: 'ticketType', key: 'ticketType' /* , viValue: '' */ },
  // { label: 'flightType', key: 'flightType' /* , viValue: '' */ },
  // { label: 'trips', key: 'trips' /* , viValue: '' */ },
  // { label: 'bookingClass', key: 'bookingClass' /* , viValue: 'Hạng đặt chỗ' */ },
  // { label: 'fareBasisCode', key: 'fareBasisCode' /* , viValue: '' */ },
  // { label: 'bookingDate', key: 'bookingDate' /* , viValue: '' */ },
  // { label: 'issueDate', key: 'issueDate' /* , viValue: '' */ },
  { label: 'booker', key: 'booker' /* , viValue: '' */ },
  // { label: 'issuer', key: 'issuer' /* , viValue: '' */ },
  { label: 'agency', key: 'agency' /* , viValue: 'Mã đại lí' */ },
  { label: 'signBook', key: 'signBook' /* , viValue: '' */ },
  // { label: 'currency', key: 'currency' /* , viValue: 'VND' */ },
  { label: 'priceTicket', key: 'priceTicket' /* , viValue: '' */ },
  // { label: 'vat', key: 'tax' /* , viValue: 'Thuế VAT' */ },
  // { label: 'tax', key: 'collectionFee' /* , viValue: '' */ },
  // { label: 'fee', key: 'fee' /* , viValue: 'Phí' */ },
  // { label: 'feeIn', key: 'feeIn' /* , viValue: 'Phí' */ },
  // { label: 'feeOut', key: 'feeOut' /* , viValue: 'Phí' */ },
  // { label: 'feeService', key: 'feeService' /* , viValue: 'Phí dịch vụ' */ },
  // { label: 'commissionPrepaid', key: 'commissionPrepaid' /* , viValue: '' */ },
  // { label: 'commissionPrepaidParent', key: 'commissionPrepaidParent' /* , viValue: '' */ }, // FIXME
  // { label: 'commissionPrepaidIn', key: 'commissionPrepaidIn' /* , viValue: '' */ },
  // { label: 'commissionPrepaidOut', key: 'commissionPrepaidOut' /* , viValue: '' */ },
  // { label: 'unpaidCommission', key: 'unpaidCommission' /* , viValue: '' */ },
  // { label: 'totalPayment', key: 'totalPayment' /* , viValue: '' */ },
  // { label: 'tradeCreditors', key: 'tradeCreditors' /* , viValue: '' */ },
  // { label: 'receivables', key: 'receivables' /* , viValue: '' */ },
  // { label: 'profit', key: 'profit' /* , viValue: '' */ },
  // { label: 'accountsPayable', key: 'accountsPayable' /* , viValue: '' */ },
  { label: 'refundDate', key: 'createdAt', sortable: true /* viValue: '', */ },
  { label: 'notePrivate', key: 'notePrivate', sortable: true /* viValue: '', */ },
  // { label: 'updated', key: 'updatedAt', sortable: true /* viValue: '', */ },
  {
    label: 'actions',
    key: 'action' /* , viValue: '' */,
    stickyColumn: true,
    class: 'position-right-0',
  },
]

export const flightTypeOptions = [
  { label: 'saleReport.domestic', value: 'Domestic' },
  { label: 'saleReport.inbound', value: 'Inbound' },
]

export const attachmentStatusOptions = [
  { label: 'saleReport.attachedInInvoice', value: true },
  { label: 'saleReport.notAttachedInInvoice', value: false },
]

// ADM, KTT của F1 được tạo và cập nhật sale report
export const TYPE_CAN_CREATE_UPDATE_MANUALLY = ['ADM', 'KTT']
