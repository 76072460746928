var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('IncorrectInvoiceInfoCard', {
    attrs: {
      "incorrectInvoiceData": _vm.incorrectInvoiceToCreate,
      "isCreate": ""
    },
    on: {
      "update:incorrectInvoiceData": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      },
      "update:incorrect-invoice-data": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      }
    }
  }), _c('IncorrectInvoiceSelectCard'), _c('IncorrectInvoiceDetailCard')], 1), _c('div', [_c('BButton', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.onCreateIncorrectInvoice
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.btn.create')) + " ")])], 1), _c('pre', [_vm._v(_vm._s(_vm.incorrectInvoiceToCreate))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }