<template lang="">
  <b-modal
    id="modal-take-photo-itineraries"
    size="lg"
    :centered="true"
    :hide-header="true"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    body-class="p-0"
  >
    <Stack
      id="take-photo-itineraries"
      class="p-1 gap-2"
    >
      <Stack
        center
        class="gap-2"
      >
        <ItineraryCard
          v-for="(itinerary, index) in selectedTrips"
          :key="index"
          :itinerary="itinerary"
        />
      </Stack>

      <slot v-if="!isHidePrice" />
    </Stack>

    <!-- ANCHOR Buttons -->
    <HStack
      between
      class="px-1 py-50"
    >
      <BButton
        variant="secondary"
        size="sm"
        @click="onCloseModal"
      >
        {{ $t('close') }}
      </BButton>

      <HStack
        center
        class="gap-3"
      >
        <BFormCheckbox v-model="isHidePrice">
          {{ $t('reservation.hidePrice') }}
        </BFormCheckbox>
        <BButton
          variant="success"
          pill
          size="sm"
          class="px-3"
          @click="onClickTakePhoto"
        >
          <HStack>
            <IAmIcon
              icon="camera"
              size="13"
            />
            {{ $t('reservation.saveImage') }}
          </HStack>
        </BButton>
      </HStack>
    </HStack>
  </b-modal>
</template>
<script>
import { ref } from '@vue/composition-api'
import { BButton, BModal, BFormCheckbox } from 'bootstrap-vue'
import html2canvas from 'html2canvas-pro'

import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'

export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
    ItineraryCard: () => import('./ItineraryCard.vue'),
  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const isHidePrice = ref(false)
    function onCloseModal() {
      this.$bvModal.hide('modal-take-photo-itineraries')
    }

    async function exportItinerary(type = 'img') {
      const IMAGE_SCALE = 2
      root.$bvModal.show('modal-api-loading')
      store.dispatch('app/setTextLoading', 'Bắt đầu chụp ảnh hành trình...')
      // eslint-disable-next-line no-restricted-syntax
      console.time('exportItinerary')

      const exportElement = document.getElementById('take-photo-itineraries')
      exportElement.style.maxWidth = '1200px'

      await html2canvas(exportElement, {
        scrollX: 0,
        scrollY: -window.scrollY,
        useCORS: true,
        scale: IMAGE_SCALE,
      })
        .then(canvas => {
          if (type === 'img') {
            const image = canvas.toDataURL('image/png', {
              allowTaint: false,
              useCORS: true,
              quality: 1,
            })
            const link = document.createElement('a')
            link.href = image
            const fileName = `HanhTrinh_${props.selectedTrips.map(itinerary => `${itinerary.departure.IATACode}${itinerary.arrival.IATACode}${convertISODateTime(itinerary.departure.at, itinerary.departure.timezone || itinerary.departure.timeZone).date.replaceAll('/', '')}`).join('_')}.png`
            link.download = fileName
            store.dispatch('app/setTextLoading', `Tải xuống ảnh ${fileName}...`)
            link.click()
          }
        })
        .finally(() => {
          exportElement.style.maxWidth = 'unset'
        })

      root.$bvModal.hide('modal-api-loading')
      store.dispatch('app/setTextLoading', null)

      // eslint-disable-next-line no-restricted-syntax
      console.timeEnd('exportItinerary')
    }

    function onClickTakePhoto() {
      exportItinerary()
    }
    return {
      onCloseModal,
      onClickTakePhoto,
      isHidePrice,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  opacity: 0.87 !important;
}

</style>
