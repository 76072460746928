var render = function () {
  var _vm$tourData, _vm$tourData$image, _vm$tourData2, _vm$tourData2$block, _vm$tourData2$block$i, _vm$tourData3, _vm$tourData4, _vm$tourData5, _vm$tourData6, _vm$tourData7, _vm$tourData8, _vm$tourData9, _vm$tourData10, _vm$tourData11, _vm$tourData12, _vm$tourData13, _vm$tourData14, _vm$tourData15, _vm$tourData16, _vm$tourData17, _vm$tourData18, _vm$tourData19, _vm$tourData20, _vm$tourData21, _vm$tourData22, _vm$tourData23, _vm$tourData24, _vm$tourData25, _vm$tourData$adultsAg, _vm$tourData26;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "border-hover-success mb-0",
    staticStyle: {
      "border-radius": "16px",
      "box-shadow": "4px 4px 8px -1px #18274B14"
    },
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('BRow', {
    class: "px-1 py-50 ".concat(_vm.isMobileView ? 'gap-2' : '')
  }, [_c('BCol', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('BImgLazy', {
    attrs: {
      "src": "".concat(((_vm$tourData = _vm.tourData) === null || _vm$tourData === void 0 ? void 0 : (_vm$tourData$image = _vm$tourData.image) === null || _vm$tourData$image === void 0 ? void 0 : _vm$tourData$image.path) || ((_vm$tourData2 = _vm.tourData) === null || _vm$tourData2 === void 0 ? void 0 : (_vm$tourData2$block = _vm$tourData2.block) === null || _vm$tourData2$block === void 0 ? void 0 : (_vm$tourData2$block$i = _vm$tourData2$block.image) === null || _vm$tourData2$block$i === void 0 ? void 0 : _vm$tourData2$block$i.path) || _vm.backgroundTourUrl),
      "rounded": "",
      "fluid-grow": ""
    }
  })], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('Stack', [_c('div', {
    class: "fw-700 fit ".concat(_vm.isMobileView ? 'text-heading-5' : 'text-heading-3', " text-truncate")
  }, [_vm._v(" " + _vm._s((_vm$tourData3 = _vm.tourData) === null || _vm$tourData3 === void 0 ? void 0 : _vm$tourData3.tourName) + " ")]), _c('div', {
    class: "d-flex-between ".concat(_vm.isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row')
  }, [(_vm$tourData4 = _vm.tourData) !== null && _vm$tourData4 !== void 0 && _vm$tourData4.tourCode ? _c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "ticketLine",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Mã chương trình: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s((_vm$tourData5 = _vm.tourData) === null || _vm$tourData5 === void 0 ? void 0 : _vm$tourData5.tourCode))])], 1) : _vm._e(), (_vm$tourData6 = _vm.tourData) !== null && _vm$tourData6 !== void 0 && _vm$tourData6.tourCode ? _c('HStack', {
    staticClass: "gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "locationOutline",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Khởi hành: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s(_vm.defaultDepartPoint))])], 1) : _vm._e()], 1), (_vm$tourData7 = _vm.tourData) !== null && _vm$tourData7 !== void 0 && _vm$tourData7.levelNam ? _c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "hotelLine",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Khách sạn: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s((_vm$tourData8 = _vm.tourData) === null || _vm$tourData8 === void 0 ? void 0 : _vm$tourData8.levelNam))])], 1) : _vm._e(), _c('div', {
    class: "d-flex-between ".concat(_vm.isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row')
  }, [_c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "clockOutline",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Thời gian: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s((_vm$tourData9 = _vm.tourData) === null || _vm$tourData9 === void 0 ? void 0 : _vm$tourData9.totalDay) + "N" + _vm._s((_vm$tourData10 = _vm.tourData) === null || _vm$tourData10 === void 0 ? void 0 : _vm$tourData10.totalNight) + "D")])], 1), _c('HStack', {
    staticClass: "gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "plane",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Phương tiện: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s(_vm.$t("tour.vehiclesType.".concat((_vm$tourData11 = _vm.tourData) === null || _vm$tourData11 === void 0 ? void 0 : _vm$tourData11.vehiclesGo))) + " "), ((_vm$tourData12 = _vm.tourData) === null || _vm$tourData12 === void 0 ? void 0 : _vm$tourData12.vehiclesBack) !== ((_vm$tourData13 = _vm.tourData) === null || _vm$tourData13 === void 0 ? void 0 : _vm$tourData13.vehiclesGo) ? _c('span', [_vm._v(" - " + _vm._s(_vm.$t("tour.vehiclesType.".concat((_vm$tourData14 = _vm.tourData) === null || _vm$tourData14 === void 0 ? void 0 : _vm$tourData14.vehiclesBack))))]) : _vm._e()])], 1)], 1), _c('div', {
    class: "d-flex-between ".concat(_vm.isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row')
  }, [_c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "softSeatOutline",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Số chỗ: "), _c('span', {
    class: "".concat((_vm$tourData15 = _vm.tourData) !== null && _vm$tourData15 !== void 0 && _vm$tourData15.numberTicketsAvailable ? 'text-info' : 'text-danger', " fw-700")
  }, [_vm._v(_vm._s(((_vm$tourData16 = _vm.tourData) === null || _vm$tourData16 === void 0 ? void 0 : _vm$tourData16.numberTicketsAvailable) || 'Hết chỗ'))])], 1), (_vm$tourData17 = _vm.tourData) !== null && _vm$tourData17 !== void 0 && _vm$tourData17.deadlineVisa ? _c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "clockOutline",
      "size": "20",
      "color": "green"
    }
  }), _vm._v(" Hạn nộp hồ sơ: "), _c('span', {
    class: "text-info fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-16px')
  }, [_vm._v(_vm._s((_vm$tourData18 = _vm.tourData) === null || _vm$tourData18 === void 0 ? void 0 : _vm$tourData18.deadlineVisa))])], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "top-border"
  }), _c('Stack', {
    staticClass: "fit-content",
    attrs: {
      "gap": "1"
    }
  }, _vm._l((_vm$tourData19 = _vm.tourData) === null || _vm$tourData19 === void 0 ? void 0 : _vm$tourData19.itineraries, function (itinerary, itineraryIndex) {
    return _c('div', {
      key: itineraryIndex
    }, _vm._l(itinerary, function (segment, segmentIndex) {
      return _c('TourShortenFlightTrip', {
        key: segmentIndex,
        attrs: {
          "segment": segment
        }
      });
    }), 1);
  }), 0), _c('div', {
    staticClass: "d-flex align-items-end justify-content-between"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "d-flex gap-2"
  }, [_vm._v(" Giá chỉ từ "), ((_vm$tourData20 = _vm.tourData) === null || _vm$tourData20 === void 0 ? void 0 : _vm$tourData20.adultsPriceSales) < ((_vm$tourData21 = _vm.tourData) === null || _vm$tourData21 === void 0 ? void 0 : _vm$tourData21.adultsPrice) ? _c('div', [_c('del', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$tourData22 = _vm.tourData) === null || _vm$tourData22 === void 0 ? void 0 : _vm$tourData22.adultsPrice)) + " VND")])]) : _vm._e()]), _c('div', {
    class: "text-danger fw-900 ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px')
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourData23 = _vm.tourData) === null || _vm$tourData23 === void 0 ? void 0 : _vm$tourData23.adultsPriceSales)) + " VND ")])]), _c('div', {
    staticClass: "flex-1 d-flex align-items-end justify-content-between flex-row-reverse"
  }, [_c('BButton', {
    class: "fw-700 ".concat(_vm.isMobileView ? 'text-12px px-50' : 'text-16px'),
    attrs: {
      "variant": "success",
      "to": {
        name: 'apps-toursDetail',
        params: {
          id: (_vm$tourData24 = _vm.tourData) === null || _vm$tourData24 === void 0 ? void 0 : _vm$tourData24.priceId
        }
      }
    }
  }, [_vm._v(" Xem chi tiết ")]), _vm.isShowCommission ? _c('div', [_c('div', [_vm._v(" Hoa hồng ")]), _c('div', {
    staticClass: "text-success fw-700"
  }, [_vm._v(" " + _vm._s((_vm$tourData25 = _vm.tourData) !== null && _vm$tourData25 !== void 0 && _vm$tourData25.adultsAgencyPromotion ? "".concat(_vm.formatCurrency((_vm$tourData$adultsAg = (_vm$tourData26 = _vm.tourData) === null || _vm$tourData26 === void 0 ? void 0 : _vm$tourData26.adultsAgencyPromotion) !== null && _vm$tourData$adultsAg !== void 0 ? _vm$tourData$adultsAg : 0), " VND") : 'Liên hệ') + " ")])]) : _vm._e()], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }