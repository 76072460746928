import service from './service'

export default {
  createPromotionUser(body) {
    return service.post('/promotion_users', body)
  },

  fetchPromotionUser(params) {
    return service.get('/promotion_users', params)
  },

  updatePromotionUser({ id, payload }) {
    return service.patch(`/promotion_users/${id}`, payload)
  },

  bulkUpdatePromotionUser(payload) {
    return service.patch('/promotion_users', payload)
  },

  activePromotionUser(id) {
    return service.patch(`/promotion_users/${id}/active`)
  },

  deactivePromotionUser(id) {
    return service.patch(`/promotion_users/${id}/deactive`)
  },
}
