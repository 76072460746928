var render = function () {
  var _vm$companyId;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "id": "invoice-list",
      "body-class": "p-1"
    }
  }, [_vm.isLoadingCompanyId && !_vm.companyId ? _c('BAlert', {
    staticClass: "py-1 px-2",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.msg.companyNotFoundOrDeactivated')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "receipt",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('Incorrect Invoices List')) + " ")])], 1), _c('IncorrectInvoiceFilter', {
    attrs: {
      "sort-by": _vm.sortBy,
      "is-sort-dir-desc": _vm.isSortDirDesc,
      "start-date": _vm.startDate,
      "end-date": _vm.endDate,
      "notificationType": _vm.notificationType,
      "notificationStatus": _vm.notificationStatus,
      "statusOfTaxAuthority": _vm.statusOfTaxAuthority
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:isSortDirDesc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:is-sort-dir-desc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:startDate": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:start-date": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:endDate": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:end-date": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:notificationType": function updateNotificationType($event) {
        _vm.notificationType = $event;
      },
      "update:notification-type": function updateNotificationType($event) {
        _vm.notificationType = $event;
      },
      "update:notificationStatus": function updateNotificationStatus($event) {
        _vm.notificationStatus = $event;
      },
      "update:notification-status": function updateNotificationStatus($event) {
        _vm.notificationStatus = $event;
      },
      "update:statusOfTaxAuthority": function updateStatusOfTaxAuthority($event) {
        _vm.statusOfTaxAuthority = $event;
      },
      "update:status-of-tax-authority": function updateStatusOfTaxAuthority($event) {
        _vm.statusOfTaxAuthority = $event;
      },
      "refetch-data": _vm.refetchData,
      "clear-filters": function clearFilters($event) {
        _vm.clearFilter();
        _vm.refetchData();
      }
    }
  }), _c('HStack', {
    staticClass: "w-100 gap-2 mb-50",
    attrs: {
      "end": ""
    }
  }, [_c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.onExportHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "download",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.export')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm",
      "to": {
        name: 'apps-incorrectInvoices-create'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "increase",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Incorrect Invoice Create')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "warning",
      "size": "sm",
      "disabled": !_vm.companyId
    },
    on: {
      "click": _vm.onReleaseHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "splitPassenger",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.releaseInvoice')) + " ")], 1)])], 1), _c('b-table', {
    ref: "refIncorrectInvoiceListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchIncorrectInvoices,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "busy": _vm.loadingList,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "fields": _vm.tableColumnIncorrectInvoiceList,
      "head-row-variant": "primary"
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "row-contextmenu": _vm.handleContextMenu
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnIncorrectInvoiceList, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [column.key === 'checkbox' ? _c('div', [_c('b-form-checkbox', {
            attrs: {
              "name": "id-all-check-box",
              "checked": _vm.isAll
            },
            on: {
              "change": _vm.selectAll
            }
          })], 1) : _c('div', [_vm._v(_vm._s(_vm.$t("invoice.incorrectInvoices.columns.".concat(data.column))))])])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "name": "item-check-box",
            "checked": _vm.isChecked(row.item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(row.item);
            }
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(notificationNumber)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [item.notificationNumber ? _c('b-link', {
          staticClass: "nav-link text-primary hover fw-700",
          attrs: {
            "to": {
              name: 'apps-incorrectInvoices-detail',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.notificationNumber))])]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])], 1)];
      }
    }, {
      key: "cell(notificationType)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$te("invoice.incorrectInvoices.notificationType.".concat(item.notificationType)) ? _vm.$t("invoice.incorrectInvoices.notificationType.".concat(item.notificationType)) : item.notificationType) + " ")];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('BBadge', {
          staticClass: "px-75 fit-content",
          attrs: {
            "variant": _vm.resolveNotificationStatusVariant(item === null || item === void 0 ? void 0 : item.status),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationStatus.".concat(item === null || item === void 0 ? void 0 : item.status))) + " ")])];
      }
    }, {
      key: "cell(statusOfTaxAuthority)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('BBadge', {
          staticClass: "px-75 fit-content",
          attrs: {
            "variant": _vm.resolveNotificationStatusOfTaxAuthorityVariant(item === null || item === void 0 ? void 0 : item.statusOfTaxAuthority),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.statusOfTaxAuthority.".concat(item === null || item === void 0 ? void 0 : item.statusOfTaxAuthority))) + " ")])];
      }
    }, {
      key: "cell(taxReviewNoticeNumber)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.taxReviewNoticeNumber ? _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.taxReviewNoticeNumber) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(dateOfTaxReviewNotice)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.dateOfTaxReviewNotice ? _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.dateOfTaxReviewNotice) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref8) {
        var item = _ref8.item,
          index = _ref8.index;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.btn.detail'),
            expression: "$t('invoice.btn.detail')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "editOutline",
            "size": "18",
            "color": "black"
          }
        })], 1), _c('BButton', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "dotsHorizontal",
            "size": "18",
            "color": "black"
          }
        })], 1)], 1)];
      }
    }], null, true)
  }), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "1"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "order": "3",
      "order-md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalIncorrectInvoices,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center justify-content-end",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "2",
      "order-md": "3"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])])], 1)], 1), _c('vue-context', {
    ref: "MenuRefForInvoiceList",
    attrs: {
      "lazy": ""
    }
  }, [_c('li', _vm._l(_vm.menuList, function (menu, menuIndex) {
    return _c('a', {
      key: menuIndex,
      staticClass: "d-flex gap-1",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return menu.onClick(_vm.itemContext);
        }
      }
    }, [menu.icon ? _c('IAmIcon', {
      attrs: {
        "icon": menu.icon,
        "size": "18"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(menu.name) + " ")], 1);
  }), 0)]), _c('InvoiceCancelModal', {
    attrs: {
      "invoice-data": _vm.invoiceSelected
    },
    on: {
      "refetch": _vm.refetchData
    }
  }), _c('InvoiceSignModal', {
    attrs: {
      "invoice-data": _vm.invoiceSelected,
      "companyId": (_vm$companyId = _vm.companyId) !== null && _vm$companyId !== void 0 ? _vm$companyId : null,
      "isBatch": _vm.isReleaseBatch,
      "checkedItems": _vm.checkedItems
    },
    on: {
      "update:isBatch": function updateIsBatch($event) {
        _vm.isReleaseBatch = $event;
      },
      "update:is-batch": function updateIsBatch($event) {
        _vm.isReleaseBatch = $event;
      },
      "refetch": _vm.refetchData
    }
  }), _c('ModalCancelBatchInvoices', {
    attrs: {
      "checkedItems": _vm.checkedItems
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }