<template>
  <v-select
    :id="label"
    v-model="inputData"
    multiple
    style="font-size: 1rem;"
    label="agencyCode"
    class="v-select-wrap"
    :close-on-select="false"
    :reduce="val => ({agencyName: val.agencyName, agencyCode: val.agencyCode})"
    :filterable="false"
    :loading="apiLoading"
    :getOptionKey="option => option.agencyCode"
    :placeholder="placeholder"
    :options="agenciesManagerList"
    :disabled="disabled"
    @open="openAgenciesManager"
    @search="searchAgenciesManager"
    @search:blur="handleUpdateSelect"
  >
    <template #option="{ agencyName, agencyCode }">
      <div class="d-flex-center justify-content-between">
        <span class="d-block text-nowrap">
          {{ agencyName }}
        </span>
        <span class="d-block text-nowrap">
          {{ agencyCode }}
        </span>
      </div>
    </template>
    <template #spinner="{ loading }">
      <div
        v-if="loading"
        style="border-left-color: rgba(88, 151, 251, 0.71)"
        class="vs__spinner"
      />
    </template>
    <template #no-options>
      {{ $t('noOptions') }}
    </template>
  </v-select>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { debounce } from 'lodash-es'

import { getUserData } from '@/api/auth/utils'
import { apiAgencies } from '@/api'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Array, null],
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputData = ref([])
    const apiLoading = ref(false)

    // fetch Agencies
    const agenciesManagerList = ref([])
    const agenciesManagerListDefault = ref([])

    const fetchAgenciesManagerByFilter = async (search = '') => {
      apiLoading.value = true
      agenciesManagerList.value = []
      try {
        const { data } = await apiAgencies.fetchAgencies({
          isActive: true,
          searchText: search,
        })
        if (data && !search) {
          agenciesManagerListDefault.value = data.items
        }
        const agency = getUserData().employeeData.agency
        agenciesManagerList.value = data.items.filter(item => item?.agencyCode !== agency?.agencyCode)
      } catch (error) {
        console.error(error)
      } finally {
        apiLoading.value = false
      }
    }

    const openAgenciesManager = () => {
      if (agenciesManagerListDefault.value.length === 0) {
        fetchAgenciesManagerByFilter()
      } else {
        agenciesManagerList.value = agenciesManagerListDefault.value
      }
    }

    const searchAgenciesManager = debounce(search => {
      if (search) fetchAgenciesManagerByFilter(search)
    }, 300)

    // watch(() => props.value, val => {
    //   inputData.value = cloneDeep(val)
    // }, { deep: true, immediate: true })

    function handleUpdateSelect() {
      emit('updateValue', inputData.value.map(it => it?.agencyCode || it))
    }

    onMounted(async () => {
      if (props.value.length) {
        inputData.value = props.value.map(it => ({ agencyName: '', agencyCode: it }))
      }
    })

    return {
      apiLoading,
      inputData,
      agenciesManagerList,
      searchAgenciesManager,
      handleUpdateSelect,
      openAgenciesManager,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
  .vs__dropdown-menu {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  }
}
</style>
