var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "rounded-8px mb-0 h-100"
  }, [_c('div', {
    class: "d-flex-center text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700")
  }, [_vm._v(" Thông tin liên lạc ")]), _vm.isRoleF1 ? _c('BRow', [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t("tour.field.chooseAgency"),
      "vid": "chooseAgency",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('BFormGroup', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "chooseAgency"
          }
        }, [_vm._v(_vm._s(_vm.$t("tour.field.chooseAgency")))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])]), _c('v-select', {
          attrs: {
            "id": "chooseAgency",
            "options": _vm.agencyOptions,
            "searchable": "",
            "label": "agencyCode",
            "clearable": "",
            "filterable": false,
            "placeholder": _vm.$t('tour.msg.agencyPlaceholder'),
            "reduce": function reduce(val) {
              return val;
            },
            "loading": _vm.agencyLoading
          },
          on: {
            "open": _vm.handleOpenAgency,
            "search": _vm.handleSearchAgency,
            "input": _vm.onInputAgencyHandle
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(option) {
              return [_c('HStack', [_c('span', {
                staticClass: "fw-700 text-uppercase"
              }, [_vm._v(_vm._s(option.agencyCode))]), _c('small', {
                staticClass: "d-block font-weight-bold text-truncate"
              }, [_vm._v(" (" + _vm._s(option.agencyName) + ") ")])])];
            }
          }, {
            key: "option",
            fn: function fn(option) {
              return [_c('HStack', [_c('span', {
                staticClass: "fw-700 text-uppercase"
              }, [_vm._v(_vm._s(option.agencyCode))]), _c('span', {
                staticClass: "d-block font-weight-bold text-truncate"
              }, [_vm._v(" (" + _vm._s(option.agencyName) + ") ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(_ref2) {
              var loading = _ref2.loading;
              return [loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.agencySelected,
            callback: function callback($$v) {
              _vm.agencySelected = $$v;
            },
            expression: "agencySelected"
          }
        }), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "d-block text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 3020023507)
  })], 1)], 1) : _vm._e(), _c('BRow', [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t("tour.field.contactName"),
      "vid": "contactName",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('BFormGroup', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "contactName"
          }
        }, [_vm._v(_vm._s(_vm.$t("tour.field.contactName")))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])]), _c('vue-autosuggest', {
          staticClass: "placeholder-text",
          attrs: {
            "id": "contactName",
            "value": _vm.contactName,
            "suggestions": _vm.customerSuggestions,
            "input-props": {
              id: 'contactName-input',
              placeholder: _vm.$t('tour.ph.contactName'),
              name: _vm.$t('tour.field.contactName'),
              state: "".concat(errors.length > 0 ? false : null),
              class: "form-control text-uppercase ".concat(errors.length > 0 ? 'is-invalid' : null)
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'contactName');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('contactName', val === null || val === void 0 ? void 0 : val.toUpperCase());
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var suggestion = _ref4.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('Stack', [_c('strong', {
                staticClass: "text-uppercase"
              }, [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))]), _c('div', [_vm._v(_vm._s(suggestion.item.phoneNumber))]), _c('div', [_vm._v(_vm._s(suggestion.item.emailAddress))])])], 1)];
            }
          }], null, true)
        }), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "d-block text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t("tour.field.contactPhone"),
      "vid": "contactPhone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('BFormGroup', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "contactPhone"
          }
        }, [_vm._v(_vm._s(_vm.$t("tour.field.contactPhone")))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])]), _c('vue-autosuggest', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "id": "contactPhone",
            "value": _vm.contactPhone,
            "suggestions": _vm.customerSuggestions,
            "input-props": {
              id: 'contactPhone-input',
              placeholder: _vm.$t('tour.ph.contactPhone'),
              name: _vm.$t('tour.field.contactPhone'),
              state: "".concat(errors.length > 0 ? false : null),
              class: "form-control ".concat(errors.length > 0 ? 'is-invalid' : null)
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'contactPhone');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('contactPhone', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var suggestion = _ref6.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('Stack', [_c('strong', {
                staticClass: "text-uppercase"
              }, [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))]), _c('div', [_vm._v(_vm._s(suggestion.item.phoneNumber))]), _c('div', [_vm._v(_vm._s(suggestion.item.emailAddress))])])], 1)];
            }
          }], null, true)
        }), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "d-block text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t("tour.field.email"),
      "vid": "email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('BFormGroup', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "email"
          }
        }, [_vm._v(_vm._s(_vm.$t("tour.field.email")))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])]), _c('vue-autosuggest', {
          attrs: {
            "id": "email",
            "value": _vm.email,
            "suggestions": _vm.customerSuggestions,
            "input-props": {
              id: 'email-input',
              placeholder: _vm.$t('tour.ph.email'),
              name: _vm.$t('tour.field.email'),
              state: "".concat(errors.length > 0 ? false : null),
              class: "form-control ".concat(errors.length > 0 ? 'is-invalid' : null)
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion, 'email');
            }
          },
          on: {
            "input": function input(val) {
              return _vm.inputHandle('email', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var suggestion = _ref8.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('Stack', [_c('strong', {
                staticClass: "text-uppercase"
              }, [_vm._v(_vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName))]), _c('div', [_vm._v(_vm._s(suggestion.item.phoneNumber))]), _c('div', [_vm._v(_vm._s(suggestion.item.emailAddress))])])], 1)];
            }
          }], null, true)
        }), errors[0] ? _c('BFormInvalidFeedback', {
          staticClass: "d-block text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t("tour.field.address"),
      "vid": "address",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('BFormGroup', [_c('div', {
          staticClass: "d-flex gap-1 font-weight-bolder"
        }, [_c('label', {
          attrs: {
            "for": "address"
          }
        }, [_vm._v(_vm._s(_vm.$t("tour.field.address")))])]), _c('BFormInput', {
          attrs: {
            "id": "address",
            "value": _vm.address,
            "state": errors.length > 0 ? false : null,
            "name": _vm.$t("tour.field.address"),
            "placeholder": _vm.$t("tour.ph.address"),
            "debounce": "200"
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:address', val);
            }
          }
        }), _c('BFormInvalidFeedback', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  })], 1)], 1), _vm.selectedCustomer ? _c('HStack', {
    staticClass: "align-items-center"
  }, [_c('div', [_vm._v("Khách hàng đã chọn:")]), _c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.selectedCustomer.contactName) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      value: _vm.$t('flight.removeCustomerSelected'),
      expression: "$t('flight.removeCustomerSelected')",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.onRemoveSelectedCustomer
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "closeOutline",
      "size": "18"
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }