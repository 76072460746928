import axios from 'axios'

import service from './service'

const VUE_APP_API_TOOL_SIGN_INVOICE = process.env.VUE_APP_API_TOOL_SIGN_INVOICE

export default {
  searchInvoice(payload = {}) {
    return service.post('/invoices/search', payload)
  },

  createInvoice(payload = {}) {
    return service.post('/invoices', payload)
  },

  getCompanyByTaxCode(code) {
    return service.get(`/company/tax-code/${code}`)
  },

  getInvoiceById(id) {
    return service.post(`/invoices/${id}/retrieve`)
  },

  getInvoicePdfById(id) {
    return service.pdf(`/invoices/${id}/pdf`)
  },

  getTransmissionHistory(id) {
    return service.get(`/invoices/${id}/get-transmission-history`)
  },

  updateInvoice(id, payload = {}) {
    return service.put(`/invoices/${id}`, payload)
  },

  updateAdditionalAmountStatus(id, payload = {}) {
    return service.patch(`/invoices/${id}/update-additional-amount-status`, payload)
  },

  getInvoiceTemplate(companyId) {
    return service.get(`/company/${companyId}/invoice-template`)
  },

  getTags(text) {
    return service.get('/invoices/tags', { text })
  },

  // ký
  signInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/sign`, payload)
  },
  // signAndReleaseInvoice(invoiceId, payload = {}) {
  //   return service.post(`/invoices/${invoiceId}/sign-and-release`, payload)
  // },

  getCompanyCertificates(companyId, params) {
    return service.get(`/company/${companyId}/certificates`, params)
  },

  generateTokenForRedirectPortal(companyId) {
    return service.post(`/company/${companyId}/generate-token`)
  },

  // gửi cơ quan thuế
  releaseInvoice(invoiceId) {
    return service.post(`/invoices/${invoiceId}/send-to-tax-authority`)
  },

  // gửi email
  sendMailInvoice(invoiceId) {
    return service.post(`/invoices/${invoiceId}/send-mail`)
  },

  // replace
  replaceInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/replace-invoice`, payload)
  },

  // adjust
  adjustInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/adjust-invoice`, payload)
  },

  // duplicate
  duplicateInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/duplicate-invoice`, payload)
  },

  // cancel
  cancelInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/cancel-invoice`, payload)
  },
  cancelBatchInvoice(payload = {}) {
    return service.post('/invoices/cancel-batch', payload)
  },

  exportInvoices(payload = {}, fileName) {
    return service.excel('/invoices/export', payload, fileName)
  },

  getXmlInvoice(invoiceId) {
    return service.get(`/invoices/${invoiceId}/xml`)
  },

  signInvoiceByToken(invoiceId, payload = {}) {
    return service.put(`/invoices/${invoiceId}/signed-data`, payload)
  },

  signCompleteInvoiceByToken(invoiceId, payload = {}) {
    return service.put(`/invoices/${invoiceId}/sign-complete`, payload)
  },

  releaseBatch(payload) {
    return service.post('/invoices/release-batch', payload)
  },

  getCountProcessingReleaseBatch() {
    return service.get('/invoices/release-batch/count-processing')
  },

  getCertsByToken() {
    return axios.get(`${VUE_APP_API_TOOL_SIGN_INVOICE}/certs`)
  },

  signInvoiceLocal(payload) {
    return axios.post(`${VUE_APP_API_TOOL_SIGN_INVOICE}/sign`, payload)
  },
  // ký lô usb
  signBatchInvoiceLocal(payload) {
    return axios.post(`${VUE_APP_API_TOOL_SIGN_INVOICE}/sign-batch`, payload)
  },

  getXmlInvoicesInBatch(batchId) {
    return service.get(`/invoices/release-batch/get-doc-xml/${batchId}`)
  },

  signCompleteBatch(payload) {
    return service.put('/invoices/release-batch/sign-complete', payload)
  },

  // INVOICE TICKET: VE NGOAI HE THONG
  createInvoiceTicket(payload = {}) {
    return service.post('/invoice-tickets', payload)
  },

  getInvoiceTickets(payload = {}) {
    return service.get('/invoice-tickets', payload)
  },

  bulkImportInvoiceTickets(payload = {}) {
    return service.post('/invoice-tickets/bulk-insert', payload)
  },

  getInvoiceTicketById(invoiceTicketId) {
    return service.get(`/invoice-tickets/${invoiceTicketId}`)
  },

  updateInvoiceTicket(invoiceTicketId, payload) {
    return service.patch(`/invoice-tickets/${invoiceTicketId}`, payload)
  },

  deleteInvoiceTicket(invoiceTicketId) {
    return service.delete(`/invoice-tickets/${invoiceTicketId}`)
  },

  exportTemplateInvoiceTickets(payload, fileName = 'MauNhapVeNgoai') {
    return service.excel('/invoice-tickets/export-template', payload, fileName)
  },

  // ANCHOR Incorrect Invoice
  searchIncorrectInvoices(payload = {}) {
    return service.post('/invoices_incorrect/search', payload)
  },
  createIncorrectInvoice(payload = {}) {
    return service.post('/invoices_incorrect', payload)
  },
  getIncorrectInvoiceById(incorrectInvoiceId) {
    return service.get(`/invoices_incorrect/${incorrectInvoiceId}`)
  },
}
