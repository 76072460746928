export default {
  paginationText: {
    showing: 'Hiển thị',
    to: 'đến',
    from: 'từ',
    outOf: 'trong tổng',
    items: 'mục',
    total: 'Tổng',
  },

  Sunday: 'Chủ Nhật',
  Monday: 'Thứ 2',
  Tuesday: 'Thứ 3',
  Wednesday: 'Thứ 4',
  Thursday: 'Thứ 5',
  Friday: 'Thứ 6',
  Saturday: 'Thứ 7',

  shortSunday: 'CN',
  shortMonday: 'T2',
  shortTuesday: 'T3',
  shortWednesday: 'T4',
  shortThursday: 'T5',
  shortFriday: 'T6',
  shortSaturday: 'T7',

  'chủ nhật': 'Chủ Nhật',
  'thứ hai': 'Thứ Hai',
  'thứ ba': 'Thứ Ba',
  'thứ tư': 'Thứ Tư',
  'thứ năm': 'Thứ Năm',
  'thứ sáu': 'Thứ Sáu',
  'thứ bảy': 'Thứ Bảy',
  account: 'Tài khoản',
  contact: 'Liên hệ',
  contacts: 'Liên hệ',
  historyBooking: 'Lịch sử đặt vé',
  filter: 'Bộ lọc',
  search: 'Tìm kiếm',
  refresh: 'Làm mới',
  createdBy: 'Tạo bởi',
  clear: 'Xoá',
  delete: 'Xoá',
  import: 'Nhập từ Excel',
  export: 'Xuất ra Excel',
  back: 'Trở lại',
  next: 'Tiếp tục',
  send: 'Gửi',
  create: 'Tạo',
  edit: 'Chỉnh sửa',
  update: 'Cập nhật',
  adjust: 'Điều chỉnh',
  replace: 'Thay thế',
  duplicate: 'Nhân bản',
  cancelEdit: 'Huỷ chỉnh sửa',
  save: 'Lưu',
  saving: 'Đang lưu...',
  cancel: 'Huỷ',
  done: 'Hoàn thành',
  pendingPayment: 'Chờ thanh toán',
  password: 'Mật khẩu',
  information: 'Thông tin',
  noInfo: 'Không có thông tin',
  accountInfoText: 'Thông tin tài khoản',
  employeeType: 'Loại tài khoản',
  addNew: 'Thêm mới',
  filters: 'Bộ lọc',
  confirmation: 'Xác nhận',
  placeholderSelect: 'Lựa chọn',
  backToHome: 'Trở về trang chủ',
  moreDropdownTitle: '',
  tickets: 'vé',
  reports: 'Thống kê',
  userName: 'Tên đăng nhập',
  email: 'Email',
  firstName: 'Tên đệm & Tên',
  lastName: 'Họ',
  phoneNumber: 'Số điện thoại',
  backupPhoneNumber: 'Số điện thoại dự phòng',
  dob: 'Ngày sinh',
  description: 'Mô tả',
  gender: 'Giới tính',
  placeholderUsername: 'Điền vào tên đăng nhập',
  placeholderEmail: 'Điền vào email',
  placeholderFirstName: 'Điền vào Tên đệm & Tên',
  placeholderLastName: 'Điền vào họ',
  placeholderSelectType: 'Chọn loại tài khoản',
  placeholderPhoneNumber: 'Nhập số điện thoại',
  placeholderDoB: 'Chọn ngày sinh',
  placeholderDescription: 'Nhập mô tả',
  passengerType: 'Loại hành khách',
  placeholderPassengerType: 'Chọn loại hành khách',
  placeholderSelectGender: 'Chọn giới tính',

  address: 'Địa chỉ',
  noResultFund: 'Không tìm thấy kết quả cho',
  needSelect_1: 'Bạn cần chọn',
  needSelect_2: 'trước.',
  country: 'Quốc gia',
  placeholderCountry: 'Chọn quốc gia',
  loadingCountryText: 'Đang tải danh sách quốc gia ...',
  city: 'Tỉnh/Thành phố',
  placeholderCity: 'Chọn Thành phố',
  loadingCityText: 'Đang tải danh sách thành phố...',
  district: 'Quận',
  placeholderDistrict: 'Chọn Quận',
  loadingDistrictText: 'Đang tải danh sách Quận...',
  ward: 'Phường',
  placeholderWard: 'Chọn Phường',
  loadingWardText: 'Đang tải danh sách Phường...',
  street: 'Đường',
  placeholderStreet: 'Nhập vào tên đường',

  tryAgain: 'Thử lại',
  importHeader: 'Nhập từ Excel/CSV',
  importBodyText_1: 'Bạn có thể nhập tệp Excel để thêm',
  importBodyText_2: 'vào hệ thống.',
  customers: 'Khách hàng',
  employees: 'Nhân viên',
  agencies: 'đại lý',
  loadFailText_1: 'Tải tập tin',
  loadFailText_2: 'thất bại, lý do:',
  importSuccess_1: 'Ấn vào',
  importSuccess_2: 'đây',
  importSuccess_3: 'để tải xuống tệp đã nhập gần đây.',
  btnAddFileText: 'Thêm tập tin Excel',
  importLoading: 'Đang nhập...',
  importCompleted: 'Quá trình nhập được hoàn thành.',
  importFailed: 'Nhập dữ liệu không thành công, lý do:',
  dropFileText: 'hoặc thả tệp để tải lên',
  fileLoading_1: 'Đang tải',
  fileLoading_2: 'tập tin',
  downloadSampleText_1: 'Ấn vào',
  downloadSampleText_2: 'đây',
  downloadSampleText_3: 'để tải xuống tệp mẫu.',
  importValidateHeader_1: 'Tải tập tin',
  importValidateHeader_2: 'thành công.',
  uploadAgain: 'Thử lại',
  formTitle_1: 'Tải lên tập tin',
  formTitle_2: 'Xác thực dữ liệu',
  formTitle_3: 'Hoàn thành',
  importDoneText: 'mục đã được nhập thành công',
  importFailText: 'mục nhập thất bại:',
  noRecordFund: 'Không tìm thấy kết quả',

  contactInfoText: 'Thông tin liên hệ',
  placeholderSearch: 'Tên, email...',

  previewMail: 'Xem trước Email',
  createdMsg_1: 'Tài khoản của bạn đã được tạo!',
  createdMsg_2:
    'Tài khoản của bạn đã được tạo. Vui lòng đăng nhập vào tài khoản của bạn bằng những thông tin sau:',
  createdMsg_3: 'Vui lòng không trả lời email này.',
  passwordMuted: '(Được tạo sau khi nhấp gửi)',
  sendTo: 'Gửi đến:',
  logout: 'Đăng xuất',
  pricing: 'Thanh toán',
  inbox: 'Hộp thư',
  profile: 'Hồ sơ',
  task: 'Nhiệm vụ',
  chat: 'Tin nhắn',
  applyDate: 'Ngày xuất vé',
  flightDate: 'Ngày bay',
  'Pre paid': 'Thanh toán trước',
  'Post paid': 'Thanh toán sau',

  upload: 'Tải lên',
  reset: 'Đặt lại',
  maxsizeUpload:
    'Cho phép định dạng JPG, GIF hoặc PNG. Kích thước tối đa 800kB',
  saveChanges: 'Lưu thay đổi',

  MALE: 'Nam',
  FEMALE: 'Nữ',
  OTHER: 'Khác',

  'Bamboo Airways': 'Bamboo Airways',
  'Vietnam Airlines': 'Vietnam Airlines',
  Vietjet: 'Vietjet Air',

  noMatchingResult: 'Không có kết quả nào phù hợp.',
  pageNotFound: 'Không tìm thấy trang',
  notAuthorized: {
    title: 'Bạn không có quyền! 🔐',
    text: 'Bạn không có quyền truy cập vào trang này. Vui lòng quay lại !!',
    backToHomeButton: 'Quay lại Trang chủ',
  },
  oops: 'Oops!',
  notFoundRequest: 'Không tìm thấy URL, vui lòng thử lại.',
  english: 'Tiếng Anh',
  vietnam: 'Tiếng Việt',
  language: 'Ngôn ngữ',
  Error: 'Lỗi',
  'Add Flights': 'Thêm chuyến bay',

  Username: 'Tài khoản',
  Agency: 'Đại lý',
  overPercentage: 'Đại lý cảnh báo hạn mức',
  featureInDevelopment: 'Tính năng đang phát triển!',
  Reservation: 'Reservation',
  Flights: 'Flights',

  accountsPayableTemp: 'Công nợ tạm tính',
  lockBalance: 'Quỹ treo',
  loading: 'Đang tải ...',

  bankAccountsInfo: 'Danh sách tài khoản ngân hàng đại lý',
  reloadAccountsPayable: 'Tải lại công nợ',
  from: 'từ',
  to: 'đến',
  by: 'bởi',
  agree: 'Đồng ý',
  close: 'Đóng',
  using: 'Đang được sử dụng',
  apply: 'Áp dụng',
  ENABLE: 'Mở khoá',
  DISABLE: 'Khoá',

  copy: 'Sao chép',
  bankSellingRate: 'Tỷ giá',
  latestUpdate: 'Cập nhật',
  Unknown: 'Không có thông tin',
  pleaseCheckError: 'Vui lòng kiểm tra mã lỗi',
  loginSessionExpired: 'Hết phiên đăng nhập',
  loginSessionExpiredContent: 'Đã hết phiên đăng nhập, vui lòng đăng nhập lại!!!',
  important: 'Lưu ý',
  noSuitableOptions: 'Không có lựa chọn phù hợp',
  inputMoneyPlaceholder: 'Nhập số tiền...',
  Warning: 'Cảnh báo!',
  Notification: 'Thông báo!',
  show: 'Hiện',
  hide: 'Ẩn',
  errorCopy: 'Copy lỗi',
  copied: 'Đã copy!',
  status: 'Trạng thái',
  imgNotFound: 'Không tìm thấy ảnh',
  page: 'trang',
  submit: 'Xác nhận',
  All: 'Tất cả',
  empty: 'trống',
  default: 'Mặc định',
  action: 'Thao tác',
}
