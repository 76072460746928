import { ref, computed, watch } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import { apiPackageConfig, apiAgencies } from '@/api'

import useToast from '@useToast'

export default function useAgencyPackgageOrder() {
  const loading = ref(false)
  const loadingAgencies = ref(false)
  const loadingPackageConfigs = ref(false)
  const { toastSuccess, toastError } = useToast()
  const agencyCodeFilter = ref()
  const orderCodeFilter = ref()
  const packageConfigIdFilter = ref()
  const orderStatusFilter = ref()
  const paymentMethodFilter = ref()
  const resultArray = ref([])
  const currentPage = ref(1)
  const sizePerPage = ref(20)
  const totalItems = ref(0)
  const sumAmountToBePaid = ref(0)

  const fromItem = computed(() => sizePerPage.value * (currentPage.value - 1) + 1)
  const toItem = computed(() => (sizePerPage.value * (currentPage.value)) >= totalItems.value ? totalItems.value : (sizePerPage.value * (currentPage.value)))

  const tableColumns = ref(['orderCode', 'buyer', 'agencyRetail', 'packageConfig', 'payment', 'action'])

  function clearFilter() {
    agencyCodeFilter.value = undefined
    orderCodeFilter.value = undefined
    packageConfigIdFilter.value = undefined
    orderStatusFilter.value = undefined
    paymentMethodFilter.value = undefined
  }

  function fetchAgencyPackageOrder() {
    loading.value = true
    const params = {
      agencyCode: agencyCodeFilter.value || undefined,
      orderCode: orderCodeFilter.value || undefined,
      packageConfigId: packageConfigIdFilter.value || undefined,
      orderStatus: orderStatusFilter.value || undefined,
      paymentMethod: paymentMethodFilter.value || undefined,
      page: currentPage.value || 1,
      size: sizePerPage.value || 20,
    }
    apiPackageConfig.findAllAgencyPackageOrder(params)
      .then(res => {
        resultArray.value = res.data.items
        totalItems.value = res.data.count
        sumAmountToBePaid.value = res.data.totalAmountToBePaid
      })
      .catch(error => {
        console.error({ error })
        toastError('Lỗi tải dữ liệu')
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function cancelOrder(id) {
    loading.value = true
    try {
      const res = await apiPackageConfig.cancelAgencyPackageOrder(id)
      toastSuccess('Huỷ đơn hàng thành công')
      return res
    } catch (error) {
      console.error({ error })
      toastError('Lỗi huỷ đơn hàng')
      return error
    } finally {
      loading.value = false
    }
  }

  function confirmExport() {
    const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
    this.$bvModal
      .msgBoxConfirm(titleMsg(), {
        title: this.$t('modal.confirm'),
        size: 'sm',
        okVariant: 'info',
        okTitle: this.$t('modal.yes'),
        cancelTitle: this.$t('modal.no'),
        cancelVariant: 'outline-danger',
        hideHeaderClose: true,
        centered: true,
      })
      .then(value => {
        if (value) {
          apiPackageConfig.exportAgencyPackageOrder()
        }
      })
  }

  watch([currentPage, sizePerPage, agencyCodeFilter, orderCodeFilter, packageConfigIdFilter, orderStatusFilter, paymentMethodFilter], () => {
    fetchAgencyPackageOrder()
  })

  const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
    loadingAgencies.value = true
    apiAgencies
      .fetchAgencies({ searchText })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loadingAgencies.value = false
      })
  })

  // ANCHOR fetch Agency
  const agencyOptionsDefault = ref([])
  const agencyOptions = ref([])

  function handleOpenAgency() {
    if (!agencyOptionsDefault.value.length) {
      fetchAgencies().then(res => {
        agencyOptions.value = res
        agencyOptionsDefault.value = res
      })
    } else {
      agencyOptions.value = agencyOptionsDefault.value
    }
  }

  const handleSearchAgency = debounce(search => {
    if (search) {
      fetchAgencies(search).then(res => {
        agencyOptions.value = res
      })
    }
  }, 300)

  // ANCHOR fetch package config
  const packageConfigOptionsDefault = ref([])
  const packageConfigOptions = ref([])

  const fetchPackageConfigs = (searchText = undefined) => new Promise((resolve, reject) => {
    loadingPackageConfigs.value = true
    apiPackageConfig.findAllPackageConfigs({ searchText, page: 1, size: 20 })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loadingPackageConfigs.value = false
      })
  })

  function handleOpenPackageConfig() {
    if (!packageConfigOptionsDefault.value.length) {
      fetchPackageConfigs().then(res => {
        packageConfigOptions.value = res
        packageConfigOptionsDefault.value = res
      })
    } else {
      packageConfigOptions.value = packageConfigOptionsDefault.value
    }
  }

  const handleSearchPackageConfig = debounce(search => {
    if (search) {
      fetchPackageConfigs(search).then(res => {
        packageConfigOptions.value = res
      })
    }
  }, 300)

  const orderStatusOptions = ref(['PENDING', 'DONE', 'CANCELED'])
  const paymentMethodOptions = ref(['CREDIT', 'MONEY'])

  return {
    agencyCodeFilter,
    orderCodeFilter,
    packageConfigIdFilter,
    orderStatusFilter,
    paymentMethodFilter,
    resultArray,
    sumAmountToBePaid,
    currentPage,
    sizePerPage,
    toItem,
    fromItem,
    loading,
    totalItems,
    tableColumns,
    fetchAgencyPackageOrder,
    // changeActive,
    // updateAgencyPackageConfig,
    fetchAgencies,
    handleOpenAgency,
    handleSearchAgency,
    agencyOptions,
    loadingAgencies,

    packageConfigOptions,
    handleOpenPackageConfig,
    handleSearchPackageConfig,
    loadingPackageConfigs,
    orderStatusOptions,
    paymentMethodOptions,
    clearFilter,
    cancelOrder,
    confirmExport,
  }
}
