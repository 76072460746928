<template lang="">
  <AppCollapse
    type="border-info card"
    class="border-info rounded mb-50"
  >
    <AppCollapseItem
      classHeader="card-header bg-light-info"
      title
      @visible="onVisibleHandle"
    >
      <template #header>
        <div class="text-dark">
          {{ $t('errorNoti.history') }}
        </div>
      </template>
      <b-table
        ref="refAuditLogListTable"
        style="max-height: 80vh"
        sticky-header
        responsive
        show-empty
        primary-key="id"
        class="position-relative"
        :items="auditLogs"
        :busy="loading"
        :fields="tableColumns"
        :empty-text="$t('noRecordFund')"
      >
        <!-- COLUMNS HEADER -->
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ column.label !== 'reapply' ? $t(`auditLog.columns.${data.label}`) : '' }}
          </span>
        </template>

        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template #cell(timestamp)="data">
          <span class="text-nowrap">
            {{ convertISODateTime(data.item.timestamp).dateTime }}
          </span>
        </template>

        <template #cell(modifiedBy)="{ item }">
          <div
            v-if="item?.modifiedBy"
            v-b-tooltip.hover.html.v-primary.window
            style="max-width: 250px;"
            :title="`<div class='d-flex flex-column align-items-start gap-2 font-small-4'>
                  <div>Username: <span class='text-uppercase'>${item?.modifiedBy?.username }</span></div>
                  <span>Tên: ${item?.modifiedBy?.lastName} ${item?.modifiedBy?.firstName}</span>
                  <span>Mã user: ${item?.modifiedBy?.employeeCode}</span>
                  </div>`"
          >
            <strong class="mb-25 text-uppercase">
              {{ item?.modifiedBy?.username }}
            </strong>
            <div
              v-if="item?.modifiedBy?.lastName || item?.modifiedBy?.firstName"
              class="text-truncate"
            >
              ({{ item?.modifiedBy?.lastName }} {{ item?.modifiedBy?.firstName }})
            </div>
          </div>
        </template>

        <template #cell(newValue)="data">
          <HStack class="gap-2">
            <Stack>
              <HStack
                v-for="(value, key) in data.item.newValue"
                :key="key"
              >
                <span class="text-nowrap">
                  {{ $te(`notification.${key}`) ? $t(`notification.${key}`) : key }}:
                </span>
                <span class="text-justify font-italic">{{ value }}</span>
              </HStack>
            </Stack>
          </HStack>
        </template>
        <template #cell(reapply)="data">
          <b-button
            v-if="data.item.newValue"
            pill
            variant="success"
            class="p-50 text-nowrap"
            @click="onReApplyHandle(data.item)"
          >
            Áp dụng lại
          </b-button>
        </template>
      </b-table>

      <b-row>
        <!-- PER PAGE -->
        <b-col
          cols="4"
          md="2"
        >
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageOptions"
            :clearable="false"
            append-to-body
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalAuditLogs"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-center"
        >
          <span class="text-muted">
            {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
            {{ dataMeta.of }} {{ $t('paginationText.items') }}
          </span>
        </b-col>
      </b-row>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import {
  BButton, BCol, BPagination, BRow, BSpinner, BTable,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'

import { apiAuditLog } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'
import { parseJsonToObjectOrText } from '@/@core/utils/utils'
import { sizePerPageOptions } from '@/constants/selectOptions'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BTable,
    BSpinner,
    BButton,
    BRow,
    BCol,
    BPagination,
    vSelect: () => import('vue-select'),
  },
  setup(_, { emit }) {
    const auditLogs = ref()
    const loading = ref(false)
    const sizePerPage = ref(10)
    const currentPage = ref(1)
    const totalAuditLogs = ref()
    const tableColumns = [
      {
        label: 'referenceId', key: 'referenceId', thStyle: { width: '100px' },
      },
      {
        label: 'modifiedBy', key: 'modifiedBy', thStyle: { width: '150px' },
      },
      { label: 'newValue', key: 'newValue' },
      { label: 'reapply', key: 'reapply', tdClass: 'd-flex align-items-start' },
      {
        label: 'timestamp', key: 'timestamp', thStyle: { width: '200px' },
      },
    ]

    const refAuditLogListTable = ref()
    const dataMeta = computed(() => {
      const localItemsCount = refAuditLogListTable.value?.localItems?.length ?? 0
      return {
        from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalAuditLogs.value,
      }
    })

    async function fetchAuditLogs() {
      try {
        loading.value = true
        const params = {
          feature: 'NOTIFICATION',
          size: sizePerPage.value,
          page: currentPage.value,
        }
        const res = await apiAuditLog.fetchAuditLogs(params)
        auditLogs.value = res.data.items.map(i => ({
          ...i,
          newValue: parseJsonToObjectOrText(i.newValue),
        }))
        totalAuditLogs.value = res.data.total
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    async function onVisibleHandle(value) {
      if (value && !auditLogs.value?.length) {
        await fetchAuditLogs()
      }
    }

    watch([currentPage, sizePerPage], () => {
      fetchAuditLogs()
    })

    function onReApplyHandle(item) {
      emit('reapply', item.newValue)
    }

    return {
      auditLogs,
      tableColumns,
      convertISODateTime,
      onVisibleHandle,
      loading,
      sizePerPage,
      sizePerPageOptions,
      currentPage,
      dataMeta,
      refAuditLogListTable,
      totalAuditLogs,
      onReApplyHandle,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.maxWidth = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="">

</style>
