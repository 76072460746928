var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-topup-modal".concat(_vm.place),
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.forChild ? _vm.$t('topup.createTopupForChild') : _vm.$t('topup.createTopup')
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.loadedImage && !!_vm.qrCodeImgUrl
    }
  }, [_c('BTabs', [_c('BTab', {
    attrs: {
      "title": _vm.$t('topup.topupInfo')
    }
  }, [_vm.isEmptyBankAccounts ? _c('div', [_c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "text-heading-4"
  }, [_vm._v(_vm._s(_vm.$t('topup.parentAgencyNotHaveBankAccount')))])])], 1) : _c('div', [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "recipient",
      "label": _vm.$t('topup.recipient')
    }
  }, [_c('v-select', {
    attrs: {
      "id": "recipient",
      "value": _vm.recipient,
      "getOptionLabel": function getOptionLabel(val) {
        return _vm.getOptionLabel(val, 'agencyCode');
      },
      "options": [],
      "clearable": false,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex gap-1"
        }, [_c('span', {
          staticClass: "text-uppercase fw-700"
        }, [_vm._v(_vm._s(data.agencyCode))]), data.agencyName ? _c('span', [_vm._v("(" + _vm._s(data.agencyName) + ")")]) : _vm._e()])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex gap-1"
        }, [_c('span', {
          staticClass: "text-uppercase fw-700"
        }, [_vm._v(_vm._s(data.agencyCode))]), data.agencyName ? _c('span', [_vm._v("(" + _vm._s(data.agencyName) + ")")]) : _vm._e()])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('topup.sender'),
      "vid": "sender"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('topup.sender')) + " "), _vm.forChild ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "sender",
            "value": _vm.senderData,
            "getOptionLabel": function getOptionLabel(val) {
              return _vm.getOptionLabel(val, 'agencyCode');
            },
            "name": _vm.$t('topup.sender'),
            "options": _vm.senderOptions,
            "clearable": false,
            "disabled": !_vm.forChild,
            "placeholder": _vm.$t('topup.placeholderSender'),
            "loading": _vm.loadingChild
          },
          on: {
            "open": _vm.getAgencyList,
            "input": function input(val) {
              return _vm.handleSenderInput(val);
            }
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex gap-1"
              }, [_c('span', {
                staticClass: "text-uppercase fw-700"
              }, [_vm._v(_vm._s(data.agencyCode))]), data.agencyName ? _c('span', [_vm._v("(" + _vm._s(data.agencyName) + ")")]) : _vm._e()])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex gap-1"
              }, [_c('span', {
                staticClass: "text-uppercase fw-700"
              }, [_vm._v(_vm._s(data.agencyCode))]), data.agencyName ? _c('span', [_vm._v("(" + _vm._s(data.agencyName) + ")")]) : _vm._e(), data !== null && data !== void 0 && data.status ? _c('span', {
                staticClass: "font-italic"
              }, [_vm._v("- " + _vm._s(_vm.$t('status')) + ": " + _vm._s(_vm.$t(_vm.resolveAgencyStatus(data === null || data === void 0 ? void 0 : data.status, 'value', 'label'))))]) : _vm._e()])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.bankName')
    }
  }, [_c('v-select', {
    attrs: {
      "id": "recipientBank_select",
      "getOptionLabel": function getOptionLabel(val) {
        return _vm.getOptionLabel(val, 'bankAccountNo');
      },
      "options": _vm.recipientBanksList,
      "clearable": false
    },
    on: {
      "input": _vm.handleBankAccountInput
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        var _vm$resolveBankById, _data$bankId, _vm$resolveBankById2, _data$bankId2;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start"
        }, [_c('BImg', {
          attrs: {
            "src": (_vm$resolveBankById = _vm.resolveBankById((_data$bankId = data === null || data === void 0 ? void 0 : data.bankId) !== null && _data$bankId !== void 0 ? _data$bankId : data === null || data === void 0 ? void 0 : data.bankCode)) === null || _vm$resolveBankById === void 0 ? void 0 : _vm$resolveBankById.bankLogo,
            "width": "50"
          }
        }), _c('div', [_vm._v(_vm._s((_vm$resolveBankById2 = _vm.resolveBankById((_data$bankId2 = data === null || data === void 0 ? void 0 : data.bankId) !== null && _data$bankId2 !== void 0 ? _data$bankId2 : data === null || data === void 0 ? void 0 : data.bankCode)) === null || _vm$resolveBankById2 === void 0 ? void 0 : _vm$resolveBankById2.bankName) + " - "), _c('b', [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.bankAccountNo))]), _vm._v(" - " + _vm._s(data === null || data === void 0 ? void 0 : data.bankAccountName))])], 1)];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        var _vm$resolveBankById3, _data$bankId3, _vm$resolveBankById4, _data$bankId4;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start"
        }, [_c('BImg', {
          attrs: {
            "src": (_vm$resolveBankById3 = _vm.resolveBankById((_data$bankId3 = data === null || data === void 0 ? void 0 : data.bankId) !== null && _data$bankId3 !== void 0 ? _data$bankId3 : data === null || data === void 0 ? void 0 : data.bankCode)) === null || _vm$resolveBankById3 === void 0 ? void 0 : _vm$resolveBankById3.bankLogo,
            "width": "50"
          }
        }), _c('div', [_vm._v(_vm._s((_vm$resolveBankById4 = _vm.resolveBankById((_data$bankId4 = data === null || data === void 0 ? void 0 : data.bankId) !== null && _data$bankId4 !== void 0 ? _data$bankId4 : data === null || data === void 0 ? void 0 : data.bankCode)) === null || _vm$resolveBankById4 === void 0 ? void 0 : _vm$resolveBankById4.bankName) + " - "), _c('b', [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.bankAccountNo))]), _vm._v(" - " + _vm._s(data === null || data === void 0 ? void 0 : data.bankAccountName))])], 1)];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.recipientBank,
      callback: function callback($$v) {
        _vm.recipientBank = $$v;
      },
      expression: "recipientBank"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.accNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientAccNumber,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.accHolder')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientBankAccountName,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.branch')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientBankBranch,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required|".concat(_vm.isTopUpF1 ? "min_value:".concat(_vm.MIN_AMOUNT_TO_TOPUP) : ''),
      "name": _vm.$t('agency.amountNumber')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_vm.amountNumber ? _c('b-form-group', {
          attrs: {
            "label": _vm.$t('agency.amountNumber')
          }
        }, [_c('b-form-input', {
          attrs: {
            "value": _vm.formatCurrency(_vm.amountNumber),
            "readonly": ""
          }
        })], 1) : _c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('agency.amountNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }])
        }, [_c('b-input-group', {
          staticClass: "input-group-merge flex-nowrap",
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('b-input-group-text', {
                class: {
                  'border-danger': errors[0]
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.currency')) + " ")])];
            },
            proxy: true
          }])
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "id": "paymentAmount",
            "name": _vm.$t('agency.amountNumber'),
            "value": _vm.paymentAmount,
            "placeholder": _vm.$t('agency.amountNumber'),
            "state": errors[0] ? false : null
          }
        }), _c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "value-money": _vm.paymentAmount,
            "state": errors[0] ? false : null
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              _vm.paymentAmount = $event;
            },
            "update:value-money": function updateValueMoney($event) {
              _vm.paymentAmount = $event;
            }
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.isTopUpF1 ? _c('div', [_c('em', {
          staticClass: "text-body-3"
        }, [_vm._v(_vm._s(_vm.$t('topup.pleaseTopupMinAmount', {
          minAmount: _vm.formatCurrency(_vm.MIN_AMOUNT_TO_TOPUP)
        })))])]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c('b-row', [_c('div', {
    staticClass: "d-flex-center w-100"
  }, [_vm.qrCodeImgUrl ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('topup.createQrSuccess')) + " ")]) : _vm._e()], 1), _vm.qrCodeImgUrl ? _c('b-img', {
    staticStyle: {
      "max-height": "400px"
    },
    attrs: {
      "src": _vm.qrCodeImgUrl,
      "fluid": "",
      "center": "",
      "alt": "QR Code"
    },
    on: {
      "load": _vm.handleLoad
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "info",
      "disabled": !!_vm.qrCodeImgUrl || _vm.isEmptyBankAccounts
    },
    on: {
      "click": _vm.handleGenerateQRcode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.generateQRcode')) + " ")])], 1)], 1)], 1)]), _vm.topupRecords ? _c('BTab', {
    attrs: {
      "title": _vm.$t('topup.history')
    }
  }, [!_vm.topupRecords.length ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('topup.noHistoryRecord')) + " ")]) : _c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "items": _vm.topupRecords,
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.topupRecords.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(createdAt)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")])];
      }
    }, {
      key: "cell(confirmedBy)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.confirmedBy && item.confirmedBy.username ? _c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.confirmedBy.username.toUpperCase()) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(balance)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.balance)) + " ")])];
      }
    }, {
      key: "cell(paymentAmount)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.paymentAmount)) + " ")])];
      }
    }, {
      key: "cell(note)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.note) + " ")])];
      }
    }], null, true)
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }