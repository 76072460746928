var render = function () {
  var _vm$couponApply, _vm$couponApply2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-buy-package-config",
      "size": "lg",
      "centered": "",
      "header-bg-variant": "light",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": _vm.$t("packageConfig.".concat(_vm.titleHeader, ".title"))
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.loadingPackage
    }
  }, [_vm.buyItem ? _c('BRow', {
    staticClass: "my-1"
  }, [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('BCard', {
    staticClass: "border border-warning bg-light-warning mb-0 w-100",
    attrs: {
      "body-class": "px-1 py-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-between font-weight-bolder font-large-1"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.buyItem.name) + " ")]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.buyItem.price)) + " "), _c('small', {
    staticClass: "font-italic"
  }, [_vm._v("VND")])])])]), _vm._l(_vm.buyItemValueToShowDetail, function (val, key) {
    return _c('div', {
      key: key,
      staticClass: "text-dark py-50"
    }, [_c('div', {
      class: "d-flex-between font-weight-bolder ".concat(key === 'specialNote' ? 'text-success' : '')
    }, [_c('div', [_vm._v(_vm._s(_vm.$te("packageConfig.columns.".concat(key)) ? _vm.$t("packageConfig.columns.".concat(key)) : key) + ":")]), typeof (val === null || val === void 0 ? void 0 : val.value) === 'number' ? _c('div', [_c('span', {
      staticClass: "font-medium-4"
    }, [_vm._v(_vm._s(_vm.formatCurrency(val.value)))]), val.unit ? _c('small', {
      staticClass: "font-italic ml-25"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.unit))]) : _vm._e()]) : typeof (val === null || val === void 0 ? void 0 : val.value) === 'boolean' ? _c('div', [val.value ? _c('feather-icon', {
      staticClass: "text-success",
      attrs: {
        "icon": "CheckIcon",
        "size": "20"
      }
    }) : _c('feather-icon', {
      staticClass: "text-danger",
      attrs: {
        "icon": "XIcon",
        "size": "20"
      }
    })], 1) : _c('div', [_c('span', {
      staticClass: "font-medium-2"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.value))])])])]);
  })], 2)], 1), !_vm.qrCodeUrl ? _c('BCol', {
    staticClass: "d-flex-center flex-column",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('BCard', {
    staticClass: "border w-100 mb-50 flex-grow-1",
    attrs: {
      "body-class": "px-1 py-50 d-flex-center flex-column align-items-stretch"
    }
  }, [_vm.isBuy && _vm.buyItem.type !== 'INVOICE' ? _c('h4', {
    staticClass: "my-1"
  }, [_vm._v(" Áp dụng cho đại lý bán lẻ mới ")]) : _vm._e(), _vm.isGift ? _c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label-for": "agency",
      "label": _vm.$t('packageConfig.columns.packageConfig')
    }
  }, [_c('SelectPackageConfig', {
    attrs: {
      "package-config-select": _vm.buyItem,
      "clearable": false
    },
    on: {
      "update:packageConfigSelect": function updatePackageConfigSelect($event) {
        _vm.buyItem = $event;
      },
      "update:package-config-select": function updatePackageConfigSelect($event) {
        _vm.buyItem = $event;
      }
    }
  })], 1) : _vm._e(), !_vm.isBuy ? _c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label-for": "agency",
      "label": _vm.$t('packageConfig.columns.agency')
    }
  }, [_c('SelectChildAgency', {
    attrs: {
      "id": "childAgency",
      "disabled": _vm.checkCreateNewAgency && _vm.isBuy || _vm.isPayment || _vm.isIncrease || _vm.disabledAgencyItem,
      "agency-select": _vm.agencySelectedToBuy,
      "placeholder": _vm.$t('saleReport.phAgencyCode'),
      "for-f1": true
    },
    on: {
      "update:agencySelect": function updateAgencySelect($event) {
        _vm.agencySelectedToBuy = $event;
      },
      "update:agency-select": function updateAgencySelect($event) {
        _vm.agencySelectedToBuy = $event;
      }
    }
  })], 1) : _vm._e(), _vm.buyItem.type !== 'INVOICE' ? _c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label-for": "monthsUse",
      "label": _vm.$t('packageConfig.columns.monthsUse')
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    attrs: {
      "id": "monthsUse",
      "lazy-formatter": "",
      "disabled": _vm.isPayment || _vm.isGift,
      "placeholder": _vm.$t('packageConfig.columns.monthsUse')
    },
    model: {
      value: _vm.monthsUse,
      callback: function callback($$v) {
        _vm.monthsUse = $$v;
      },
      expression: "monthsUse"
    }
  }), !_vm.isGift ? _c('div', {
    staticClass: "small text-info"
  }, [_vm._v(" Mua 6 tháng sẽ được khuyến mại 1 tháng ")]) : _vm._e(), _vm.monthsUse >= 6 ? _c('div', {
    staticClass: "small text-success"
  }, [_vm._v(" Bạn sẽ được cộng thêm " + _vm._s(Math.floor(_vm.monthsUse / 6)) + " tháng miễn phí ")]) : _vm._e()], 1) : _vm._e(), _vm.isBuy || _vm.isIncrease ? _c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label-for": "paymentMethod",
      "label": _vm.$t('packageConfig.columns.paymentMethod')
    }
  }, [_c('PaymentMethodSelect', {
    attrs: {
      "payment-method": _vm.paymentMethod,
      "disabled": _vm.isPayment
    },
    on: {
      "update:paymentMethod": function updatePaymentMethod($event) {
        _vm.paymentMethod = $event;
      },
      "update:payment-method": function updatePaymentMethod($event) {
        _vm.paymentMethod = $event;
      }
    }
  })], 1) : _vm._e(), _vm.isBuy || _vm.isIncrease ? _c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label-for": "paymentMethod",
      "label": _vm.$t('packageConfig.columns.code')
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-50"
  }, [_c('BFormInput', {
    attrs: {
      "placeholder": _vm.$t('packageConfig.columns.code'),
      "formatter": _vm.removeAccentsUpperCaseFormatter,
      "maxlength": "8"
    },
    model: {
      value: _vm.couponCode,
      callback: function callback($$v) {
        _vm.couponCode = $$v;
      },
      expression: "couponCode"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm.loadingCheckCouponCode ? _c('b-spinner', {
    attrs: {
      "variant": "primary",
      "small": ""
    }
  }) : _vm.couponApply && !_vm.couponApply.isApplied && _vm.couponApply.enable ? _c('feather-icon', {
    staticClass: "text-success",
    attrs: {
      "icon": "CheckIcon",
      "size": "16"
    }
  }) : _vm.couponApply === false || _vm.couponApply && ((_vm$couponApply = _vm.couponApply) !== null && _vm$couponApply !== void 0 && _vm$couponApply.isApplied || !((_vm$couponApply2 = _vm.couponApply) !== null && _vm$couponApply2 !== void 0 && _vm$couponApply2.enable)) ? _c('feather-icon', {
    staticClass: "text-danger",
    attrs: {
      "icon": "XIcon",
      "size": "16"
    }
  }) : _vm._e()], 1)], 1), _vm.couponApply ? _c('div', [_vm.couponApply.enable ? _c('em', {
    staticClass: "d-flex-center justify-content-end gap-1",
    class: "".concat(_vm.couponApply.isApplied ? 'text-danger' : 'text-success')
  }, [_vm._v(" Giảm giá "), _vm.couponApply.percentage ? _c('span', {
    staticClass: "d-flex gap-1"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.couponApply.percentage) + "%")]), _vm.couponApply.amountMax ? _c('span', [_vm._v("(tối đa " + _vm._s(_vm.formatCurrency(_vm.couponApply.amountMax)) + " VND)")]) : _vm._e()]) : _vm.couponApply.amountMax ? _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.couponApply.amountMax)) + " VND ")]) : _vm._e()]) : _vm._e(), _vm.couponApply.isApplied ? _c('div', {
    staticClass: "d-flex-center justify-content-end text-danger gap-1"
  }, [_vm._v(" Mã giảm giá đã được sử dụng ")]) : _vm._e(), !_vm.couponApply.enable || _vm.couponApply === false ? _c('div', {
    staticClass: "d-flex-center justify-content-end text-danger gap-1"
  }, [_vm._v(" Mã giảm giá không tồn tại ")]) : _vm._e()]) : _vm._e(), _vm.couponApply === false ? _c('div', {
    staticClass: "d-flex-center justify-content-end text-danger gap-1"
  }, [_vm._v(" Mã giảm giá không tồn tại ")]) : _vm._e(), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" Số tiền cần thanh toán: "), _c('b', {}, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalAmountToBePaid)) + " "), _c('small', [_vm._v("VND")])])])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('BButton', {
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Huỷ ")]), _vm.isGift ? _c('BButton', {
    attrs: {
      "variant": "warning",
      "disabled": _vm.disabledSubmit
    },
    on: {
      "click": _vm.giftButtonHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.agencyPackageConfigsGift')) + " ")]) : _vm._e(), _vm.isIncrease ? _c('BButton', {
    attrs: {
      "variant": "warning",
      "disabled": _vm.disabledSubmit
    },
    on: {
      "click": _vm.buyButtonHandle
    }
  }, [_vm._v(" Gia hạn ")]) : _vm._e(), _vm.isBuy ? _c('BButton', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.disabledSubmit
    },
    on: {
      "click": _vm.buyButtonHandle
    }
  }, [_vm._v(" Mua ")]) : _vm._e()], 1)], 1) : _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-column"
  }, [_c('em', [_vm._v("Vui lòng quét để thanh toán !")]), _c('b-img', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "src": _vm.qrCodeUrl
    }
  })], 1)])], 1) : _vm._e()], 1), _c('ModalBuyConfirm', {
    on: {
      "confirm-buy-package": _vm.submitBuyHandle
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }