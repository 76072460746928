<template>
  <div>
    <!-- SECTION: MODAL IMPORT -->
    <customers-import-modal
      v-if="showImportModal"
      :show-import-modal.sync="showImportModal"
      @fetch-data="refetchData"
    />

    <!-- Filters -->
    <CustomersListFilter
      :created-by-filter.sync="createdByFilter"
      :empty-filter="isEmptyFilter"
      @fetch-data="refetchData"
      @reset="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      style="max-height: 70vh"
    >
      <!-- Table Top -->
      <div class="mx-2 my-1">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >
          <!-- ANCHOR Per page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
              <span class="text-muted ml-50">{{ $t('customer.textShowCustomer') }}</span>
            </v-select>
          </b-col>

          <!-- ANCHOR Button -->
          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="d-none d-lg-flex">
              <!-- Button create -->
              <!-- :disabled="!$can('airdata-cms.customer.create')" -->
              <b-button
                :disabled="!canAccess('customer.createCustomer')"
                variant="info"
                class="mr-1 px-lg-1 px-md-75"
                @click="() => $router.push({name: 'apps-customers-add'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('customer.textBtnCreate') }}
                </span>
              </b-button>

              <!-- Import -->
              <!-- FIXME <b-button
                variant="info"
                :disabled="!canAccess('customer.importCustomer')"
                class="mr-1 px-lg-1 px-md-75"
                @click="showImportModalHandle"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="UploadIcon"
                    size="16"
                  />
                  <span class="d-sm-inline ml-50"> {{ $t('import') }}</span>
                </span>
              </b-button> -->

              <b-button
                variant="info"
                class="mr-1 px-lg-1 px-md-75"
                :disabled="!canAccess('customer.exportCustomer')"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>

              <!-- button DELETE -->
              <!-- <b-button
                variant="danger"
                class="px-lg-1 px-md-75"
                :disabled="activeDelete() || !canAccess('customer.bulkDeleteCustomer')"
                @click="confirmDelete()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    style="cursor: pointer"
                  />
                  {{ $t('delete') }}
                </span>
              </b-button> -->
            </div>
            <div class="d-block d-lg-none">
              <b-dropdown
                variant="info"
                class="m-lg-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('customer.moreDropdown.title') }}</span>
                </template>
                <b-dropdown-item
                  :disabled="!canAccess('customer.createCustomer')"
                  @click="() => $router.push({name: 'apps-customers-add'})"
                >
                  {{ $t('customer.moreDropdown.add') }}
                </b-dropdown-item>
                <!-- FIXME <b-dropdown-item
                  :disabled="!canAccess('customer.importCustomer')"
                  @click="showImportModalHandle"
                >
                  {{ $t('customer.moreDropdown.import') }}
                </b-dropdown-item> -->
                <b-dropdown-item
                  :disabled="!canAccess('customer.exportCustomer')"
                  @click="confirmExport()"
                >
                  {{ $t('customer.moreDropdown.export') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="activeDelete() || !canAccess('customer.bulkDeleteCustomer')"
                  @click="confirmDelete()"
                >
                  {{ $t('customer.moreDropdown.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <!-- ANCHOR Search -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- Search Text -->
              <b-input-group
                size="md"
                class="w-100"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="searchTextFilter"
                  type="search"
                  :placeholder="$t('customer.placeholderSearch')"
                  :value="searchTextFilter"
                  :reduce="val => val.value"
                  maxlength="255"
                  debounce="500"
                  trim
                  @input="(val) => $emit('update:searchTextFilter', val)"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refCustomerListTable"
          style="max-height: 50vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchCustomers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          no-border-collapse
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`customer.${data.label}`) }}
            </span>
          </template>

          <!-- Column -->
          <!-- <template #cell(checkbox)="data">
            <b-form-checkbox
              class="mr-0 mt-50 p-0"
              name="check-box"
              inline
              :checked="isChecked(data.item.id)"
              @change="chooseItem(data.item.id)"
            />
          </template> -->

          <template #cell(code)="data">
            <div class="text-nowrap">
              <b-link
                :disabled="!canAccess('customer.detailCustomer')"
                :to="{
                  name: 'apps-customers-edit',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap text-info"
              >
                <span class="align-text-top text-capitalize font-weight-bold">
                  {{ data.item.code }}
                </span>
              </b-link>
            </div>
          </template>

          <template #cell(information)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="70px"
                  :src="data.item.photo"
                  :text="
                    avatarText(`${data.item.lastName} ${data.item.firstName}`)
                  "
                  variant="light-info"
                />
              </template>
              <div class="d-flex flex-column justify-content-center align-items-start">
                <h5 class="mb-0">
                  {{ data.item.lastName }} {{ data.item.firstName }}
                </h5>
                <small class="d-flex align-items-center">
                  <feather-icon
                    size="14"
                    icon="MailIcon"
                    class="mr-25"
                  />
                  <span class="">{{
                    data.item.emailAddress
                  }}
                  </span>
                </small>
                <small class="d-flex align-items-center">
                  <feather-icon
                    size="14"
                    icon="PhoneCallIcon"
                    class="mr-25"
                  />
                  <span class="">{{
                    data.item.phoneNumber
                  }}
                  </span>
                </small>
                <small
                  v-if="data.item.birthDay"
                  class="d-flex align-items-center"
                >
                  <feather-icon
                    size="14"
                    icon="GiftIcon"
                    class="mr-25"
                  />
                  <span class="">{{ formatDateMonth(data.item.birthDay) || "No Information" }}
                  </span>
                </small>
                <!-- </div> -->
              </div>
            </b-media>
          </template>

          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
                <small>
                  {{
                    data.item.createdBy
                      ? `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
            <!-- <p>
              {{ dateTime(data.item.createdAt) }}
            </p> -->
          </template>

          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
                <small>
                  {{
                    data.item.updatedBy
                      ? `${data.item.updatedBy.firstName} ${data.item.updatedBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
          </template>

          <!-- <template #cell(action)="data">
            <div>
              <feather-icon
                icon="EditIcon"
                size="18"
                class="mr-2"
                style="cursor: pointer"
                @click="
                  $router.push({
                    name: 'apps-employees-edit',
                    params: { id: data.item.id },
                  })
                "
              />
              <feather-icon
                icon="TrashIcon"
                size="18"
                style="cursor: pointer"
                @click="confirmDelete(data.item)"
              />
            </div>
          </template> -->
        </b-table>
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            md="2"
            class="
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <span class="text-muted">{{ dataMeta.from }}  {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            md="8"
            class="
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-md-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination, BMediaBody, BMediaAside,
  BFormInput,
  BInputGroup,
  BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import { sizePerPageLgOptions } from '@/constants/selectOptions'

import { avatarText, dateTime, formatDateMonth } from '@core/utils/filter'

import CustomersImportModal from '@customer/customers-list/customers-import/CustomersImportModal.vue'
import customerStoreModule from '@customer/customerStoreModule'
import useCustomerHandle from '@customer/useCustomerHandle'

import CustomersListFilter from './CustomersListFilter.vue'

export default {
  components: {
    CustomersImportModal,
    CustomersListFilter,
    BMediaBody,
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BFormInput,
    BInputGroup,
    BOverlay,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) {
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
      }
    })

    const isAll = ref(false)
    const deleteIds = ref([])

    // function confirmDelete() {
    //   // show modal confirm delete employee
    //   const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
    //   this.$bvModal
    //     .msgBoxConfirm(titleMsg(), {
    //       title: this.$t('modal.confirm'),
    //       size: 'sm',
    //       okVariant: 'info',
    //       okTitle: this.$t('modal.yes'),
    //       cancelTitle: this.$t('modal.no'),
    //       cancelVariant: 'outline-danger',
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then(value => {
    //       if (value) {
    //         this.bulkDeleteCustomers(this.deleteIds)
    //         this.deleteIds = []
    //       }
    //     })
    // }

    function confirmExport() {
      // show modal confirm export employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportCustomers(this.$i18n.locale)
            // this.deleteIds = []
          }
        })
    }

    function chooseItem(id) {
      this.deleteIds = this.setArray(this.deleteIds, id)
    }

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }

    function isChecked(id) {
      return (
        this.deleteIds.includes(id)
      )
    }

    function activeDelete() {
      return this.deleteIds.length === 0
    }

    // SECTION: IMPORT MODAL
    const showImportModal = ref(false)

    const showImportModalHandle = () => {
      showImportModal.value = !showImportModal.value
    }

    const {
      fetchCustomers,
      tableColumns,
      sizePerPage,
      currentPage,
      totalCustomers,
      dataMeta,
      sortBy, isSortDirDesc,
      refCustomerListTable,
      refetchData, clearFilter,
      updateDeactive,
      updateActive,
      bulkDeleteCustomers,

      // Extra Filters
      searchTextFilter,
      createdByFilter,

      exportCustomers,

      // Loading
      loading,

      isEmptyFilter,

    } = useCustomerHandle()

    return {
      isAll,
      deleteIds,
      chooseItem,
      isChecked,
      setArray,
      activeDelete,

      // Sidebar
      updateDeactive,
      updateActive,
      fetchCustomers,
      bulkDeleteCustomers,
      // confirmDelete,
      tableColumns,
      totalCustomers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
      clearFilter,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // select Options
      sizePerPageLgOptions,

      // Extra Filters
      searchTextFilter,
      createdByFilter,

      avatarText,
      dateTime,
      formatDateMonth,

      confirmExport,
      exportCustomers,
      showImportModalHandle,
      showImportModal,

      // Loading
      loading,

      isEmptyFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
