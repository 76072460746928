<template lang="">
  <div>
    <ValidationObserver ref="refFormObserver">
      <IncorrectInvoiceInfoCard :incorrectInvoiceData.sync="incorrectInvoiceData" />
      <IncorrectInvoiceSelectCard />
      <IncorrectInvoiceDetailCard />
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'

import IncorrectInvoiceDetailCard from './components/IncorrectInvoiceDetailCard.vue'
import IncorrectInvoiceInfoCard from './components/IncorrectInvoiceInfoCard.vue'
import IncorrectInvoiceSelectCard from './components/IncorrectInvoiceSelectCard.vue'
import useIncorrectInvoiceHandle, { INCORRECT_INVOICE_NOTIFICATION_TYPE, useIncorrectInvoiceDetailHandle } from './useIncorrectInvoiceHandle'
import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    IncorrectInvoiceDetailCard,
    IncorrectInvoiceSelectCard,
    IncorrectInvoiceInfoCard,
    ValidationObserver,
  },
  setup() {
    const { refFormObserver } = formValidation()
    // const isCompanyActive = computed(() => store.getters['userStore/getAgencyCompany']?.id)

    const { route } = useRouter()
    const { getIncorrectInvoiceById } = useIncorrectInvoiceDetailHandle()
    const { INVOICE_STORE } = useIncorrectInvoiceHandle()
    // const {
    //   createInvoice, SALE_REPORT_STORE, loading, isInvoiceRequest,
    // } = useInvoiceCreateHandle()
    // const { INVOICE_STORE, GOODS_SERVICE_TYPES, PAYMENT_METHOD_DEFAULT } = useInvoiceHandle()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
    })
    const incorrectInvoiceData = ref()

    async function fetchInvoiceById(id = null) {
      const res = await getIncorrectInvoiceById(id)
      console.log({ res })
      incorrectInvoiceData.value = cloneDeep(res)
    }

    watch(route, () => {
      // store.dispatch('app-invoice/clearSelectedTickets')
      fetchInvoiceById(route.value.params.id)
    }, { immediate: true })
    const blankIncorrectInvoice = {
      // companyId: 0,
      notificationType: INCORRECT_INVOICE_NOTIFICATION_TYPE.TYPE_1,
      taxReviewNoticeNumber: '',
      dateOfTaxReviewNotice: '',
      placeOfIssuance: '',
      dateOfIssuance: '',
      incorrectItems: [],
    }

    // const selectedTickets = computed(() => store.getters['app-invoice/getSelectedTickets'])
    // const getCollectionFee = computed(() => store.getters['app-invoice/getCollectionFee'])
    // const getNeedToPayAmount = computed(() => store.getters['app-invoice/getNeedToPayAmount'])
    // const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])

    // const isInvalidInvoiceItems = ref(false)
    // watch(selectedTickets, (newValue, oldValue) => {
    //   if (selectedTickets.value.length && isInvalidInvoiceItems.value) {
    //     isInvalidInvoiceItems.value = false
    //   }
    //   if (!newValue.length && oldValue.length) {
    //     store.dispatch('app-invoice/clearSelectedTickets')
    //   }
    // })

    // async function createInvoiceHandle() {
    //   if (!selectedTickets.value.length) {
    //     isInvalidInvoiceItems.value = true
    //   }
    //   const isValid = await refFormObserver.value.validate()
    //   if (!isValid || isInvalidInvoiceItems.value) return
    //   const payload = {
    //     ...invoiceToCreate.value,
    //     invoiceItems: selectedTickets.value.map(item => ({
    //       itemType: item.itemType,
    //       name: `${item.name}`,
    //       ticketNumber: `${item.ticketNumber ?? ''}`,
    //       unit: item.unit,
    //       quantity: item.quantity ?? 0,
    //       unitPrice: numberToFixed(item.unitPrice ?? 0),
    //       valueAddedTax: item.valueAddedTax,
    //       discountRate: 0,
    //     })),
    //     templateId: invoiceToCreate.value?.template?.id,
    //     companyId: invoiceToCreate.value?.company?.id,
    //     agencyIssueId: invoiceToCreate.value?.agencyIssue?.id,
    //     agencyReceiveId: invoiceToCreate.value?.agencyReceive?.id,
    //     collectionFee: getCollectionFee.value,
    //     totalAmountAdditional: getNeedToPayAmount.value ?? 0,
    //     // xoá khỏi payload
    //     template: undefined, // xoá template khỏi payload
    //     company: undefined, // xoá company khỏi payload
    //     createdAt: undefined,
    //     invoiceType: undefined,
    //     agencyIssue: undefined,
    //     agencyReceive: undefined,
    //     invoiceStatus: undefined,
    //     createdType: isInvoiceRequest.value ? CREATE_TYPE.REQUEST : undefined,
    //   }
    //   createInvoice(payload)
    // }

    // const collapseSelectedTicket = ref(false)

    // function exitHandle() {
    //   this.$bvModal
    //     .msgBoxConfirm(this.$t('modal.confirmCancelInput'), {
    //       title: this.$t('modal.confirm'),
    //       size: 'sm',
    //       okVariant: 'danger',
    //       okTitle: this.$t('modal.iamConfirm'),
    //       cancelTitle: this.$t('modal.no'),
    //       cancelVariant: 'outline-secondary',
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then(value => {
    //       if (value) {
    //         router.push({ name: 'apps-invoiceList' })
    //       }
    //     })
    // }

    // function superQuickInputInfoHandle() {
    //   if (process.env.VUE_APP_ENV !== 'development') return
    //   invoiceToCreate.value.templateNumber = '1'
    //   invoiceToCreate.value.symbol = 'C24TBB'
    //   invoiceToCreate.value.buyerTaxCode = '0317618074'
    //   invoiceToCreate.value.buyerUnitCode = null
    //   invoiceToCreate.value.buyerUnitName = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ AIRDATA'
    //   invoiceToCreate.value.buyerName = `${fakerVI.person.lastName()} ${fakerVI.person.firstName()}`
    //   invoiceToCreate.value.buyerAddress = 'Số 77 Thoại Ngọc Hầu, Phường Hoà Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh, Việt Nam'
    //   invoiceToCreate.value.buyerEmail = 'lethaiduc11@gmail.com'
    //   invoiceToCreate.value.discountType = 'BY_TOTAL_INVOICE'
    //   invoiceToCreate.value.taxRateType = 'MULTIPLE'
    //   invoiceToCreate.value.customerType = 'KHACH_LE'
    //   invoiceToCreate.value.tags = [fakerVI.person.zodiacSign()]
    //   invoiceToCreate.value.invoiceType = 'TYPE_1'
    //   invoiceToCreate.value.company = {
    //     id: getAgencyData.value?.company?.id,
    //     externalId: getAgencyData.value?.company?.externalId,
    //     name: getAgencyData.value?.company?.name,
    //   }
    //   invoiceToCreate.value.template = {
    //     id: 'd4681d34-c557-4beb-b1fc-cba1dc0c5d0c',
    //     companyId: 231,
    //     name: 'ATS_nTS_0CK',
    //   }
    //   invoiceToCreate.value.agencyIssue = null
    //   toastSuccess('Nhập thông tin siêu nhanh')
    // }

    // const resetInvoice = ref({})

    // const updateInvoiceByField = debounce(({ key, value }) => {
    //   invoiceToCreate.value[key] = value
    // }, 300)

    return {
      refFormObserver,
      blankIncorrectInvoice,
      incorrectInvoiceData,
      // invoiceToCreate,
      // createInvoiceHandle,
      // loading,
      // collapseSelectedTicket,
      // exitHandle,
      // superQuickInputInfoHandle,
      // resetInvoice,
      // isInvalidInvoiceItems,
      // isCompanyActive,
      // updateInvoiceByField,
      // isInvoiceRequest,
    }
  },
}
</script>
<style lang="">

</style>
