export default {
  title: 'Agent Requests',
  columns: {
    no: 'No',
    agentCode: 'Agency',
    flightDates: 'Flight Dates',
    // request: 'Request',
    requestType: 'Request Type',
    routes: 'Routes',
    trips: 'Trips',
    createdAt: 'Created at',
    source: 'Source',
  },
  requestType: {
    SEARCH: 'Search',
    PAYMENT: 'Payment',
    CREATE: 'Create',
  },
  filters: {
    startDate: 'Start date',
    endDate: 'End date',
    agencyCode: 'Agency',
    requestType: 'Request Type',
    searchText: 'Search',
    ph: {
      searchText: 'Input and press ↵ to search',
    },
  },
}
