var render = function () {
  var _vm$customerDataInfo, _vm$customerDataInfo2, _vm$customerDataInfo3, _vm$customerDataInfo4, _vm$customerDataInfo5, _vm$customerDataInfo6, _vm$customerDataInfo7, _vm$customerDataInfo8, _vm$customerDataInfo$, _vm$customerDataInfo$2, _vm$customerDataInfo$3, _vm$customerDataInfo9, _vm$customerDataInfo10, _vm$customerDataInfo11, _vm$customerDataInfo12, _vm$customerDataInfo13, _vm$customerDataInfo14, _vm$customerDataInfo15, _vm$customerDataInfo16, _vm$customerDataInfo17, _vm$customerDataInfo18, _vm$customerDataInfo19, _vm$customerDataInfo20, _vm$customerDataInfo21, _vm$customerDataInfo22, _vm$customerDataInfo23, _vm$customerDataInfo24, _vm$customerDataInfo25, _vm$customerDataInfo26, _vm$customerDataInfo27, _vm$customerDataInfo28, _vm$customerDataInfo29, _vm$customerDataInfo30, _vm$customerDataInfo31, _vm$customerDataInfo32, _vm$customerDataInfo33, _vm$customerDataInfo34, _vm$customerDataInfo35, _vm$customerDataInfo36, _vm$customerDataInfo37, _vm$customerDataInfo38;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center ",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center  ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.lastName'), " ").concat(_vm.isEditActive),
      "label-for": "last-name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Last Name",
      "rules": "required",
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "last-name",
            "disabled": _vm.readonlys,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.lastName')
          },
          model: {
            value: _vm.customerDataInfo.lastName,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "lastName", $$v);
            },
            expression: "customerDataInfo.lastName"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.lastName || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center  ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.firstName'), " ").concat(_vm.isEditActive),
      "label-for": "first-name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "First Name",
      "rules": "required",
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "first-name",
            "disabled": _vm.readonlys,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.firstName')
          },
          model: {
            value: _vm.customerDataInfo.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "firstName", $$v);
            },
            expression: "customerDataInfo.firstName"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.firstName || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.nickName'), " "),
      "label-for": "nick-name"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-form-input', {
    attrs: {
      "id": "nick-name",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.nickName')
    },
    model: {
      value: _vm.customerDataInfo.nickName,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "nickName", $$v);
      },
      expression: "customerDataInfo.nickName"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.nickName || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.createdAt')),
      "label-for": "createdAt"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('flat-pickr', {
    staticClass: "form-control mb-0 ",
    staticStyle: {
      "background": "#e0e0e0  !important"
    },
    attrs: {
      "id": "createdAt",
      "disabled": _vm.mode === 'edit',
      "placeholder": _vm.$t('customer.createdAt'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        altInput: true,
        disableMobile: true,
        minDate: 'today'
      }
    },
    model: {
      value: _vm.customerDataInfo.createdAt,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "createdAt", $$v);
      },
      expression: "customerDataInfo.createdAt"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate(_vm.customerDataInfo.createdAt) || '-'))])])])], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between align-items-center "
  }, [_c('h6', {
    staticClass: "text-body m-0"
  }, [_vm._v(" Mạng xã hội ")]), _vm.readonlys === false ? _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary.window",
      modifiers: {
        "hover": true,
        "v-primary": true,
        "window": true
      }
    }],
    staticClass: "btn-icon  py-25 px-50 d-flex-center",
    attrs: {
      "title": "Chỉnh sửa",
      "size": "sm",
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.editSocialNetwork(_vm.customerDataInfo);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@icons/edit.svg")
    }
  }), !_vm.isMobileView ? _c('span', {
    staticClass: "font-medium-2 ml-25 ",
    staticStyle: {
      "margin-top": "1px",
      "display": "flex",
      "justify-content": "center"
    }
  }) : _vm._e()])], 1) : _c('div', {
    staticClass: "d-none"
  })]), _vm.customerDataInfo.socialAccounts ? _c('div', _vm._l(_vm.customerDataInfo.socialAccounts, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "mb-50 d-flex align-items-center ml-1"
    }, [_c('span', {
      staticClass: "mr-50 font-weight-bolder",
      staticStyle: {
        "min-width": "120px"
      }
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('b-link', {
      staticClass: "url-ellipsis",
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.url) + " ")])], 1);
  }), 0) : _c('div', [_vm._v(" Chưa thêm mạng xã hội ")]), _vm.customerDataInfo ? _c('ModalEditSocialNetwork', {
    attrs: {
      "social-accounts": _vm.customerDataInfo.socialAccounts,
      "customer-data": _vm.customerDataInfo
    },
    on: {
      "update:socialAccounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.customerDataInfo, "socialAccounts", $event);
      },
      "update:social-accounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.customerDataInfo, "socialAccounts", $event);
      }
    }
  }) : _vm._e()], 1)], 1)], 1), _c('b-card', {
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.phoneNumber'), " ").concat(_vm.isEditActive),
      "label-for": "phone-number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Phone Number",
      "rules": _vm.mode === 'add' || _vm.mode === 'edit' ? "required|phoneNumber|isUnique:customer,phoneNumber,".concat(_vm.customerDataInfo.phoneNumber) : '',
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          staticClass: "ml-1",
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "phone-number",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.phoneNumber')
          },
          model: {
            value: _vm.customerDataInfo.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "phoneNumber", $$v);
            },
            expression: "customerDataInfo.phoneNumber"
          }
        })], 1) : _c('div', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.customerDataInfo.phoneNumber))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.emailAddress'), " ").concat(_vm.isEditActive),
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": _vm.mode === 'add' || _vm.mode === 'edit' ? "required|email|isUnique:customer,email,".concat(_vm.customerDataInfo.emailAddress) : '',
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "email",
            "disabled": _vm.readonlys,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.emailAddress')
          },
          model: {
            value: _vm.customerDataInfo.emailAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "emailAddress", $$v);
            },
            expression: "customerDataInfo.emailAddress"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.emailAddress || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Birthday')),
      "label-for": "birthDay"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    attrs: {
      "id": "birthDay",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Birthday'),
      "config": {
        allowInput: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true,
        maxDate: 'today'
      }
    },
    model: {
      value: _vm.customerDataInfo.birthDay,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "birthDay", $$v);
      },
      expression: "customerDataInfo.birthDay"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate(_vm.customerDataInfo.birthDay) || '-'))])])])], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Gender'), " ").concat(_vm.isEditActive),
      "label-for": "gender"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Gender",
      "rules": "required",
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('v-select', {
          staticClass: "fixed-width-select",
          staticStyle: {
            "width": "100%",
            "background": "#ffff",
            "border-radius": "10px"
          },
          attrs: {
            "label": "label",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.genderOptions,
            "reduce": function reduce(val) {
              return val.value;
            },
            "clearable": false,
            "disabled": _vm.readonlys,
            "input-id": "gender-type",
            "placeholder": _vm.$t('customer.gender')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref) {
              var value = _ref.value;
              return [_c('span', [_vm._v(_vm._s(_vm.$t(value)))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref2) {
              var value = _ref2.value;
              return [_c('span', [_vm._v(_vm._s(_vm.$t(value)))])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.customerDataInfo.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "gender", $$v);
            },
            expression: "customerDataInfo.gender"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("".concat(_vm.customerDataInfo.gender)) || '-'))])]), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center ",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticClass: "mb-1",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.identifyNumber'), " "),
      "label-for": "identify-number"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    attrs: {
      "id": "identify-number",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.identifyNumber')
    },
    model: {
      value: _vm.customerDataInfo.identifyNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "identifyNumber", $$v);
      },
      expression: "customerDataInfo.identifyNumber"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.identifyNumber || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: " font-weight-bold d-flex justify-content-between align-items-center align-items-center ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Dateofissue')),
      "label-for": "Dateofissue"
    }
  }, [_vm.readonlys === false ? _c('div', [(_vm$customerDataInfo = _vm.customerDataInfo) !== null && _vm$customerDataInfo !== void 0 && _vm$customerDataInfo.identifyConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "mb-0 bg-white",
    attrs: {
      "id": "Dateofissue",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Dateofissue'),
      "config": {
        allowInput: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true
      }
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "startDate", $$v);
      },
      expression: "customerDataInfo.identifyConfig.startDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo2 = _vm.customerDataInfo) === null || _vm$customerDataInfo2 === void 0 ? void 0 : (_vm$customerDataInfo3 = _vm$customerDataInfo2.identifyConfig) === null || _vm$customerDataInfo3 === void 0 ? void 0 : _vm$customerDataInfo3.startDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: " font-weight-bold d-flex justify-content-between align-items-center align-items-center ",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.expirationDate')),
      "label-for": "expirationDate"
    }
  }, [_vm.readonlys === false ? _c('div', [(_vm$customerDataInfo4 = _vm.customerDataInfo) !== null && _vm$customerDataInfo4 !== void 0 && _vm$customerDataInfo4.identifyConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "bg-white",
    attrs: {
      "id": "expirationDate",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.expirationDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true
      }
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "endDate", $$v);
      },
      expression: "customerDataInfo.identifyConfig.endDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo5 = _vm.customerDataInfo) === null || _vm$customerDataInfo5 === void 0 ? void 0 : (_vm$customerDataInfo6 = _vm$customerDataInfo5.identifyConfig) === null || _vm$customerDataInfo6 === void 0 ? void 0 : _vm$customerDataInfo6.endDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex  justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Placeofissue'), " "),
      "label-for": "placeOfIssue"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.identifyConfig ? _c('div', [_c('b-form-input', {
    attrs: {
      "id": "placeOfIssue",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.Placeofissue')
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.placeOfIssue,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "placeOfIssue", $$v);
      },
      expression: "customerDataInfo.identifyConfig.placeOfIssue"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo7 = _vm.customerDataInfo) === null || _vm$customerDataInfo7 === void 0 ? void 0 : (_vm$customerDataInfo8 = _vm$customerDataInfo7.identifyConfig) === null || _vm$customerDataInfo8 === void 0 ? void 0 : _vm$customerDataInfo8.placeOfIssue) || '-'))])])])], 1)], 1), _vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.focus.top",
      value: "Qu\xE9t QR Code CCCD/Passport",
      expression: "`Quét QR Code CCCD/Passport`",
      modifiers: {
        "hover": true,
        "focus": true,
        "top": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickQrScanHandle("qr-code-scan-passport-identify");
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "qrCode",
      "size": "18",
      "color": "black"
    }
  })], 1), _c('IAmQrCodeScan', {
    attrs: {
      "idProp": "qr-code-scan-passport-identify"
    },
    on: {
      "apply": function apply(value) {
        return _vm.customerDataInfo.identifyNumber = value;
      }
    }
  }), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.focus.top",
      value: "T\u1EA3i \u1EA2nh \u0111\u1EC3 qu\xE9t QR Code CCCD/Passport",
      expression: "`Tải Ảnh để quét QR Code CCCD/Passport`",
      modifiers: {
        "hover": true,
        "focus": true,
        "top": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickQrScanHandle("qr-code-scan-by-upload-passport-identify");
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "cloudUploadOutline",
      "size": "18",
      "color": "black"
    }
  })], 1), _c('IAmQrCodeScanByUpload', {
    attrs: {
      "idProp": "qr-code-scan-by-upload-passport-identify"
    },
    on: {
      "apply": function apply(value) {
        return _vm.customerDataInfo.identifyNumber = value;
      }
    }
  })], 1) : _c('div', {
    staticClass: "d-none"
  })], 1), _c('b-card', {
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex  justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.city')),
      "label-for": "city"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('v-select', {
    staticClass: "fixed-width-select bg-white",
    staticStyle: {
      "width": "100%",
      "border-radius": "10px"
    },
    attrs: {
      "id": "city",
      "reduce": function reduce(val) {
        return val.id;
      },
      "options": _vm.optionsIdCity,
      "label": "name",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.city')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref3) {
        var name = _ref3.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref4) {
        var name = _ref4.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.addressCity,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressCity", $$v);
      },
      expression: "customerDataInfo.addressCity"
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$customerDataInfo$ = _vm.customerDataInfo.addressCity) === null || _vm$customerDataInfo$ === void 0 ? void 0 : _vm$customerDataInfo$.name) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.district')),
      "label-for": "district"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('v-select', {
    staticClass: "fixed-width-select bg-white",
    staticStyle: {
      "width": "100%",
      "border-radius": "10px"
    },
    attrs: {
      "id": "district",
      "options": _vm.optionsIdDitric,
      "reduce": function reduce(val) {
        return val.id;
      },
      "disabled": _vm.readonlys,
      "label": "name",
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.district')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref5) {
        var name = _ref5.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref6) {
        var name = _ref6.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.addressDistrict,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressDistrict", $$v);
      },
      expression: "customerDataInfo.addressDistrict"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo$2 = _vm.customerDataInfo.addressDistrict) === null || _vm$customerDataInfo$2 === void 0 ? void 0 : _vm$customerDataInfo$2.name) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.ward')),
      "label-for": "social-accounts"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-select', {
    staticClass: "fixed-width-select bg-white",
    staticStyle: {
      "width": "100%",
      "border-radius": "10px"
    },
    attrs: {
      "id": "social-accounts",
      "disabled": _vm.readonlys,
      "reduce": function reduce(val) {
        return val.id;
      },
      "label": "name",
      "options": _vm.optionsIdWard,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.ward')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref7) {
        var name = _ref7.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref8) {
        var name = _ref8.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.addressWard,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressWard", $$v);
      },
      expression: "customerDataInfo.addressWard"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo$3 = _vm.customerDataInfo.addressWard) === null || _vm$customerDataInfo$3 === void 0 ? void 0 : _vm$customerDataInfo$3.name) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.street')),
      "label-for": "street"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    staticStyle: {
      "background": "#ffff",
      "border-radius": "5px"
    },
    attrs: {
      "id": "street",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.street')
    },
    model: {
      value: _vm.customerDataInfo.addressStreet,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressStreet", $$v);
      },
      expression: "customerDataInfo.addressStreet"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.addressStreet || '-'))])])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticClass: "mb-1 ",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.passPort')),
      "label-for": "passportNumber"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-form-input', {
    attrs: {
      "id": "passportNumber",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.passPort')
    },
    model: {
      value: _vm.customerDataInfo.passportNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "passportNumber", $$v);
      },
      expression: "customerDataInfo.passportNumber"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.passportNumber || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex  justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Dateofissue')),
      "label-for": "Dateofissue"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "bg-white",
    attrs: {
      "id": "Dateofissue",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Dateofissue'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null
      }
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "startDate", $$v);
      },
      expression: "customerDataInfo.passportConfig.startDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo9 = _vm.customerDataInfo) === null || _vm$customerDataInfo9 === void 0 ? void 0 : (_vm$customerDataInfo10 = _vm$customerDataInfo9.passportConfig) === null || _vm$customerDataInfo10 === void 0 ? void 0 : _vm$customerDataInfo10.startDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex   justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.expirationDate')),
      "label-for": "expirationDate"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "bg-white",
    attrs: {
      "id": "expirationDate",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.expirationDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null
      }
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "endDate", $$v);
      },
      expression: "customerDataInfo.passportConfig.endDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo11 = _vm.customerDataInfo) === null || _vm$customerDataInfo11 === void 0 ? void 0 : (_vm$customerDataInfo12 = _vm$customerDataInfo11.passportConfig) === null || _vm$customerDataInfo12 === void 0 ? void 0 : _vm$customerDataInfo12.endDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex   justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Placeofissue'), " "),
      "label-for": "identify-number"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('IAmCountrySelect', {
    staticClass: "bg-white fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "border-radius": "10px"
    },
    attrs: {
      "id": 'identify-number',
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Placeofissue')
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.placeOfIssue,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "placeOfIssue", $$v);
      },
      expression: "customerDataInfo.passportConfig.placeOfIssue"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [(_vm$customerDataInfo13 = _vm.customerDataInfo) !== null && _vm$customerDataInfo13 !== void 0 && (_vm$customerDataInfo14 = _vm$customerDataInfo13.passportConfig) !== null && _vm$customerDataInfo14 !== void 0 && _vm$customerDataInfo14.placeOfIssue ? _c('span', [_vm._v(" " + _vm._s((_vm$customerDataInfo15 = _vm.customerDataInfo) !== null && _vm$customerDataInfo15 !== void 0 && (_vm$customerDataInfo16 = _vm$customerDataInfo15.passportConfig) !== null && _vm$customerDataInfo16 !== void 0 && (_vm$customerDataInfo17 = _vm$customerDataInfo16.placeOfIssue) !== null && _vm$customerDataInfo17 !== void 0 && _vm$customerDataInfo17.countryName ? "".concat((_vm$customerDataInfo18 = _vm.customerDataInfo) === null || _vm$customerDataInfo18 === void 0 ? void 0 : (_vm$customerDataInfo19 = _vm$customerDataInfo18.passportConfig) === null || _vm$customerDataInfo19 === void 0 ? void 0 : (_vm$customerDataInfo20 = _vm$customerDataInfo19.placeOfIssue) === null || _vm$customerDataInfo20 === void 0 ? void 0 : _vm$customerDataInfo20.countryName, "(").concat((_vm$customerDataInfo21 = _vm.customerDataInfo) === null || _vm$customerDataInfo21 === void 0 ? void 0 : (_vm$customerDataInfo22 = _vm$customerDataInfo21.passportConfig) === null || _vm$customerDataInfo22 === void 0 ? void 0 : (_vm$customerDataInfo23 = _vm$customerDataInfo22.placeOfIssue) === null || _vm$customerDataInfo23 === void 0 ? void 0 : _vm$customerDataInfo23.longCode, ")") : (_vm$customerDataInfo24 = _vm.customerDataInfo) === null || _vm$customerDataInfo24 === void 0 ? void 0 : (_vm$customerDataInfo25 = _vm$customerDataInfo24.passportConfig) === null || _vm$customerDataInfo25 === void 0 ? void 0 : _vm$customerDataInfo25.placeOfIssue) + " ")]) : _c('span', [_vm._v("-")])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex mb-0  justify-content-between align-items-center align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.nationality'), " "),
      "label-for": "nationality"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('IAmCountrySelect', {
    staticClass: "bg-white fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "border-radius": "10px"
    },
    attrs: {
      "id": 'city',
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.nationality')
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "nationality", $$v);
      },
      expression: "customerDataInfo.passportConfig.nationality"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [(_vm$customerDataInfo26 = _vm.customerDataInfo) !== null && _vm$customerDataInfo26 !== void 0 && (_vm$customerDataInfo27 = _vm$customerDataInfo26.passportConfig) !== null && _vm$customerDataInfo27 !== void 0 && _vm$customerDataInfo27.nationality ? _c('span', [_vm._v(" " + _vm._s((_vm$customerDataInfo28 = _vm.customerDataInfo) !== null && _vm$customerDataInfo28 !== void 0 && (_vm$customerDataInfo29 = _vm$customerDataInfo28.passportConfig) !== null && _vm$customerDataInfo29 !== void 0 && (_vm$customerDataInfo30 = _vm$customerDataInfo29.nationality) !== null && _vm$customerDataInfo30 !== void 0 && _vm$customerDataInfo30.countryName ? "".concat((_vm$customerDataInfo31 = _vm.customerDataInfo) === null || _vm$customerDataInfo31 === void 0 ? void 0 : (_vm$customerDataInfo32 = _vm$customerDataInfo31.passportConfig) === null || _vm$customerDataInfo32 === void 0 ? void 0 : (_vm$customerDataInfo33 = _vm$customerDataInfo32.nationality) === null || _vm$customerDataInfo33 === void 0 ? void 0 : _vm$customerDataInfo33.countryName, "(").concat((_vm$customerDataInfo34 = _vm.customerDataInfo) === null || _vm$customerDataInfo34 === void 0 ? void 0 : (_vm$customerDataInfo35 = _vm$customerDataInfo34.passportConfig) === null || _vm$customerDataInfo35 === void 0 ? void 0 : (_vm$customerDataInfo36 = _vm$customerDataInfo35.nationality) === null || _vm$customerDataInfo36 === void 0 ? void 0 : _vm$customerDataInfo36.longCode, ")") : (_vm$customerDataInfo37 = _vm.customerDataInfo) === null || _vm$customerDataInfo37 === void 0 ? void 0 : (_vm$customerDataInfo38 = _vm$customerDataInfo37.passportConfig) === null || _vm$customerDataInfo38 === void 0 ? void 0 : _vm$customerDataInfo38.nationality) + " ")]) : _c('span', [_vm._v("-")])])])], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1 ",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "d-flex align-items-center  justify-content-between align-items-center font-weight-bold",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.receivables'), " "),
      "label-for": "receivables"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: " d-flex justify-content-end"
  }, [_c('b-form-input', {
    attrs: {
      "id": "receivables",
      "disabled": _vm.readonly,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.receivables')
    },
    model: {
      value: _vm.customerDataInfo.receivables,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "receivables", $$v);
      },
      expression: "customerDataInfo.receivables"
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: "text-info"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.customerDataInfo.receivables) || '-'))])])])], 1), _c('b-row', [_c('b-form-group', {
    staticClass: "d-flex align-items-center  justify-content-between align-items-center font-weight-bold",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.profit')),
      "label-for": "profit"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    attrs: {
      "id": "profit",
      "disabled": _vm.readonly,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.profit')
    },
    model: {
      value: _vm.customerDataInfo.profit,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "profit", $$v);
      },
      expression: "customerDataInfo.profit"
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: " text-info"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.customerDataInfo.profit)))])])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-card', {
    staticClass: "mx-2",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "100%"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-textarea', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "note",
      "lazy-formatter": "",
      "disabled": _vm.readonlys,
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.note'),
      "rows": "4",
      "no-auto-shrink": ""
    },
    model: {
      value: _vm.customerDataInfo.note,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "note", $$v);
      },
      expression: "customerDataInfo.note"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.note || '-'))])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }