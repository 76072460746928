import { STATUS_TOUR } from '@/constants/tour'

export const tourTypes = {
  OUTBOUND: 'OUTBOUND',
  DOMESTIC: 'DOMESTIC',
}

export const defaultDepartPoint = 'TP. Hồ Chí Minh'

export const VEHICLE_TYPES = [
  'CUSTOM',
  'AIRPLANE',
  'CAR',
  'TRAIN',
  'HIGH_SPEED_TRAIN',
]
