var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.showLoading
    }
  }, [_vm._l(_vm.messNotifications, function (notification, notificationIndex) {
    var _notification$data, _notification$data2;
    return _c('div', {
      key: notificationIndex
    }, [_c('b-card', {
      class: "mb-1 cursor-pointer border-".concat(notification !== null && notification !== void 0 && notification.readStatus ? 'dark' : 'danger', " hover-card shadow-md"),
      attrs: {
        "body-class": "px-1 py-50",
        "bg-variant": _vm.readContent(notification)
      },
      on: {
        "click": function click($event) {
          return _vm.readContentMess(notificationIndex);
        }
      }
    }, [_c('HStack', [!notification.readStatus ? _c('IAmIcon', {
      staticClass: "px-50",
      attrs: {
        "icon": "circle",
        "size": "14"
      }
    }) : _vm._e(), _c('Stack', {
      staticClass: "gap-2 flex-1"
    }, [_c('HStack', {
      attrs: {
        "between": ""
      }
    }, [_c('div', {
      staticClass: "pb-0"
    }, [_c('h3', {
      staticClass: "mb-0"
    }, [_c('span', {
      staticStyle: {
        "font-size": "large",
        "font-weight": "700"
      }
    }, [_vm._v(_vm._s(notification.subject))])])]), _c('div', [_c('span', {
      staticStyle: {
        "color": "black",
        "display": "flex",
        "gap": "3px"
      }
    }, [_vm._v(" " + _vm._s(_vm.dateTime(notification.createdAt)) + " ")])])]), _c('HStack', {
      attrs: {
        "between": ""
      }
    }, [_c('div', {
      staticClass: "text-dark"
    }, [notification.isShow ? _c('div', {
      key: "expanded"
    }, [_c('p', {
      staticClass: "mb-0 text-justify"
    }, [_vm._v(" " + _vm._s(notification.content) + " ")])]) : _c('div', {
      key: "truncated"
    }, [_c('p', {
      staticClass: "mb-0 text-justify"
    }, [_vm._v(" " + _vm._s(notification.content) + " ")])])]), notification !== null && notification !== void 0 && (_notification$data = notification.data) !== null && _notification$data !== void 0 && _notification$data.path ? _c('a', {
      staticClass: "btn btn-flat-danger",
      attrs: {
        "href": "/apps".concat(notification === null || notification === void 0 ? void 0 : (_notification$data2 = notification.data) === null || _notification$data2 === void 0 ? void 0 : _notification$data2.path),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.viewBooking')) + " ")]) : _vm._e()])], 1)], 1)], 1)], 1);
  }), _c('infinite-loading', {
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [!_vm.messNotifications.length ? _c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }) : _vm._e(), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.allNotiShown')) + " ")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }