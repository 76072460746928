<template lang="">
  <IAmOverlay :loading="loading">
    <BAlert
      v-if="!isCompanyActive && !isInvoiceRequest"
      show
      variant="danger"
      class="py-1 px-2"
    >
      {{ $t('invoice.msg.companyNotFoundOrDeactivated') }}
    </BAlert>
    <ValidationObserver ref="refFormObserver">
      <div
        id="invoice-create"
        class="pb-2"
      >
        <InvoiceInfoCard
          :invoice-data.sync="invoiceToCreate"
          :isInvoiceRequest="isInvoiceRequest"
          @quick-input="superQuickInputInfoHandle"
        />
        <BRow>
          <BCol
            cols="12"
            :md="collapseSelectedTicket ? 12 : 5"
            :class="!collapseSelectedTicket ? 'pr-md-50' : ''"
          >
            <InvoiceSelectTicketCard
              :invoice-data.sync="invoiceToCreate"
              :collapse-selected-ticket.sync="collapseSelectedTicket"
            />
          </BCol>
          <BCol
            cols="12"
            :md="collapseSelectedTicket ? 12 : 7"
            :class="!collapseSelectedTicket ? 'pl-md-50' : ''"
          >
            <InvoiceDetailCard
              :invoice-data.sync="invoiceToCreate"
              :is-create="true"
              :resetInvoice="resetInvoice"
              :isInvalidInvoiceItems="isInvalidInvoiceItems"
              :isInvoiceRequest="isInvoiceRequest"
              @update-invoice="updateInvoiceByField"
            />
            <InvoicePaymentCard :invoice-data.sync="invoiceToCreate" />

            <!-- ANCHOR Buttons -->
            <div class="d-flex-center gap-3">
              <BButton
                pill
                class="min-width-120"
                variant="outline-secondary"
                @click="exitHandle"
              >
                {{ $t('invoice.btn.exit') }}
              </BButton>
              <BButton
                pill
                class="min-width-120"
                variant="info"
                :disabled="!isCompanyActive && !isInvoiceRequest"
                @click="createInvoiceHandle"
              >
                {{ $tc('invoice.btn.save', isInvoiceRequest ? 2 : 1) }}
              </BButton>
            </div>
          </BCol>
        </BRow>
      </div>
    </ValidationObserver>
  </IAmOverlay>
</template>
<script>
import {
  BCol, BRow, BButton, BAlert,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { fakerVI } from '@faker-js/faker'
import debounce from 'lodash/debounce'

import store from '@/store'
import router from '@/router'
import useInvoiceCreateHandle from '@/views/invoices/create/useInvoiceCreateHandle'
import useInvoiceHandle, { numberToFixed } from '@/views/invoices/useInvoiceHandle'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'
import { CREATE_TYPE } from '@/constants/invoice'

import formValidation from '@core/comp-functions/forms/form-validation'

import saleReportStoreModule from '@saleReport/saleReportStoreModule'

import useToast from '@useToast'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BAlert,
    InvoiceInfoCard: () => import('@/views/invoices/components/InvoiceInfoCard.vue'),
    InvoiceSelectTicketCard: () => import('@/views/invoices/components/InvoiceSelectTicketCard.vue'),
    InvoiceDetailCard: () => import('@/views/invoices/components/InvoiceDetailCard.vue'),
    InvoicePaymentCard: () => import('@/views/invoices/components/InvoicePaymentCard.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    ValidationObserver,
  },
  setup() {
    const { toastSuccess } = useToast()
    const { refFormObserver } = formValidation()
    const isCompanyActive = computed(() => store.getters['userStore/getAgencyCompany']?.id)

    const {
      createInvoice, SALE_REPORT_STORE, loading, isInvoiceRequest,
    } = useInvoiceCreateHandle()
    const { INVOICE_STORE, GOODS_SERVICE_TYPES, PAYMENT_METHOD_DEFAULT } = useInvoiceHandle()
    // Register module
    if (!store.hasModule(SALE_REPORT_STORE)) store.registerModule(SALE_REPORT_STORE, saleReportStoreModule)
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
      if (store.hasModule(SALE_REPORT_STORE)) store.unregisterModule(SALE_REPORT_STORE)
    })

    const blankInvoice = {
      itemType: GOODS_SERVICE_TYPES.GOODS_SERVICE,
      templateNumber: '', // lấy theo template
      symbol: '', // lấy theo template
      buyerTaxCode: '',
      buyerUnitCode: null,
      buyerUnitName: '',
      buyerName: '',
      buyerAddress: '',
      buyerEmail: '',
      buyerPhoneNumber: '',
      paymentMethod: PAYMENT_METHOD_DEFAULT,
      bankAccountNumber: null,
      bankName: null,
      discountType: null, // lấy theo template
      taxRateType: null, // lấy theo template
      valueAddedTax: null, // lấy theo template
      totalDiscountAmount: 0,
      invoiceItems: [],
      createdAt: new Date(),
      template: null, // lấy cả object, khi create lấy id
      customerType: null,
      note: null,
      tags: [],
      collectionFee: 0, // phí thu hộ
      agencyReceive: null, // đại lý nợ/nhận/bên mua
      agencyIssue: null, // đại lý có/xuất/bên bán
      invoiceType: null, // loại hoá đơn
      company: null,
      invoiceStatus: 'NEW',
    }

    const invoiceToCreate = ref()
    const initInvoiceToCreate = () => {
      invoiceToCreate.value = cloneDeep(blankInvoice)
      store.dispatch('app-invoice/clearSelectedTickets')
    }
    initInvoiceToCreate()

    const selectedTickets = computed(() => store.getters['app-invoice/getSelectedTickets'])
    const getCollectionFee = computed(() => store.getters['app-invoice/getCollectionFee'])
    const getNeedToPayAmount = computed(() => store.getters['app-invoice/getNeedToPayAmount'])
    const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])

    const isInvalidInvoiceItems = ref(false)
    watch(selectedTickets, (newValue, oldValue) => {
      if (selectedTickets.value.length && isInvalidInvoiceItems.value) {
        isInvalidInvoiceItems.value = false
      }
      if (!newValue.length && oldValue.length) {
        store.dispatch('app-invoice/clearSelectedTickets')
      }
    })

    async function createInvoiceHandle() {
      if (!selectedTickets.value.length) {
        isInvalidInvoiceItems.value = true
      }
      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidInvoiceItems.value) return
      const payload = {
        ...invoiceToCreate.value,
        invoiceItems: selectedTickets.value.map(item => ({
          itemType: item.itemType,
          name: `${item.name}`,
          ticketNumber: `${item.ticketNumber ?? ''}`,
          unit: item.unit,
          quantity: item.quantity ?? 0,
          unitPrice: numberToFixed(item.unitPrice ?? 0),
          valueAddedTax: item.valueAddedTax,
          discountRate: 0,
        })),
        templateId: invoiceToCreate.value?.template?.id,
        companyId: invoiceToCreate.value?.company?.id,
        agencyIssueId: invoiceToCreate.value?.agencyIssue?.id,
        agencyReceiveId: invoiceToCreate.value?.agencyReceive?.id,
        collectionFee: getCollectionFee.value,
        totalAmountAdditional: getNeedToPayAmount.value ?? 0,
        // xoá khỏi payload
        template: undefined, // xoá template khỏi payload
        company: undefined, // xoá company khỏi payload
        createdAt: undefined,
        invoiceType: undefined,
        agencyIssue: undefined,
        agencyReceive: undefined,
        invoiceStatus: undefined,
        createdType: isInvoiceRequest.value ? CREATE_TYPE.REQUEST : undefined,
      }
      createInvoice(payload)
    }

    const collapseSelectedTicket = ref(false)

    function exitHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmCancelInput'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            router.push({ name: 'apps-invoiceList' })
          }
        })
    }

    function superQuickInputInfoHandle() {
      if (process.env.VUE_APP_ENV !== 'development') return
      invoiceToCreate.value.templateNumber = '1'
      invoiceToCreate.value.symbol = 'C24TBB'
      invoiceToCreate.value.buyerTaxCode = '0317618074'
      invoiceToCreate.value.buyerUnitCode = null
      invoiceToCreate.value.buyerUnitName = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ AIRDATA'
      invoiceToCreate.value.buyerName = `${fakerVI.person.lastName()} ${fakerVI.person.firstName()}`
      invoiceToCreate.value.buyerAddress = 'Số 77 Thoại Ngọc Hầu, Phường Hoà Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh, Việt Nam'
      invoiceToCreate.value.buyerEmail = 'lethaiduc11@gmail.com'
      invoiceToCreate.value.discountType = 'BY_TOTAL_INVOICE'
      invoiceToCreate.value.taxRateType = 'MULTIPLE'
      invoiceToCreate.value.customerType = 'KHACH_LE'
      invoiceToCreate.value.tags = [fakerVI.person.zodiacSign()]
      invoiceToCreate.value.invoiceType = 'TYPE_1'
      invoiceToCreate.value.company = {
        id: getAgencyData.value?.company?.id,
        externalId: getAgencyData.value?.company?.externalId,
        name: getAgencyData.value?.company?.name,
      }
      invoiceToCreate.value.template = {
        id: 'd4681d34-c557-4beb-b1fc-cba1dc0c5d0c',
        companyId: 231,
        name: 'ATS_nTS_0CK',
      }
      invoiceToCreate.value.agencyIssue = null
      toastSuccess('Nhập thông tin siêu nhanh')
    }

    const resetInvoice = ref({})

    const updateInvoiceByField = debounce(({ key, value }) => {
      invoiceToCreate.value[key] = value
    }, 300)

    return {
      invoiceToCreate,
      createInvoiceHandle,
      loading,
      collapseSelectedTicket,
      refFormObserver,
      exitHandle,
      superQuickInputInfoHandle,
      resetInvoice,
      isInvalidInvoiceItems,
      isCompanyActive,
      updateInvoiceByField,
      isInvoiceRequest,
    }
  },
}
</script>
<style lang="scss" scoped>
#invoice-create ::v-deep {
  .form-group {
    margin-bottom: 0.25rem;
    label,
    legend {
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 1rem;
      // white-space: nowrap;
    }
  }
}
</style>
