import useJwtCms from '@/api/auth/jwt/useJwtCms'
import useJwtNoti from '@/api/auth/jwt/useJwtNoti'
import useJwtUser from '@/api/auth/jwt/useJwt'
// import useJwtProm from '@/api/auth/jwt/useJwtProm'
import useJwtPublic from '@/api/auth/jwt/useJwtPublic'

export default useJwtCms
export {
  useJwtNoti,
  useJwtUser,
  // useJwtProm,
  useJwtPublic,
}
