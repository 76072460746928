<template>
  <div>
    <!-- SECTION modal-cancel-flight -->
    <b-modal
      id="modal-cancel-flight"
      :title="(actionCancelTrip === 'REFUND') && airlines.some(a => ['VJ','VZ'].includes(a))
        ? $t('reservation.refundTicketVJ')
        : (actionCancelTrip === 'REFUND')
          ? $t('reservation.refundTicket')
          : $t('reservation.cancelFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      footer-class="px-50 m-0"
      centered
      no-close-on-backdrop
      size="lg"
      @show="showHandle"
    >
      <div class="text-heading-5">
        {{ (actionCancelTrip === 'REFUND') ? $t('reservation.selectItineraryToRefund') : $t('reservation.selectItineraryToCancel') }}
      </div>

      <b-card
        class="mt-1"
        body-class="p-75"
      >
        <b-form-group :disabled="['VN1A', 'VN1A_MT'].includes(getBookingData.source)">
          <template #label>
            <!-- v-if="(getBookingData.itineraries.length > 1 && !airlines.includes('QH')) && !['VJ'].includes(getBookingData.source)" -->
            <!-- ANCHOR - Check All -->
            <b-form-checkbox
              v-if="!['VJ'].includes(getBookingData.source) && getBookingData.itineraries.length > 1"
              v-model="allSelected"
              :disabled="(['VJ', 'VU'].includes(getBookingData.source) && actionCancelTrip === 'REFUND')
                || ['1A', '1B', 'VN1A', 'VN1A_MT'].includes(getBookingData.source)
                || (!['QH'].includes(getBookingData.source) && !isEmpty(segmentIdToDisabled))"
              aria-describedby="flights"
              aria-controls="flights"
              class="custom-checkbox-label mb-1"
              @change="toggleAll"
            >
              <div class="text-body-2">
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>
          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-cancel-flight"
              v-model="indexSelectedFlightToCancel"
              :aria-describedby="ariaDescribedby"
              stacked
              name="flights"
            >
              <div
                v-for="(itinerary, indexItinerary) in (['1G'].includes(getBookingData.source)
                  ? addArunksToItineraries(getBookingData, true)
                  : getBookingData.itineraries)"
                :key="indexItinerary"
                class="d-flex"
              >
                <b-form-checkbox
                  :value="indexItinerary"
                  :disabled="['VN1A_MT', 'VN1A'].includes(getBookingData.source)
                    ? false
                    : (
                      ['1A', '1B', 'TR', 'JQ'].includes(getBookingData.source)
                      || disableSegmentCheckbox(actionCancelTrip, segmentIdToDisabled, itinerary)
                      || disabledByFareRuleRefund(actionCancelTrip, itinerary)
                    )"
                  class="custom-checkbox-label mb-50"
                >
                  <div v-if="isArray(itinerary)">
                    <div
                      v-for="(segment) in itinerary"
                      :key="segment.segmentId"
                      class="d-flex align-items-center"
                    >
                      <span class="text-dark font-weight-bolder"> {{ convertShortTrip(segment) }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <span class="text-dark font-weight-bolder"> {{ convertShortTrip(itinerary) }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <b-card
        v-if="['VJ'].includes(getBookingData.source) && actionCancelTrip === 'REFUND'"
        class="font-weight-bolder mt-1"
      >
        <div>
          <BBadge
            pill
            variant="light"
            class="font-medium-3 text-danger px-1 py-50 border-danger mb-25"
          >
            ! Lưu ý quan trọng:
          </BBadge>
          <div>
            - Phí treo sẽ tự động cấn trừ vào tiền hoàn bảo lưu.
          </div>
          <div class="text-danger">
            - Đại lý vui lòng xử lý phí treo (nếu có) trước khi làm hoàn để tối đa hóa số tiền được bảo lưu định danh.
          </div>
          <div>
            - Xóa "em bé đi kèm" (nếu cần) trước khi thao tác Hoàn bảo lưu để tối ưu hóa số tiền được hoàn (nếu cần đăng chợ định danh).
          </div>
          <div>
            - Danh sách code hoàn bảo lưu ở mục: Công nợ -> Hoàn định danh VJ
          </div>
        </div>
        <div class="mt-1">
          <div class="font-medium-3 text-warning mb-25">
            Điều kiện hoàn định danh Vietjet Air:
          </div>
          <div>
            - Hành trình đã xuất vé.
          </div>
          <div>
            - Eco, Deluxe: trước thời gian khởi hành 24 tiếng.
          </div>
          <div>
            - Skyboss, Skyboss Business: trước thời gian khởi hành 3 tiếng (qua giờ bay gọi tổng đài hoàn vé trong vòng 72 tiếng so với giờ bay khởi hành trên vé).
          </div>
        </div>
      </b-card>

      <div v-if="['VJ'].includes(getBookingData.source) && actionCancelTrip === 'CANCEL'">
        <!-- Cảnh báo F3 ko cho huỷ VJ (https://discord.com/channels/1054696448110903327/1237365726356963339/1237685464735944786) -->
        <BAlert
          v-if="isDisableCancelForF3VJ"
          show
          variant="danger"
          class="px-1 py-50"
        >
          Tài khoản đại lý của bạn không có quyền huỷ VJ, vui lòng liên hệ booker.
        </BAlert>
        <b-card class="text-warning font-weight-bolder mt-2">
          Điều kiện huỷ hành trình Vietjet Air:
          <div>
            - Các hành trình chưa xuất vé
          </div>
        </b-card>
      </div>

      <b-card
        v-if="getBookingData.source === 'VU' && actionCancelTrip === 'REFUND'"
        class="text-warning font-weight-bolder mt-2"
      >
        Điều kiện hoàn Vietravel Airlines:
        <div>
          - Hành trình đã xuất vé
        </div>
        <div>
          - Vé được hoàn: Trước thời gian khởi hành 3 tiếng
        </div>
      </b-card>

      <b-card
        v-if="getBookingData.source === 'VU' && actionCancelTrip === 'CANCEL'"
        class="text-warning font-weight-bolder mt-2"
      >
        Điều kiện huỷ hành trình Vietravel Airlines:
        <div>
          - Hành trình chưa xuất vé
        </div>
        <!-- <div>
          - Vé được hoàn: Trước thời gian khởi hành 3 tiếng.
        </div> -->
      </b-card>

      <!-- <div v-else>
        <span class="font-weight-bolder text-warning">
          Không có hành trình đủ điều kiện hoàn vé
        </span>
      </div> -->

      <BAlert
        v-if="actionCancelTrip === 'CANCEL'"
        show
        variant="danger"
        class="px-2 py-1 mt-50"
      >
        Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có)
      </BAlert>

      <div v-if="errorCancel">
        <div class="text-danger text-heading-4">
          {{ $t('reservation.error') }}
        </div>
        <div>{{ errorCancel }}</div>
      </div>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="center rounded-pill px-1"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="border-0 px-1"
          :variant="actionCancelTrip === 'REFUND' ? 'outline-warning' : 'outline-danger'"
          :disabled="!indexSelectedFlightToCancel.length"
          pill
          @click="submitAndCheckPriceHandle"
        >
          <span v-if="actionCancelTrip === 'REFUND' && airlines.some(a => ['VJ', 'VZ'].includes(a))">
            Tính tiền hoàn định danh
          </span>

          <span v-else>
            Tiếp tục
            <!-- {{ actionCancelTrip === 'REFUND' ? $t('reservation.refundTicket') : $t('reservation.cancelFlight') }} -->
          </span>
        </b-button>
      </template>
    </b-modal>
    <!-- !SECTION -->

    <!-- SECTION modal-cancel-flight-price -->
    <b-modal
      id="modal-cancel-flight-price"
      :title="$t('reservation.confirm')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      :no-close-on-backdrop="true"
      @hide="hidePriceModalHandle"
      @show="showPriceHandle"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class=""
          :variant="actionCancelTrip === 'REFUND' ? 'warning' : 'danger'"
          :disabled="disabledSummitModalCancelFlightPrice()"
          pill
          @click="submitCancelFlightHandle"
        >
          <span>{{ $t('reservation.confirm') }}</span>
        </b-button>
      </template>

      <div class="text-heading-5 mb-1">
        {{ (actionCancelTrip === 'REFUND') ? $t('reservation.confirmRefundFlight') : $t('reservation.confirmCancelFlight') }}
      </div>

      <div
        v-for="(itineraryIndex, index) in indexSelectedFlightToCancelComputed"
        :key="index"
        class="border rounded p-75 mb-1"
      >
        <div v-if="getBookingData.source.includes('1S')">
          <span class="text-dark font-weight-bolder pl-md-1">{{ convertShortTrip(addArunksToItineraries(getBookingData, true)[itineraryIndex]) }}</span>
        </div>
        <div v-else>
          <div
            v-for="(segment, indexSegment) in getBookingData.itineraries[itineraryIndex]"
            :key="indexSegment"
            class=""
          >
            <span class="text-dark font-weight-bolder pl-md-1">{{ convertShortTrip(segment) }}</span>
          </div>
        </div>
      </div>

      <div class="my-2">
        <div v-if="priceCancel && (getBookingData.source !== 'VU')">
          <b-row
            v-if="getBookingData.source === 'QH' && priceCancel.cancelFee"
            class="font-weight-bolder"
          >
            <b-col class="text-right">
              Phí hoàn vé:
            </b-col>
            <b-col
              md="4"
              cols="6"
              class="text-right text-warning"
            >
              {{ formatCurrency(priceCancel.cancelFee ) }} {{ priceCancel.currency }}
            </b-col>
          </b-row>

          <b-row class="font-weight-bolder">
            <!-- v-if="!['REFUND'].includes(actionCancelTrip)
                && (indexSelectedFlightToCancelComputed.length > 1)
                && ['VJ'].includes(getBookingData.source)"
            -->
            <template v-if="!['REFUND'].includes(actionCancelTrip) && ['VJ'].includes(getBookingData.source)">
              <b-col
                cols="12"
                class="text-danger fw-700"
              >
                <p class="mb-50">
                  Lưu ý:
                </p>
                <p>Hủy chuyến bay sẽ có phí treo, AG lưu ý không sử dụng lại code đã hủy. Hoặc gọi tổng đài để gỡ phí treo trước khi thao tác lại trên code.</p>
              </b-col>
            </template>

            <template v-else>
              <b-col class="text-right">
                <span class="font-medium-1">{{ (['REFUND'].includes(actionCancelTrip))
                  ? $t('reservation.refundFlightAmount')
                  : $t('reservation.chargeAmount') }}:
                </span>
              </b-col>
              <b-col
                cols="6"
                md="4"
                class="text-right text-warning font-medium-2"
              >
                {{ formatCurrency(priceCancel.total ) }} {{ priceCancel.currency }}
              </b-col>
            </template>
          </b-row>
        </div>

        <!-- ANCHOR - HOÀN VÉ VU -->
        <div v-else-if="priceCancel && (getBookingData.source === 'VU')">
          <p class="d-flex justify-content-between">
            <span class="text-dark">
              {{ $t('reservation.refundFee') }}:
            </span>
            <span class="font-medium-3 font-weight-bolder">
              {{ formatCurrency(priceCancel.chargeAmount.total) }}
            </span>
          </p>
          <p class="d-flex justify-content-between">
            <span class="text-warning">
              <!-- Số tiền nhận lại sau hoàn vé: -->
              {{ $t('reservation.refundFlightAmount') }}:
            </span>
            <span class="font-medium-3 text-warning font-weight-bolder">
              {{ formatCurrency(priceCancel.refundAmount.total) }}
            </span>
          </p>
        </div>

        <div v-else-if="errorCancel">
          <div class="text-danger text-heading-4">
            {{ $t('reservation.error') }}
          </div>
          <div>{{ errorCancel }}</div>
        </div>

        <div
          v-else-if="priceCancel === false"
          class="text-center"
        >
          <!-- {{ $t('reservation.loading') }}... -->
          <p class="mb-50 text-info font-italic">
            Đang tính giá, vui lòng đợi...
          </p>

          <b-spinner variant="info" />
        </div>

        <!-- ANCHOR: Check hành lý VJ -->
        <b-row
          v-if="['VJ'].includes(getBookingData.source) && ['REFUND'].includes(actionCancelTrip)"
          class="text-warning font-weight-bold mx-0 mt-2 flex-column"
          :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
        >
          <p class="mb-50">
            Lưu ý:
          </p>
          <p>Vui lòng kiểm tra lại hành lý ký gửi sau khi đổi.</p>
        </b-row>
      </div>

      <!-- ANCHOR: Alert phí huỷ VJ => liên hệ TỔNG ĐÀI -->
      <div v-if="['VJ'].includes(getBookingData.source) && getBookingData.status === 'HOLD' && priceCancel && priceCancel.total">
        <BAlert
          show
          variant="danger"
          class="mt-1 p-1"
        >
          {{ $t('reservation.removeCancelFee') }}
        </BAlert>
      </div>

      <BAlert
        v-if="actionCancelTrip === 'CANCEL'"
        show
        variant="danger"
        class="px-2 py-1 mt-50"
      >
        Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có)
      </BAlert>

      <IAmConfirm
        class="mt-1"
        :is-confirm.sync="isConfirm"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BButton,
  BAlert,
  BCard,
  BRow,
  BCol,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import store from '@/store'
import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import { getUserData } from '@/api/auth/utils'
import IAmConfirm from '@/components/IAmConfirm.vue'
import { resolveTitle } from '@/constants/selectOptions'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    IAmConfirm,
    BAlert,
    BSpinner,
    BCard,
    BBadge,
  },
  props: {
    actionCancelTrip: {
      type: String,
      required: true,
    },
    isCheckPrice: {
      type: Boolean,
      default: () => false,
    },
    segmentIdToDisabled: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    // NOTE ========================

    // VN(HOLD) => HUỶ VÉ => HUỶ VÉ
    // VN(PAID) => HUỶ VÉ => HUỶ VÉ

    // QH(HOLD) => HUỶ VÉ => HUỶ VÉ
    // QH(PAID) => HOÀN VÉ => TÍNH GIÁ => HOÀN VÉ

    // VJ(HOLD) => HUỶ VÉ => TÍNH GIÁ => HUỶ VÉ
    // VJ(PAID) => HOÀN ĐỊNH DANH => TÍNH GIÁ => HOÀN ĐỊNH DANH

    // VU(HOLD) => HUỶ VÉ => HUỶ VÉ
    // VU(PAID) => HOÀN VÉ => TÍNH GIÁ => HOÀN VÉ

    // ===========================

    const toast = useToast()
    const priceCancel = ref()
    const errorCancel = ref()
    const indexSelectedFlightToCancel = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const indexSelectedFlightToCancelComputed = computed(() => indexSelectedFlightToCancel.value.sort())
    const {
      getBookingData,
      cancelFlight,
      cancelFlightPrice,
      addArunksToItineraries,
    } = useReservationHandle()
    const airlines = computed(() => getBookingData.value.airlines?.split(' | ') || [getBookingData.value.source])
    const isConfirm = ref(false)
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const isDisableCancelForF3VJ = computed(() => isRoleF3.value && getBookingData.value.source === 'VJ')

    const currentTimeObj = new Date(new Date().toISOString())

    function disabledByFareRuleRefund(action, trips) {
      if (!isEmpty(trips) && (action === 'REFUND')) {
        if (['VJ', 'VZ'].includes(trips[0].airline)) {
          const departureTimeObj = new Date(convertISODateTime(trips[0].departure.at, trips[0].departure.timezone).ISOdatetime)
          const checkTime = (trips[0].groupClass.toUpperCase().includes('ECO') || trips[0].groupClass.toUpperCase().includes('DELUXE'))
            ? (departureTimeObj.getTime() - currentTimeObj.getTime() < (24 * 60 * 60 * 1000)) // trước 24 tiếng
            : (departureTimeObj.getTime() - currentTimeObj.getTime() < (3 * 60 * 60 * 1000)) // trước 3 tiếng
          return checkTime
        }

        if (trips[0].airline === 'VU') {
          if (['A'].includes(trips[0].bookingClass.toUpperCase())) {
            return true
          }
          const departureTimeObj = new Date(convertISODateTime(trips[0].departure.at, trips[0].departure.timezone).ISOdatetime)
          const checkTime = (departureTimeObj.getTime() - currentTimeObj.getTime() < (3 * 60 * 60 * 1000)) // 3 tiếng
          return checkTime
        }
      }

      return false
    }

    function disableSegmentCheckbox(action, idSegmentDisableArr, trips) {
      if (!isEmpty(idSegmentDisableArr)) {
        if (action === 'REFUND') {
          // return trips.some(item => idSegmentDisableArr.includes(item.segmentId))
          return false
        }

        if (action === 'CANCEL') {
          if (isDisableCancelForF3VJ.value) return true
          return trips.some(item => !idSegmentDisableArr.includes(item.segmentId))
        }
      }

      return false
    }

    function showPriceHandle() {
      errorCancel.value = undefined
      priceCancel.value = undefined
      isConfirm.value = false

      if (airlines.value[0] === '1S') { // FIXME
        priceCancel.value = true
      }

      const payload = {
        contact: getUserData().employeeData.id,
        recalculatePrice: true,
        airline: airlines.value[0],
        source: getBookingData.value.source,
        agencyCode: getBookingData.value.agency,
        paxCode: getBookingData.value.paxContact.code,
        pnrNumber: getBookingData.value.bookingCode || getBookingData.value.pnr,
        paxList: getBookingData.value.paxLists.map(item => ({
          firstName: item.firstName,
          lastName: item.lastName,
          title: item.title || resolveTitle(item.firstName.split(' ')[item.firstName.split(' ').length - 1]),
          // birthday: item.birthday,
          paxType: item.paxType,
          paxId: item.paxId,
          PaxDocuments: item.document,
        })),
        itineraries: indexSelectedFlightToCancelComputed.value
          .map(index => {
            const trip = getBookingData.value.itineraries[Number(index)]
            return {
              source: getBookingData.value.source,
              clientId: '',
              bookingKey: '',
              itineraryId: trip[0].itineraryId,
              fareBreakdowns: [
                {
                  paxType: 'ADULT',
                  netFare: 0,
                  tax: 0,
                  total: 0,
                  fareValue: '',
                },
              ],
              segments: trip.map(segment => ({
                leg: segment.leg,
                airline: segment.airline,
                departure: segment.departure.iataCode,
                arrival: segment.arrival.iataCode,
                departureTime: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
                arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
                flightNumber: segment.flightNumber,
                fareType: '',
                fareBasisCode: '',
                bookingClass: segment.bookingClass,
                groupClass: segment.groupClass || '', // FIXME: QH không trả về groupClass ở segment, trip thứ 2
                marriageGrp: '',
                segmentValue: segment?.segmentValue || '',
              })),
            }
          }),
      }

      // Call API tính giá
      if ((['QH'].includes(payload.airline) && props.isCheckPrice)
      || (['VJ', 'VZ'].includes(payload.airline) && (['HOLD', 'PAID', 'PARTIALLY_PAID'].includes(getBookingData.value.status.toUpperCase())))
      || (['VU'].includes(payload.airline) && (['PAID', 'PARTIALLY_PAID'].includes(getBookingData.value.status.toUpperCase())))
      ) {
        priceCancel.value = false
        errorCancel.value = null

        cancelFlightPrice(payload)
          .then(res => {
            // eslint-disable-next-line no-nested-ternary
            priceCancel.value = ['QH'].includes(payload.airline) ? { ...res.refundAmount } : ['VU'].includes(payload.airline) ? res : { ...res.chargeAmount }
          })
          .catch(error => {
            errorCancel.value = error.response.data.message || error
          })
      }
    }

    function hidePriceModalHandle() {
      errorCancel.value = undefined
      priceCancel.value = undefined
    }

    function submitAndCheckPriceHandle(bvModalEvent) {
      bvModalEvent.preventDefault()
      // if ((airlines.value[0] === 'VJ') && (indexSelectedFlightToCancel.value.length > 1)) {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Vui lòng chọn lại!',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'warning',
      //       text: 'Mỗi lần thao tác chỉ hỗ trợ thực hiện tối đa 1 chuyến!',
      //     },
      //   })
      // } else {
      //   this.$bvModal.show('modal-cancel-flight-price')
      // }

      this.$bvModal.show('modal-cancel-flight-price')
    }

    function submitCancelFlightHandle(bvModalEvent) {
      bvModalEvent.preventDefault()

      let recalculatePrice = true

      if (airlines.value[0] === 'VN') {
        const selectTripLength = indexSelectedFlightToCancelComputed.value.length
        const reservationTripLength = getBookingData.value.itineraries.length
        const hasPaymentSsrType = getBookingData.value.ssr.some(item => item.ssrType === 'TKNE')

        if (reservationTripLength === selectTripLength) {
          recalculatePrice = false
        }
        if (hasPaymentSsrType) { // FIXME
          recalculatePrice = false
        }
      }

      this.$bvModal.show('modal-api-loading')

      const payload = {
        contact: getUserData().employeeData.id,
        recalculatePrice,
        airline: airlines.value[0],
        source: getBookingData.value.source,
        agencyCode: getBookingData.value.agency,
        paxCode: getBookingData.value.paxContact.code,
        pnrNumber: getBookingData.value.bookingCode,
        paxList: getBookingData.value.paxLists.map(item => ({
          firstName: item.firstName,
          lastName: item.lastName,
          title: (['VJ', 'VZ'].includes(airlines.value[0]) && item.title === 'MASTER') ? 'MSTR' : item.title ? item.title : resolveTitle(item.firstName.split(' ')[item.firstName.split(' ').length - 1]),
          paxType: item.paxType || 'ADULT', // FIXME fix "ADULT"
          paxId: item.paxId,
          PaxDocuments: item.document,
        })),
        itineraries: indexSelectedFlightToCancelComputed.value
          .map(item => {
            if (getBookingData.value.source.includes('1S')) {
              const trip = addArunksToItineraries(getBookingData.value, true)[Number(item)]

              return {
                source: getBookingData.value.source,
                clientId: '',
                bookingKey: '',
                fareBreakdowns: [
                  {
                    paxType: 'ADULT',
                    netFare: 0,
                    tax: 0,
                    total: 0,
                    fareValue: '',
                  },
                ],
                segments: [{
                  leg: trip?.leg || 1,
                  airline: trip?.airline || 'VN',
                  departure: trip?.departure?.iataCode || 'SGN',
                  arrival: trip?.arrival?.iataCode || 'HAN',
                  departureTime: convertISODateTime(trip?.departure?.at, trip?.departure?.timeZone)?.ISOdatetime || '2023-08-06T07:30:00+07:00',
                  arrivalTime: convertISODateTime(trip?.arrival?.at, trip?.arrival?.timeZone)?.ISOdatetime || '2023-08-06T08:50:00+07:00',
                  flightNumber: trip?.flightNumber || '',
                  fareType: '',
                  fareBasisCode: '',
                  bookingClass: trip?.bookingClass || 'A',
                  groupClass: trip?.groupClass || '',
                  marriageGrp: '',
                  segmentValue: trip?.segmentId || '',
                }],
              }
            }
            const trip = getBookingData.value.itineraries[Number(item)]
            return {
              source: getBookingData.value.source,
              clientId: '',
              bookingKey: '',
              ...(['VJ', 'VZ', 'VU'].includes(trip[0].airline) && { itineraryId: trip[0].itineraryId }),
              fareBreakdowns: [
                {
                  paxType: 'ADULT',
                  netFare: 0,
                  tax: 0,
                  total: 0,
                  fareValue: '',
                },
              ],
              segments: trip.map(segment => ({
                leg: segment.leg,
                ...(['VN1A_MT', 'VN1A', 'VJ'].includes(getBookingData.value.source) && { segmentId: segment.segmentId }),
                airline: segment.airline,
                departure: segment.departure.iataCode,
                arrival: segment.arrival.iataCode,
                departureTime: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
                arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
                flightNumber: segment.flightNumber,
                fareType: '',
                fareBasisCode: '',
                bookingClass: segment?.bookingClass || '',
                groupClass: segment?.groupClass || '',
                marriageGrp: '',
                segmentValue: segment?.segmentValue || '',
              })),
            }
          }),
      }

      cancelFlight(payload)
        .then(() => {
          this.$bvModal.hide('modal-cancel-flight')
          this.$bvModal.hide('modal-cancel-flight-price')
          toast({
            component: ToastificationContent,
            props: {
              // title: this.$t('reservation.cancelFlightSuccessful'),
              title: `${props.actionCancelTrip === 'REFUND' ? 'Hoàn hành trình thành công' : 'Huỷ hành trình thành công'}`,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          errorCancel.value = error.response.data.message || error
          toast({
            component: ToastificationContent,
            props: {
              // title: this.$t('reservation.cancelFlightError'),
              title: 'Thao tác thất bại, vui lòng kiểm tra lại thông tin vé',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      // eslint-disable-next-line no-nested-ternary
      indexSelectedFlightToCancel.value = checked
        ? getBookingData.value.source.includes('1S') ? addArunksToItineraries(getBookingData.value, true).map((item, index) => index).slice() : getBookingData.value.itineraries.map((item, index) => index).slice()
        : []
    }

    watch(indexSelectedFlightToCancel, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === (getBookingData.value.source.includes('1S') ? addArunksToItineraries(getBookingData.value, true).map((item, index) => index).slice() : getBookingData.value.itineraries.map((item, index) => index).slice()).length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    function showHandle() {
      errorCancel.value = undefined
      priceCancel.value = undefined

      if (['1A', '1B', 'QH', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(getBookingData.value.source)
      //  || (getBookingData.value.source === 'VJ' && isRoleF2.value) // F2 VJ tự động check các trip có thể hoàn/huỷ
      ) {
        allSelected.value = true

        if ((['VJ'].includes(getBookingData.value.source) && isRoleF2.value)) {
          const segmentIdToDisabled = props.segmentIdToDisabled
          if (['REFUND'].includes(props.actionCancelTrip)) {
            indexSelectedFlightToCancel.value = getBookingData.value.itineraries.map((item, index) => {
              if (!item.some(seg => segmentIdToDisabled.includes(seg.segmentId))) {
                return index
              }
              return null
            }).filter(item => item !== null)
          } else {
            indexSelectedFlightToCancel.value = getBookingData.value.itineraries.map((item, index) => {
              if (item.some(seg => segmentIdToDisabled.includes(seg.segmentId))) {
                return index
              }
              return null
            }).filter(item => item !== null)
          }
        } else {
          indexSelectedFlightToCancel.value = getBookingData.value.itineraries.map((item, index) => index)
        }
      } else {
        allSelected.value = false
        indexSelectedFlightToCancel.value = []
      }
    }

    function disabledSummitModalCancelFlightPrice() {
      if (['VN1A_MT', 'VN1A', '1A', '1B', 'QH', 'VU', 'TR', 'JQ'].includes(getBookingData.value.source) && (props.actionCancelTrip !== 'REFUND')) {
        if (!isConfirm.value) {
          return true
        }
      } else if (!priceCancel.value || !isConfirm.value) {
        return true
      }

      return false
    }

    return {
      getBookingData,
      showHandle,
      showPriceHandle,

      convertShortTrip,

      priceCancel,
      errorCancel,
      hidePriceModalHandle,
      formatCurrency,
      submitCancelFlightHandle,
      submitAndCheckPriceHandle,
      indexSelectedFlightToCancel,
      indexSelectedFlightToCancelComputed,
      disabledSummitModalCancelFlightPrice,
      convertISODateTime,
      allSelected,
      toggleAll,
      airlines,
      isEmpty,
      isConfirm,
      isArray,
      disableSegmentCheckbox,
      addArunksToItineraries,
      disabledByFareRuleRefund,
      // resolveSegmentToRefund,
      isDisableCancelForF3VJ,
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
