<template>
  <b-modal
    :id="`create-topup-modal${place}`"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="forChild ? $t('topup.createTopupForChild') : $t('topup.createTopup')"
    @show="showHandle"
  >
    <IAmOverlay :loading="!loadedImage && !!qrCodeImgUrl">
      <BTabs>
        <!-- SECTION thong tin nap tien -->
        <BTab :title="$t('topup.topupInfo')">
          <div v-if="isEmptyBankAccounts">
            <BAlert
              show
              variant="danger"
              class="px-2 py-1"
            >
              <span class="text-heading-4">{{ $t('topup.parentAgencyNotHaveBankAccount') }}</span>
            </BAlert>
          </div>
          <div v-else>
            <validation-observer ref="refFormObserver">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-for="recipient"
                      :label="$t('topup.recipient')"
                    >
                      <v-select
                        id="recipient"
                        :value="recipient"
                        :getOptionLabel="val => getOptionLabel(val, 'agencyCode')"
                        :options="[]"
                        :clearable="false"
                        disabled
                      >
                        <template #selected-option="data">
                          <div class="d-flex gap-1">
                            <span class="text-uppercase fw-700">{{ data.agencyCode }}</span>
                            <span v-if="data.agencyName">({{ data.agencyName }})</span>
                          </div>
                        </template>
                        <template #option="data">
                          <div class="d-flex gap-1">
                            <span class="text-uppercase fw-700">{{ data.agencyCode }}</span>
                            <span v-if="data.agencyName">({{ data.agencyName }})</span>
                          </div>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('topup.sender')"
                      vid="sender"
                    >
                      <b-form-group :class="{ 'is-invalid': errors[0] }">
                        <template
                          #label
                          for="sender"
                        >
                          {{ $t('topup.sender') }}
                          <span
                            v-if="forChild"
                            class="text-danger"
                          >(*)</span>
                        </template>
                        <v-select
                          id="sender"
                          :value="senderData"
                          :getOptionLabel="val => getOptionLabel(val, 'agencyCode')"
                          :name="$t('topup.sender')"
                          :options="senderOptions"
                          :clearable="false"
                          :disabled="!forChild"
                          :placeholder="$t('topup.placeholderSender')"
                          :loading="loadingChild"
                          @open="getAgencyList"
                          @input="val => handleSenderInput(val)"
                        >
                          <template #selected-option="data">
                            <div class="d-flex gap-1">
                              <span class="text-uppercase fw-700">{{ data.agencyCode }}</span>
                              <span v-if="data.agencyName">({{ data.agencyName }})</span>
                            </div>
                          </template>
                          <template #option="data">
                            <div class="d-flex gap-1">
                              <span class="text-uppercase fw-700">{{ data.agencyCode }}</span>
                              <span v-if="data.agencyName">({{ data.agencyName }})</span>
                              <span
                                v-if="data?.status"
                                class="font-italic"
                              >- {{ $t('status') }}: {{ $t(resolveAgencyStatus(data?.status, 'value', 'label')) }}</span>
                            </div>
                          </template>
                          <template #no-options>
                            {{ $t('noOptions') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :label="$t('agency.bankName')">
                      <v-select
                        id="recipientBank_select"
                        v-model="recipientBank"
                        :getOptionLabel="val => getOptionLabel(val, 'bankAccountNo')"
                        :options="recipientBanksList"
                        :clearable="false"
                        @input="handleBankAccountInput"
                      >
                        <template #selected-option="data">
                          <div class="d-flex-center justify-content-start">
                            <BImg
                              :src="resolveBankById(data?.bankId ?? data?.bankCode)?.bankLogo"
                              width="50"
                            />
                            <div>{{ resolveBankById(data?.bankId ?? data?.bankCode)?.bankName }} - <b>{{ data?.bankAccountNo }}</b> - {{ data?.bankAccountName }}</div>
                          </div>
                        </template>
                        <template #option="data">
                          <div class="d-flex-center justify-content-start">
                            <BImg
                              :src="resolveBankById(data?.bankId ?? data?.bankCode)?.bankLogo"
                              width="50"
                            />
                            <div>{{ resolveBankById(data?.bankId ?? data?.bankCode)?.bankName }} - <b>{{ data?.bankAccountNo }}</b> - {{ data?.bankAccountName }}</div>
                          </div>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('agency.accNumber')">
                      <b-form-input
                        :value="recipientAccNumber"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('agency.accHolder')">
                      <b-form-input
                        :value="recipientBankAccountName"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('agency.branch')">
                      <b-form-input
                        :value="recipientBankBranch"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :rules="`required|${isTopUpF1 ? `min_value:${MIN_AMOUNT_TO_TOPUP}` : ''}`"
                      :name="$t('agency.amountNumber')"
                    >
                      <b-form-group
                        v-if="amountNumber"
                        :label="$t('agency.amountNumber')"
                      >
                        <b-form-input
                          :value="formatCurrency(amountNumber)"
                          readonly
                        />
                      </b-form-group>
                      <b-form-group v-else>
                        <template
                          #label
                          for="paymentAmount"
                        >
                          {{ $t('agency.amountNumber') }}
                          <span class="text-danger">(*)</span>
                        </template>
                        <b-input-group class="input-group-merge flex-nowrap">
                          <b-form-input
                            id="paymentAmount"
                            :name="$t('agency.amountNumber')"
                            :value="paymentAmount"
                            :placeholder="$t('agency.amountNumber')"
                            class="d-none"
                            :state="errors[0] ? false : null"
                          />
                          <IAmInputMoney
                            :value-money.sync="paymentAmount"
                            class="flex-grow-1"
                            :state="errors[0] ? false : null"
                          />
                          <template #append>
                            <b-input-group-text :class="{ 'border-danger': errors[0] }">
                              {{ $t('agency.currency') }}
                            </b-input-group-text>
                          </template>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <div v-if="isTopUpF1">
                          <em class="text-body-3">{{ $t('topup.pleaseTopupMinAmount', { minAmount: formatCurrency(MIN_AMOUNT_TO_TOPUP) }) }}</em>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="d-flex-center w-100">
                    <BAlert
                      v-if="qrCodeImgUrl"
                      show
                      variant="success"
                      class="px-2 py-1"
                    >
                      {{ $t('topup.createQrSuccess') }}
                    </BAlert>
                  </div>
                  <b-img
                    v-if="qrCodeImgUrl"
                    :src="qrCodeImgUrl"
                    fluid
                    center
                    alt="QR Code"
                    style="max-height: 400px"
                    @load="handleLoad"
                  />
                </b-row>
              </b-form>

              <div class="d-flex-center gap-3">
                <b-button
                  variant="secondary"
                  @click="handleCancel"
                >
                  {{ $t('cancel') }}
                </b-button>
                <b-button
                  variant="info"
                  :disabled="!!qrCodeImgUrl || isEmptyBankAccounts"
                  @click="handleGenerateQRcode"
                >
                  {{ $t('agency.generateQRcode') }}
                </b-button>
              </div>
            </validation-observer>
          </div>
        </BTab>
        <!-- !SECTION -->

        <!-- SECTION Lich su nap tien -->
        <BTab
          v-if="topupRecords"
          :title="$t('topup.history')"
        >
          <div v-if="!topupRecords.length">
            {{ $t('topup.noHistoryRecord') }}
          </div>
          <b-table-lite
            v-else
            bordered
            responsive
            :sticky-header="true"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            class="mb-1 rounded"
            thead-class="text-dark font-weight-bolder text-nowrap"
            :items="topupRecords"
            :fields="tableColumns"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column})`]="data"
            >
              <span
                :key="column"
                class="text-dark"
              >
                {{ $t(`agency.topupRecords.${data.column}`) }}
              </span>
            </template>

            <template #cell(createdAt)="{ item }">
              <div>
                {{ convertISODateTime(item.createdAt).dateTime }}
              </div>
            </template>
            <template #cell(confirmedBy)="{ item }">
              <div
                v-if="item.confirmedBy && item.confirmedBy.username"
                class="font-weight-bolder"
              >
                {{ item.confirmedBy.username.toUpperCase() }}
              </div>
            </template>
            <template #cell(balance)="{ item }">
              <div>
                {{ formatCurrency(item.balance) }}
              </div>
            </template>

            <template #cell(paymentAmount)="{ item }">
              <div>
                {{ formatCurrency(item.paymentAmount) }}
              </div>
            </template>

            <template #cell(note)="{ item }">
              <div>
                {{ item.note }}
              </div>
            </template>
          </b-table-lite>
        </BTab>
        <!-- !SECTION -->
      </BTabs>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  computed,
  nextTick,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupText,
  BModal,
  BRow,
  BTab,
  BTableLite,
  BTabs,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import cloneDeep from 'lodash/cloneDeep'

import { apiAgencies } from '@/api'
import IAmInputMoney from '@/components/IAmInputMoney.vue'
import IAmOverlay from '@/components/IAmOverlay.vue'
import {
  MAX_LENGTH,
  MIN_AMOUNT_TO_TOPUP,
  TOPUP_APP_STORE_MODULE_NAME as TOPUP_STORE,
  SENDER_TYPES,
} from '@/constants/topup'
import store from '@/store'
import useBankAccounts from '@/views/agency-setting/useBankAccounts'
import {
  resolveAgencyStatus,
} from '@/constants/selectOptions'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'

import topupStoreModule from '@agencies/topupStoreModule'
import useTopupHandle from '@agencies/useTopupHandle'

import { minValue } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BImg,
    BAlert,
    vSelect,
    IAmOverlay,
    IAmInputMoney,
    BTabs,
    BTab,
    BButton,
    BTableLite,
  },

  props: {
    forChild: {
      type: Boolean,
      default: false,
    },
    sender: {
      type: Object,
      default: () => {},
    },
    recipient: {
      type: Object,
      required: true,
    },
    receivables: {
      type: [Number, String],
      default: '',
    },
    isEmptyBankAccounts: {
      type: Boolean,
      default: false,
    },
    topupRecords: {
      type: [Array, null],
      default: null,
    },
    place: {
      type: String,
      default: '',
    },
  },

  setup(props, { root, emit }) {
    // Register module
    if (!store.hasModule(TOPUP_STORE)) store.registerModule(TOPUP_STORE, topupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOPUP_STORE)) store.unregisterModule(TOPUP_STORE)
    })

    const { refFormObserver } = formValidation()

    const paymentAmount = ref(null)
    const transferContent = ref('')
    const recipientBank = ref({})

    const {
      fetchTopupQrCode,
    } = useTopupHandle()

    const { findBank } = useBankAccounts()

    function resolveBankById(bankId) { // bankId hoac bankCode
      const bankInfo = findBank(bankId)
      return bankInfo ? { bankName: `${bankInfo.shortName} (${bankInfo.code})`, bankLogo: bankInfo.logo } : { bankName: bankId }
    }
    // computed
    const senderAgencyCode = computed(() => props.sender?.agencyCode || '')
    const recipientAgencyName = computed(() => props.recipient?.agencyName || '')
    const recipientBanksList = computed(() => (props.recipient?.bankAccs ?? props.recipient?.bankAccounts)?.filter(item => item) || [])
    const recipientBanksListTotal = computed(() => ((props.recipient?.bankAccs ?? props.recipient?.bankAccounts) ?? [])?.length)
    const recipientAccNumber = computed(() => recipientBank.value?.bankAccountNo)
    const recipientBankAccountName = computed(() => recipientBank.value?.bankAccountName)
    const recipientBankBranch = computed(() => recipientBank.value?.branch)
    const qrCodeImgUrl = computed(() => store.state[TOPUP_STORE]?.topupQrCode || '')
    const amountNumber = computed(() => props?.receivables || '')
    const isTopUpF1 = computed(() => props?.recipient?.id === 1000000)
    const senderData = ref()

    // methods
    function handleGenerateQRcode(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.$bvModal.show('modal-api-loading')
            fetchTopupQrCode({
              senderId: senderData.value?.id,
              senderType: senderData.value?.type,
              recipientId: props.recipient.id,
              paymentAmount: Number(paymentAmount.value) || amountNumber.value,
              bankCode: recipientBank.value.bankCode,
              bankAccountNo: recipientBank.value.bankAccountNo,
              bankAccountName: recipientBank.value.bankAccountName,
            })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function handleBankAccountInput() {
      if (qrCodeImgUrl.value) {
        store.dispatch(`${TOPUP_STORE}/removeTopupQrCode`)
      }
    }

    watch(recipientBanksListTotal, newVal => {
      if (newVal) {
        recipientBank.value = recipientBanksList.value?.find(item => item?.isDefault)
      }
    }, { immediate: true })

    watch(paymentAmount, () => {
      handleBankAccountInput()
    }, { immediate: true })

    const loadedImage = ref(false)
    function handleLoad() {
      loadedImage.value = true
    }

    function handleCancel() {
      Array.from(window.document.querySelectorAll('.modal.show'))
        .map(el => el.id)
        .forEach(id => {
          root.$root.$emit('bv::hide::modal', id)
        })
    }

    const tableColumns = ['createdAt', 'confirmedBy', 'balance', 'paymentAmount', 'note']

    // for child
    const senderOptions = ref([])
    function handleSenderInput(agency) {
      if (qrCodeImgUrl.value) {
        store.dispatch(`${TOPUP_STORE}/removeTopupQrCode`)
      }
      senderData.value = {
        id: agency.id,
        agencyName: agency.agencyName,
        agencyCode: agency.agencyCode,
        type: SENDER_TYPES.AGENCY,
      }
    }

    const loadingChild = ref(false)
    function getAgencyList() {
      if (!senderOptions.value.length) {
        loadingChild.value = true
        apiAgencies
          .getChild(props.recipient.id, { isActive: true })
          .then(response => {
            senderOptions.value = response.data.items.filter(ag => (ag.id !== 1000000 && ag.id !== props.recipient.id))
          })
          .finally(() => {
            loadingChild.value = false
          })
      }
    }

    async function showHandle() {
      if (!store.getters['userStore/hasListBank']) {
        await store.dispatch('userStore/fetchListBank')
      }
      emit('show')
      nextTick(() => {
        senderData.value = props?.sender ? cloneDeep(props.sender) : null
      })
    }

    function getOptionLabel(val, field) {
      return val?.[field] ?? ''
    }
    return {
      formatCurrency,
      showHandle,
      // constant
      MAX_LENGTH,
      loadedImage,
      handleLoad,
      // data
      paymentAmount,
      transferContent,
      qrCodeImgUrl,
      recipientBank,

      // computed
      senderAgencyCode,
      recipientAgencyName,
      recipientBanksList,
      recipientAccNumber,
      recipientBankAccountName,
      recipientBankBranch,
      amountNumber,

      // methods
      handleGenerateQRcode,
      handleBankAccountInput,

      isEmpty,

      handleCancel,
      tableColumns,
      convertISODateTime,
      resolveBankById,

      MIN_AMOUNT_TO_TOPUP,
      minValue,
      isTopUpF1,
      senderOptions,
      handleSenderInput,
      getAgencyList,
      loadingChild,
      senderData,
      resolveAgencyStatus,
      refFormObserver,
      getOptionLabel,
    }
  },
}
</script>
