<template lang="">
  <IAmOverlay :loading="loading">
    <!-- SECTION Filter -->
    <BCard
      header-class="py-1"
    >
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('reservation.findBookingVJ.headerFilter') }}
        </div>
      </template>
      <BRow>
        <!-- ANCHOR bookingCode -->
        <BCol
          cols="12"
          md="4"
          class="flex-grow-1"
          :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="bookingCode"
              maxlength="255"
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('reservation.code')"
            />
          </b-input-group>
        </BCol>

        <!-- ANCHOR lastname -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
          :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="lastName"
              maxlength="255"
              :formatter="removeAccentsUpperCaseFormatterNotTrim"
              :placeholder="$t('reservation.lastName')"
            />
          </b-input-group>
        </BCol>

        <!-- ANCHOR firstName -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
          :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="firstName"
              maxlength="255"
              :formatter="removeAccentsUpperCaseFormatterNotTrim"
              :placeholder="$t('reservation.firstName')"
            />
          </b-input-group>
        </BCol>

        <!-- ANCHOR departureDateFrom -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
        >
          <b-form-group class="w-100 mb-0">
            <!-- <label class="font-weight-bold">{{ $t('reservation.bookDate') }}</label> -->
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="departureDateFrom"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.findBookingVJ.departureDateFrom')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR departureDateTo -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
        >
          <b-form-group class="w-100 mb-0">
            <!-- <label class="font-weight-bold">{{ $t('reservation.bookDate') }}</label> -->
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="departureDateTo"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.findBookingVJ.departureDateTo')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR departureAirport -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
          :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
        >
          <b-input-group
            class="input-group-merge"
          >
            <v-select
              v-model="departureAirport"
              :filterable="false"
              :options="airportOptions"
              class="w-100"
              label="iata"
              :reduce="opt => opt.iata"
              :placeholder="$t('reservation.findBookingVJ.departureAirport')"
              @search="handleSearchAirport"
            >

              <template #no-options="{ search, searching }">
                <template v-if="isLoadingSearchAirport">
                  <b-spinner
                    variant="primary"
                    label="Text Centered"
                    small
                    tag="span"
                  />
                  <span class="pl-1"> {{ $t('flight.loadingAirport') }} </span>
                </template>
                <template v-else-if="searching">
                  {{ $t('flight.noResultFound') }} <b>{{ search }}</b>
                </template>
                <template v-else>
                  {{ $t('reservation.findBookingVJ.findAirportPleaseSearch') }}
                </template>
              </template>

              <template #selected-option="data">
                <div class="text-truncate">
                  <div class="d-flex-center justify-content-start">
                    <span class="mb-0">{{ data.city || data.name }}</span>
                    <span class="text-warning ml-50">{{ data.iata }}</span>
                  </div>
                </div>
              </template>

              <template #option="data">
                <div>
                  <div class="d-flex justify-content-start text-truncate">
                    <strong>{{ data.city }}</strong>
                    <strong class="text-warning ml-1 text-truncate font-weight-bold">{{ data.iata }}</strong>
                  </div>
                  <div class="d-flex justify-content-between text-truncate">
                    <span class="text-truncate">{{ data.name }}, {{ data.country }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </b-input-group>
        </BCol>

        <!-- ANCHOR createDateFrom -->
        <BCol
          cols="12"
          md="4"
          class="mt-50"
        >
          <b-form-group class="w-100 mb-0">
            <!-- <label class="font-weight-bold">{{ $t('reservation.bookDate') }}</label> -->
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="createDateFrom"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.findBookingVJ.createDateFrom')"
              />
            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR createDateTo -->
        <!-- <BCol
          cols="12"
          md="4"
          class="mt-50"
        >
          <b-form-group class="w-100 mb-0">
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="createDateTo"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.findBookingVJ.createDateTo')"
              />
            </b-input-group>
          </b-form-group>
        </BCol> -->

        <!-- ANCHOR - BUTTONs -->
        <BCol
          cols="12"
          md="8"
          class="mt-50 d-flex-center justify-content-md-end gap-3"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder"
            variant="outline-warning"
            :disabled="isDisabledButtons"
            @click="submitFindBookingVJ"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="SearchIcon"
              class="text-warning"
            />
            <span
              class="align-middle text-warning font-weight-bolder"
            >
              {{ $t('search') }}
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder"
            variant="outline-danger"
            :disabled="isDisabledButtons"
            @click="clearFilter"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="RefreshCwIcon"
              class="text-danger"
            />
            <span
              class="align-middle text-danger font-weight-bolder"
            >
              {{ $t('clear') }}
            </span>
          </b-button>
        </BCol>
      </BRow>
    </BCard>
    <!-- !SECTION -->

    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('reservation.findBookingVJ.headerResult') }}
        </div>
      </template>

      <BTable
        style="max-height: 100%"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        bordered
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
      >
        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.findBookingVJ.${column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>

        <template #cell(bookingCode)="{ item }">
          <div>
            <b-link
              v-if="item.pnr"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
              @click="openBookingCode(item.pnr)"
            >
              <!-- :to="{ name: 'apps-reservations-modify', params: { id: `VJ-${item.pnr}` } }" -->
              {{ item.pnr }}
            </b-link>
          </div>
        </template>

        <template #cell(passenger)="{ item }">
          <div>
            <span>{{ item.bookingOwner.lastName }} {{ item.bookingOwner.firstName }}</span>
          </div>
        </template>

        <template #cell(itineraries)="{ item }">
          <div>
            <div
              v-for="(trip, tripIndex) in item.itineraries"
              :key="tripIndex"
            >
              {{ trip.departure.iataCode }}{{ trip.arrival.iataCode }} {{ convertISODateTime(trip.departure.at, trip.departure.timeZone).date }}
            </div>
          </div>
        </template>

        <template #cell(status)="{ item }">
          <div>
            <div
              v-for="(trip, tripIndex) in item.itineraries"
              :key="tripIndex"
            >
              {{ trip.status }}
            </div>
          </div>
        </template>

        <template #cell(dateCreate)="{ item }">
          <div>
            {{ convertISODateTime(item.dateCreate).dateTime }}
          </div>
        </template>
      </BTable>

    </BCard>
    <!-- !SECTION -->
  </IAmOverlay>
</template>

<script>
import {
  BCard, BButton, BFormInput, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormGroup, BSpinner, BTable, BLink,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'

import { apiReservation, apiBooking } from '@/api'

import { convertISODateTime } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatterNotTrim, removeAccentsUpperCaseFormatter, removeAccents } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BSpinner,
    BTable,
    BLink,
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const { toastError } = useToast()

    // ANCHOR - DEFINE FILTERS
    const bookingCode = ref()
    const lastName = ref()
    const firstName = ref()
    const departureAirport = ref()
    const departureDateFrom = ref()
    const departureDateTo = ref()
    const createDateFrom = ref()
    const createDateTo = ref()

    const resultArray = ref([])

    const airportOptions = ref([])
    const loading = ref(false)

    function clearFilter() {
      bookingCode.value = ''
      lastName.value = ''
      firstName.value = ''
      departureAirport.value = ''
      departureDateFrom.value = ''
      departureDateTo.value = ''
      createDateFrom.value = ''
      createDateTo.value = ''
    }

    function submitFindBookingVJ() {
      loading.value = true
      const payload = {
        source: 'VJ',
        filters: {
          pnr: bookingCode.value || undefined,
          passengerLastName: lastName.value || undefined,
          passengerFirstName: firstName.value || undefined,
          earliestBooking: createDateFrom.value || undefined,
          latestBooking: createDateTo.value || undefined,
          departureAirport: departureAirport.value || undefined,
          earliestDeparture: departureDateFrom.value || undefined,
          latestDeparture: departureDateTo.value || undefined,

        },
      }
      apiReservation.searchReservations(payload)
        .then(res => {
          resultArray.value = res
        })
        .catch(() => {
          toastError('Lỗi tìm kiếm')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const isDisabledButtons = computed(() => !bookingCode.value
      && !lastName.value
      && !firstName.value
      && !departureAirport.value
      && !departureDateFrom.value
      && !departureDateTo.value
      && !createDateFrom.value
      && !createDateTo.value)

    // ANCHOR Result
    const tableColumns = ref(['no', 'bookingCode', 'passenger', 'itineraries', 'status', 'dateCreate'])

    // ANCHOR function for airport search
    const isLoadingSearchAirport = ref(false)
    function fetchAirportHandle(searchText = null) {
      airportOptions.value = []
      isLoadingSearchAirport.value = true

      apiBooking
        .fetchAirports({ searchText })
        .then(response => {
          airportOptions.value = response.data.items
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          isLoadingSearchAirport.value = false
        })
    }

    const handleSearchAirport = debounce(searchTextAirport => {
      if (searchTextAirport) fetchAirportHandle(removeAccents(searchTextAirport))
    }, 300)

    function openBookingCode(pnr) {
      const source = 'VJ'
      const path = `${`${source}-${pnr}`}`
      const route = this.$router.resolve({ path })
      console.log({ route })
      window.open(route.href)
    }

    return {
      // variable filter
      bookingCode,
      lastName,
      firstName,
      departureAirport,
      departureDateFrom,
      departureDateTo,
      createDateFrom,
      createDateTo,

      airportOptions,

      loading,
      isDisabledButtons,
      isLoadingSearchAirport,

      // result
      resultArray,
      tableColumns,

      // methods
      submitFindBookingVJ,
      clearFilter,
      handleSearchAirport,
      openBookingCode,

      // extras
      Vietnamese,
      removeAccentsUpperCaseFormatterNotTrim,
      removeAccentsUpperCaseFormatter,
      convertISODateTime,
    }
  },
}
</script>

<style lang="">

</style>
