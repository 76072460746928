var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "calculate-trip-price-modal-vn1a",
      "title": "Giá vé",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showHandle,
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")])];
      }
    }])
  }, [!_vm.isEmpty(_vm.discountFare) ? _c('b-alert', {
    staticClass: "py-50 px-1 shadow-sm",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('b', {
    staticClass: "fw-700"
  }, [_vm._v("Giá vé có giá khuyến mãi: "), _c('span', {
    staticClass: "font-medium-2"
  }, [_vm._v(_vm._s(_vm.discountFare))]), _vm._v(" . Bạn nên đặt tách chặng để có giá khuyến mãi trong trường hợp chỉ có 1 chặng có khuyến mãi !")])]) : _vm._e(), _vm._l(_vm.selectedTrip, function (trip, tripIndex) {
    return _c('div', {
      key: tripIndex
    }, [_c('div', {
      staticClass: "font-weight-bolder mb-50"
    }, [_vm._v(" Hành trình " + _vm._s(_vm.selectedTrip.length > 1 ? "".concat(tripIndex + 1) : ':') + " ")]), _vm._l(trip.segments, function (segment, segmentIndex) {
      return _c('b-card', {
        key: segmentIndex,
        staticClass: "font-weight-bolder border px-2",
        attrs: {
          "no-body": ""
        }
      }, [_c('div', {
        staticClass: "my-75 fw-700"
      }, [_c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber))))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.bookingClass)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.departure.IATACode).concat(segment.arrival.IATACode)))]), _c('span', {
        staticClass: "mr-50 text-nowrap"
      }, [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin)))]), _c('span', [_vm._v(_vm._s("".concat(_vm.convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin)))])])]);
    })], 2);
  }), _c('div', [_c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_vm._v(" Chi tiết giá: ")]), _c('b-table', {
    attrs: {
      "outline": "",
      "responsive": "",
      "bordered": "",
      "thead-class": "text-center fw-700 text-nowrap",
      "tbody-class": "text-center",
      "busy": _vm.loading,
      "items": _vm.payments,
      "fields": _vm.priceTableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.priceTableColumns, function (column, indexColumn) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref2) {
          var label = _ref2.label;
          return [_c('div', {
            key: indexColumn
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-warning my-2"
        }, [_c('p', [_vm._v(" Đang tải dữ liệu...")]), _c('b-spinner', {
          attrs: {
            "variant": "warning"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell(Passenger)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(item[0].paxType))) + " ")];
      }
    }, {
      key: "cell(Net)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item[0].pricingInfo.netFare))) + " ")];
      }
    }, {
      key: "cell(Tax)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item[0].pricingInfo.taxCalc))) + " ")];
      }
    }, {
      key: "cell(Fare)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item[0].pricingInfo.totalCalc))) + " ")];
      }
    }, {
      key: "cell(Amount)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.length) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s("".concat(_vm.formatCurrency(item.reduce(function (total, it) {
          return total + it.pricingInfo.totalCalc;
        }, 0)))) + " ")])];
      }
    }], null, true)
  }), !_vm.isEmpty(_vm.payments) ? _c('div', {
    staticClass: "text-airline fw-600 mt-2"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Tổng tiền: ")]), _c('span', {
    staticClass: "font-medium-3 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceBooking)) + " ")])]), _c('div', {
    staticClass: "text-center text-warning"
  }, [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ")])])]) : _vm._e()], 1), _vm.totalDiscountAmount ? _c('div', {
    staticClass: "text-right mb-50"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Chiết khấu "), _c('em', [_vm._v("(tạm tính)")]), _vm._v(": ")]), _c('span', {
    staticClass: "font-medium-3 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalDiscountAmount)) + " ")])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }