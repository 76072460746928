<template lang="">
  <div class="d-flex flex-column gap-16px">
    <!-- ticket -->
    <BAlert
      :show="flights && !flights.length"
      variant="warning"
      class="px-2 py-1 mb-0"
    >
      Không có hành trình trong mã đặt chỗ.
    </BAlert>
    <div
      v-for="(flight, index) in flights"
      :key="index"
      class="section-flight rounded-12px d-flex flex-column text-gray bg-theme position-relative"
      :style="`
        ${config.showBackgroundColor ? `background-color: ${airlineColors?.[index]?.[0]?.bg ?? currentBgColor} !important;` : ''}
        ${!config.showBackgroundColor && config.showColorByAirline ? `border-color: ${airlineColors?.[index]?.[0]?.color} !important;` : ''}
      `"
      :class="[
        { 'border-theme': !config.showBackgroundColor}
      ]"
    >
      <div
        class="d-flex gap-4px position-absolute"
        style="top: 8px; right: 8px; z-index: 10;"
      >
        <b-button
          class="pax-button"
          variant="outline-danger"
          style="padding: 4px;"
          @click="removeFlight(index)"
        >
          <feather-icon
            icon="MinusIcon"
            size="16"
          />
        </b-button>
        <b-button
          class="pax-button"
          variant="outline-success"
          style="padding: 4px;"
          @click="addFlight(index, flight)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
        </b-button>
      </div>

      <template v-for="(segment, sIndex) in flight.segments">
        <div
          :key="`segment-wrapper-${index}-${sIndex}`"
          class="segment-content"
          :style="config.showBackgroundColor && airlineColors?.[index]?.[sIndex]?.bg ? `background-color: ${airlineColors?.[index]?.[sIndex]?.bg} !important` : ''"
        >
          <div
            v-if="agencyAvatar"
            v-show="config.showBackground"
            :style="`position: absolute; inset: 30px; opacity: 0.08; background: url(${agencyAvatar}) no-repeat center / contain`"
          />
          <div class="segment-content-l d-flex flex-column gap-16px text-black">
            <div
              class="segment-booking-code border-theme p-0 d-flex-center align-self-stretch color-theme bg-white position-relative"
              :style="`
                ${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                ${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
              `"
            >
              <svg
                class="d-flex-center"
                viewBox="0 0 67 67"
                width="80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <!-- Màu đậm nhất: currentColor as css class .color-theme -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M-2.18175 31.151L-35 -13.198L-19.727 -24.5L21.4547 31.151L-19.727 86.802L-35 75.5L-2.18175 31.151Z"
                  fill="currentColor"
                />
                <!-- Màu trung bình: currentColor với opacity ~69% -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.2728 31.151L-12.5454 -13.198L2.72759 -24.5L43.9093 31.151L2.72759 86.802L-12.5454 75.5L20.2728 31.151Z"
                  fill="currentColor"
                  fill-opacity="0.6869"
                />
                <!-- Màu nhạt: currentColor với opacity 20% -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M42.7279 31.151L9.90967 -13.198L25.1827 -24.5L66.3644 31.151L25.1827 86.802L9.90967 75.5L42.7279 31.151Z"
                  fill="currentColor"
                  fill-opacity="0.2"
                />
              </svg>

              <div
                v-show="config.showStatus"
                id="electronic-ticket-status"
                class="payment-status position-absolute cursor-pointer"
                :class="flight.bookingStatus
                  ? (
                    flight.bookingStatus.includes('PAID')
                      ? 'bg-current'
                      : (flight.bookingStatus === 'HOLD'
                        ? 'border-theme'
                        : 'bg-danger border-danger')
                  )
                  : config.status.toUpperCase().includes('PAID') ? 'bg-current' : 'border-theme'"
                style="top: 24px"
                :style="`
                    ${airlineColors?.[index]?.[sIndex]?.color && flight.bookingStatus === 'HOLD' ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                  `"
                @click="toggleConfigStatus(flight)"
              >
                <span
                  :class="flight.bookingStatus
                    ? (
                      flight.bookingStatus.includes('PAID')
                        ? 'text-white'
                        : (flight.bookingStatus === 'HOLD'
                          ? 'color-theme'
                          : ' text-white')
                    )
                    : config.status.toUpperCase().includes('PAID')
                      ? 'text-white'
                      : (config.status === 'EXPIRED' ? 'text-red' : 'color-theme')"
                  :style="`
                    ${airlineColors?.[index]?.[sIndex]?.color && flight.bookingStatus === 'HOLD' ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                  `"
                >
                  {{ $t(`reservation.${flight.bookingStatus || config.status}`, config.locale) }}
                </span>
              </div>
              <b-tooltip
                class="cursor-pointer"
                target="electronic-ticket-status"
                triggers="hover"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                {{ $t('ticket.clickToChangeStatus') }}
                <span class="fw-700">
                  {{
                    config.status.toUpperCase().includes('PAID')
                      ? $t('ticket.statusHold', config.locale)
                      : config.status.toUpperCase() === 'HOLD'
                        ? $t('ticket.statusCancel', config.locale)
                        : $t('ticket.statusPaid', config.locale)
                  }}
                </span>
              </b-tooltip>

              <div
                class="fw-700 text-center text-24px flex-grow-0 px-1"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.bookingID', config.locale) }}:
              </div>
              <div
                class="fw-700 text-center text-30px text-uppercase text-nowrap flex-grow-1"
                style="padding-right: 36px"
                contenteditable="true"
              >
                {{ flight.bookingCode }}
                <span v-if="resolveAirPnr(flight.supplierPNRs, flight.airline)">/{{ resolveAirPnr(flight.supplierPNRs, flight.airline) }}</span>
              </div>
            </div>

            <div class="d-flex-between fw-700 text-20px">
              <div contenteditable="true">
                {{ getAirportName(segment.from) }}
              </div>
              <div
                contenteditable="true"
                class="text-right"
              >
                {{ getAirportName(segment.to) }}
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-end fw-700">
              <div>
                <div class="d-flex gap-4px align-items-center">
                  <span
                    class="text-16px"
                    contenteditable="true"
                  >
                    {{ $t('ticket.flightsInfo.departTime', config.locale) }}
                  </span>
                  <span
                    class="text-30px px-12px color-theme bg-white rounded-8px border-theme"
                    style="padding-top: 2px;"
                    :style="`
                      ${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                      ${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                    `"
                    contenteditable="true"
                  >
                    {{ segment.departTime }}
                  </span>
                </div>
                <div class="text-20px mt-4px">
                  <span contenteditable="true">
                    {{
                      convertISODateTime(
                        segment?.segmentNotModified?.departure?.at, segment?.segmentNotModified?.departure?.timeZone, config.locale
                      ).dayOfWeek
                        || segment.departWeekday
                    }}, {{ segment.departDate }}
                  </span>
                </div>
              </div>
              <IAmIcon
                icon="airplane"
                size="34"
                color="#747783"
              />
              <div class="text-right">
                <div class="d-flex gap-4px align-items-center">
                  <span
                    class="text-28px px-12px color-theme bg-white rounded-8px border-theme fw-500"
                    style="padding-top: 2px;"
                    contenteditable="true"
                    :style="`
                      ${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                      ${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                    `"
                  >
                    {{ segment.arrivalTime }}
                  </span>
                  <span
                    class="text-14px fw-500"
                    contenteditable="true"
                  >
                    {{ $t('ticket.flightsInfo.arrivalTime', config.locale) }}
                  </span>
                </div>
                <div class="text-18px mt-4px">
                  <span
                    contenteditable="true"
                    class="fw-500"
                  >
                    {{
                      convertISODateTime(
                        segment?.segmentNotModified?.arrival?.at, segment?.segmentNotModified?.arrival?.timeZone, config.locale
                      ).dayOfWeek
                        || segment.arrivalWeekday
                    }}, {{ segment.arrivalDate }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="segment.startTerminal && segment.endTerminal"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="d-flex gap-8px align-items-center font-weight-bolder text-black">
                <span contenteditable="true">
                  {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.startTerminal.replace('TERMINAL', '') }}
                </span>
                <IAmIcon
                  icon="dotOutline"
                  size="18"
                />
              </div>
              <div class="d-flex gap-8px align-items-center font-weight-bolder">
                <IAmIcon
                  icon="location"
                  size="18"
                />

                <span contenteditable="true">
                  {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.endTerminal.replace('TERMINAL', '') }}
                </span>
              </div>
            </div>
          </div>

          <div class="barcode-wrapper">
            <img
              src="/ticket/barcode26x240.png"
              alt="barcode"
            >
          </div>

          <div class="segment-content-r d-flex flex-column gap-8px fw-700">
            <div
              :id="`div-airline-${index}-${sIndex}`"
              v-b-tooltip.hover.top.window="`Thay đổi hãng vận chuyển`"
              class="airline d-flex-center gap-16px cursor-pointer airline-hover"
            >
              <div v-if="airlinesLogoLong.includes(segment.operating || segment.airline)">
                <IAmLogoAirline
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  :long-logo="true"
                  :hide-tooltip="true"
                  size="lg"
                  blob
                />
              </div>
              <div
                v-else
                class="d-flex-center gap-1"
              >
                <IAmLogoAirline
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  :hide-tooltip="true"
                  :height="50"
                  blob
                />
                <div class="text-24px text-black font-weight-bolder">
                  {{ getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline) }}
                </div>
              </div>

              <b-popover
                :id="`popover-airline-${index}-${sIndex}`"
                :ref="`popoverAirline${index}${sIndex}`"
                :target="`div-airline-${index}-${sIndex}`"
                placement="top"
                class="popover-airline"
                variant="info"
                triggers="click"
                boundary="window"
                header-class="py-50"
              >
                <template #title>
                  <div class="d-flex-between">
                    Thay đổi hãng vận chuyển
                    <span
                      class="p-25 cursor-pointer"
                      @click="onClosePopoverHandle(`popoverAirline${index}${sIndex}`)"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="20"
                      />
                    </span>
                  </div>
                </template>
                <v-select
                  :value="segment.operating === 'BL' ? segment.operating : segment.airline"
                  :options="airlineOptions"
                  :reduce="(val) => val.key"
                  label="key"
                  :clearable="false"
                  class="width-200"
                  @input="(val) => {
                    segment.airline = val;
                    segment.operating = ''
                    onClosePopoverHandle(`popoverAirline${index}${sIndex}`)
                  }"
                >
                  <template #option="data">
                    <span>
                      <span class="fw-700">{{ data.key }}</span> ({{ data.value }})
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      <span class="fw-700">{{ data.key }}</span> ({{ data.value }})
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-popover>
            </div>

            <!-- ANCHOR booking class -->
            <div
              v-if="config.showBookingClass"
              :key="bookingClassKey"
              style="position: relative;"
              class="d-flex-center cursor-pointer"
              @click="onClickBookingClass(segment)"
            >
              <img
                v-if="segment?.customBookingClass || getGroupClass(segment)"
                v-b-tooltip.hover.top.window="$t('ticket.clickChangeBookingClassBadge')"
                :src="require(`@/assets/images/ticket/img_ticket_${(segment?.customBookingClass || getGroupClass(segment)).toLowerCase()}_class.png`)"
                class="rounded-8px w-100"
                style="max-width: 320px;"
              >
              <img
                v-else
                v-b-tooltip.hover.v-danger.top.window="$t('ticket.errorBookingClassBadge')"
                :src="require(`@/assets/images/ticket/img_ticket_${'economy'}_class.png`)"
                class="rounded-8px w-100 border-danger"
                style="max-width: 320px; border-width: 2px !important;"
              >
              <!-- <div
                style="position: absolute; inset: 0px;"
                class="group-class d-flex justify-content-center align-items-center"
                contenteditable="true"
              >
                {{ getGroupClass(segment) }}
              </div> -->
            </div>

            <!-- flight -->
            <div class="d-flex justify-content-between align-items-center gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
                style="margin-top: 10px;"
              >
                {{ $t('ticket.flightsInfo.flight', config.locale) }}:
              </div>
              <div
                class="text-24px px-12px color-theme bg-white rounded-8px border-theme"
                style="padding-top: 2px"
                contenteditable="true"
                :style="`
                      ${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                      ${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};
                    `"
              >
                {{ segment.flight }}
              </div>
            </div>

            <!-- aircraft -->
            <div
              v-if="segment.airCraft"
              class="d-flex justify-content-between align-items-center gap-4px"
            >
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.aircraft', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                {{ getAirCraftByIata(segment.airCraft) }}
              </div>
            </div>

            <!-- duration -->
            <div class="d-flex justify-content-between align-items-center gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.duration', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                {{ segment.durationHM }}
              </div>
            </div>

            <!-- boarding baggage -->
            <div class="d-flex-between gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.handBaggage', config.locale) }}:
              </div>
              <div
                class="text-black flex-1 text-right"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).carryBag
                  : getHandBaggage(segment) }}
              </div>
            </div>

            <!-- checkin baggage -->
            <div class="d-flex-between gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.freeBaggage', config.locale) }}:
              </div>
              <div
                class="text-black flex-1 text-right"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).checkinBag
                  : getFreeBaggage(segment) }}
              </div>
            </div>
          </div>
        </div>

        <!-- ANCHOR StopAp -->
        <div
          v-if="segment?.segmentNotModified?.stopAp?.length"
          :key="`stop-${index}-${sIndex}`"
          class="d-flex-center gap-12px px-2 color-theme my-12px"
        >
          <div
            class="border-dot flex-grow-1"
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          />
          <div
            class="flex-shrink-0 font-weight-bolder text-16px"
            contenteditable
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          >
            {{ $t('reservation.stopAp', config.locale) }}
            <span
              v-for="(stop,stopIndex) in segment.segmentNotModified.stopAp"
              :key="stop"
            >
              {{ getAirportName(stop) }} {{ stopIndex !== segment?.segmentNotModified?.stopAp?.length - 1 ? '; ' : '' }}
            </span>
          </div>
          <div
            class="border-dot flex-grow-1"
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          />
        </div>
        <!-- ANCHOR transit time -->
        <div
          v-if="segment.transitTime"
          :key="`transit-time${index}-${sIndex}`"
          class="d-flex-center gap-12px px-2 color-theme my-12px"
        >
          <div
            class="border-dot flex-grow-1"
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          />
          <div
            class="flex-shrink-0 font-weight-bolder text-16px"
            contenteditable
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          >
            {{ $t('ticket.flightsInfo.transitIn', config.locale) }}
            {{ getAirportName(segment.to) }}
            -
            {{ $t('ticket.flightsInfo.transitTime', config.locale) }}:
            {{ segment.transitTime }}
          </div>
          <div
            class="border-dot flex-grow-1"
            :style="`${airlineColors?.[index]?.[sIndex]?.color ? `border-color: ${airlineColors?.[index]?.[sIndex]?.color}` : ''};`"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>

import {
  BAlert,
  BTooltip, BButton, VBTooltip,
  BPopover,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { airlinesLogoLong } from '@/constants/reservation'
import store from '@/store'
import { colorByAirline, convertISODateTime } from '@/@core/utils/filter'

import { resolveAirPnr } from '@reservation/useReservationHandle'

export default {
  components: {
    BButton,
    BAlert,
    BTooltip,
    BPopover,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    vSelect: () => import('vue-select'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    ticketData: {
      type: Object,
      default: () => ({}),
    },
    flights: {
      type: Array,
      default: () => [],
    },
    agencyAvatar: {
      type: [String, null],
      default: null,
    },
    currentBgColor: {
      type: [String, null],
      default: null,
    },
    getAirportName: {
      type: Function,
      default: () => {},
    },
    getGroupClass: {
      type: Function,
      default: () => {},
    },
    getFreeBaggage: {
      type: Function,
      default: () => {},
    },
    getHandBaggage: {
      type: Function,
      default: () => {},
    },
    getBaggageInBookingDetail: {
      type: Function,
      default: () => {},
    },
    addFlight: {
      type: Function,
      default: () => {},
    },
    removeFlight: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    function getAirCraftByIata(code) {
      return store.getters['globalConfig/getAirCraftByIata'](code)
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    const bookingClassKey = ref(uuidv4())
    const bookingClassBadge = ['ECONOMY', 'BUSINESS', 'PREMIUM', 'FIRST']
    function onClickBookingClass(segment) {
      const bookingClassOfSegment = segment?.customBookingClass ?? this.getGroupClass(segment) ?? 'ECONOMY'
      const indexBookingClassInArray = bookingClassBadge.indexOf(bookingClassOfSegment.toUpperCase())
      const nextBookingClass = bookingClassBadge[(indexBookingClassInArray >= 3 ? -1 : indexBookingClassInArray) + 1]
      segment.customBookingClass = nextBookingClass
      bookingClassKey.value = uuidv4()
    }

    function toggleConfigStatus(flight) {
      const statusOptions = ['PAID', 'HOLD', 'CANCEL']
      const indexBookingStatus = statusOptions.findIndex(i => i === flight.bookingStatus || i === props.config.status)
      flight.bookingStatus = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
    }

    const airlineColors = computed(() => {
      if (props.config?.showColorByAirline) {
        return props.flights.map(flight => flight.segments.map(segment => colorByAirline(segment.airline)))
      }

      return []
    })

    const priorityAirlines = [
      'CI',
      'JQ',
      'TR',
      'AK',
      'BL',
      'VU',
      'VJ',
      'QH',
      'VN',
    ]
    const airlineOptions = computed(() => {
      const airlines = store.getters['globalConfig/getConfig']('airlines')
      return airlines.sort((a, b) => priorityAirlines.indexOf(b.key) - priorityAirlines.indexOf(a.key)) })

    function onClosePopoverHandle(val) {
      this.$refs[val][0].doClose()
    }

    return {
      resolveAirPnr,
      airlinesLogoLong,
      getAirCraftByIata,
      onClickBookingClass,
      bookingClassKey,
      toggleConfigStatus,
      getAirlineNameByCode,
      airlineColors,
      convertISODateTime,
      airlineOptions,
      onClosePopoverHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.airline-hover {
  border-radius: 8px;
  &:hover {
    background-color: #b4d5f067;
  }
}
</style>
