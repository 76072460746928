export default {
  vehiclesType: {
    CUSTOM: 'Tự chọn',
    AIRPLANE: 'Máy bay',
    CAR: 'Xe ô tô',
    TRAIN: 'Tàu hỏa',
    HIGH_SPEED_TRAIN: 'Tàu cao tốc',
  },
  tourType: {
    DOMESTIC: 'Tour trong nước',
    OUTBOUND: 'Tour nước ngoài',
  },
  msg: {
    selectedTourNotFound: 'Vui lòng chọn lại tour!',
    agencyPlaceholder: 'Vui lòng chọn đại lý để đặt hộ Tour Du lịch',
  },
  field: {
    contactName: 'Họ và tên',
    contactPhone: 'Số điện thoại',
    email: 'Email',
    address: 'Địa chỉ',
    type: 'Loại khách',
    unitPrice: 'Giá tour/người',
    commission: 'Hoa hồng',
    totalCommission: 'Tổng tiền hoa hồng',
    number: 'Số lượng',
    totalAmount: 'Tổng tiền',
    chooseAgency: 'Chọn đại lý',
    agency: 'Đại lý',
    totalSingleRoom: 'Số phòng đơn phụ thu',
  },
  ph: {
    contactName: 'Nhập họ và tên',
    contactPhone: 'Nhập số điện thoại',
    email: 'Nhập email',
    address: 'Nhập địa chỉ',
  },
  passenger: {
    Adults: 'Người lớn',
    Children1: 'Trẻ em 1 (có giường)',
    Children2: 'Trẻ em 2 (ngủ ghép)',
    Infant: 'Em bé',
    ageFrom: 'Từ {ageFrom} tuổi trở lên',
    ageBetween: 'Từ {ageFrom}-{ageTo} tuổi',
    ageTo: 'Dưới {ageTo} tuổi',
  },
  totalPrice: 'Tổng tiền',
  displaySettings: 'Hiển thị',

  tableColumns: {
    no: 'STT', // No.
    bookingCode: 'Mã đặt chỗ', // Booking code
    status: 'Trạng thái', // Status
    customerName: 'Tên khách hàng', // Customer name
    tourName: 'Tên tour', // Tour name
    startDate: 'Ngày khởi hành', // Start date
    totalPrice: 'Tổng tiền', // Total price
    totalCommission: 'Hoa hồng', // Total commission
    createdAt: 'Ngày tạo', // Created at
    createdBy: 'Người tạo', // Created by
    note: 'Ghi chú', // Note
    actions: 'Hành động', // Actions
    totalPriceToBePaid: 'Cần thanh toán',
    totalPriceRefund: 'Tiền hoàn',
    depositPrice: 'Đã cọc / thanh toán',

    paxType: 'Loại hành khách',
    tourPrice: 'Giá tour',
    paxQuantity: 'Số lượng',
    // totalPrice: 'Total price',
  },

  status: {
    ALL: 'Tất cả',
    RESERVED: 'Giữ chỗ', // Giữ chỗ
    DEPOSITED: 'Đã đặt cọc', // Đã đặt cọc
    REQUEST_DEPOSIT: 'Yêu cầu Đặt cọc',
    REQUEST_PAYMENT: 'Yêu cầu Thanh toán',
    REQUEST_UPDATE: 'Yêu cầu Chỉnh sửa',
    PAID: 'Đã thanh toán', // Đã thanh toán
    CANCELLATION_REQUESTED: 'Yêu cầu hủy', // Yêu cầu huỷ
    CANCELLED: 'Đã hủy', // Đã huỷ
    EXPIRED: 'Đã hết hạn',
  },
  showCommission: 'Hiển thị hoa hồng',
  hideCommission: 'Ẩn hoa hồng',
  bookingDetail: {
    tourInfo: 'Thông tin Tour',
    contactInfo: 'Thông tin liên hệ',
    paymentDeadline: 'Thời hạn thanh toán',
    timeHold: 'Thời hạn giữ chỗ',
  },
  historyActions: {
    CREATE: 'Đặt tour',
    CONFIRM_PAYMENT: 'Xác nhận đặt cọc/thanh toán',
    CONFIRM_UPDATE: 'Xác nhận chỉnh sửa',
    REQUEST_CANCEL: 'Yêu cầu huỷ tour',
    REQUEST_SUPPORT: 'Xác nhận tour',
  },
  btn: {
    requestSupport: 'Xác nhận Tour',
    requestedSupport: 'Đã xác nhận Tour',
  },
}
