var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Stack', {
    class: "".concat(_vm.isMobileView ? 'gap-2' : 'gap-3'),
    attrs: {
      "center": ""
    }
  }, [_c('div', {
    class: "text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700")
  }, [_vm._v(" Các tour khác ")]), _c('div', {
    style: "width: 100%; border-top: ".concat(_vm.isMobileView ? 1.5 : 2.5, "px solid #2B3378;")
  }), _c('div', {
    staticClass: "w-100",
    style: "display: grid; grid-template-".concat(!['xl'].includes(_vm.$store.getters['app/currentBreakPoint']) ? 'rows' : 'columns', ": repeat(3, 1fr); gap: 14px;")
  }, _vm._l(3, function (item) {
    var _vm$anotherTours, _vm$anotherTours$imag, _vm$anotherTours2, _vm$anotherTours3, _vm$anotherTours4, _vm$anotherTours5, _vm$anotherTours6, _vm$anotherTours7;
    return _c('div', {
      key: item
    }, [_vm.anotherTours[item - 1] ? _c('div', {
      staticClass: "rounded-16px d-flex-center align-items-end shadow-lg",
      staticStyle: {
        "background-size": "cover",
        "background-position": "center",
        "height": "300px"
      },
      style: "".concat("background-image: url(".concat(((_vm$anotherTours = _vm.anotherTours[item - 1]) === null || _vm$anotherTours === void 0 ? void 0 : (_vm$anotherTours$imag = _vm$anotherTours.image) === null || _vm$anotherTours$imag === void 0 ? void 0 : _vm$anotherTours$imag.path) || _vm.backgroundTourUrl, ")"))
    }, [_c('Stack', {
      staticClass: "m-1 rounded-8px p-50 w-100 cursor-none",
      staticStyle: {
        "backdrop-filter": "blur(4px)",
        "background": "rgba(255, 255, 255, 0.8)"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.window",
        value: (_vm$anotherTours2 = _vm.anotherTours[item - 1]) === null || _vm$anotherTours2 === void 0 ? void 0 : _vm$anotherTours2.name,
        expression: "anotherTours[item-1]?.name",
        modifiers: {
          "hover": true,
          "window": true
        }
      }],
      class: "fw-700 text-dark ".concat(_vm.isMobileView ? 'text-12px' : 'text-18px', " clamped-content")
    }, [_vm._v(" " + _vm._s((_vm$anotherTours3 = _vm.anotherTours[item - 1]) === null || _vm$anotherTours3 === void 0 ? void 0 : _vm$anotherTours3.name) + " ")]), _c('HStack', [_c('small', {
      staticClass: "fw-700"
    }, [_vm._v("Mã chương trình:")]), _c('small', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s((_vm$anotherTours4 = _vm.anotherTours[item - 1]) === null || _vm$anotherTours4 === void 0 ? void 0 : _vm$anotherTours4.tourCode))])]), _c('div', {
      staticClass: "d-flex-between align-items-end"
    }, [(_vm$anotherTours5 = _vm.anotherTours[item - 1]) !== null && _vm$anotherTours5 !== void 0 && _vm$anotherTours5.adultsPriceSales ? _c('div', [_c('small', {
      staticClass: "fw-700"
    }, [_vm._v("Giá từ")]), _c('div', {
      class: "fw-900 text-danger ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px')
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$anotherTours6 = _vm.anotherTours[item - 1]) === null || _vm$anotherTours6 === void 0 ? void 0 : _vm$anotherTours6.adultsPriceSales)) + " ")])]) : _vm._e(), _c('BButton', {
      staticClass: "cursor-pointer fw-700 text-dark text-14px p-50",
      attrs: {
        "variant": "flat-dark",
        "to": {
          name: 'apps-toursDetail',
          params: {
            id: (_vm$anotherTours7 = _vm.anotherTours[item - 1]) === null || _vm$anotherTours7 === void 0 ? void 0 : _vm$anotherTours7.priceId
          }
        }
      }
    }, [_vm._v(" Xem chi tiết ")])], 1)], 1)], 1) : _vm._e()]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }