var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [!_vm.isCompanyActive && !_vm.isInvoiceRequest ? _c('BAlert', {
    staticClass: "py-1 px-2",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.msg.companyNotFoundOrDeactivated')) + " ")]) : _vm._e(), _c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('div', {
    staticClass: "pb-2",
    attrs: {
      "id": "invoice-create"
    }
  }, [_c('InvoiceInfoCard', {
    attrs: {
      "invoice-data": _vm.invoiceToCreate,
      "isInvoiceRequest": _vm.isInvoiceRequest
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "quick-input": _vm.superQuickInputInfoHandle
    }
  }), _c('BRow', [_c('BCol', {
    class: !_vm.collapseSelectedTicket ? 'pr-md-50' : '',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedTicket ? 12 : 5
    }
  }, [_c('InvoiceSelectTicketCard', {
    attrs: {
      "invoice-data": _vm.invoiceToCreate,
      "collapse-selected-ticket": _vm.collapseSelectedTicket
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update:collapseSelectedTicket": function updateCollapseSelectedTicket($event) {
        _vm.collapseSelectedTicket = $event;
      },
      "update:collapse-selected-ticket": function updateCollapseSelectedTicket($event) {
        _vm.collapseSelectedTicket = $event;
      }
    }
  })], 1), _c('BCol', {
    class: !_vm.collapseSelectedTicket ? 'pl-md-50' : '',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedTicket ? 12 : 7
    }
  }, [_c('InvoiceDetailCard', {
    attrs: {
      "invoice-data": _vm.invoiceToCreate,
      "is-create": true,
      "resetInvoice": _vm.resetInvoice,
      "isInvalidInvoiceItems": _vm.isInvalidInvoiceItems,
      "isInvoiceRequest": _vm.isInvoiceRequest
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update-invoice": _vm.updateInvoiceByField
    }
  }), _c('InvoicePaymentCard', {
    attrs: {
      "invoice-data": _vm.invoiceToCreate
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToCreate = $event;
      }
    }
  }), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('BButton', {
    staticClass: "min-width-120",
    attrs: {
      "pill": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.exitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exit')) + " ")]), _c('BButton', {
    staticClass: "min-width-120",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": !_vm.isCompanyActive && !_vm.isInvoiceRequest
    },
    on: {
      "click": _vm.createInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('invoice.btn.save', _vm.isInvoiceRequest ? 2 : 1)) + " ")])], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }