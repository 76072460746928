export default {
  title: 'Báo cáo bán chi tiết',
  columns: {
    // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
    notePrivate: 'Ghi chú bán định danh',
    no: 'Số thứ tự',
    created: 'Thời gian tạo',
    updated: 'Thời gian sửa đổi',
    airline: 'Hãng hàng không',
    source: 'Hệ thống đặt vé',
    bookingCode: 'Mã đặt chỗ',
    paxName: 'Tên hành khách',
    paxId: 'Mã số hành khách',
    paxType: 'Loại hành khách',
    ticketNumber: 'Số vé',
    paymentCode: 'Mã thanh toán',
    ticketType: 'Loại vé',
    flightType: 'Loại hành trình',
    trips: 'Hành trình',
    flightDates: 'Ngày bay',
    bookingClass: 'Hạng đặt chỗ',
    fareBasisCode: 'Loại giá vé',
    booker: 'Người đặt vé',
    issuer: 'Người xuất vé',
    issueDate: 'Ngày thanh toán',
    bookingDate: 'Ngày đặt',
    agency: 'Đại lý',
    signBook: 'Tài khoản đăng ký',
    currency: 'Loại tiền',
    priceTicket: 'Giá vé net',
    vat: 'Vat',
    otherTax: 'Thuế phí khác',
    tax: 'Phí thu hộ',
    fee: 'Phí',
    feeIn: 'Phí xuất vé F1',
    feeOut: 'Phí xuất vé F2',
    feeService: 'Phí dịch vụ',
    commissionPrepaid: 'Hoa hồng đã trả',
    commissionPrepaidIn: 'Hoa hồng đã được trả',
    commissionPrepaidOut: 'Hoa hồng đã chi',
    commission: 'Hoa hồng hãng',
    unpaidCommission: 'Hoa hồng trả sau',
    unpaidCommissionIn: 'Hoa hồng trả sau được nhận',
    unpaidCommissionOut: 'Hoa hồng trả sau phải trả',
    totalPayment: 'Tổng tiền',
    tradeCreditors: 'Tổng phải trả',
    receivables: 'Tổng phải thu',
    accountsPayable: 'Công nợ',
    profit: 'Lợi nhuận',
    balanceAdjustment: 'Số dư điều chỉnh',
    dob: 'Xuất vé kèm ngày sinh',

    // for CHOOSE_TICKET_TABLE_COLUMNS
    checkbox: '',
    ticketNo: 'Số vé',
    invoiceAirline: 'Hãng',
    invoiceIssueDate: 'Ngày đặt vé',
    unitPrice: 'Đơn giá',
    invoiceVAT: 'Tiền thuế',
    invoiceCollectionFee: 'Phí thu hộ',
    invoiceTotalPayment: 'Tổng tiền thanh toán',
    customer: 'Đại lý/ Khách hàng',
    refundDate: 'Ngày hoàn',
    actions: 'Thao tác',
    // ANCHOR short title
    shortTitle: {
      // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
      no: 'STT',
      created: 'TG tạo',
      updated: 'TG sửa đổi',
      airline: 'Hãng',
      source: 'HTĐV',
      bookingCode: 'Mã ĐC',
      paxName: 'Tên HK',
      paxId: 'Mã HK',
      paxType: 'Loại HK',
      ticketNumber: 'Số vé',
      paymentCode: 'Mã TT',
      ticketType: 'Loại vé',
      flightType: 'Loại HT',
      trips: 'Hành trình',
      flightDates: 'Ngày bay',
      bookingClass: 'Hạng ĐC',
      fareBasisCode: 'Loại giá vé',
      booker: 'Người đặt',
      issuer: 'Người xuất',
      issueDate: 'Ngày TT',
      bookingDate: 'Ngày đặt',
      agency: 'Đại lý',
      signBook: 'TK đăng ký',
      currency: 'Loại tiền',
      priceTicket: 'Giá vé net',
      vat: 'Vat',
      otherTax: 'Thuế phí khác',
      tax: 'Phí thu hộ',
      fee: 'Phí',
      feeIn: 'Phí xuất vé F1',
      feeOut: 'Phí xuất vé F2',
      feeService: 'Phí dịch vụ',
      commissionPrepaid: 'HH đã trả',
      commissionPrepaidIn: 'HH đã được trả',
      commissionPrepaidOut: 'HH đã chi',
      commission: 'HH hãng',
      unpaidCommission: 'HH trả sau',
      unpaidCommissionIn: 'HH trả sau được nhận',
      unpaidCommissionOut: 'HH trả sau phải trả',
      totalPayment: 'Tổng tiền',
      tradeCreditors: 'Tổng phải trả',
      receivables: 'Tổng phải thu',
      balanceAdjustment: 'Số dư điều chỉnh',
      accountsPayable: 'Công nợ',
      profit: 'Lợi nhuận',

      // for CHOOSE_TICKET_TABLE_COLUMNS
      checkbox: '',
      ticketNo: 'Số vé',
      invoiceAirline: 'Hãng',
      invoiceIssueDate: 'Ngày đặt vé',
      unitPrice: 'Đơn giá',
      invoiceVAT: 'Tiền thuế',
      invoiceCollectionFee: 'Phí thu hộ',
      invoiceTotalPayment: 'Tổng tiền thanh toán',
      customer: 'Đại lý/ Khách hàng',
      refundDate: 'Ngày hoàn',
      actions: 'Thao tác',
      dob: 'Ngày sinh',
    },
  },
  domestic: 'Nội địa',
  inbound: 'Quốc tế',
  domesticAndInbound: 'Nội địa và quốc tế',
  Domestic: 'Nội địa',
  Inbound: 'Quốc tế',
  DomesticAndInbound: 'Nội địa và quốc tế',
  attachmentStatus: 'Trạng thái đính kèm trong hóa đơn',
  attachedInInvoice: 'Đang được đính kèm trong hóa đơn',
  notAttachedInInvoice: 'Chưa được đính kèm trong hóa đơn',

  // 'ph'acronym of 'placeholder'
  phAgencyCode: 'Lựa chọn đại lý',
  phBookingCode: 'Nhập vào mã đặt chỗ',
  phTicketNumber: 'Nhập vào số vé',
  phSearch: 'Tìm kiếm theo ...',
  phAirline: 'Điền vào hãng hàng không',
  clearDobFilter: 'Xoá lọc ngày sinh',

  createManually: {
    title: 'Nhập báo cáo bán thủ công',
    subTitle: 'Nhập thông tin báo cáo bán',

    // field
    no: 'STT',
    agency: 'Đại lý',
    customer: 'Khách hàng',
    ticketNumber: 'Số vé',
    airline: 'Hãng hàng không',
    source: 'Hệ thống đặt vé',
    bookingCode: 'Mã đặt chỗ',
    paxName: 'Họ tên hành khách',
    paxType: 'Loại hành khách',
    paxId: 'ID Hành khách',
    bookingDate: 'Ngày đặt chỗ',
    issueDate: 'Ngày xuất vé',
    signBook: 'Tài khoản đặt vé',
    signIss: 'Tài khoản xuất vé',
    booker: 'Người đặt vé',
    issuer: 'Người xuất vé',
    ticketType: 'Loại vé',
    currency: 'Loại tiền',
    priceTicket: 'Giá vé net',
    tax: 'Thuế phí',
    otherTax: 'Thuế phí khác',
    fee: 'Phí',
    collectionFee: 'Phí thu hộ',
    feeService: 'Phí dịch vụ',
    commissionPrepaid: 'Hoa hồng trả trước',
    unpaidCommission: 'Hoa hồng trả sau',
    fFlyer: 'Mã số thẻ thành viên',
    trips: 'Hành trình',
    bookingClass: 'Hạng vé',
    fareBasisCode: 'Fare basis code',
    flightType: 'Loại hành trình',
    flownFare: 'Giá đã bay (Flown fare)',
    flownDate: 'Ngày đã bay (Flown date)',
    flightDates: 'Ngày bay',
    commission: 'Hoa hồng (commission)',
    originalTk: 'Số vé gốc (original ticket)',
    ticketSubType: 'Loại vé phụ (ticket sub type)',
    placeholder: {
      agency: 'Chọn đại lý',
      customer: 'Chọn khách hàng',
      ticketNumber: 'Điền vào số vé',
      airline: 'Điền vào hãng hàng không',
      source: 'Điền vào hệ thống đặt vé',
      bookingCode: 'Điền vào mã đặt chỗ',
      paxName: 'Điền vào họ tên hành khách',
      paxType: 'Chọn loại hành khách',
      paxId: 'Điền vào ID hành khách',
      bookingDate: 'Nhập vào ngày đặt vé',
      issueDate: 'Nhập vào ngày xuất vé',
      signBook: 'Nhập Tài khoản đặt vé',
      signIss: 'Nhập Tài khoản xuất vé',
      booker: 'Nhập người đặt vé',
      issuer: 'Nhập người xuất vé',
      ticketType: 'TKT, EMD,...',
      currency: 'VND, USD,...',
      priceTicket: 'Điền vào Giá vé net',
      tax: 'Điền vào Thuế phí',
      otherTax: 'Điền vào Thuế phí khác',
      fee: 'Điền vào Phí',
      collectionFee: 'Điền vào Phí thu hộ',
      feeService: 'Điền vào Phí dịch vụ',
      commissionPrepaid: 'Điền vào Hoa hồng trả trước',
      unpaidCommission: 'Điền vào Hoa hồng trả sau',
      fFlyer: 'Điền vào Mã số thẻ thành viên',
      trips: 'Nhập vào Hành trình',
      bookingClass: 'Nhập vào Hạng vé',
      fareBasisCode: 'Nhập vào Fare basis code',
      flightType: 'Chọn Loại hành trình',
      flownFare: 'Nhập vào Giá đã bay (Flown fare)',
      flownDate: 'Nhập vào Ngày đã bay (Flown date)',
      flightDates: 'Nhập vào Ngày bay',
      commission: 'Nhập vào Hoa hồng (commission)',
      originalTk: 'Nhập vào Số vé gốc (original ticket)',
      ticketSubType: 'Nhập vào Loại vé phụ (ticket sub type)',
    },
  },
  detail: {
    title: 'Chi tiết báo cáo bán',
    updateTitle: 'Cập nhật báo cáo bán',
    update: 'Cập nhật',
  },
  confirmDelete: 'Bạn có chắc chắn xoá báo cáo bán nhập thủ công này không?',
  flightDate: 'Ngày bay',

  f2: {
    title: 'Báo cáo bán chi tiết F2',
  },

  sum: 'Tổng',

  RECEIPTS_NOTE: 'Tăng số dư',
  SPENDING_NOTE: 'Giảm số dư',
  TOPUP: 'TOPUP',
  TKT: 'TKT',
  EMD: 'EMD',
  RFD: 'RFD',
  VOID: 'VOID',
  INSURANCE: 'Bảo hiểm',
  PACKAGE_ORDER: 'Gói bán lẻ',
  TOUR_REFUND: 'Hoàn Tour',
  TOUR_DEPOSIT: 'Đặt cọc Tour',
  TOUR_PAYMENT: 'Thanh toán Tour',
}
