var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative div-hover"
  }, [_c('div', {
    staticClass: "image-div w-100",
    style: "\n      ".concat(_vm.backgroundTourUrl ? "background-image: url(".concat(_vm.backgroundTourUrl, ")") : '', ";\n      height: ").concat(_vm.isMobileView ? '100vw' : '600px', ";\n      max-height: ").concat(_vm.isMobileView ? '400px' : '', ";\n      min-height: ").concat(_vm.isMobileView ? '330px' : '', ";\n      ").concat(_vm.isMobileView ? 'border-radius: 16px;' : '', "\n    ")
  }), _c('div', {
    staticClass: "text-uppercase fw-900",
    staticStyle: {
      "position": "absolute",
      "top": "10%",
      "left": "50%",
      "transform": "translate(-50%, 0%)",
      "color": "white",
      "text-align": "center"
    }
  }, [_c('div', {
    staticClass: "position-relative",
    style: "font-size: ".concat(_vm.isMobileView ? '30px' : '70px')
  }, [_vm._v(" Khám phá ")]), _c('div', {
    staticClass: "text-nowrap",
    staticStyle: {
      "font-size": "140px",
      "opacity": "0.7",
      "mix-blend-mode": "overlay"
    },
    style: "font-size: ".concat(_vm.isMobileView ? '60px' : '140px')
  }, [_vm._v(" thế giới ")])]), _c('div', {
    staticClass: "px-1 py-75 rounded-lg text-wrap",
    staticStyle: {
      "position": "absolute",
      "bottom": "5%",
      "left": "50%",
      "transform": "translate(-50%, 0%)",
      "color": "white",
      "text-align": "center",
      "backdrop-filter": "blur(16px)",
      "opacity": "90%"
    },
    style: "width: ".concat(_vm.isMobileView ? '90%' : '70%')
  }, [_vm._v(" Dòng tour này " + _vm._s(_vm.appName) + " hướng đến mục tiêu bất cứ Du Khách nào cũng có cơ hội đi du lịch với mức chi phí tiết kiệm nhất. Các điểm tham quan và dịch vụ chọn lọc phù hợp với ngân sách của Du Khách nhưng vẫn đảm bảo hành trình du lịch đầy đủ và thú vị. ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }