<template>
  <ValidationObserver
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <b-modal
      id="modal-send-queues"
      :title="$t('reservation.sendQueues.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      @show="openModalHandle"
    >
      <div class="font-medium-4 fw-700">
        {{ $t('reservation.bookingCode') }}:
        <span class="text-info text-uppercase">{{ bookingData.bookingCode }}</span>
      </div>

      <div class="text-danger font-italic">
        (***) Lưu ý: bạn sẽ chịu mọi trách nhiệm cho thao tác gửi Q này, luôn theo dõi phản hồi của hãng sau khi gửi Q.
      </div>

      <b-form-group label-for="queueCode">
        <template #label>
          {{ $t('reservation.sendQueues.chooseQueue') }}
          <span class="text-danger">(*)</span>
        </template>
        <v-select
          id="queueCode"
          v-model="queueCode"
          taggable
          :options="queueCodeOptions"
          :reduce="(val) => val.code"
          label="code"
          filterable
          input-id="queueCode"
          :placeholder="$t('reservation.sendQueues.placeholderQueueCode')"
        >
          <template #option="item">
            <b-row>
              <b-col cols="12">
                <code class="font-weight-bolder mb-0 bg-transparent text-nowrap">
                  {{ item.code }}{{ `${item.title ? ` - ${item.title}`: ''}` }}
                </code>
              </b-col>
              <b-col cols="12">
                <small class="text-wrap">
                  {{ item.description }}
                </small>
              </b-col>
            </b-row>
          </template>

          <template #selected-option="item">
            <code class="font-weight-bolder mb-0 bg-transparent">
              {{ item.code }}{{ `${item.title ? ` - ${item.title}`: ''}` }}
            </code>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>

      <div v-if="queueCode && ['CN'].includes(queueCode)">
        <ValidationProvider
          #default="validationContext"
          :name="$t('reservation.sendQueues.oldPassenger')"
          rules="required"
        >
          <b-form-group
            label-for="newPassenger"
          >
            <template #label>
              {{ $t('reservation.sendQueues.oldPassenger') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="oldPassenger"
              v-model="oldPassenger"
              :options="passengerOptions"
              :reduce="(val) => val"
              label="firstName"
              filterable
              input-id="queueCode"
              :placeholder="$t('reservation.sendQueues.oldPassenger')"
            >
              <template #option="item">
                {{ `${item.title} ${item.lastName} ${item.firstName}` }}
              </template>

              <template #selected-option="item">
                {{ `${item.title} ${item.lastName} ${item.firstName}` }}
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group label-for="newPassenger">
          <template #label>
            {{ $t('reservation.sendQueues.changeName') }}
            <span class="text-danger">(*)</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="validationContext"
                :name="$t('reservation.Title')"
                rules="required"
              >
                <v-select
                  id="title"
                  v-model="newPassenger.title"
                  :options="titleOptions"
                  :reduce="(val) => val"
                  label="label"
                  filterable
                  :state="getValidationState(validationContext) === false ? false : null"
                  input-id="queueCode"
                  :placeholder="$t('reservation.Title')"
                >
                  <template #option="item">
                    {{ item.label }}
                  </template>

                  <template #selected-option="item">
                    {{ item.label }}
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="validationContext"
                :name="$t('reservation.lastName')"
                rules="required"
              >
                <b-form-input
                  id="lastName"
                  v-model="newPassenger.lastName"
                  lazy-formatter
                  :state="getValidationState(validationContext) === false ? false : null"
                  :formatter="trimUpperCaseInput"
                  :placeholder="$t('reservation.lastName')"
                />
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="validationContext"
                :name="$t('reservation.lastName')"
                rules="required"
              >
                <b-form-input
                  id="firstName"
                  v-model="newPassenger.firstName"
                  lazy-formatter
                  :state="getValidationState(validationContext) === false ? false : null"
                  :formatter="trimUpperCaseInput"
                  :placeholder="$t('reservation.firstName')"
                />
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form-group>
      </div>

      <div v-else-if="queueCode && ['SC', 'SCD'].includes(queueCode)">
        <ValidationProvider
          #default="validationContext"
          :name="$t('reservation.sendQueues.chooseSegmentToSC')"
          rules="required"
        >
          <b-form-group>
            <template #label>
              {{ $t('reservation.sendQueues.chooseSegmentToSC') }}
              <span class="text-danger">(*)</span>
            </template>
            <div v-if="segmentTK.length">
              <b-form-radio-group
                v-model="segmentToSC"
                :name="$t('reservation.sendQueues.chooseSegmentToSC')"
                :state="getValidationState(validationContext) === false ? false : null"
                class="custom-checkbox"
                plain
                stacked
              >
                <b-form-radio
                  v-for="seg in segmentTK"
                  :key="seg.segmentId"
                  :value="seg.segmentId"
                >
                  <span class="fw-600 font-medium-2">{{ seg.trip }}</span>
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div
              v-else
              class="text-danger fw-600"
            >
              {{ $t('reservation.sendQueues.noSegmentTK') }}
            </div>

          </b-form-group>
        </ValidationProvider>
      </div>

      <b-alert
        :show="(queueCode && queueCode.description) ? true : false"
        variant="warning"
        class="p-1"
      >
        <div>
          <small class="font-weight-bolder">
            {{ queueCode && queueCode.description }}
          </small>
        </div>
      </b-alert>

      <b-alert
        v-if="queueCode && ['SC', 'SCD'].includes(queueCode)"
        show
        variant="danger"
        class="p-1"
      >
        <div class="font-weight-bolder">
          Lưu ý các bước bắt buộc để xác nhận chỗ SC :
        </div>
        <div class="ml-50">
          <div>1. Đặt chổ hạng B với tình trạng HK cho chuyến cần xác nhận.</div>
          <div>2. Đặt sổ chờ (GL) hạng cũ cho chuyến cần xác nhận.</div>
          <div v-if="queueCode === 'SC'">
            3. Thực hiện gỡ checkin trước khi gởi Queue nếu có.
          </div>
        </div>
      </b-alert>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          :disabled="!(queueCode) || invalid"
          pill
          @click="sendQueuesHandle"
        >
          <span class="align-middle">{{ $t('reservation.sendQueues.title') }}</span>
        </b-button>
      </template>
    </b-modal>

    <ModalShowText
      :text="responseCommand"
      :title-modal="$t('reservation.sendQueues.title')"
      :id-modal="idModal"
    />
  </ValidationObserver>
</template>

<script>
import {
  BModal, BButton, BFormInput, BRow, BCol, BFormGroup, BAlert, VBTooltip, BFormInvalidFeedback, BFormRadio,
  BFormRadioGroup,
  // BCollapse, BCard,
} from 'bootstrap-vue'
import {
  ref, toRefs, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'
import { convertShortTrip } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BAlert,
    // BCollapse,
    // BCard,
    BFormInvalidFeedback,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalShowText: () => import('@reservation/find-ticket/ModalShowText.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { sendQueue } = useReservationHandle()
    const { refFormObserver, getValidationState } = formValidation()
    const bookingData = toRefs(props).bookingData
    const queueCode = ref()
    const oldPassenger = ref()
    const newPassenger = ref()
    const segmentToSC = ref()
    const passengerOptions = computed(() => bookingData.value.paxLists.map(pax => ({
      firstName: pax.firstName,
      lastName: pax.lastName,
      title: pax.title,
    })))
    const queueCodeOptions = ref([
      {
        code: 'CN',
        title: 'Sửa tên',
        description: '',
      },
      {
        code: 'SC',
        title: 'Chuyến bay SC trong ngày',
        description: 'SC phải có hành trình có status TK',
      },
      {
        code: 'SCD',
        title: 'SC xa ngày',
        description: 'SCD phải có hành trình có status TK',
      },
    ])
    const responseCommand = ref('')
    const idModal = ref('add-queues')
    async function sendQueuesHandle() {
      this.$bvModal.show('modal-api-loading')
      const paxInfo = queueCode.value === 'CN' ? oldPassenger.value : undefined
      const newPaxInfo = queueCode.value === 'CN' ? newPassenger.value : undefined
      const remark = ['SC', 'SCD'].includes(queueCode.value) ? segmentToSC.value : undefined
      const res = await sendQueue({
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        agencyCode: bookingData.value.agency,
        queueCode: queueCode.value,
        paxInfo,
        newPaxInfo,
        remark,
      })
      responseCommand.value = res?.booking.length ? res?.booking.map(i => i?.data || '').join('\n') : ''
      this.$bvModal.hide('modal-api-loading')
      this.$bvModal.hide('modal-send-queues')
      this.$bvModal.show(`modal-reservation-show-text${idModal.value}`)
    }

    const titleOptions = ref(['MR', 'MRS', 'MS', 'MSTR', 'MISS'])

    const segmentTK = computed(() => bookingData.value.itineraries.flat()
      .filter(seg => seg?.status === 'TK')
      .map(seg => ({ trip: convertShortTrip(seg), segmentId: seg.segmentId })))

    function openModalHandle() {
      queueCode.value = 'CN'
      newPassenger.value = {
        firstName: null,
        lastName: null,
        title: null,
      }
      oldPassenger.value = null
      segmentToSC.value = null
      responseCommand.value = null
    }

    return {
      sendQueuesHandle,
      passengerOptions,
      queueCode,
      queueCodeOptions,
      trimUpperCaseInput,
      refFormObserver,
      getValidationState,
      newPassenger,
      oldPassenger,
      openModalHandle,
      titleOptions,
      segmentTK,
      segmentToSC,
      responseCommand,
      idModal,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.custom-checkbox ::v-deep {
  .custom-control-label {
    margin-top: 0;
  }
}
</style>
