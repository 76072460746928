var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "border-info rounded mb-50",
    attrs: {
      "type": "border-info card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "classHeader": "card-header bg-light-info",
      "title": ""
    },
    on: {
      "visible": _vm.onVisibleHandle
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.history')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    ref: "refAuditLogListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "80vh"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "items": _vm.auditLogs,
      "busy": _vm.loading,
      "fields": _vm.tableColumns,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(column.label !== 'reapply' ? _vm.$t("auditLog.columns.".concat(data.label)) : '') + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(timestamp)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.timestamp).dateTime) + " ")])];
      }
    }, {
      key: "cell(modifiedBy)",
      fn: function fn(_ref) {
        var _item$modifiedBy, _item$modifiedBy2, _item$modifiedBy3, _item$modifiedBy4, _item$modifiedBy5, _item$modifiedBy6, _item$modifiedBy7, _item$modifiedBy8, _item$modifiedBy9;
        var item = _ref.item;
        return [item !== null && item !== void 0 && item.modifiedBy ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.html.v-primary.window",
            modifiers: {
              "hover": true,
              "html": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "title": "<div class='d-flex flex-column align-items-start gap-2 font-small-4'>\n                <div>Username: <span class='text-uppercase'>".concat(item === null || item === void 0 ? void 0 : (_item$modifiedBy = item.modifiedBy) === null || _item$modifiedBy === void 0 ? void 0 : _item$modifiedBy.username, "</span></div>\n                <span>T\xEAn: ").concat(item === null || item === void 0 ? void 0 : (_item$modifiedBy2 = item.modifiedBy) === null || _item$modifiedBy2 === void 0 ? void 0 : _item$modifiedBy2.lastName, " ").concat(item === null || item === void 0 ? void 0 : (_item$modifiedBy3 = item.modifiedBy) === null || _item$modifiedBy3 === void 0 ? void 0 : _item$modifiedBy3.firstName, "</span>\n                <span>M\xE3 user: ").concat(item === null || item === void 0 ? void 0 : (_item$modifiedBy4 = item.modifiedBy) === null || _item$modifiedBy4 === void 0 ? void 0 : _item$modifiedBy4.employeeCode, "</span>\n                </div>")
          }
        }, [_c('strong', {
          staticClass: "mb-25 text-uppercase"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy5 = item.modifiedBy) === null || _item$modifiedBy5 === void 0 ? void 0 : _item$modifiedBy5.username) + " ")]), item !== null && item !== void 0 && (_item$modifiedBy6 = item.modifiedBy) !== null && _item$modifiedBy6 !== void 0 && _item$modifiedBy6.lastName || item !== null && item !== void 0 && (_item$modifiedBy7 = item.modifiedBy) !== null && _item$modifiedBy7 !== void 0 && _item$modifiedBy7.firstName ? _c('div', {
          staticClass: "text-truncate"
        }, [_vm._v(" (" + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy8 = item.modifiedBy) === null || _item$modifiedBy8 === void 0 ? void 0 : _item$modifiedBy8.lastName) + " " + _vm._s(item === null || item === void 0 ? void 0 : (_item$modifiedBy9 = item.modifiedBy) === null || _item$modifiedBy9 === void 0 ? void 0 : _item$modifiedBy9.firstName) + ") ")]) : _vm._e()]) : _vm._e()];
      }
    }, {
      key: "cell(newValue)",
      fn: function fn(data) {
        return [_c('HStack', {
          staticClass: "gap-2"
        }, [_c('Stack', _vm._l(data.item.newValue, function (value, key) {
          return _c('HStack', {
            key: key
          }, [_c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$te("notification.".concat(key)) ? _vm.$t("notification.".concat(key)) : key) + ": ")]), _c('span', {
            staticClass: "text-justify font-italic"
          }, [_vm._v(_vm._s(value))])]);
        }), 1)], 1)];
      }
    }, {
      key: "cell(reapply)",
      fn: function fn(data) {
        return [data.item.newValue ? _c('b-button', {
          staticClass: "p-50 text-nowrap",
          attrs: {
            "pill": "",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onReApplyHandle(data.item);
            }
          }
        }, [_vm._v(" Áp dụng lại ")]) : _vm._e()];
      }
    }], null, true)
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalAuditLogs,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }