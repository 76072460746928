<template>
  <div
    ref="divRef"
    class="bg-image d-flex-center flex-column"
    :style="`
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${backgroundFlightSearchUrl});
      min-height: ${heightCard};
    `"
  >
    <div class="search-flight-modal d-flex flex-column justify-content-start align-items-center mt-50">
      <div class="w-100 d-flex-between">
        <SearchSwitchTypeCheckbox :type.sync="searchData.type" />
        <b-button
          v-if="isShowResetSearch"
          variant="flat-dark"
          class="p-50 mb-50"
          @click="handleResetSearch"
        >
          <feather-icon
            class="cursor-pointer"
            icon="RotateCwIcon"
            size="20"
          />
        </b-button>
      </div>
      <oneWay
        v-if="searchData.type === 'OW'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        @swap-from-to="swapHandle"
      />
      <roundTrip
        v-if="searchData.type === 'RT'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        :isDisabledGroupedItinerary="isDisabledGroupedItinerary"
        @swap-from-to="swapHandle"
      />
      <multiCity
        v-if="searchData.type === 'MC'"
        :search-data.sync="searchData"
        :is-disabled-permitted-cariers="isDisabledPermittedCariers"
        :isDisabledGroupedItinerary="isDisabledGroupedItinerary"
        @swap-from-to="swapHandle"
      />

      <div class="w-100 d-flex justify-content-center align-items-center mt-0">
        <b-button
          v-ripple.400
          style="background-color: #1A5A7E !important;"
          pill
          :disabled="!isActiveSearchButton"
          @click="handleSearchButton"
        >
          <div class="d-flex align-items-center">
            <img
              src="@icons/search.svg"
              alt="Search"
              class="mr-25 ico-bold"
            >
            {{ $t('flight.searchFlight') }}
          </div>
        </b-button>
      </div>
    </div>

    <div class="search-flight-history my-1 p-1">
      <div class="fw-700 text-center mb-50">
        {{ $t('flight.historySearchFlight') }}
      </div>
      <div
        v-if="!isEmpty(flightsHistory)"
        class="scroll-container d-flex overflow-auto gap-2"
        style="justify-content: safe center"
      >
        <div
          v-for="(item, index) of flightsHistory"
          :key="index"
          class="p-50 bg-white rounded-lg mb-25 border cursor-pointer hover-overlay"
          @click="handleApplyHistory(item)"
        >
          <div class="d-flex-between fw-800 text-nowrap mb-50">
            <span class="mr-75">
              {{ $t(`flight.${item.flightRequest.type}`) }}
            </span>
            <small class="fw-700 text-nowrap ml-75">
              <span
                v-if="item.flightRequest.adult"
                class="text-nowrap fw-800"
              >
                <span class="text-primary">{{ item.flightRequest.adult }}</span> NL
              </span>
              <span
                v-if="item.flightRequest.child"
                class="text-nowrap fw-800"
              >
                <span class="text-primary">{{ item.flightRequest.child }}</span> TE
              </span>
              <span
                v-if="item.flightRequest.infant"
                class="text-nowrap"
              >
                <span class="text-primary fw-800">{{ item.flightRequest.infant }}</span> EB
              </span>
            </small>
          </div>

          <!-- ANCHOR - RT -->
          <template v-if="item.flightRequest.type === 'RT'">
            <div class="border-primary rounded mb-25 py-25 px-50">
              <div class="d-flex-center gap-1">
                <div :class="`d-flex align-items-center text-primary`">
                  <span class="fw-700 text-nowrap">{{ item.flightRequest.flights[0].startPoint }}-{{ item.flightRequest.flights[0].endPoint }}</span>
                </div>
                <div class="fw-700 text-nowrap">
                  - {{ convertISODateTime(item.flightRequest.flights[0].departDate).date }}
                </div>
              </div>
            </div>
            <div class="border-primary rounded mb-25 py-25 px-50">
              <div class="d-flex-center gap-1">
                <div :class="`d-flex align-items-center text-primary`">
                  <span class="fw-700 text-nowrap">{{ item.flightRequest.flights[0].endPoint }}-{{ item.flightRequest.flights[0].startPoint }}</span>
                </div>
                <div class="fw-700 text-nowrap">
                  - {{ convertISODateTime(item.flightRequest.flights[0].returnDate).date }}
                </div>
              </div>
            </div>
          </template>

          <!-- ANCHOR - OW, MC -->
          <template v-else>
            <div
              v-for="(flight, flightIndex) of item.flightRequest.flights"
              :key="flightIndex"
              class="border-primary rounded mb-25 py-25 px-50"
            >
              <div
                v-if="flightIndex < 3"
                class="d-flex align-items-center gap-1"
              >
                <div v-if="item.flightRequest.flights.length > 1">
                  {{ flightIndex + 1 }}:
                </div>
                <div :class="`d-flex align-items-center text-primary`">
                  <span class="fw-700 text-nowrap">{{ flight.startPoint }}-{{ flight.endPoint }}</span>
                </div>
                <div class="fw-700 text-nowrap">
                  - {{ convertISODateTime(flight.departDate).date }}
                </div>
                <span
                  v-if="item.flightRequest.type === 'RT'"
                  class="fw-700 text-nowrap "
                >
                  - {{ convertISODateTime(flight.returnDate).date }}
                </span>
              </div>
              <template v-else-if="flightIndex === 3">
                <small class="pr-1 text-info fw-700">
                  +{{ item.flightRequest.flights.length - 3 }} {{ $t('reservation.itinerary') }}...
                </small>
              </template>
            </div>
          </template>
        </div>
      </div>

      <div
        v-else-if="loadingHistory"
        class="d-flex-center my-50"
      >
        <b-spinner
          variant="info"
          label="Text Centered"
          small
          tag="span"
        />
      </div>

      <div
        v-else
        class="d-flex-center my-50 fw-700 bg-white rounded-lg py-75"
      >
        {{ $t('flight.cheapestFareNotFound') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onUnmounted, watch, computed, onMounted,
} from '@vue/composition-api'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { BButton, BSpinner } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { defaultDeparture, defaultArrival } from '@/constants/flight'
import { isDomesticFlight } from '@/constants/selectOptions'
import env from '@/libs/env'
import { apiEmployee } from '@/api'

import { convertISODateTime } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'
import storeModule from '@flightv2/bookingStoreModule'
import oneWay from '@flightv2/search/one-way.vue'
import roundTrip from '@flightv2/search/round-trip.vue'
import multiCity from '@flightv2/search/multi-city.vue'

import useToast from '@useToast'

import SearchSwitchTypeCheckbox from './components/SearchSwitchTypeCheckbox.vue'

export default {
  components: {
    BButton,
    BSpinner,

    oneWay,
    roundTrip,
    multiCity,
    SearchSwitchTypeCheckbox,
  },
  setup(_, { root }) {
    const { toastError, toastSuccess } = useToast()
    const {
      fetchAirportGroup,
      FLIGHT_APP_STORE_MODULE_NAME,
      resetStore,
      getAirportsByCodeList,
      checkDateTimeMultiCityFlight,
    } = useBookingHandle()

    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }
    resetStore(true)
    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(async () => {
      const distributorOptions = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getDistributorOptions`])
      if (isEmpty(distributorOptions.value)) {
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDistributorOptions`)
      }
    })

    const loadingHistory = ref(false)
    const getMeData = computed(() => store.getters['userStore/getMeData'])
    const getId = computed(() => store.getters['userStore/getMeDataId'])
    const flightsHistory = ref(getMeData.value?.flightsHistory || [])

    async function fetchHistorySearch(id) {
      try {
        loadingHistory.value = true
        const { data } = await apiEmployee.getEmployeeById(id)
        flightsHistory.value = data?.flightsHistory || getMeData.value?.flightsHistory || []
      } catch (error) {
        console.error(error)
      } finally {
        loadingHistory.value = false
      }
    }

    watch(() => getId.value, id => {
      if (!id) return
      fetchHistorySearch(id)
    }, { immediate: true })

    const { router } = useRouter()
    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])

    const blankSearchData = {
      adult: 1,
      child: 0,
      infant: 0,
      type: 'OW',
      currency: 'VND',
      numberOfStop: 0,
      flights: [
        {
          startPoint: null,
          endPoint: null,
          departDate: convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter,
          returnDate: convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4))).dateFilter,
        },
      ],
      airlines: [],
      promoCodes: [], // 'TESTBB01'
      isSearchClassBooking: false,
      isSearchMonthlyCheapestFare: false,
      cabinPreference: undefined,
      contentSource: undefined,
      isGroupedItineraryResponse: true,
      permittedCarriers: [],
    }
    const searchData = ref(cloneDeep(blankSearchData))
    const searchFlightPrev = env.ignoreCacheFlightSearchData ? null : JSON.parse(localStorage.getItem('searchFlight'))
    if (searchFlightPrev) {
      if (!searchFlightPrev.flights[0].departDate) {
        searchFlightPrev.flights[0].departDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
      }
      // handle case chọn OW => lưu localStorage => chuyển sang RT ko có returnDate
      if (!searchFlightPrev.flights[0].returnDate) {
        searchFlightPrev.flights[0].returnDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4))).dateFilter
      }
      // handle contentSource
      if (searchFlightPrev.contentSource) {
        searchFlightPrev.airlines.push(searchFlightPrev.contentSource)
        searchFlightPrev.contentSource = undefined
      }
      // handle case ngày lưu trong localStorage < ngày hiện tại => gán = ngày hiện tại + 1
      searchFlightPrev.flights.forEach(trip => {
        if (new Date(trip.departDate) - new Date() < 0) {
          trip.departDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
        }
        if (new Date(trip.returnDate) - new Date() < 0) {
          trip.returnDate = convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter
        }
      })

      if (searchFlightPrev.adult === 0) {
        searchFlightPrev.adult = 1
      }

      if (searchFlightPrev.permittedCarriers) {
        searchFlightPrev.permittedCarriers = []
      }

      if (searchFlightPrev.isSearchClassBooking && (!searchFlightPrev.airline || !['VN1A', 'VU', '1G'].includes(searchFlightPrev.airline))) {
        searchFlightPrev.airline = ''
        searchFlightPrev.airlines = []
        searchFlightPrev.isSearchClassBooking = false
      }

      searchData.value = cloneDeep(searchFlightPrev)
    }

    if (isEmpty(store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`])) { fetchAirportGroup() }

    if (!searchData.value.flights[0].startPoint) {
      searchData.value.flights[0].startPoint = defaultDeparture
    }

    if (!searchData.value.flights[0].endPoint) {
      searchData.value.flights[0].endPoint = defaultArrival
    }

    async function fetchConfigSearch(codes) {
      await getAirportsByCodeList(codes).then(response => {
        searchData.value.flights[0].startPoint = response.data.find(point => point.iata === codes[0])
        searchData.value.flights[0].endPoint = response.data.find(point => point.iata === codes[1])
      })
    }

    watch(employeeConfig, async (config, oldConfig) => {
      if (!searchFlightPrev && !oldConfig && config?.departureCode && config?.arrivalCode) {
        await fetchConfigSearch([config.departureCode, config.arrivalCode])
      }
    }, { deep: true, immediate: true })

    async function handleResetSearch() {
      root.$emit('bv::show::modal', 'modal-api-loading')
      searchData.value = cloneDeep(blankSearchData)
      await fetchConfigSearch([employeeConfig.value.departureCode, employeeConfig.value.arrivalCode])
      localStorage.setItem('searchFlight', JSON.stringify(searchData.value))
      setTimeout(() => {
        root.$emit('bv::hide::modal', 'modal-api-loading')
      }, 300)
    }

    function swapHandle(index = 0) {
      const temp = searchData.value.flights[index].startPoint
      searchData.value.flights[index].startPoint = searchData.value.flights[index].endPoint
      searchData.value.flights[index].endPoint = temp
    }

    const searchV2Data = ref(null)

    const defaultSearchClassBookingData = {
      airlines: ['VU'],
      numberOfStop: 0,
      isSearchMonthlyCheapestFare: false,
      isGroupedItineraryResponse: false,
    }

    const defaultSearchMonthlyCheapestData = {
      airlines: ['VN', 'VJ', 'QH', 'VU'],
      // numberOfStop: 1,
      isSearchClassBooking: false,
      isGroupedItineraryResponse: false,
    }

    const { backgroundFlightSearchUrl, isProduction } = env
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    watch(() => searchData.value.isSearchClassBooking, val => {
      if (val) { // true
        searchV2Data.value = {
          airlines: searchData.value.airlines,
          numberOfStop: searchData.value.numberOfStop,
          isGroupedItineraryResponse: searchData.value?.isGroupedItineraryResponse,
        }

        const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))

        searchData.value = {
          ...searchData.value,
          ...defaultSearchClassBookingData,
          numberOfStop: isDomestic ? 0 : 99,
          airlines: isDomestic ? ['VN1A'] : (!isProduction || (isProduction && !isRoleF3.value)) ? ['1G'] : ['VN1A'],
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
          airlines: [],
          airline: '',
        }
      }
    }, { immediate: true })

    watch(() => searchData.value.isSearchMonthlyCheapestFare, val => {
      if (val) {
        searchV2Data.value = {
          airlines: searchData.value.airlines,
          // numberOfStop: searchData.value.numberOfStop,
          isGroupedItineraryResponse: searchData.value?.isGroupedItineraryResponse,
        }

        searchData.value = {
          ...searchData.value,
          ...defaultSearchMonthlyCheapestData,
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
        }
      }
    }, { immediate: true })

    watch(() => searchData.value.isGroupedItineraryResponse, val => {
      if (val && (searchData.value.isSearchClassBooking || searchData.value.isSearchMonthlyCheapestFare)) {
        searchData.value.isSearchClassBooking = false
        searchData.value.isSearchMonthlyCheapestFare = false
      }
    }, { immediate: true })

    function modifySearchData(searchData) {
      searchData.airlines = searchData.airlines ?? []
      const hasSelectValidSource = searchData?.airlines?.some(al => !!al && ['1G', '1A', 'VN1A'].includes(al))

      // handle contentSource for 1G
      if (searchData.airlines.includes('GDS') && searchData.airlines.includes('NDC')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'GDS' && item !== 'NDC')
        searchData.contentSource = undefined
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      } else if (searchData.airlines.includes('GDS')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'GDS')
        searchData.contentSource = 'GDS'
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      } else if (searchData.airlines.includes('NDC')) {
        searchData.airlines = searchData.airlines.filter(item => item !== 'NDC')
        searchData.contentSource = 'NDC'
        if (!hasSelectValidSource) searchData.airlines.push('1G')
      }

      // NOTE: OW, vé rẻ tháng bỏ giá kết hợp
      // if (searchData.type === 'OW' || searchData.value?.isSearchMonthlyCheapestFare) {
      //   searchData.isGroupedItineraryResponse = false
      // }

      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }
      if (!searchData?.airlines && searchData.airline) {
        searchData.airlines = [searchData.airline]
      }
      const payload = { ...JSON.parse(JSON.stringify(searchData)) }
      payload.airlines = searchData.airlines.map(item => item.toUpperCase())
      payload.flights = payload.flights.map((item, indexFlight) => {
        if ((!item?.returnDate || !item?.departDate
          || (moment(item?.returnDate) - moment().subtract(1, 'day') < 0)
          || (moment(item?.departDate) - moment().subtract(1, 'day') < 0))
          && indexFlight === 0 && payload.type === 'RT') {
          toastError('messagesList["Please select departure date / arrival date"]')
          throw Error('messagesList["Please select departure date / arrival date"]')
        }
        if (!(item?.startPoint?.iata || item?.startPoint) || !(item?.endPoint?.iata || item?.endPoint)) {
          toastError('messagesList["Please select departure / destination"]')
          throw Error('messagesList["Please select departure / destination"]')
        }
        // eslint-disable-next-line consistent-return
        return {
          ...item,
          startPoint: item?.startPoint?.iata || item?.startPoint,
          endPoint: item?.endPoint?.iata || item?.endPoint,
        }
      })

      if (searchData.type === 'MC') {
        const dates = searchData.flights.map(flight => flight.departDate)
        const invalidDateValue = checkDateTimeMultiCityFlight(dates)
        if (invalidDateValue) {
          toastError('messagesList["invalid date multi city"]')
          throw Error('invalid date multi city')
        }
      }

      return JSON.stringify(payload)
    }

    function modifySearchClassBooking(searchData) {
      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }

      const payload = {
        airline: searchData.airlines.some(i => ['VU'].includes(i)) ? ['VU'] : [searchData.airlines[0]],
        airlines: [searchData.airlines[0]],
        adult: searchData.adult,
        child: searchData.child,
        infant: searchData.infant,
        type: searchData.type,
        isGroupedItineraryResponse: searchData.isGroupedItineraryResponse,
        isSearchMonthlyCheapestFare: searchData.isSearchMonthlyCheapestFare,
        isSearchClassBooking: searchData.isSearchClassBooking,
        numberOfStop: searchData?.numberOfStop || 0,
        flights: searchData.flights.map(i => ({
          startPoint: i.startPoint?.iata || i.startPoint,
          endPoint: i.endPoint?.iata || i.endPoint,
          departDate: convertISODateTime(i.departDate).dateFilter,
          ...((searchData.type === 'RT') && { returnDate: convertISODateTime(i.returnDate).dateFilter }),
        })),
        nextResultReference: '',
        currency: 'VND',
      }

      if (searchData.airlines?.includes('1G')) {
        if (!isEmpty(searchData?.permittedCarriers)) {
          payload.permittedCarriers = searchData.permittedCarriers
        }

        payload.source = '1G'
      } else if (searchData.airlines?.includes('VN1A')) {
        // if (payload.permittedCarriers) delete payload.permittedCarriers
        payload.permittedCarriers = !isEmpty(searchData.permittedCarriers) ? searchData.permittedCarriers : undefined

        payload.isBook = true
        payload.source = 'VN1A'
      } else if (payload.source) {
        if (payload.permittedCarriers) delete payload.permittedCarriers
        delete payload.source
      }

      return JSON.stringify(payload)
    }

    function handleSearchButton() {
      if (searchData.value?.isSearchClassBooking) {
        localStorage.setItem('searchFlight', modifySearchClassBooking(searchData.value))

        router.push({
          name: 'apps-flightsV2-class-booking-result',
        })
      } else if (searchData.value?.isSearchMonthlyCheapestFare) {
        localStorage.setItem('searchFlight', modifySearchData(searchData.value))

        router.push({
          name: 'apps-flightsV2-monthly-cheap-fare-result',
        })
      } else {
        const resModify = modifySearchData(searchData.value)
        if (!resModify) return
        localStorage.setItem('searchFlight', resModify)

        router.push({
          name: 'apps-flightsV2-result',
        })
      }
    }

    const isActiveSearchButton = ref(true)
    watch(searchData.value.flights, () => {
      isActiveSearchButton.value = searchData.value.flights.every(item => {
        if (!item?.departDate || (!item?.returnDate && ['RT'].includes(searchData.value?.type))) {
          return false
        }
        const sPoint = item?.startPoint?.iata || item?.startPoint
        const ePoint = item?.endPoint?.iata || item?.endPoint

        if (sPoint && ePoint && (sPoint === ePoint)) {
          toastError({
            title: 'messagesList["Departure and destination must be difference"]',
          })
          return false
        }
        return true
      })

      const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
      const hasVCS = searchData.value.flights.some(item => [item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint].includes('VCS'))

      if ((!isDomestic && searchData.value.numberOfStop !== 99) || hasVCS) {
        searchData.value.numberOfStop = 99
      } else if (isDomestic && searchData.value.numberOfStop !== 0) {
        searchData.value.numberOfStop = 0
      }

      // searchData.value.isGroupedItineraryResponse = (!['OW'].includes(searchData.value.type) && !searchData.value.isSearchClassBooking) ? !isDomestic : false

      if (!isDomestic && searchData.value.isSearchClassBooking) {
        if (!isProduction || (isProduction && !isRoleF3.value)) {
          searchData.value.airlines = ['1G']
        }
      }

      if (isDomestic && searchData.value.isSearchClassBooking && searchData.value.airlines.includes('1G')) {
        searchData.value.airlines = ['VN1A']
      }
    }, { deep: true, immediate: true })

    const airlinesLength = computed(() => searchData.value?.airlines?.length || 0)

    // const isDisabledGroupedItinerary = computed(() => {
    //   // const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
    //   const checkAirlinesHas1G = airlinesLength.value && !['1G', 'GDS', 'NDC', '1A', 'VN1A'].some(item => searchData.value.airlines.includes(item))
    //   if (// isDomestic ||
    //     checkAirlinesHas1G) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     searchData.value.isGroupedItineraryResponse = false
    //     return true
    //   }
    //   return false
    // })

    const isDisabledGroupedItinerary = ref(false)

    const isDisabledPermittedCariers = computed(() => {
      const isDomestic = searchData.value.flights.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
      const checkAirlinesHas1G = airlinesLength.value && !['1G', 'GDS', 'NDC', '1A', 'VN1A'].some(item => searchData.value.airlines.includes(item))

      if (searchData.value.isSearchClassBooking && searchData.value?.airlines?.some(a => ['VN1A'].includes(a))) {
        return false
      }

      if (isDomestic || checkAirlinesHas1G) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        searchData.value.permittedCarriers = []
        return true
      }
      return false
    })

    const permittedCarriersLength = computed(() => searchData.value?.permittedCarriers?.length || 0)
    watch(() => permittedCarriersLength.value, permittedCarriersLength => {
      searchData.value.airlines = permittedCarriersLength > 0 ? searchData.value?.airlines?.some(a => ['1A', 'VN1A', '1G'].includes(a)) ? searchData.value?.airlines?.filter(a => ['1A', 'VN1A', '1G'].includes(a)) : [] : []
    })

    const isShowResetSearch = computed(() => !isNil(employeeConfig.value))

    const divRef = ref()
    const heightCard = computed(() => {
      const offsetTop = divRef.value?.offsetTop ?? 0
      return offsetTop ? `calc(100vh - ${offsetTop}px - 20px)` : 'calc(100vh - 140px)'
    })

    function handleApplyHistory(item) {
      const history = cloneDeep(item)
      const startDate = history?.flightRequest?.flights?.[0]?.departDate
      if (!startDate) {
        toastError({ title: 'messagesList["Invalid date"]' })
        return
      }

      const timeStartDate = moment(history.flightRequest.flights[0].startDate)
      const timeCurrentDate = moment()
      const isBefore = timeStartDate.isBefore(timeCurrentDate, 'day')
      if (isBefore) {
        toastError({ title: 'messagesList["Cannot search for flights of past dates"]' })
        return
      }

      searchData.value.type = history.flightRequest.type
      searchData.value.adult = history.flightRequest.adult
      searchData.value.child = history.flightRequest.child
      searchData.value.infant = history.flightRequest.infant
      searchData.value.numberOfStop = history.flightRequest.numberOfStop
      searchData.value.flights = history.flightRequest.flights

      searchData.value.isSearchClassBooking = false
      searchData.value.isSearchMonthlyCheapestFare = false

      if (history.flightRequest.isGroupedItineraryResponse) {
        searchData.value.airlines = history.flightRequest.sources
        searchData.value.isGroupedItineraryResponse = true
      } else {
        searchData.value.airlines = []
      }

      if (searchData.value?.isSearchMonthlyCheapestFare || searchData.value?.isSearchClassBooking) {
        searchData.value.isSearchMonthlyCheapestFare = false
        searchData.value.isSearchClassBooking = false
      }

      toastSuccess({ title: 'messagesList["Updated search information"]' })

      // if (searchData.value.airline) delete searchData.value.airline
      // if (searchData.value.source) delete searchData.value.source

      // nextTick(() => {
      //   handleSearchButton()
      // })
    }

    return {
      searchData,
      isEmpty,
      loadingHistory,
      swapHandle,
      convertISODateTime,
      modifySearchData,
      handleResetSearch,
      handleSearchButton,
      handleApplyHistory,
      isActiveSearchButton,
      isDisabledPermittedCariers,
      isShowResetSearch,
      isDisabledGroupedItinerary,
      backgroundFlightSearchUrl,
      divRef,
      heightCard,
      flightsHistory,
    }
  },
}
</script>

<style scoped lang="scss">
.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
}

.search-flight-modal {
  background: #FFFFFF33;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1136px;
  max-width: calc(100% - 24px);
  padding: 30px
}

@media (max-width: 768px) {
  .search-flight-modal {
    padding: 12px
  }
}

.search-flight-history {
  background: #FFFFFF33;
  // backdrop-filter: blur(10px); // lỗi z index select country
  border-radius: 20px;
  width: 1136px;
  max-width: calc(100% - 24px);
  padding: 30px;
}

@media (max-width: 768px) {
  .search-flight-history {
    padding: 12px
  }
}

/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

</style>
