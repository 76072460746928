var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-ancillary-pay-options",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between align-items-center"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(" Thanh toán ")]), _c('b-button', {
          staticClass: "px-1 py-50",
          attrs: {
            "variant": "flat-secondary"
          },
          on: {
            "click": close
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })], 1)], 1)];
      }
    }, {
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 px-md-2",
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          class: ['VU', 'QH'].includes(_vm.bookingData.source) ? 'btn-gradient' : '',
          attrs: {
            "variant": ['VU', 'QH'].includes(_vm.bookingData.source) ? '' : _vm.isPay ? 'success' : 'warning',
            "pill": ""
          },
          on: {
            "click": _vm.handleConfirm
          }
        }, [_c('span', [_vm._v(" " + _vm._s(['VU', 'QH'].includes(_vm.bookingData.source) ? 'Xác nhận' : _vm.isPay ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU') + " ")])])];
      }
    }])
  }, [_vm.bookingData && ['VJ'].includes(_vm.bookingData.source) ? _c('CardAncillaryPayOptions', {
    attrs: {
      "reservations-data": _vm.bookingData,
      "is-pay-now": _vm.isPay,
      "has-paid-trip": _vm.hasPaidTrip,
      "place": _vm.place
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPay = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPay = $event;
      }
    }
  }) : _vm._e(), _vm.bookingData && !['VJ'].includes(_vm.bookingData.source) ? _c('div', [_vm._v(" Xác nhận thêm dịch vụ? ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }