import service from './service'

export default {
  getTourBlock(params) {
    return service.get('/tours/block', params)
  },

  getTourCategory(params) {
    return service.get('/tours/category', params)
  },

  getTourRow(params) {
    return service.get('/tours/row', params)
  },

  getTourRowDetail(rowId) {
    return service.get(`/tours/row/${rowId}`)
  },

  getTourCalendar(tourId, params) {
    return service.get(`/tours/calendar/list/${tourId}`, params)
  },

  checkSeatAvailable(priceId) {
    return service.get(`/tours/check_seat_available/${priceId}`)
  },

  createTourBooking(payload) {
    return service.post('/tours/booking', payload)
  },

  fetchBookingList(params) {
    return service.get('/tours/booking', params)
  },
  getBooking(id) {
    return service.get(`/tours/booking/${id}`)
  },
  requestCancelBooking(id, note = undefined) {
    return service.post(`/tours/booking/${id}/request_cancel`, { note })
  },
  confirmPaymentBooking(bookingCode, payload) {
    return service.post(`/tours/booking/${bookingCode}/confirm_payment`, payload)
  },
  confirmUpdateBooking(bookingCode, payload) {
    return service.post(`/tours/booking/${bookingCode}/confirm_update`, payload)
  },

  // xac nhan tour
  requestSupportBooking(bookingCode, payload) {
    return service.post(`/tours/booking/${bookingCode}/request_support`, payload)
  },
}
