<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header class="p-1">
        <div class="flex-grow-1 text-truncate">
          <h4 class="card-title d-flex-center justify-content-start font-weight-bolder flex-grow-1">
            <b-button
              v-b-tooltip.hover.v-info.window
              variant="flat-info"
              class="px-1 mr-50"
              title="Quay lại danh sách thư mục"
              @click="() => $router.push({ name: 'apps-documents-folder-view' })"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>
            <span>{{ folder.name }}</span>
          </h4>
        </div>
        <b-row class="m-1 w-100">
          <b-col cols="4">
            <b-form-input
              v-model="documentNameFilter"
              type="search"
              :placeholder="$t('document.searchNameDoc')"
              :value="documentNameFilter"
              :reduce="val => val.value"
              maxlength="255"
              debounce="500"
              trim
            />
          </b-col>
          <b-col cols="8">
            <div class="d-flex align-items-center justify-content-end">
              <!-- Button create -->
              <b-button
                v-if="canModify"
                variant="info"
                @click="() => $router.push({ name: 'apps-documents-add', params: { id: folderId } })"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer mr-25"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('document.createDoc') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDocumentListTable"
          style="max-height: 64vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchAllDocuments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ data.label ? $t(`document.columns.docs.${data.label}`) : '' }}
            </span>
          </template>
          <template #cell(name)="data">
            <div
              class="d-flex"
              style="max-width: 300px;"
            >
              <div class="w-100 flex-grow-1">
                <b-link
                  :to="{
                    name: 'apps-documents-edit',
                    params: { id: data.item.id },
                  }"
                  class="font-weight-bold d-block text-info"
                >
                  {{ data.item.name }}
                </b-link>
              </div>
            </div>
          </template>

          <template #cell(file)="data">
            <div
              v-if="data.item.files && data.item.files.length"
              class="d-flex flex-column"
            >
              <b-link
                v-for="(file, indexFile) in data.item.files"
                :key="indexFile"
                v-b-tooltip.hover.v-info.window
                :title="file.file"
                @click="confirmDownloadFile(file)"
              >
                <small>{{ shortenFileName(file.file) }}</small>
              </b-link>
            </div>
            <span v-else>N/A</span>
          </template>

          <template #cell(isActive)="data">
            <BBadge
              :variant="data.item.isActive ? 'success' : 'secondary'"
              pill
              :class="`${data.item.isActive ? 'badge-glow' : ''}`"
            >
              {{ data.item.isActive ? $t('document.published') : $t('document.draft') }}
            </BBadge>
          </template>

          <template #cell(status)="data">
            <div class="d-flex-center flex-column gap-1">
              <BBadge
                :variant="data.item.isActive ? 'success' : 'secondary'"
                pill
                :class="`${data.item.isActive ? 'badge-glow' : ''}`"
              >
                {{ data.item.isActive ? $t('document.published') : $t('document.draft') }}
              </BBadge>
              <BBadge
                variant="success"
                pill
                class="badge-glow"
              >
                {{ data.item.isShow ? $t('document.forF3') : $t('document.forF2') }}
              </BBadge>
            </div>
          </template>

          <template #cell(createdAt)="data">
            <div>
              <div>{{ dateTime(data.item.createdAt) }}</div>
              <small v-if="isShowUserUpdated">{{ $t('by') }} <span class="font-weight-bolder">{{ data.item.createdBy.username }}</span></small>
            </div>
          </template>

          <template #cell(updatedAt)="data">
            <div>
              <div>{{ dateTime(data.item.updatedAt) }}</div>
              <small v-if="isShowUserUpdated">{{ $t('by') }} <span class="font-weight-bolder">{{ data.item.updatedBy.username }}</span></small>
            </div>
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="canModify"
              v-b-tooltip.hover.v-info
              variant="flat-info"
              class="p-75"
              :title="isMobileView ? '' : (data.item.isPin ? 'Gỡ treo thông báo' : 'Treo thông báo')"
              @click="confirmTogglePin(data.item)"
            >
              <img
                v-if="data.item.isPin"
                :src="require('@icons/unpin.svg')"
                style="cursor: pointer"
              >
              <img
                v-else
                :src="require('@icons/pin.svg')"
                style="cursor: pointer"
              >
            </b-button>

            <b-button
              v-if="canModify"
              v-b-tooltip.hover.v-danger
              variant="flat-danger"
              class="px-1"
              :title="isMobileView ? '' : 'Xoá tài liệu'"
              @click="confirmDelete(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-1 d-flex-center justify-content-sm-start">
        <div class="text-muted text-nowrap">
          {{ $t('paginationText.total') }} {{ totalDocuments }} {{ $t('document.doc') }}
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BButton,
  BTable,
  BLink,
  BOverlay,
  BBadge,
  VBTooltip,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  computed,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'

import store from '@/store'

import { useRouter } from '@core/utils/utils'
import { dateTime } from '@core/utils/filter'

import documentStoreModule from '@document/documentStoreModule'
import useDocumentHandle from '@document/useDocumentHandle'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
    BTable,
    BLink,
    BBadge,
    BOverlay,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const DOCUMENT_APP_STORE_MODULE_NAME = 'app-documents'

    // Register module
    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
      }
    })
    const { route } = useRouter()

    const {
      fetchAllDocuments,
      refDocumentListTable,
      documentTableColumns,
      totalDocuments,
      loading,
      deleteDocument,
      togglePinDocument,
      getDocumentById,
      canModify,
      shortenFileName,
      confirmDownloadFile,
      documentNameFilter,
      refetchDocumentData,
    } = useDocumentHandle()

    const tableColumns = computed(() => !canModify.value
      ? documentTableColumns.value.filter(item => !['action', 'isActive', 'status', 'isShow'].includes(item.key))
      : documentTableColumns.value)

    watch(
      [documentNameFilter],
      () => {
        refetchDocumentData()
      },
    )

    const folderId = computed(() => route.value.params.id)
    const folder = ref({})
    getDocumentById(folderId.value)
      .then(res => {
        folder.value = res.data
      })

    function confirmDelete(item) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `<span class='text-break'>Bạn có muốn xoá tài liệu <em class='font-weight-bolder'>${item?.name}</em> ?</span>` } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'XOÁ TÀI LIỆU',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteDocument(item)
          }
        })
    }
    function confirmTogglePin(item) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `<span class='text-break'>Bạn có muốn ${item.isPin ? 'gỡ treo thông báo' : 'treo thông báo'} tài liệu <em class='font-weight-bolder'>${item?.name}</em> ?</span>` } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'TREO TÀI LIỆU',
          size: 'sm',
          okVariant: item.isPin ? 'danger' : 'info',
          okTitle: item.isPin ? 'Gỡ treo thông báo' : 'Treo thông báo',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            togglePinDocument(item)
          }
        })
    }

    // nếu là ADM OPE F1 thì mới hiển thị người tạo/sửa đổi của tài liệu
    const getUserType = computed(() => store.getters['userStore/getMeDataType'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isShowUserUpdated = computed(() => (getUserType.value === 'ADM' || getUserType.value === 'OPE') && isRoleF1.value)
    return {
      folderId,
      folder,
      fetchAllDocuments,
      refDocumentListTable,
      confirmDelete,
      tableColumns,
      totalDocuments,
      confirmDownloadFile,
      dateTime,
      loading,
      shortenFileName,
      canModify,
      isShowUserUpdated,
      documentNameFilter,
      confirmTogglePin,
    }
  },
}
</script>
