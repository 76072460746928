<template>
  <component
    :is="isMobileView ? 'Stack' : 'HStack'"
    class="mb-50"
  >
    <!-- ANCHOR - Filter Booking -->
    <b-row :class="`${isMobileView ? 'gap-2' : 'd-flex-between flex-nowrap flex-1'}`">
      <!-- ANCHOR book date -->
      <b-col
        cols="12"
        md="6"
        lg="3"
      >
        <b-form-group class="w-100 mb-0">
          <b-input-group id="range-date">
            <flat-pickr
              v-model="rangeDate"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                class: 'form-control-md',
                altInput: true,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
                onClose: (selectedDates, dateStr, instance) => onCloseHandler(selectedDates, dateStr, instance),
                onChange: (selectedDates, dateStr, instance) => onChangeHandler(selectedDates, dateStr, instance),
              }"
              :placeholder="$t('train.filter.bookDatePlaceholder')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR flight date -->
      <b-col
        cols="12"
        md="6"
        lg="3"
      >
        <b-form-group class="w-100 mb-0">
          <b-input-group id="range-date">
            <flat-pickr
              v-model="rangeTripDate"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                altInput: true,
                class: 'form-control-md',
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
                onClose: (selectedDates, dateStr, instance) => onCloseHandler(selectedDates, dateStr, instance),
                onChange: (selectedDates, dateStr, instance) => onChangeHandler(selectedDates, dateStr, instance),
              }"
              :placeholder="$t('train.filter.tripDatePlaceholder')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR: Status -->
      <b-col
        cols="12"
        md="6"
        lg="3"
      >
        <b-form-group
          label-for="status-filter"
          class="w-100 mb-0"
        >
          <v-select
            id="status-filter"
            :value="statusFilter"
            :options="statusOptionsWithAll"
            label="label"
            style="font-size: 1rem"
            searchable
            clearable
            :placeholder="$t('train.filter.statusPlaceholder')"
            :reduce="val => val.value"
            append-to-body
            :calculate-position="withPopper2"
            @input="(val) => $emit('update:statusFilter', val)"
          >
            <template #selected-option="{ label }">
              <div class="d-flex gap-1">
                {{ $t(`tour.status.${label}`) }}
                <span
                  v-if="label === 'ALL'"
                  class="text-lowercase"
                >
                  ({{ $t('default') }})
                </span>
              </div>
            </template>
            <template #option="{ label }">
              <div class="d-flex gap-1">
                {{ $t(`tour.status.${label}`) }}
                <span
                  v-if="label === 'ALL'"
                  class="text-lowercase"
                >
                  ({{ $t('default') }})
                </span>
              </div>
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="3"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="input-booking-code"
            v-remove-non-numeric-chars.allNumber
            :value="bookingCodeFilter"
            type="search"
            maxlength="6"
            minlength="6"
            :formatter="removeAccentsUpperCaseFormatter"
            :placeholder="$t('train.filter.bookingCodePlaceholder')"
            @input="(value) => handleDebounceBookingCode(value)"
            @keyup.enter="$emit('fetch-data')"
          />
          <b-tooltip
            v-if="appBreakPoint !== 'xs'"
            id="tooltip-booking-code"
            target="input-booking-code"
            triggers="hover focus"
            placement="top"
            boundary="viewport"
            variant="info"
            style="width: max-content;"
          >
            <div
              class="text-white"
              style="font-size: 15px"
            >
              Nhập chính xác <span class="font-weight-bolder">Mã đặt chỗ</span> để tìm kiếm
            </div>
          </b-tooltip>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- ANCHOR - Reset and SEARCH -->
    <div>
      <!-- ANCHOR - BUTTONs -->
      <!-- md="4" -->
      <b-col
        cols="12"
        class="d-flex-center gap-2"
      >
        <!-- <b-button
          v-if="isExport"
          class="font-weight-bolder text-primary d-flex-center gap-1"
          variant="outline-primary"
          @click="openModalFilterToExport"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="DownloadIcon"
          />
          <span>{{ $t('export') }}</span>
        </b-button> -->

        <b-button
          class="font-weight-bolder d-flex-center gap-1 px-50"
          variant="outline-warning"
          @click="$emit('fetch-data')"
        >
          <!-- :disabled="emptyFilter" -->
          <feather-icon
            v-if="!isMobileView"
            icon="SearchIcon"
          />
          {{ $t('search') }}
        </b-button>

        <b-button
          class="font-weight-bolder d-flex-center gap-1 px-50"
          variant="outline-danger"
          :disabled="emptyFilter"
          @click="$emit('reset'), resetFilter()"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="TrashIcon"
          />
          {{ $t('train.filter.clearFilter') }}
        </b-button>
      </b-col>
    </div>
  </component>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'
import { ref, computed, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

import store from '@/store'
import api from '@/api'
import { isActiveOptions } from '@/constants/selectOptions'
import VueI18n from '@/libs/i18n'
import { formatDateMonth } from '@/@core/utils/filter'

import { removeAccentsUpperCaseFormatter, removeAccentsUpperCaseFormatterNotTrim } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTooltip,
    flatPickr,
    vSelect,
  },
  props: {
    emptyFilter: {
      type: Boolean,
      default: null,
    },
    startDateFilter: {
      type: String,
      default: null,
    },
    endDateFilter: {
      type: String,
      default: null,
    },
    statusFilter: {
      type: String,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    // createdByFilter: {
    //   type: [Object, null],
    //   default: () => { },
    // },
    // agencyCodeFilter: {
    //   type: [Object, null],
    //   default: () => { },
    // },
    // searchTextFilter: {
    //   type: String,
    //   default: '',
    // },
    bookingCodeFilter: {
      type: String,
      default: '',
    },
    // isExport: {
    //   type: Boolean,
    //   default: true,
    // },
  },

  methods: {
    // withPopper(dropdownList, component, { width }) {
    //   const dropdownWidth = `calc(${Number(width.split('px')[0]) + 100}px )` || width // + 25px
    //   dropdownList.style.width = dropdownWidth
    //   dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
    //   const popper = createPopper(component.$refs.toggle, dropdownList, {
    //     placement: 'auto',
    //   })
    //   return () => popper.destroy()
    // },
    withPopper2(dropdownList, component, { width }) {
      dropdownList.style.width = width
      dropdownList.style.minWidth = '270px'

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const handleDebounceBookingCode = bookingCode => {
      emit('update:bookingCodeFilter', bookingCode)
    }

    const createdByOptions = ref([])
    const agencyCodeOptions = ref([])
    const isLoadingCreatedBy = ref(false)
    const isLoadingAgencyCode = ref(false)
    const createdByOptionsDefault = ref([])
    const agencyCodeOptionsDefault = ref([])

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoadingCreatedBy.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingCreatedBy.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 300)

    const fetchAgenciesByFilterSearch = async (search = '') => {
      agencyCodeOptions.value = []
      isLoadingAgencyCode.value = true
      try {
        const { data } = await api.agencies.fetchAgencies({ searchText: search })
        if (data && !search) {
          agencyCodeOptionsDefault.value = data.items
        }
        agencyCodeOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingAgencyCode.value = false
      }
    }
    function handleOpenAgencyCode() {
      if (agencyCodeOptionsDefault.value.length === 0) {
        fetchAgenciesByFilterSearch()
      } else {
        agencyCodeOptions.value = agencyCodeOptionsDefault.value
      }
    }

    const handleSearchAgencyCode = debounce(search => {
      if (search) fetchAgenciesByFilterSearch(search)
    }, 300)
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const rangeDate = ref([])
    const rangeTripDate = ref([])

    watch(rangeDate, value => {
      if (!value.length) return
      const sepSign = VueI18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        emit('update:startDateFilter', dateRange[0])
        emit('update:endDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        emit('update:startDateFilter', dateRange[0])
        emit('update:endDateFilter', dateRange[0])
      }
    }, { deep: true, immediate: true })

    watch(rangeTripDate, value => {
      if (!value.length) return
      const sepSign = VueI18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        emit('update:startTripDateFilter', dateRange[0])
        emit('update:endTripDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        emit('update:startTripDateFilter', dateRange[0])
        emit('update:endTripDateFilter', dateRange[0])
      }
    }, { deep: true, immediate: true })

    function resetFilter() {
      rangeDate.value = []
      rangeTripDate.value = []
    }

    function onCloseHandler(selectedDates, dateStr, instance) {
      if (selectedDates.length === 1) {
        instance.setDate([selectedDates[0], selectedDates[0]], true)
      }
    }
    function onChangeHandler(selectedDates, dateStr, instance) {
      const date = formatDateMonth(selectedDates[0], 'DD-MM-YYYY')
      // eslint-disable-next-line no-underscore-dangle
      instance._input.value = `${date}${instance.l10n.rangeSeparator}${date}`
    }

    const statusOptionsWithAll = computed(() => [{ label: 'ALL', value: null }].concat(props.statusOptions))

    return {
      Vietnamese,
      handleDebounceBookingCode,
      handleSearchCreatedBy,
      handleOpenCreatedBy,
      createdByOptions,
      isLoadingCreatedBy,
      isLoadingAgencyCode,
      removeAccentsUpperCaseFormatter,
      isRoleF3,
      isActiveOptions,

      agencyCodeOptions,
      handleOpenAgencyCode,
      handleSearchAgencyCode,
      removeAccentsUpperCaseFormatterNotTrim,

      rangeDate,
      rangeTripDate,
      resetFilter,
      onCloseHandler,
      onChangeHandler,
      statusOptionsWithAll,
    }
  },
}
</script>

<style lang="scss" scoped>
#range-date ::v-deep {
  input.form-control.input {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
  }
}

#tooltip-booking-code ::v-deep {
  .tooltip-inner {
    max-width: fit-content;
  }
}
</style>
