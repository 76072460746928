<template>
  <AppCollapse class="mb-50">
    <AppCollapseItem
      title
      :is-visible="true"
      class-body="p-0"
      class-header="p-0"
    >
      <template #header>
        <h4 class="card-title font-weight-bolder py-50">
          {{ $t('filters') }}
        </h4>
      </template>

      <div
        id="invoice-list-filter"
        class="p-0"
      >
        <b-row
          align-v="center"
          align-h="start"
        >
          <!-- ANCHOR Search text -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('agentRequests.filters.searchText')"
            >
              <b-form-input
                :value="searchText"
                :placeholder="$t('agentRequests.filters.ph.searchText')"
                @input="val => $emit('update:searchText', val)"
                @keyup.enter="refetchData"
              />
            </b-form-group>
          </b-col>
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('agentRequests.filters.startDate')"
            >
              <flat-pickr
                :value="startDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: endDate ? endDate : null,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:startDate', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('agentRequests.filters.endDate')"
            >
              <flat-pickr
                :value="endDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: startDate ?? null,
                  maxDate: null,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:endDate', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR AGENCY CODE -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('agentRequests.filters.agencyCode')"
            >
              <vue-autosuggest
                id="agency-code"
                :value="agencyCode"
                :suggestions="agencySuggestions"
                :input-props="{
                  placeholder: `${$t('invoice.ph.agencyCode')}`,
                  class: 'form-control'
                }"
                :get-suggestion-value="getSuggestionValue"
                @input="inputAgencyFilterHandle"
                @selected="val => $emit('update:agencyCode', val?.item?.agencyCode?.toUpperCase())"
              >
                <template #default="{ suggestion }">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="text-info text-uppercase">{{ suggestion.item.agencyCode }}</span> <small>({{ suggestion.item.agencyName }})</small>
                  </span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- ANCHOR loại request -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('agentRequests.filters.requestType')"
            >
              <v-select
                :value="requestType"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="REQUEST_TYPE_OPTIONS"
                label="label"
                disabled
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:requestType', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="resolveRequestTypeVariant(data.label)"
                    pill
                    class="px-75"
                  >
                    {{ $te(`agentRequests.requestType.${data.label}`)
                      ? $t(`agentRequests.requestType.${data.label}`)
                      : data.label }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="resolveRequestTypeVariant(data.label)"
                    pill
                    class="px-75"
                  >
                    {{ $te(`agentRequests.requestType.${data.label}`)
                      ? $t(`agentRequests.requestType.${data.label}`)
                      : data.label }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="flex-fill"
            align-self="end"
          >
            <HStack
              end
              class="gap-2"
            >
              <!-- ANCHOR Button Search -->
              <b-button
                variant="info"
                size="sm"
                class="p-50"
                @click="refetchData"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="search"
                    size="16"
                  />
                  {{ $t('search') }}
                </span>
              </b-button>

              <!-- ANCHOR Button Clear Search Filters -->
              <b-button
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="onClearFilters"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="delete"
                    size="16"
                  />
                  {{ $t('invoice.create.selectTicket.clearFilter') }}
                </span>
              </b-button>
            </HStack>
          </b-col>
        </b-row>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { VueAutosuggest } from 'vue-autosuggest'
import { debounce } from 'lodash-es'

import { apiAgencies } from '@/api'

import { onlyUpperCaseFormatter, trimInput } from '@core/comp-functions/forms/formatter-input'

import { REQUEST_TYPE_OPTIONS, resolveRequestTypeVariant } from './useAgentRequestStatsList'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
    flatPickr,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    VueAutosuggest,
  },
  props: {
    startDate: {
      type: String, default: '',
    },
    endDate: {
      type: String, default: '',
    },
    agencyCode: {
      type: String, default: '',
    },
    searchText: {
      type: String, default: '',
    },
    requestType: {
      type: String, default: '',
    },
  },
  setup(props, { emit }) {
    const agencySuggestions = ref([
      {
        data: [],
      },
    ])
    const inputAgencyFilterHandle = debounce(input => {
      emit('update:agencyCode', input)
      if (input === '' || input === undefined) {
        return
      }
      apiAgencies
        .fetchAgencies({ searchText: input })
        .then(response => {
          agencySuggestions.value = [
            {
              data: response.data.items,
            },
          ]
        })
    }, 200)

    function getSuggestionValue(suggestion) {
      return suggestion?.item?.agencyCode
    }

    const refetchData = () => {
      emit('refetchData')
    }

    const onClearFilters = () => {
      emit('resetFilters')
    }

    return {
      Vietnamese,
      onClearFilters,
      onlyUpperCaseFormatter,
      trimInput,
      inputAgencyFilterHandle,
      agencySuggestions,
      getSuggestionValue,
      refetchData,
      REQUEST_TYPE_OPTIONS,
      resolveRequestTypeVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}

</style>
