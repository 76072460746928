var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "flex-grow-1 text-truncate"
  }, [_c('h4', {
    staticClass: "card-title d-flex-center justify-content-start font-weight-bolder flex-grow-1"
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info.window",
      modifiers: {
        "hover": true,
        "v-info": true,
        "window": true
      }
    }],
    staticClass: "px-1 mr-50",
    attrs: {
      "variant": "flat-info",
      "title": "Quay lại danh sách thư mục"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-documents-folder-view'
        });
      }
    }
  }, [_c('feather-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "ChevronLeftIcon",
      "size": "16"
    }
  })], 1), _c('span', [_vm._v(_vm._s(_vm.folder.name))])], 1)]), _c('b-row', {
    staticClass: "m-1 w-100"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('document.searchNameDoc'),
      "value": _vm.documentNameFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    model: {
      value: _vm.documentNameFilter,
      callback: function callback($$v) {
        _vm.documentNameFilter = $$v;
      },
      expression: "documentNameFilter"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_vm.canModify ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-documents-add',
          params: {
            id: _vm.folderId
          }
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer mr-25",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('document.createDoc')) + " ")], 1)]) : _vm._e()], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refDocumentListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "64vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchAllDocuments,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label ? _vm.$t("document.columns.docs.".concat(data.label)) : '') + " ")])];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "max-width": "300px"
          }
        }, [_c('div', {
          staticClass: "w-100 flex-grow-1"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-info",
          attrs: {
            "to": {
              name: 'apps-documents-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])], 1)])];
      }
    }, {
      key: "cell(file)",
      fn: function fn(data) {
        return [data.item.files && data.item.files.length ? _c('div', {
          staticClass: "d-flex flex-column"
        }, _vm._l(data.item.files, function (file, indexFile) {
          return _c('b-link', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.v-info.window",
              modifiers: {
                "hover": true,
                "v-info": true,
                "window": true
              }
            }],
            key: indexFile,
            attrs: {
              "title": file.file
            },
            on: {
              "click": function click($event) {
                return _vm.confirmDownloadFile(file);
              }
            }
          }, [_c('small', [_vm._v(_vm._s(_vm.shortenFileName(file.file)))])]);
        }), 1) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: "cell(isActive)",
      fn: function fn(data) {
        return [_c('BBadge', {
          class: "".concat(data.item.isActive ? 'badge-glow' : ''),
          attrs: {
            "variant": data.item.isActive ? 'success' : 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(data.item.isActive ? _vm.$t('document.published') : _vm.$t('document.draft')) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center flex-column gap-1"
        }, [_c('BBadge', {
          class: "".concat(data.item.isActive ? 'badge-glow' : ''),
          attrs: {
            "variant": data.item.isActive ? 'success' : 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(data.item.isActive ? _vm.$t('document.published') : _vm.$t('document.draft')) + " ")]), _c('BBadge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "success",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(data.item.isShow ? _vm.$t('document.forF3') : _vm.$t('document.forF2')) + " ")])], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', [_c('div', [_vm._v(_vm._s(_vm.dateTime(data.item.createdAt)))]), _vm.isShowUserUpdated ? _c('small', [_vm._v(_vm._s(_vm.$t('by')) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.username))])]) : _vm._e()])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', [_c('div', [_vm._v(_vm._s(_vm.dateTime(data.item.updatedAt)))]), _vm.isShowUserUpdated ? _c('small', [_vm._v(_vm._s(_vm.$t('by')) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.updatedBy.username))])]) : _vm._e()])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_vm.canModify ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-info",
            modifiers: {
              "hover": true,
              "v-info": true
            }
          }],
          staticClass: "p-75",
          attrs: {
            "variant": "flat-info",
            "title": _vm.isMobileView ? '' : data.item.isPin ? 'Gỡ treo thông báo' : 'Treo thông báo'
          },
          on: {
            "click": function click($event) {
              return _vm.confirmTogglePin(data.item);
            }
          }
        }, [data.item.isPin ? _c('img', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": require('@icons/unpin.svg')
          }
        }) : _c('img', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": require('@icons/pin.svg')
          }
        })]) : _vm._e(), _vm.canModify ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger",
            modifiers: {
              "hover": true,
              "v-danger": true
            }
          }],
          staticClass: "px-1",
          attrs: {
            "variant": "flat-danger",
            "title": _vm.isMobileView ? '' : 'Xoá tài liệu'
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(data.item);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-1 d-flex-center justify-content-sm-start"
  }, [_c('div', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.total')) + " " + _vm._s(_vm.totalDocuments) + " " + _vm._s(_vm.$t('document.doc')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }