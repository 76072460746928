<template>
  <div ref="otherProductRef">
    <AppCollapse
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <b-img
              src="@icons/suitcase.svg"
              class="icon-card"
              width="50px"
            />
            <h6 class="text-airline font-medium-3 m-0 fw-700">
              {{ $t('reservation.otherServiceProduct') }}
            </h6>
          </div>

          <div
            v-if="!isHideFunctionButton && !isRoleF3 && !hideFeature && reservationsData.status !== 'CANCEL'"
            :class="isMobileView ? 'd-flex-center flex-wrap' : ''"
            style="margin-right: 2.5rem;"
          >
            <b-button
              v-b-tooltip.hover.window
              variant="flat-dark"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`reservation.${showServiceFee ? 'Hide' : 'Show'}`)} ${$t('reservation.priceDetails.titleTable.feeService')}`"
              @click.prevent.stop="showServiceFee = !showServiceFee"
            >
              <img
                v-if="showServiceFee"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button>
            <!-- <b-button
              v-else
              v-b-tooltip.hover.window
              variant="flat-dark"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`reservation.${showServiceFee ? 'Hide' : 'Show'}`)} ${$t('reservation.priceDetails.titleTable.agencyFee')}`"
              @click.prevent.stop="showAgencyfee = !showAgencyfee"
            >
              <img
                v-if="showAgencyfee"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button> -->
          </div>
        </template>

        <b-card-body class="d-flex flex-column gap-3 py-25 px-0">
          <div v-if="!isProduction && !isEmpty(reservationsData.specialServiceInsurance) && reservationsData.specialService">
            <div
              v-if="!isEmpty(reservationsData.specialServiceInsurance) && reservationsData.specialService"
              class="fw-700 text-airline"
            >
              {{ $t('reservation[\'Business lounge\']') }}
            </div>
            <b-table
              centered
              responsive
              small
              bio
              hover
              bordered
              class="mb-0 border-secondary rounded-8px"
              thead-class="text-body text-center align-middle text-nowrap"
              tbody-class="text-body align-middle text-nowrap"
              show-empty
              :empty-text="$t('reservation.noAddons')"
              :fields="loungeTableColumns"
              :items="resolveSpecialServices(reservationsData)"
            >
              <template
                v-for="(column, indexColumn) in loungeTableColumns"
                #[`head(${column})`]="data"
              >
                <span :key="indexColumn">
                  {{ $t(`reservation.${data.column}`) }}
                </span>
              </template>
              <template #cell(qrcode)="{item}">
                <b-button
                  class="d-flex-center text-nowrap px-75"
                  variant="outline-primary"
                  size="sm"
                  style="max-width:300px"
                  @click="showSpecialServicesQRCode(item)"
                >
                  <span
                    class="font-small-4"
                    style="padding-bottom: 1px;"
                  >
                    {{ $t('reservation[\'information & QR\']') }}
                  </span>
                </b-button>
              </template>
              <template #cell(itinerary)="{item}">
                <div class="fw-700">
                  <span class="text-airline">{{ item.flightNumber.split(' ').join('') }}</span>
                </div>
                <div class="fw-700">
                  {{ item.flightTrip.split(' ').join('-') }}
                </div>
              </template>
              <template #cell(services)="{item}">
                <div class="fw-700">
                  {{ item.airportLounge.name }} ({{ item.airportLounge.isDomestic ? $t('reservation[\'domestic\']') : $t('reservation[\'international\']') }})
                </div>
                <div class="fw-600">
                  {{ item.airport.name }}
                </div>
              </template>
              <template #cell(amount)="{item}">
                <div
                  v-for="(lounge, indexLounge) of item.bookingFares"
                  :key="indexLounge"
                  class="w-100 d-flex-between"
                >
                  <span class="fw-700 pr-50">
                    {{ lounge.airportLoungeFare.name }} (x{{ lounge.count }})
                  </span>
                  <span class="font-italic font-small-3 font-weight-bolder text-warning">
                    ({{ formatCurrency(lounge.price) }})
                  </span>
                </div>
              </template>
              <template #cell(status)>
                <div class="fw-700 p-50 rounded text-center text-success border-success">
                  {{ $t('reservation[\'paid\']') }}
                </div>
              </template>
              <template #cell(total)="{item}">
                <div class="fw-700 text-right px-50">
                  {{ formatCurrency(item.servingPrice) }}
                </div>
              </template>
            </b-table>
            <div
              v-if="!isEmpty(reservationsData.specialService)"
              class="d-flex justify-content-end m-1"
            >
              <div class="mr-1 font-medium-2">
                {{ $t('reservation[\'totalPrice\']') }}
              </div>
              <div class="fw-700 text-right font-medium-2 text-airline">
                {{ getTotalPriceSpecialService(reservationsData.specialService) }} VND
              </div>
            </div>
          </div>

          <div v-if="!isEmpty(reservationsData.specialServiceInsurance)">
            <div class="fw-700 text-airline">
              {{ $t('reservation[\'insuranceServices\']') }}
            </div>

            <b-table
              centered
              responsive
              small
              bio
              hover
              bordered
              class="mb-0 border-secondary rounded-8px"
              thead-class="text-body text-center align-middle text-nowrap"
              tbody-class="text-body text-center align-middle text-nowrap"
              show-empty
              :items="resolveInsuranceServices(reservationsData)"
              :fields="insuranceTableColumns"
              :empty-text="$t('reservation.noAddons')"
            >
              <template
                v-for="(column, indexColumn) in insuranceTableColumns"
                #[`head(${column})`]="data"
              >
                <span :key="indexColumn">
                  {{ $t(`reservation.${data.column}`) }}
                </span>
              </template>

              <!-- ['passenger', 'itinerary', 'services', 'serviceName', 'status', 'discountPrice', 'total', 'action'] -->
              <template #cell(passenger)="{item}">
                <div>
                  {{ item.passengerName }}
                </div>
              </template>

              <template #cell(itinerary)="{item}">
                <div class="text-airline fw-700 mb-25">
                  {{ resolveInsuranceItinerariesName(item.flightJourney, 'journeys') }}
                </div>
              </template>

              <template #cell(services)>
                <div>
                  {{ $t('reservation[\'insuranceServices\']') }}
                </div>
              </template>

              <template #cell(serviceName)="{item}">
                <div>
                  {{ item.planName }}
                </div>
              </template>

              <template #cell(status)="{item}">
                <b-badge
                  :variant="resolveVariantInsuranceServices(item.status)"
                  class="py-50 fw-700 w-100"
                >
                  {{ resolveInsuranceSpecialServicesName(item.status) }}
                </b-badge>
              </template>

              <template #cell(insurancePrice)="{item}">
                <div class="fw-700 px-50">
                  {{ formatCurrency(item.price) }}
                </div>
              </template>

              <template #cell(priceToPay)="{item}">
                <div class="fw-700 px-50">
                  {{ formatCurrency(item.totalPrice) }}
                </div>
              </template>

              <template #cell(benefit)="{item}">
                <div class="fw-600 text-right px-50">
                  {{ formatCurrency(item.priceCommission) }}
                </div>
              </template>

              <template #cell(action)="{item}">
                <div class="d-flex">
                  <b-button
                    v-if="!['PENDING_SUBMISSION'].includes(item.status) || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-info"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleShowInsuranceInfo(item)"
                  >
                    <span>
                      {{ $t('reservation[\'detail\']') }}
                    </span>
                  </b-button>
                  <b-button
                    v-if="['PENDING_SUBMISSION'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-info"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleCreateInsuranceCoverNote(item)"
                  >
                    <span class="fw-700">
                      {{ $t('reservation[\'createCoverNote\']') }}
                    </span>
                  </b-button>
                  <b-button
                    v-if="['PENDING_SUBMISSION'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-warning"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleUpdateInsuranceDraft(item, reservationsData)"
                  >
                    <span class="fw-700">{{ $t('reservation[\'updateCover\']') }}</span>
                  </b-button>
                  <b-button
                    v-if="['PENDING_SUBMISSION'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleDeleteInsuranceDraft(item)"
                  >
                    <span class="fw-700">{{ $t('reservation[\'deleteCover\']') }}</span>
                  </b-button>
                  <b-button
                    v-if="['PENDING_PAYMENT'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-success"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleCreateInsurancePolicy(item, reservationsData)"
                  >
                    <span class=""> {{ $t('reservation[\'payment\']') }} </span>
                  </b-button>
                  <b-button
                    v-if="['PENDING_PAYMENT'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleCancelInsuranceItem(item, reservationsData)"
                  >
                    <span class=""> {{ $t('reservation[\'cancelCover\']') }} </span>
                  </b-button>
                  <b-button
                    v-if="['PAID'].includes(item.status) && !isRoleF3 || !isBookingOutSystem"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-warning"
                    class="p-50 mr-50 rounded fw-700"
                    @click="handleRefundInsuranceItem(item)"
                  >
                    <span class=""> {{ $t('reservation[\'refundCover\']') }} </span>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>

          <div
            v-if="!isEmpty(reservationsData.specialServiceInsurance)"
            class="d-flex-between w-100"
          >
            <!-- SECTION - Lợi nhuận -->
            <div
              v-if="!isRoleF3"
              class="px-1 py-50 rounded d-flex-center align-items-start flex-column"
              :style="`border: 1px solid ${showBenefit ? '#E6E6E6' : 'transparent'}; height: 72px`"
            >
              <div>
                <BButton
                  v-if="!hideFeature"
                  variant="primary"
                  class="p-25 rounded-circle"
                  @click="showBenefit = !showBenefit"
                >
                  <feather-icon
                    v-if="showBenefit"
                    icon="EyeIcon"
                    size="16"
                  />
                  <feather-icon
                    v-else
                    icon="EyeOffIcon"
                    size="16"
                  />
                </BButton>
              </div>
              <div v-if="showBenefit">
                <span>{{ $t('reservation.benefit') }}: </span>
                <span :class="`text-${benefitInsurance > 0 ? 'success' : 'danger'} ml-50 font-medium-4`">{{ formatCurrency(benefitInsurance) }} </span>
              </div>
            </div>
            <!-- !SECTION  -->

            <div v-if="!isRoleF3">
              <div v-if="!isEmpty(reservationsData.specialServiceInsurance?.filter(i => TO_BE_PAID_INSURANCE_ITEM.includes(i.status)))">
                <b-alert
                  show
                  variant="danger"
                  class="p-1 text-center d-flex-center flex-nowrap fw-700"
                >
                  <div class="mr-75 font-medium-2">
                    {{ $t('reservation[\'totalAmountToPay\']') }}
                  </div>
                  <div class="fw-700 text-right font-medium-2 text-airline text-nowrap">
                    {{ formatCurrency(totalAmountInsToBePay) }} VND
                  </div>
                </b-alert>
              </div>

              <!-- ANCHOR - Giá trả nhà cung cấp -->
              <div
                v-if="showServiceFee"
                class="mt-50 text-right"
              >
                <span class="text-body font-medium-1">{{ $t('reservation.pricePaidToSupplier') }}:</span>
                <span class="text-success ml-50 font-medium-4">
                  {{ formatCurrency(totalAllInsurance) }} VND
                </span>
              </div>
            </div>
          </div>

          <TourSuggestion
            v-if="showTourSuggestion && isVisibleTourSuggestion"
            :points="pointsForTourSuggestion"
          />
        </b-card-body>
      </AppCollapseItem>
    </AppCollapse>

    <!-- SECTION button footer -->
    <div
      v-if="!hideFeature && !isHideFunctionButton"
      class="d-flex-center justify-content-end my-50 gap-1 flex-wrap"
    >
      <b-button
        v-if="!isRoleF3"
        class="d-flex-center text-nowrap px-75 gap-1"
        variant="primary"
        :disabled="['CANCEL'].includes(reservationsData.status) || isBookingOutSystem"
        size="sm"
        style="max-width:300px"
        @click="handleShowModalAddInsuranceDraft(reservationsData)"
      >
        <b-img
          src="@icons/increase.svg"
          width="16"
        />
        <span
          class="font-medium-1"
          style="padding-top: 1px;"
        >
          <span>{{ $t('reservation[\'createInsurance\']') }}</span>
        </span>
      </b-button>

      <b-button
        v-if="!isProduction"
        class="d-flex-center text-nowrap px-75 gap-1"
        variant="primary"
        :disabled="!canAccess('booking.addBag') || isBookingOutSystem"
        size="sm"
        style="max-width:300px"
        @click="showAddOtherSpecialServices(reservationsData)"
      >
        <b-img
          src="@icons/increase.svg"
          width="16"
        />
        <span
          class="font-medium-1"
          style="padding-top: 1px;"
        >
          <span>{{ $t('reservation[\'createLounge\']') }}</span>
        </span>
      </b-button>
    </div>

    <!-- ANCHOR - INSURANCE -->
    <ModalAddInsuranceService
      v-if="!['CANCEL'].includes(reservationsData.status)"
      :booking-data="reservationsData"
      :pax-avail-to-add-draft-insurance.sync="paxAvailToAddDraftInsurance"
      :data-insurance-draft.sync="dataInsuranceDraft"
      :is-edit.sync="isEdit"
    />
    <ModalCreateInsuranceCoverNote
      :booking-data="reservationsData"
      :data-insurance-draft.sync="dataInsuranceDraft"
    />
    <ModalShowInsuranceInfo
      :booking-data="reservationsData"
      :data-insurance-item="dataInsuranceItem"
    />
    <ModalRefundInsurance
      :booking-data="reservationsData"
      :data-insurance-item="dataInsuranceItem"
    />

    <!-- ANCHOR - LOUNGE -->
    <ModalAddLoungeService :booking-data="reservationsData" />
    <ModalShowQRCode
      :booking-data="reservationsData"
      :data-special-item="dataSpecialItem"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BTable,
  BButton,
  VBTooltip,
  BBadge,
  BAlert,
  BCardBody,
  BImg,
} from 'bootstrap-vue'
import {
  computed, ref,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { uniq } from 'lodash-es'
import { useIntersectionObserver } from '@vueuse/core'

import {
  resolveInsuranceSpecialServicesName,
  resolveVariantInsuranceServices,
  domesticIATACodes,
  isDomesticFlight,
} from '@/constants/selectOptions'
import store from '@/store'
import env from '@/libs/env'

import {
  formatCurrency, convertISODateTime, compareDateBySubtract,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BTable,
    BBadge,
    BAlert,
    BImg,
    BCardBody,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),

    ModalAddLoungeService: () => import('./lounge/ModalAddLoungeService.vue'),
    ModalCreateInsuranceCoverNote: () => import('./insurance/ModalCreateInsuranceCoverNote.vue'),
    ModalAddInsuranceService: () => import('./insurance/ModalAddInsuranceService.vue'),
    ModalRefundInsurance: () => import('./insurance/ModalRefundInsurance.vue'),
    ModalShowInsuranceInfo: () => import('./insurance/ModalShowInsuranceInfo.vue'),
    ModalShowQRCode: () => import('./lounge/ModalShowQRCode.vue'),
    TourSuggestion: () => import('./suggest-tour/TourSuggestion.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
    isLockedModify: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      STATUS_NAME_ANCILLARY,
      cancelInsurance,
      deleteInsuranceDraft,
      createInsurancePolicy,
      delay,
      resolveInsuranceItinerariesName,
      getFlightByMultiPNRInsurances,
      insuranceIdPaxGenerate,
      hideFeature,
    } = useReservationHandle()

    const { toastError, toastWarning, toastSuccess } = useToast()

    const isProduction = computed(() => process.env.VUE_APP_ENV === 'production')
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const loungeTableColumns = ['qrcode', 'itinerary', 'services', 'amount', 'status', 'total']
    const ACTIVE_INSURANCE_ITEM = ['PENDING_PAYMENT', 'PENDING_SUBMISSION', 'PAID']
    const TO_BE_PAID_INSURANCE_ITEM = ['PENDING_SUBMISSION', 'PENDING_PAYMENT']
    const SUBTRACT_1_HOURS_BY_SECONDS_INS = { subtractValue: 3600, subtractType: 'seconds' }
    // const CANNOT_HANDLE_INSURANCE_SERVICE = 'Chỉ có thể mua dịch vụ trước 1 tiếng so với giờ khởi hành đầu tiên'
    const paxAvailToAddDraftInsurance = ref([])

    // const SHOW_PRICE_INSURANCE_ITEM = ['PENDING_PAYMENT', 'PAID']
    const showBenefit = ref(false)
    const showServiceFee = ref(false)
    const benefitInsurance = computed(() => props.reservationsData?.specialServiceInsurance?.reduce((total, item) => total + (ACTIVE_INSURANCE_ITEM.includes(item?.status) ? item.priceCommission : 0), 0))

    const insuranceTableColumns = computed(() => {
      const arr = ['passenger', 'itinerary', 'services', 'serviceName', 'status', 'insurancePrice', 'priceToPay', 'benefit', 'action']
      return showServiceFee.value ? arr : arr.filter(item => !['benefit', 'priceToPay'].includes(item))
    })

    // Tổng tien can thanh toan
    const totalAmountInsToBePay = computed(() => props.reservationsData?.specialServiceInsurance?.reduce((total, item) => total + (TO_BE_PAID_INSURANCE_ITEM.includes(item?.status) ? item.price : 0), 0))

    // Tổng giá trả nhà cung cấp insurance
    const totalAllInsurance = computed(() => props.reservationsData?.specialServiceInsurance?.reduce((total, item) => total + (ACTIVE_INSURANCE_ITEM.includes(item?.status) ? item.totalPrice : 0), 0))

    function showAddOtherSpecialServices(dataBooking) {
      if (!dataBooking.status.includes('PAID')) {
        toastError({ title: 'reservation[\'Please pay for tickets before purchasing additional services!\']' })
        return
      }

      if (isEmpty(dataBooking.itineraries)) {
        toastError({ title: 'reservation[\'No itinerary found in booking\']' })
        return
      }

      if (!isEmpty(dataBooking.itineraries)) {
        const hasDepartureSGN = dataBooking.itineraries.flatMap(segment => segment).some(seg => ['SGN'].includes(seg.departure.iataCode))
        if (!hasDepartureSGN) {
          toastError({ title: 'reservation[\'Lounge service is available for flights originating from the city. Ho Chi Minh (SGN)!\']' })
          return
        }
      }

      this.$bvModal.show('modal-reservation-modify-add-other-special-services')
    }

    const dataItineraryInsurances = computed(() => getFlightByMultiPNRInsurances(props.reservationsData))

    async function handleShowModalAddInsuranceDraft(dataBooking) {
      if (isEmpty(dataBooking.itineraries)) {
        toastError({ title: 'reservation[\'No itinerary found in booking\']' })
        return
      }

      const departureTime = convertISODateTime(dataBooking?.itineraries[0][0]?.departure?.at, dataBooking?.itineraries[0][0]?.departure?.timeZone).ISOdatetime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)

      if (isBefore || isSame) {
        // toastError({ title: CANNOT_HANDLE_INSURANCE_SERVICE })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      const { data } = dataItineraryInsurances.value

      const isDepartureDomestic = domesticIATACodes.includes(data?.[0]?.itineraries?.[0]?.departure)
      const hasInternationalTrip = data.flatMap(i => i.itineraries).some(trip => !isDomesticFlight(trip.departure, trip.arrival))

      if (!isDepartureDomestic || !hasInternationalTrip) {
        toastError({ title: 'reservation[\'Insurance services can only be purchased for international itineraries originating from Vietnam!\']' })
        return
      }

      // ['PENDING_PAYMENT', 'PENDING_SUBMISSION', 'PAID']
      if (isEmpty(dataBooking.specialServiceInsurance)) {
        paxAvailToAddDraftInsurance.value = dataBooking.paxLists
      } else {
        const availItems = dataBooking.specialServiceInsurance.filter(item => ACTIVE_INSURANCE_ITEM.includes(item.status))
        if (isEmpty(availItems)) {
          paxAvailToAddDraftInsurance.value = dataBooking.paxLists
        } else {
          paxAvailToAddDraftInsurance.value = dataBooking.paxLists.filter(pax => availItems.every(item => item.passengerId !== insuranceIdPaxGenerate(pax)))
        }
      }

      if (isEmpty(paxAvailToAddDraftInsurance.value)) {
        toastWarning({ title: 'reservation[\'No passengers found who can add a new insurance package!\']' })
      } else {
        try {
          await delay(250)
          this.$bvModal.show('modal-reservation-modify-add-insurance-services')
        } catch (error) {
          console.error('Error handleShowModalAddInsuranceDraft::', error)
        }
      }
    }

    const dataInsuranceDraft = ref(null)
    const loadingInsuranceButton = ref(false)
    const isEdit = ref(false)

    async function handleCreateInsuranceCoverNote(data) {
      const departureTime = data.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)

      if (isBefore || isSame) {
        // toastError({ title: CANNOT_HANDLE_INSURANCE_SERVICE })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      isEdit.value = false
      dataInsuranceDraft.value = data
      loadingInsuranceButton.value = true

      try {
        await delay(300)
        this.$bvModal.show('modal-reservation-modify-insurance-create-cover-note')
      } catch (error) {
        console.error('Error handleCreateInsuranceCoverNote::', error)
      } finally {
        await delay(200)
        loadingInsuranceButton.value = false
      }
    }

    async function handleUpdateInsuranceDraft(item, bookingData) {
      const departureTime = item.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
      if (isBefore || isSame) {
        // toastError({ title: 'CANNOT_HANDLE_INSURANCE_SERVICE' })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      isEdit.value = true
      dataInsuranceDraft.value = item
      loadingInsuranceButton.value = true

      const pax = bookingData.paxLists.find(p => insuranceIdPaxGenerate(p) === item.passengerId)
      if (!pax) {
        toastWarning({ title: 'reservation[\'No passengers found!\']' })
        return
      }

      paxAvailToAddDraftInsurance.value = [pax]

      try {
        await delay(300)
        this.$bvModal.show('modal-reservation-modify-add-insurance-services')
      } catch (error) {
        console.error('Error handleUpdateInsuranceDraft::', error)
      } finally {
        await delay(200)
        loadingInsuranceButton.value = false
      }
    }

    function handleDeleteInsuranceDraft(item) {
      const departureTime = item.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
      if (isBefore || isSame) {
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      const payload = {
        id: item.id,
        bookingCode: item.bookingCode,
      }

      const titleMsg = this.$createElement('div',
        { class: 'fw-700 text-danger font-medium-3' },
        this.$t('reservation.deleteInsurance'))

      const bodyMsg = data => this.$createElement('div', [
        this.$t('reservation.deleteInsurance'),
        this.$createElement('span', { class: 'fw-700' }, ` ${data.planName}`),
        this.$createElement('div', { class: 'mt-75' }, [
          this.$createElement('span', { class: '' }, this.$t('reservation.paxType')),
          this.$createElement('span', { class: 'fw-700' }, ` ${data.passengerName} ?`)]),
      ])

      this.$bvModal
        .msgBoxConfirm(bodyMsg(item), {
          title: titleMsg,
          headerClass: 'font-medium-2',
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('reservation.cancelCover'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            deleteInsuranceDraft(payload)
              .then(() => {
                toastSuccess({ title: 'reservation[\'deleteInsSuccess\']' })
              })
              .catch(() => {
                toastError({ title: 'reservation[\'deleteInsErr\']' })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    const dataSpecialItem = ref(null)

    async function showSpecialServicesQRCode(dataItem) {
      if (!dataItem.qrCode) {
        toastError({ title: 'reservation[\'QR code information error, please contact booker to handle!\']' })
        return
      }
      await new Promise(resolve => {
        dataSpecialItem.value = dataItem
        resolve()
      })
      this.$bvModal.show('modal-modify-special-services-qr-codes')
    }

    function resolveSpecialServices(bookingData) {
      if (isEmpty(bookingData)) return []
      if (isEmpty(bookingData.specialService)) return []
      return bookingData.specialService
    }

    function resolveInsuranceServices(bookingData) {
      if (isEmpty(bookingData)) return []
      if (isEmpty(bookingData.specialServiceInsurance)) return []
      return bookingData.specialServiceInsurance.filter(i => !['HIDE'].includes(i.status))
    }

    function getTotalPriceSpecialService(arrSpecial) {
      const d = arrSpecial.reduce((total, item) => total + item.servingPrice, 0)
      return formatCurrency(d)
    }

    function handleCancelInsuranceItem(item, dataBooking) {
      // const isCancel = ['PENDING_PAYMENT'].includes(item.status)
      const departureTime = item.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
      if (isBefore || isSame) {
        // toastError({ title: CANNOT_HANDLE_INSURANCE_SERVICE })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      const payload = {
        insuranceId: item.id,
        bookingCode: item.bookingCode,
        bookingSource: dataBooking.source,
      }

      const titleMsg = this.$createElement('div',
        { class: 'fw-700 text-danger font-medium-3' },
        this.$t('reservation.cancelInsurance'))

      const bodyMsg = data => this.$createElement('div', [
        this.$t('reservation.cancelInsurance'),
        this.$createElement('span', { class: 'fw-700' }, ` ${data.planName}`),
        this.$createElement('div', { class: 'mt-75' }, [
          this.$createElement('span', { class: '' }, this.$t('reservation.paxType')),
          this.$createElement('span', { class: 'fw-700' }, ` ${data.passengerName} ?`)]),
      ])

      this.$bvModal
        .msgBoxConfirm(bodyMsg(item), {
          title: titleMsg,
          headerClass: 'font-medium-2',
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('reservation.cancelCover'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            cancelInsurance(payload)
              .then(() => {
                toastSuccess({ title: 'reservation[\'cancelInsSuccess\']' })
              })
              .catch(() => {
                toastError({ title: 'reservation[\'cancelInsErr\']' })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    const dataInsuranceItem = ref(null)
    async function handleRefundInsuranceItem(item) {
      const departureTime = item.flightJourney[0].boardingTime

      // ko hoàn trong ngày và qua ngày
      const { isBefore, isSame } = compareDateBySubtract(departureTime, { subtractValue: null, subtractType: 'day' })
      if (isBefore || isSame) {
        toastError({ title: 'reservation[\'The insurance package has reached its effective date and cannot be refunded!\']' })
        return
      }

      dataInsuranceItem.value = item

      await delay(250)

      this.$bvModal.show('modal-reservation-modify-refund-insurance')
    }

    function handleCreateInsurancePolicy(item, dataBooking) {
      const departureTime = item.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)

      if (isBefore || isSame) {
        // toastError({ title: CANNOT_HANDLE_INSURANCE_SERVICE })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      const payload = {
        insuranceId: item.id,
        bookingCode: item.bookingCode,
        bookingSource: dataBooking.source,
      }

      const titleMsg = this.$createElement('div', { class: 'fw-700 text-success font-medium-3' }, this.$t('reservation[\'paymentInsurance\']'))

      const bodyMsg = data => this.$createElement('div', [
        this.$t('reservation[\'Confirmation of insurance package payment:\']'),
        this.$createElement('span', { class: 'fw-700' }, data.planName),
        this.$createElement('div', { class: 'mt-75' }, [
          this.$createElement('span', { class: '' }, this.$t('reservation.paxType')),
          this.$createElement('span', { class: 'fw-700' }, ` ${data.passengerName} ?`)]),
        this.$createElement('div', { class: 'mt-75' }, [
          this.$createElement('span', { class: '' }, this.$t('reservation.planPrice')),
          this.$createElement('span', { class: 'fw-700 text-airline' }, ` ${formatCurrency(data.totalPrice)} VND`)]),
        this.$createElement('div', { class: 'mt-75' }, [
          this.$createElement('span', { class: '' }, this.$t('reservation[\'Payment term\']')),
          this.$createElement('span', { class: 'fw-700 text-airline' }, ` ${convertISODateTime(data.flightJourney[0].boardingTime).date}`)]),
      ])

      this.$bvModal
        .msgBoxConfirm(bodyMsg(item), {
          title: titleMsg,
          size: 'sm',
          okVariant: 'success',
          okTitle: 'Thanh toán',
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            createInsurancePolicy(payload)
              .then(() => {
                toastSuccess({ title: 'reservation[\'paymentInsSuccess\']' })
              })
              .catch(() => {
                toastError({ title: 'reservation[\'paymentInsErr\']' })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    async function handleShowInsuranceInfo(item) {
      dataInsuranceItem.value = null
      await new Promise(resolve => {
        dataInsuranceItem.value = item
        resolve()
      })
      await delay(250)
      this.$bvModal.show('modal-modify-special-services-insurance-info')
    }

    const getAgencyEnableTour = computed(() => store.getters['userStore/getAgencyEnableTour'])

    const showTourSuggestion = computed(() => getAgencyEnableTour.value && !env.isHideTour)
    const pointsForTourSuggestion = computed(() => uniq(props.reservationsData.itineraries
      .map(itinerary => {
        const len = itinerary.length || 0
        return len ? (itinerary?.[len - 1]?.arrival?.iataCode ?? null) : null
      })
      .filter(Boolean)))

    const otherProductRef = ref()
    const isVisibleTourSuggestion = ref(false)

    const { stop } = useIntersectionObserver(
      otherProductRef,
      ([{ isIntersecting }]) => {
        if (isIntersecting && !isVisibleTourSuggestion.value) {
          isVisibleTourSuggestion.value = true
          stop()
        }
      },
    )

    return {
      formatCurrency,
      loungeTableColumns,
      insuranceTableColumns,
      STATUS_NAME_ANCILLARY,
      isProduction,
      resolveSpecialServices,
      showAddOtherSpecialServices,
      convertISODateTime,
      isEmpty,
      showBenefit,
      showServiceFee,
      benefitInsurance,
      totalAllInsurance,
      showSpecialServicesQRCode,
      getTotalPriceSpecialService,
      dataSpecialItem,

      resolveInsuranceServices,
      handleCancelInsuranceItem,
      handleRefundInsuranceItem,
      paxAvailToAddDraftInsurance,
      handleShowModalAddInsuranceDraft,
      handleCreateInsuranceCoverNote,
      handleUpdateInsuranceDraft,
      handleDeleteInsuranceDraft,
      isEdit,
      dataInsuranceDraft,
      handleCreateInsurancePolicy,
      handleShowInsuranceInfo,
      dataInsuranceItem,
      resolveInsuranceSpecialServicesName,
      resolveVariantInsuranceServices,
      resolveInsuranceItinerariesName,
      hideFeature,
      TO_BE_PAID_INSURANCE_ITEM,
      totalAmountInsToBePay,
      isRoleF3,
      showTourSuggestion,
      pointsForTourSuggestion,
      otherProductRef,
      isVisibleTourSuggestion,
    }
  },
}
</script>
