var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('IncorrectInvoiceInfoCard', {
    attrs: {
      "incorrectInvoiceData": _vm.incorrectInvoiceData
    },
    on: {
      "update:incorrectInvoiceData": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceData = $event;
      },
      "update:incorrect-invoice-data": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceData = $event;
      }
    }
  }), _c('IncorrectInvoiceSelectCard'), _c('IncorrectInvoiceDetailCard')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }