import {
  ref, computed, watch,
} from '@vue/composition-api'

import { apiGeneral } from '@/api'

import useToast from '@useToast'

export const REQUEST_TYPE_OPTIONS = [
  { label: 'SEARCH', value: 'SEARCH', variant: 'info' },
  { label: 'CREATE', value: 'CREATE', variant: 'success' },
  { label: 'PAYMENT', value: 'PAYMENT', variant: 'danger' },
]
export const REQUEST_TYPE = REQUEST_TYPE_OPTIONS.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})
export const resolveRequestTypeVariant = type => REQUEST_TYPE_OPTIONS.find(item => item.value === type)?.variant ?? 'secondary'

export default function useAgentRequestStatsList() {
  // const { route } = useRouter()
  const { toastError } = useToast()

  const refAgentRequestsListTable = ref(null)
  const loading = ref(false)

  const tableColumns = [
    { label: 'no', key: 'no' },
    { label: 'agentCode', key: 'agentCode' },
    { label: 'routes', key: 'routes' },
    { label: 'flightDates', key: 'flightDates' },
    { label: 'source', key: 'source' },
    { label: 'trips', key: 'trips' },
    // { label: 'request', key: 'request' },
    { label: 'requestType', key: 'requestType' },
    { label: 'createdAt', key: 'createdAt' },
  ]

  // filter
  const startDate = ref('') // từ ngày
  const endDate = ref('') // đến ngày
  const agencyCode = ref('') // mã đại lý F2
  const requestType = ref(REQUEST_TYPE.SEARCH) // SEARCH / CREATE / PAYMENT
  const searchText = ref('')

  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalAgentRequests = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refAgentRequestsListTable.value ? refAgentRequestsListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAgentRequests.value,
    }
  })

  const clearFilter = () => {
    startDate.value = ''
    endDate.value = ''
    agencyCode.value = ''
    requestType.value = REQUEST_TYPE.SEARCH
    searchText.value = ''
  }

  const refetchData = () => {
    refAgentRequestsListTable.value.refresh()
  }

  const fetchAgentRequests = (_ctx, callback) => {
    loading.value = true
    const params = {
      startDate: startDate.value || undefined,
      endDate: endDate.value || undefined,
      agencyCode: agencyCode.value.toLowerCase() || undefined,
      searchText: searchText.value || undefined,
      requestType: requestType.value || undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      size: sizePerPage.value,
      page: currentPage.value,
    }
    apiGeneral.agentRequests(params)
      .then(response => {
        totalAgentRequests.value = response.data.count
        callback(response.data.items)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách hoá đơn')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  return {
    refAgentRequestsListTable,
    fetchAgentRequests,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalAgentRequests,

    // Filter
    isSortDirDesc,
    sortBy,
    startDate,
    endDate,
    agencyCode,
    searchText,
    requestType,
    tableColumns,
  }
}
