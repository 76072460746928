<template lang="">
  <Stack
    :class="`${isMobileView ? 'gap-2' : 'gap-3'}`"
    center
  >
    <div :class="`text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
      Các tour khác
    </div>
    <div :style="`width: 100%; border-top: ${isMobileView ? 1.5 : 2.5}px solid #2B3378;`" />
    <div
      :style="`display: grid; grid-template-${!['xl'].includes($store.getters['app/currentBreakPoint']) ? 'rows' : 'columns'}: repeat(3, 1fr); gap: 14px;`"
      class="w-100"
    >
      <div
        v-for="item in 3"
        :key="item"
      >
        <div
          v-if="anotherTours[item-1]"
          :style="`${`background-image: url(${anotherTours[item-1]?.image?.path || backgroundTourUrl})`}`"
          style="background-size: cover; background-position: center; height: 300px"
          class="rounded-16px d-flex-center align-items-end shadow-lg"
        >
          <Stack
            style="backdrop-filter: blur(4px); background: rgba(255, 255, 255, 0.8);"
            class="m-1 rounded-8px p-50 w-100 cursor-none"
          >
            <div
              v-b-tooltip.hover.window="anotherTours[item-1]?.name"
              :class="`fw-700 text-dark ${isMobileView ? 'text-12px' : 'text-18px'} clamped-content`"
            >
              {{ anotherTours[item-1]?.name }}
            </div>
            <HStack>
              <small class="fw-700">Mã chương trình:</small>
              <small class="fw-700">{{ anotherTours[item-1]?.tourCode }}</small>
            </HStack>
            <div class="d-flex-between align-items-end">
              <div v-if="anotherTours[item-1]?.adultsPriceSales">
                <small class="fw-700">Giá từ</small>
                <div :class="`fw-900 text-danger ${isMobileView ? 'text-16px' : 'text-24px'}`">
                  {{ formatCurrency(anotherTours[item-1]?.adultsPriceSales) }}
                </div>
              </div>
              <BButton
                variant="flat-dark"
                class="cursor-pointer fw-700 text-dark text-14px p-50"
                :to="{ name: 'apps-toursDetail', params: { id: anotherTours[item-1]?.priceId } }"
              >
                Xem chi tiết
              </BButton>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  </Stack>
</template>
<script>
import { BButton } from 'bootstrap-vue'

import { formatCurrency } from '@/@core/utils/filter'
import env from '@/libs/env'

export default {
  components: { BButton },
  props: {
    anotherTours: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      formatCurrency,
      backgroundTourUrl: env.backgroundTourUrl,
    }
  },
}
</script>
<style lang="scss" scoped>
.clamped-content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-height: 100%;
}

.divider-line {
  width: 100%;
  border-top: 2.5px solid #2B3378;
}
</style>
