var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "id": "agent-requests-list",
      "body-class": "p-1"
    }
  }, [_c('AgentRequestStatsFilters', {
    attrs: {
      "start-date": _vm.startDate,
      "end-date": _vm.endDate,
      "agency-code": _vm.agencyCode,
      "searchText": _vm.searchText,
      "requestType": _vm.requestType
    },
    on: {
      "update:startDate": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:start-date": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:endDate": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:end-date": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:agencyCode": function updateAgencyCode($event) {
        _vm.agencyCode = $event;
      },
      "update:agency-code": function updateAgencyCode($event) {
        _vm.agencyCode = $event;
      },
      "update:searchText": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "update:search-text": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "update:requestType": function updateRequestType($event) {
        _vm.requestType = $event;
      },
      "update:request-type": function updateRequestType($event) {
        _vm.requestType = $event;
      },
      "refetchData": _vm.refetchData,
      "resetFilters": function resetFilters($event) {
        _vm.clearFilter();
        _vm.refetchData();
      }
    }
  }), _c('h4', {
    staticClass: "fw-700 py-50 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('agentRequests.title')) + " ")]), _c('b-table', {
    ref: "refAgentRequestsListTable",
    staticClass: "position-relative",
    style: _vm.maxHeightStyle,
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchAgentRequests,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "busy": _vm.loading,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "fields": _vm.tableColumns
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_c('div', [_vm._v(_vm._s(_vm.$t("agentRequests.columns.".concat(data.column))))])])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(agentCode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "fw-700 text-uppercase"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.agentCode))])];
      }
    }, {
      key: "cell(routes)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.routes) + " ")];
      }
    }, {
      key: "cell(flightDates)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return _vm._l(item.flightDates.split(' - '), function (flightDate, flightDateIndex) {
          return _c('div', {
            key: flightDateIndex,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(flightDate) + " ")]);
        });
      }
    }, {
      key: "cell(source)",
      fn: function fn(_ref4) {
        var _item$request;
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((((_item$request = item.request) === null || _item$request === void 0 ? void 0 : _item$request.sources) || []).join(', ')) + " ")];
      }
    }, {
      key: "cell(trips)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('HStack', [_c('div', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.request.type) + " ")]), _vm._v(": " + _vm._s(item.trips) + " ")]), _c('HStack', {
          staticClass: "gap-3"
        }, [_c('HStack', {
          attrs: {
            "end": ""
          }
        }, [_c('small', {
          staticClass: "font-italic"
        }, [_vm._v("Người lớn:")]), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.request.adult))])]), _c('HStack', {
          attrs: {
            "end": ""
          }
        }, [_c('small', {
          staticClass: "font-italic"
        }, [_vm._v("Trẻ em:")]), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.request.child))])]), _c('HStack', {
          attrs: {
            "end": ""
          }
        }, [_c('small', {
          staticClass: "font-italic"
        }, [_vm._v("Em bé:")]), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.request.infant))])])], 1), _c('HStack', {
          staticClass: "gap-3"
        }, [_c('div', [_vm._v("Giá " + _vm._s(item.request.isGroupedItineraryResponse ? 'kết hợp' : 'tách chặng'))]), _c('div', [_vm._v(_vm._s(item.request.numberOfStop === 0 ? 'Bay thẳng' : 'Nối chuyến'))])])];
      }
    }, {
      key: "cell(requestType)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveRequestTypeVariant(item.requestType),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$te("agentRequests.requestType.".concat(item.requestType)) ? _vm.$t("agentRequests.requestType.".concat(item.requestType)) : item.requestType) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")])];
      }
    }], null, true)
  }), _c('b-row', {
    ref: "refTableFooter"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "1"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "order": "3",
      "order-md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalAgentRequests,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center justify-content-end",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "2",
      "order-md": "3"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }