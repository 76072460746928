<template>
  <div>
    <!-- SECTION modal-flightv2-show-price-group-booking -->
    <BModal
      id="modal-flightv2-show-price-group-booking"
      title="Tính giá, book đoàn lẻ"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-1"
      centered
      no-close-on-backdrop
      size="lg"
      lazy
      @show="handleShowModal"
      @hide="handleHideModal"
    >
      <template #modal-footer="{ close }">
        <div class="d-flex gap-3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            variant="outline-secondary"
            class="center rounded-pill width-100"
            @click="close()"
          >
            {{ $t('reservation.back') }}
          </b-button>
          <b-button
            v-if="isShowBookingGroupButton"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            class="center rounded-pill btn-gradient"
            :disabled="!quantityAdult"
            @click="handleGoToReservation()"
          >
            {{ $t('reservation.confirm') }}
          </b-button>
        </div>
      </template>

      <AlertGroupBooking />

      <div class="text-body fw-700">
        1. Hành trình:
      </div>
      <b-card
        v-for="(trip, indexTrip) of listSelectedTrips"
        :key="indexTrip"
        class="mb-50 border rounded-lg text-center"
        body-class="px-50 px-lg-1 py-75 d-flex-center flex-column flex-lg-row"
      >
        <div class="mb-50 mb-lg-0">
          <span class="mr-50 fw-700 text-airline">
            #{{ indexTrip + 1 }}:
          </span>
          <span class="mr-50 fw-700 text-airline">
            {{ `
            ${trip.source.includes('1S') ? 'VN' : trip.source }${trip.flightNumber}
            ${trip.departure.IATACode}${trip.arrival.IATACode}
            ${convertISODateTime(trip.departure.at, trip.departure.timezone).hourMin }
            ${ convertISODateTime(trip.arrival.at, trip.arrival.timezone).hourMin}
            | ${trip.segments[0].airCraft}
            ` }}
          </span>
        </div>

        <div>
          <code
            v-if="resolveTripClasses(trip)"
            :class="`fw-700 ${isMobileView ? 'font-small-4' : 'font-medium-2'}`"
          >
            {{ resolveTripClasses(trip) }}
          </code>
        </div>
      </b-card>

      <div class="d-flex align-items-center mt-1">
        <span class="text-body fw-700">2. Số lượng khách:</span>
      </div>

      <b-card
        class="mb-50 border rounded-lg text-center"
        body-class="py-75"
      >
        <b-row>
          <b-col
            cols="4"
            class="px-50"
          >
            <b-form-group label-for="quantity-adult">
              <template #label>
                <div class="d-flex justify-content-start gap-1 text-nowrap fw-700">
                  <span>Người lớn</span>
                </div>
              </template>
              <b-form-input
                id="quantity-adult"
                v-model.number="quantityAdult"
                v-remove-non-numeric-chars.allNumber
                style="min-width: 80px"
                max="3"
                size="sm"
                type="number"
                class="font-medium-2 fw-600"
                placeholder="Nhập số lượng người lớn"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center px-50"
          >
            <b-form-group label-for="quantity-child">
              <template #label>
                <div class="d-flex justify-content-start gap-1 text-nowrap fw-700">
                  <span>Trẻ em</span>
                </div>
              </template>
              <b-form-input
                id="quantity-adult"
                v-model.number="quantityChild"
                v-remove-non-numeric-chars.allNumber
                style="min-width: 80px"
                max="3"
                size="sm"
                type="number"
                class="font-medium-2 fw-600"
                placeholder="Nhập số lượng trẻ em"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center px-50"
          >
            <b-form-group label-for="quantity-infant">
              <template #label>
                <div class="d-flex justify-content-start gap-1 text-nowrap fw-700">
                  <span>Em bé</span>
                </div>
              </template>
              <b-form-input
                id="quantity-infant"
                v-model.number="quantityInfant"
                v-remove-non-numeric-chars.allNumber
                style="min-width: 80px"
                max="3"
                size="sm"
                type="number"
                class="font-medium-2 fw-600"
                placeholder="Nhập số lượng em bé"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex-center">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-warning"
            class="py-75 fw-700"
            pill
            :disabled="loading"
            @click="handleCalcPriceGroupBooking()"
          >
            {{ $t('flight.groupBooking.calculate') }}
          </b-button>
        </div>
      </b-card>

      <div v-if="isCalcPriceGroupBooking">
        <IAmOverlay
          :loading="loading || isEmpty(arrTripsModify)"
          spinner-variant="info"
          :class="`py-1 ${loading || isEmpty(arrTripsModify) ? 'mt-3 mb-1' : ''}`"
        >
          <b-card
            v-if="!isEmpty(listBookingCalculated)"
            header-bg-variant="light-success"
            header-class="py-75"
            class="border"
          >
            <template #header>
              <div>
                Chi tiết tính giá đoàn:
              </div>
            </template>

            <div class="fw-700 text-body mt-50">
              Số hành khách nhập vào (Người lớn + trẻ em): <span class="ml-1 text-airline">{{ totalPassengerToCal }}</span>
            </div>
            <div class="fw-700 text-body mt-50 d-flex align-items-center">
              Tổng số chỗ có thể đặt: <span class="ml-1 text-success">{{ maxSeatsToBook }}</span>
              <feather-icon
                id="seat-available-can-book"
                icon="InfoIcon"
                size="16"
                class="ml-50 text-info cursor-pointer"
              />
              <b-tooltip
                target="seat-available-can-book"
                triggers="hover focus"
                boundary="window"
                variant="info"
              >
                <small>Số chỗ còn trống trên hành trình và có thể đặt với hạng vé đã chọn</small>
              </b-tooltip>
            </div>
            <div
              v-if="maxSeatsToBook < totalPassengerToCal"
              class="fw-700 text-body mt-50 d-flex align-items-center"
            >
              <span class="text-danger">
                Số hành khách còn lại:
              </span>
              <span class="ml-1 text-danger">{{ totalPassengerToCal - (Number(maxSeatsToBook) || 0) }}</span>
              <feather-icon
                id="seat-unavailable-can-book"
                icon="InfoIcon"
                size="16"
                class="ml-50 text-info cursor-pointer"
              />
              <b-tooltip
                target="seat-unavailable-can-book"
                triggers="hover focus"
                boundary="window"
                variant="info"
              >
                <small>Số chỗ còn thiếu, hết chỗ</small>
              </b-tooltip>
            </div>

            <!-- <div class="fw-700 text-body mt-50">
              Tổng số booking cần đặt: <span class="ml-1 text-success">{{ listBookingCalculated.length }}</span>
            </div> -->
            <!-- <div class="fw-700 text-body mt-50">
              Tổng tiền:
              <span class="ml-1 text-danger">
                {{ resolveTotalPriceGroupBooking(listBookingCalculated) }}
              </span>
            </div> -->
            <b-alert
              variant="danger"
              class="mb-0 mt-1 px-1 py-50 font-weight-bolder"
              :show="listBookingCalculated.length > 0"
            >
              Thông tin giá chỉ mang tính chất tham khảo, chưa bao gồm phí dịch vụ.
            </b-alert>
          </b-card>

          <b-card
            v-for="(tripData, indexTrip) of listBookingCalculated"
            :key="indexTrip"
            header-bg-variant="light-info"
            header-class="py-75 mb-50"
            class="border"
          >
            <template #header>
              <div class="fw-700 text-airline">
                Hành trình: #{{ indexTrip + 1 }}
              </div>
            </template>

            <!-- <div class="fw-700 text-body mt-50">
              Hành trình:
            </div> -->

            <!-- v-for="(item, indexTrip) of tripData[0]" -->
            <!-- :key="indexTrip" -->
            <b-card
              class="mb-0 ml-md-1"
              no-body
            >
              <div class="text-body text-airline fw-700 my-50">
                {{ `
                ${resolveAirlineFlightNumber(tripData[0].trip.airline, tripData[0].trip.flightNumber)}
                ${convertISODateTime(tripData[0].trip.departure.at, tripData[0].trip.departure.timezone).dayMonth}
                ${tripData[0].trip.departure.IATACode}${tripData[0].trip.arrival.IATACode}
                ${convertISODateTime(tripData[0].trip.departure.at, tripData[0].trip.departure.timezone).hourMin}
                ${convertISODateTime(tripData[0].trip.arrival.at, tripData[0].trip.arrival.timezone).hourMin}
                ` }}
              </div>
            </b-card>

            <!-- <div class="fw-700 text-body my-50 mr-2">
              Hạng vé:
              <span class="fw-700 text-airline">
                {{ trip[0].code }}
              </span>
            </div> -->

            <div class="fw-700 text-body my-50 mr-2 d-flex">
              <span class="mr-2">
                Tổng số chỗ (người lớn, trẻ em):
              </span>
              <span class="fw-700 text-airline mr-1">
                {{ tripData.reduce((total, item) => total + item.prices.reduce((tSeat, pax) => tSeat + (['INFANT'].includes(pax.paxType) ? 0 : pax.quantityPax),0), 0) }}
              </span>
            </div>

            <div class="fw-700 text-body mt-50">
              Thông tin giá:
            </div>
            <b-table-lite
              small
              bordered
              responsive
              show-empty
              no-border-collapse
              class="mb-1 rounded"
              thead-class="text-body font-weight-bolder text-nowrap"
              tbody-class="text-body font-weight-bolder text-nowrap"
              :empty-text="$t('noMatchingResult')"
              :items="tripData.flatMap(c => c.prices)"
              :fields="['paxType', 'bookingClass', 'net', 'tax', 'fare', 'quantity', 'totalPrice']"
            >
              <template
                v-for="column in ['paxType', 'bookingClass', 'net', 'tax', 'fare', 'quantity', 'totalPrice']"
                #[`head(${column})`]="data"
              >
                <span
                  :key="column"
                  class="text-body"
                >
                  {{ $t(`reservation.${data.column}`) }}
                </span>
              </template>

              <template #cell(pax_type)="{item}">
                {{ $t(`${capitalize(item.paxType)}`) }}
              </template>
              <template #cell(bookingClass)="{item}">
                <span class="fw-700 text-danger mr-25">{{ item.code }}</span> ({{ item.fareBasisCode }})
              </template>
              <template #cell(net)="{item}">
                <div class="text-right fw-600">
                  {{ item.net ? formatCurrency(item.net) : '-' }}
                </div>
              </template>
              <template #cell(tax)="{item}">
                <div class="text-right fw-600">
                  {{ item.tax ? formatCurrency(item.tax) : '-' }}
                </div>
              </template>
              <template #cell(fare)="{item}">
                <div class="text-right fw-600">
                  {{ formatCurrency(item.fare) }}
                </div>
              </template>
              <template #cell(quantity)="{item}">
                <div class="text-center fw-600">
                  {{ item.quantityPax }}
                </div>
              </template>
              <template #cell(totalPrice)="{item}">
                <div class="text-airline text-right fw-700">
                  {{ formatCurrency(Number(item.fare) * item.quantityPax ) }}
                </div>
              </template>
            </b-table-lite>
          </b-card>
        </IAmOverlay>
      </div>
    </BModal>
  </div>
</template>

<script>
import {
  BModal,
  BTableLite,
  BButton,
  BCard,
  BTooltip,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue'
import {
  computed, ref,
} from '@vue/composition-api'
import {
  isEmpty, cloneDeep, min, capitalize,
} from 'lodash-es'
import groupBy from 'lodash/groupBy'

import { isDomesticFlight } from '@/constants/selectOptions'
import store from '@/store'
import { apiBooking } from '@/api'

import {
  formatCurrency,
  convertShortTrip,
  formatDateMonth, resolveAirlineFlightNumber,
  convertISODateTime,
} from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BTableLite,
    BTooltip,
    BModal,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BAlert,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    AlertGroupBooking: () => import('./AlertGroupBooking.vue'),
  },
  props: {
    selectedTrip: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const tableColumns = ref(['paxType', 'bookingClass', 'net', 'tax', 'fare', 'quantity', 'totalPrice'])
    const { toastError } = useToast()
    const loading = ref(false)
    const isCalcPriceGroupBooking = ref(false)

    const quantityAdult = ref(null)
    const quantityChild = ref(null)
    const quantityInfant = ref(null)
    const totalPassengerToCal = ref(0)

    const VNA_BUSINESS_CLASSES = ['I', 'D', 'C', 'J'] // lọc các hạng Bussiness cho Vietnam Airlines
    const VU_BUSINESS_CLASSES = ['J'] // lọc các hạng Bussiness cho Vietravel
    const maxSeatsToBook = ref(null) // Số chỗ(pax) max có thể đặt trong lần book đoàn này, phụ thuộc vào số ghế còn trống trên hành trình
    const listSelectedTrips = ref(props.selectedTrip)

    const compSelectedTrips = computed(() => {
      if (isEmpty(props.selectedTrip)) return []
      const data = props.selectedTrip.map((t, index) => ({ ...t, indexTrip: index }))
      return data
    })

    const arrTripsModify = ref([])
    const listBookingCalculated = ref([]) // list booking show

    // Hiển thị group đã chọn là phổ thông hay thương gia, hoàn hay không hoàn
    function resolveTripClasses(trip) {
      if (['VJ', 'QH'].includes(trip.source)) {
        const isBusinessClass = trip.fareOptions.groupClass.toUpperCase().includes('BUSINESS') || VNA_BUSINESS_CLASSES.includes(trip.fareOptions.bookingClass)
        if (isBusinessClass) {
          return 'Hạng thương gia'
        }
        return 'Hạng phổ thông'
      }

      if (trip.source.includes('VU')) {
        const isBusinessClass = trip.fareOptions.fareType.toUpperCase().includes('PREMIUM') || VU_BUSINESS_CLASSES.includes(trip.fareOptions.bookingClass)
        const noRefund = trip?.fareOptions?.noRefund
        if (!isBusinessClass) {
          if (noRefund) {
            return 'Hạng phổ thông (Không hoàn)'
          }
          return 'Hạng phổ thông (Được phép hoàn)'
        }
        return 'Hạng thương gia (Được phép hoàn)'
      }
      return ''
    }

    // SP: CheckInfoSegment - để phí cộng sau ? (có hideFee) ================================
    function getBookingClassAvail(bookingClassAvail, isDomestic, airline) {
      const result = []
      const hideFee = store.getters['userStore/getHideFee']
      const source = airline.includes('VN') ? '1S' : airline
      const agencyFee = store.getters['userStore/getAgencyFee'](isDomestic, source)
      const agencyFeeAmount = agencyFee?.amount || 0
      const d = groupBy(bookingClassAvail, item => item.code)
      for (const key in d) {
        if (Object.hasOwnProperty.call(d, key)) {
          const ele = d[key]
          const fareObj = {
            ...ele[0],
            code: key,
            availability: ele[0].truthOfAvailability ?? ele[0].availability, // FIXME: remove availability on upd
            refundable: ele[0]?.refundable ?? null, // VU airlines
            prices: ele
              .map(item => ({
                paxType: item.paxType,
                fareBasisCode: item.fareBasisCode,
                net: item.net,
                tax: item.tax + (hideFee ? agencyFeeAmount : 0), // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
                fare: item.fare + (hideFee ? agencyFeeAmount : 0), // NOTE: hiện tại chưa thêm gì ngoài phí ẩn
              }))
              .sort((a, b) => {
                if (a.paxType === 'ADULT') {
                  return -1 // ADULT 1
                } if (a.paxType === 'CHILD' && b.paxType === 'INFANT') {
                  return -1 // CHILD 2
                } if (a.paxType === 'CHILD' && b.paxType !== 'INFANT') {
                  return -1 // INFANT 3
                } if (a.paxType === 'INFANT' && b.paxType !== 'CHILD') {
                  return 1
                }
                return 1
              }),
          }
          result.push(fareObj)
        }
      }
      return result
    }

    // SP: CheckInfoSegment
    function addIndexSegmentToData(res, data) {
      const isDomesticArray = []
      const airline = data.trip.airline
      const filterBy = airline === 'VN'
        ? VNA_BUSINESS_CLASSES.includes(data.trip.fareOptions.bookingClass)
          ? 'BUSINESS'
          : 'ECO'
        : VU_BUSINESS_CLASSES.includes(data.trip.fareOptions.bookingClass)
          ? 'BUSINESS'
          : data.trip.fareOptions.noRefund
            ? 'ECO_NO_REFUND'
            : 'ECO_REFUND'

      if (data) {
        const flights = cloneDeep(data.flights)
        if (data.type === 'RT') {
          flights.push(data.flights[0])
        }
        flights.forEach(({ startPoint, endPoint }) => {
          isDomesticArray.push(isDomesticFlight(startPoint, endPoint))
        })
      }
      return res.map((item, itineraryIndex) => {
        const isDomestic = isDomesticArray ? isDomesticArray[itineraryIndex] : null
        return {
          trips: item.trips.map(trip => ({
            ...trip,
            segments: trip.segments.map(segment => {
              const filterBookingClassAvail = segment.bookingClassAvail.filter(item => item.availability
            && (['VN'].includes(airline)
              ? ['BUSINESS'].includes(filterBy)
                ? VNA_BUSINESS_CLASSES.includes(item.code)
                : !VNA_BUSINESS_CLASSES.includes(item.code)
              : ['BUSINESS'].includes(filterBy)
                ? VU_BUSINESS_CLASSES.includes(item.code)
                : ['ECO_NO_REFUND'].includes(filterBy)
                  ? (!VU_BUSINESS_CLASSES.includes(item.code) && (!item.refundable))
                  : (!VU_BUSINESS_CLASSES.includes(item.code) && (item.refundable))
            )) // 1 cái là noRefund, 1 cái là refundable

              // : item.refundable === !filterBy)) // 1 cái là noRefund, 1 cái là refundable

              return {
                ...segment,
                source: segment.airline === 'VN' ? '1S' : segment.airline,
                bookingClassAvail: getBookingClassAvail(filterBookingClassAvail, isDomestic, airline),
              }
            }),
          })),
        }
      })
    }

    // SP: CheckInfoSegment
    async function handleCheckInfoSegment(trip) {
      loading.value = true
      const result = []
      try {
        const payload = { classBookingId: trip.segments[0].bookingClassId }
        const data = {
          flights: [{ startPoint: trip.segments[0].departure.IATACode, endPoint: trip.segments[0].arrival.IATACode }],
          trip,
          airline: trip.segments[0].airline,
        }
        await apiBooking.getClassBookingInfoTrip(payload)
          .then(async response => {
            const tripDataItem = cloneDeep(trip)
            const wrapRes = await addIndexSegmentToData(response, data)
            const arrClassFare = wrapRes[0].trips[0].segments[0].bookingClassAvail
            const newItem = {
              ...tripDataItem,
              arrClassFare,
            }
            result.push(newItem)
          })
          .catch(err => {
            toastError({ title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!' })
            console.error({ err })
          })

        return result
      } catch (error) {
        console.error('Error in handleCheckInfoSegment: ', { error })
        return result
      } finally {
        loading.value = false
      }
    }

    // SP: handleGenerate - arr seat
    function getMaxSeatToBook(values) {
      try {
        const seatAvailInAll = values.map(trip => trip?.arrClassFare?.reduce((total, item) => total + item?.availability || 0, 0))
        const totalPaxInputSeat = Number(quantityAdult.value ? quantityAdult.value : 0) + Number(quantityChild.value ? quantityChild.value : 0)
        return [...seatAvailInAll.flat(), totalPaxInputSeat]
      } catch (error) {
        console.error('Error in getMaxSeatToBook:', { error })
        return []
      }
    }

    function getSeatsAvailOnEachClass(arrayTripPrice, totalPaxCanBook) {
      try {
        const data = []
        arrayTripPrice.forEach(trip => {
          const result = []
          let num = cloneDeep(totalPaxCanBook)
          trip.arrClassFare.forEach(item => {
            let seat
            if (num === 0) {
              seat = 0
            } else if (item?.availability <= num) {
              seat = item?.availability
              num -= item?.availability
            } else if (item?.availability > num) {
              seat = num
              num = 0
            }
            result.push({
              numSeatsToUse: seat,
              ...item,
              trip,
            })
          })
          data.push(result)
        })
        // console.log('final. getSeatsAvailOnEachClass', { data })
        return data
      } catch (error) {
        console.error('Error in getSeatsAvailOnEachClass:', { error })
        return []
      }
    }

    // SP: get seat by paxInput
    function resolveSeatByPaxInput(tripArray) {
      const result = tripArray.map(trip => { // NOTE: trip => mảng các bookingClass
        const dataPax = {
          adult: quantityAdult.value ? Number(quantityAdult.value) : 0,
          child: quantityChild.value ? Number(quantityChild.value) : 0,
          infant: quantityInfant.value ? Number(quantityInfant.value) : 0,
        }

        const arrayBookingClass = []

        // NOTE: Lấy số lượng từ ADT > CHD => lấy giá từ ADT để tổng giá vé sau cùng sẽ rẻ hơn
        trip.forEach(bClass => {
          let numAdult = 0
          let numChild = 0
          let numInfant = 0

          if (dataPax.adult > 0) {
            if (dataPax.adult < bClass.numSeatsToUse) { // dataPax.adult < bClass.numSeatsToUse
              const residual = bClass.numSeatsToUse - dataPax.adult // phần dư cho child
              numAdult = dataPax.adult

              if (dataPax.child >= residual) {
                numChild = residual
                dataPax.child -= residual
              }

              if (dataPax.infant > 0) {
                if (dataPax.infant >= dataPax.adult) {
                  numInfant = dataPax.adult
                  dataPax.infant -= dataPax.adult
                } else {
                  numInfant = dataPax.infant
                  dataPax.infant = 0
                }
              }
              dataPax.adult = 0
            } else { // dataPax.adult >= numSeatsToUse
              numAdult = bClass.numSeatsToUse
              dataPax.adult -= numAdult

              if (dataPax.infant > 0) {
                if (dataPax.infant >= numAdult) {
                  numInfant = numAdult
                  dataPax.infant -= numAdult
                } else {
                  numInfant = dataPax.infant
                  dataPax.infant = 0
                }
              }
            }
          } else if (dataPax.adult === 0) {
            if (dataPax.child > bClass.numSeatsToUse) {
              numChild = bClass.numSeatsToUse
              dataPax.child -= bClass.numSeatsToUse
            } else {
              numChild = dataPax.child
              dataPax.child = 0
            }
          }

          const wrapBClass = {
            ...bClass,
            prices: bClass.prices.map(price => {
              const data = { ...price, code: bClass.code }
              if (price.paxType === 'ADULT') {
                data.quantityPax = numAdult
              }
              if (price.paxType === 'CHILD') {
                data.quantityPax = numChild
              }
              if (price.paxType === 'INFANT') {
                data.quantityPax = numInfant
              }
              return data
            }).filter(p => p.quantityPax > 0),
          }

          arrayBookingClass.push(wrapBClass)
        })

        return arrayBookingClass
      })
      return result
    }

    // SP: handleGenerate
    function handleGenerateDraftRequest(arrTrips) {
      maxSeatsToBook.value = min(getMaxSeatToBook(arrTrips))
      const classTripArray = getSeatsAvailOnEachClass(arrTrips, maxSeatsToBook.value)
      listBookingCalculated.value = resolveSeatByPaxInput(classTripArray)

      // console.log('handleGenerateDraftRequest: ', {
      //   DONE_listBookingCalculated: listBookingCalculated.value,
      //   quantity_of_seats: {
      //     _Input: {
      //       adultInput: quantityAdult.value,
      //       childInput: quantityChild.value,
      //       infantInput: quantityInfant.value,
      //     },
      //     _Can_Book: maxSeatsToBook.value,
      //     classTripArray,
      //   },
      // })
    }

    async function handleCalcPriceGroupBooking() {
      arrTripsModify.value = []
      const notAllowedToCalculatePrice = compSelectedTrips.value.some(trip => !['VU'].includes(trip.source))
      if (notAllowedToCalculatePrice) {
        toastError({ title: 'Chỉ hỗ trợ tính giá đoàn với Vietravel Airlines (VU) !' })
        isCalcPriceGroupBooking.value = false
        return
      }
      const hasTransit = compSelectedTrips.value.some(trip => trip.segments.length > 1)
      if (hasTransit) {
        toastError({ title: 'Không hỗ trợ với hành trình có điểm dừng!' })
        isCalcPriceGroupBooking.value = false
        return
      }
      if (!quantityAdult.value) {
        toastError({ title: 'Vui lòng nhập vào số lượng hành khách người lớn!' })
        isCalcPriceGroupBooking.value = false
        return
      }
      if (Number(quantityInfant.value) > Number(quantityAdult.value)) {
        toastError({ title: 'Số hành khách em bé phải nhỏ hơn số hành khách người lớn!' })
        isCalcPriceGroupBooking.value = false
        return
      }

      totalPassengerToCal.value = Number(quantityAdult.value) + Number(quantityChild.value || 0)

      const hasSourceCalcPrice = compSelectedTrips.value.some(trip => ['VU'].includes(trip.source))
      if (hasSourceCalcPrice) {
        isCalcPriceGroupBooking.value = true
        const results = await Promise.all(compSelectedTrips.value.map(async trip => {
          if (['VU'].includes(trip.source)) {
            const res = await handleCheckInfoSegment(trip)
            return res
          }
          return null
          // else {
          //   arrTripsModify.value.push(trip)
          // }
        }))
        const compareIndexTrip = (a, b) => a?.indexTrip - b?.indexTrip
        arrTripsModify.value = results.flat().filter(Boolean).sort(compareIndexTrip)
        handleGenerateDraftRequest(arrTripsModify.value)
      }
    }

    function handleGoToReservation() {
      // NOTE: Tổng số ghế không lq với số pax nhập vào
      // TH1: nhập số lượng và không tính giá > xác nhận > all hãng => nhập bao nhiêu lấy bấy nhiêu.
      // TH2: nhập số lượng và tính giá > xác nhận > số pax có thể đặt thay đổi => lấy số pax có thể đặt (theo seat available).
      // TH3: nhập số lượng và tính giá > nhập lại số lượng > xác nhận => lấy theo số pax đã nhập trước khi tính giá.

      const paxData = {
        adult: quantityAdult.value ? Number(quantityAdult.value) : 0,
        adultModified: quantityAdult.value ? Number(quantityAdult.value) : 0,
        child: quantityChild.value ? Number(quantityChild.value) : 0,
        childModified: quantityChild.value ? Number(quantityChild.value) : 0,
        infant: quantityInfant.value ? Number(quantityInfant.value) : 0,
        infantModified: quantityInfant.value ? Number(quantityInfant.value) : 0,
      }

      store.dispatch('app-flight-v2/setIsCreateGroupBooking', true)
      store.dispatch('app-flight-v2/setDataCreateGroupBooking', listBookingCalculated.value)
      store.dispatch('app-flight-v2/setPaxDataGroupBooking', paxData)
      emit('goToReservation')
    }

    function handleShowModal() {
      listSelectedTrips.value = cloneDeep(props.selectedTrip)
      maxSeatsToBook.value = null
      isCalcPriceGroupBooking.value = false

      quantityAdult.value = null
      quantityChild.value = null
      quantityInfant.value = null
      totalPassengerToCal.value = 0
    }

    function handleHideModal() {
      listSelectedTrips.value = []
      arrTripsModify.value = []
      listBookingCalculated.value = []
    }

    const canBookingGroup = computed(() => store.getters['userStore/canBookingGroup'])

    const isShowBookingGroupButton = computed(() => canBookingGroup.value)
    return {
      loading,
      isEmpty,
      capitalize,
      quantityAdult,
      quantityChild,
      quantityInfant,
      arrTripsModify,
      listSelectedTrips,
      handleCheckInfoSegment,
      listBookingCalculated,

      handleShowModal,
      handleHideModal,

      tableColumns,
      formatCurrency,
      formatDateMonth,
      convertShortTrip,
      convertISODateTime,

      resolveTripClasses,
      handleGoToReservation,
      handleCalcPriceGroupBooking,
      isCalcPriceGroupBooking,
      maxSeatsToBook,
      isShowBookingGroupButton,
      totalPassengerToCal,
      resolveAirlineFlightNumber,
    }
  },
}
</script>
