var render = function () {
  var _vm$fnAncillary, _vm$fnAncillary$BAGGA;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-add-baggage",
      "title": "Thêm hành lý",
      "title-class": "text-airline font-medium-3 fw-700",
      "body-class": "px-75",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "d-flex-center w-100"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-2 d-flex-center",
          attrs: {
            "variant": "info",
            "pill": ""
          },
          on: {
            "click": _vm.handleConfirmChange
          }
        }, [_c('div', {
          staticClass: "d-flex-center ml-25"
        }, [_vm.loadingNextButton ? _c('BSpinner', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }) : _c('span', [_vm._v("Tiếp tục")])], 1)])], 1)];
      }
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isShow,
      "rounded": "sm",
      "no-fade": "",
      "variant": "white",
      "spinner-variant": "info",
      "opacity": 0.9,
      "no-center": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center py-2 mt-4"
        }, [_c('p', {
          staticClass: "font-weight-bolder text-airline",
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v(" Đang tải dữ liệu ... ")]), _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "info"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "fw-700 text-medium-1"
  }, [_vm._v(" Chọn gói hành lý mua thêm: ")]), ['QH'].includes(_vm.bookingSource) ? _c('BAlert', {
    staticClass: "mx-25 px-1 py-50 fw-700 mb-75",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('p', {
    staticClass: "text-danger"
  }, [_vm._v(" - Bamboo Airways: Tối đa mua thêm " + _vm._s((_vm$fnAncillary = _vm.fnAncillary) === null || _vm$fnAncillary === void 0 ? void 0 : (_vm$fnAncillary$BAGGA = _vm$fnAncillary.BAGGAGE) === null || _vm$fnAncillary$BAGGA === void 0 ? void 0 : _vm$fnAncillary$BAGGA.maximumWeight) + "KG/ 1 khách/ 1 hành trình. ")]), _vm.isShowWarningQH ? _c('p', {
    staticClass: "text-danger"
  }, [_vm._v(" - Vé đã mua hành lý 1 chặng, chặng còn lại phải mua gói hành lý khác gói đã mua trước đó, nếu cần mua gói tương tự vui lòng chat Booker. ")]) : _vm._e()]) : _vm._e(), _vm._l(_vm.arrDataHandle, function (tripData, indexTripData) {
    return _c('b-card', {
      key: indexTripData,
      staticClass: "border-warning shadow-lg",
      attrs: {
        "header-bg-variant": "light-warning",
        "header-class": "py-50 px-1",
        "body-class": "pb-0 pt-50 px-75"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-airline fw-700"
          }, [_c('span', {
            staticClass: "font-small-4 mr-50"
          }, [_vm._v("Hành trình: ")]), _vm._v(" " + _vm._s(['TR', 'JQ'].includes(_vm.bookingSource) ? tripData.flights : "".concat(tripData.trip[0].departure.iataCode, "-").concat(tripData.trip[tripData.trip.length - 1].arrival.iataCode)) + " (" + _vm._s(_vm.convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date) + ") ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('CardNamePax', {
      attrs: {
        "arr-pax": tripData.arrPax,
        "name-type": 'hành lý'
      }
    }), _c('b-card', {
      staticClass: "mb-50",
      attrs: {
        "header-class": "px-0 pt-50 pb-50 pb-md-0",
        "body-class": "px-0 py-50 px-md-1 px-lg-2"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('span', {
            staticClass: "text-body fw-700"
          }, [_vm._v("Chọn gói hành lý: ")])];
        },
        proxy: true
      }], null, true)
    }, [!_vm.isEmpty(_vm.getBaggageByTrip(tripData)) ? _vm._l(_vm.getBaggageByTrip(tripData), function (bagItem, indexBag) {
      return _c('b-row', {
        key: indexBag,
        staticClass: "mb-1 mb-md-75 justify-content-center",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('b-col', {
        staticClass: "d-flex align-items-center",
        attrs: {
          "cols": _vm.fnAncillary.BAGGAGE.increase || _vm.fnAncillary.BAGGAGE.decrease ? '12' : '10',
          "md": "8"
        }
      }, [_c('b-form-checkbox', {
        attrs: {
          "id": "baggageItem_".concat(indexTripData, "_").concat(indexBag),
          "checked": _vm.isChecked(tripData.newAddItems, bagItem),
          "inline": "",
          "disabled": _vm.checkDisableItem(tripData, bagItem)
        },
        on: {
          "change": function change($event) {
            return _vm.chooseItem(tripData.newAddItems, bagItem);
          }
        }
      }, [_c('span', {
        class: "font-weight-bolder ".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-small-3')
      }, [_vm._v(" " + _vm._s(_vm.resolveSsrBagsName(bagItem)))]), _c('span', {
        class: "text-warning font-italic fw-700 font-medium-1 ".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-small-3')
      }, [_vm._v(" " + _vm._s(!_vm.isEmpty(bagItem) && bagItem.fares.find(function (f) {
        return ['ALL', 'ADULT', ''].includes(f.paxType);
      }) ? _vm.formatCurrency(bagItem.fares.find(function (f) {
        return ['ALL', 'ADULT', ''].includes(f.paxType);
      }).total) : '-') + " ")])])], 1), _c('b-col', {
        attrs: {
          "cols": _vm.fnAncillary.BAGGAGE.increase || _vm.fnAncillary.BAGGAGE.decrease ? '12' : '2',
          "md": "4"
        }
      }, [_vm.isChecked(tripData.newAddItems, bagItem) ? _c('div', {
        staticClass: "d-flex align-items-center justify-content-end"
      }, [_c('div', {
        staticClass: "mr-50 d-none d-lg-inline"
      }, [_vm._v(" Số lượng: ")]), _c('div', {
        staticClass: "d-flex"
      }, [_vm.fnAncillary.BAGGAGE.decrease ? _c('b-button', {
        staticClass: "px-75 py-0 fw-700",
        attrs: {
          "variant": "flat-danger",
          "pill": ""
        },
        on: {
          "click": function click($event) {
            return _vm.decreaseQuantity(tripData.newAddItems, bagItem);
          }
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "MinusIcon",
          "size": "14"
        }
      })], 1) : _vm._e(), _c('b-form-input', {
        directives: [{
          name: "remove-non-numeric-chars",
          rawName: "v-remove-non-numeric-chars"
        }],
        staticClass: "mx-50 px-50 fw-700 text-center font-medium-3 rounded",
        staticStyle: {
          "width": "40px"
        },
        attrs: {
          "value": _vm.getValueQuantityBagItem(tripData.newAddItems, bagItem),
          "maxlength": 2,
          "disabled": true,
          "size": "sm"
        }
      }), _vm.fnAncillary.BAGGAGE.increase ? _c('b-button', {
        staticClass: "px-75 py-0 fw-700",
        attrs: {
          "variant": "flat-info",
          "disabled": !_vm.fnAncillary.BAGGAGE.increase,
          "pill": ""
        },
        on: {
          "click": function click($event) {
            return _vm.increaseQuantity(tripData, bagItem);
          }
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "PlusIcon",
          "size": "14"
        }
      })], 1) : _vm._e()], 1)]) : _c('div', {
        staticClass: "d-flex align-items-center justify-content-end"
      }, [_c('div', {
        staticClass: "mr-50 text-secondary d-none d-lg-inline"
      }, [_vm._v(" Số lượng: ")]), _c('div', {
        staticClass: "d-flex"
      }, [_vm.fnAncillary.BAGGAGE.decrease ? _c('b-button', {
        staticClass: "px-75 py-0",
        attrs: {
          "variant": "flat-secondary",
          "disabled": "",
          "pill": ""
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "MinusIcon",
          "size": "14"
        }
      })], 1) : _vm._e(), _c('b-form-input', {
        staticClass: "mx-50 px-50 text-secondary text-center rounded",
        staticStyle: {
          "width": "40px"
        },
        attrs: {
          "value": '',
          "size": "sm",
          "disabled": true
        }
      }), _vm.fnAncillary.BAGGAGE.increase ? _c('b-button', {
        staticClass: "px-75 py-0",
        attrs: {
          "variant": "flat-secondary",
          "disabled": "",
          "pill": ""
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "PlusIcon",
          "size": "14"
        }
      })], 1) : _vm._e()], 1)])])], 1);
    }) : _vm.isShow ? [_c('b-spinner', {
      staticClass: "m-1",
      attrs: {
        "variant": "info"
      }
    })] : [_c('div', {
      staticClass: "text-danger my-50 font-weight-bolder"
    }, [_vm._v(" Không có gói hành lý khả dụng trên hành trình hiện tại! ")])]], 2)], 1);
  }), _c('ModalConfirmAddBaggage', {
    attrs: {
      "arr-data-to-confirm": _vm.arrDataToConfirm
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }