var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-take-photo-itineraries",
      "size": "lg",
      "centered": true,
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-backdrop": true,
      "body-class": "p-0"
    }
  }, [_c('Stack', {
    staticClass: "p-1 gap-2",
    attrs: {
      "id": "take-photo-itineraries"
    }
  }, [_c('Stack', {
    staticClass: "gap-2",
    attrs: {
      "center": ""
    }
  }, _vm._l(_vm.selectedTrips, function (itinerary, index) {
    return _c('ItineraryCard', {
      key: index,
      attrs: {
        "itinerary": itinerary
      }
    });
  }), 1), !_vm.isHidePrice ? _vm._t("default") : _vm._e()], 2), _c('HStack', {
    staticClass: "px-1 py-50",
    attrs: {
      "between": ""
    }
  }, [_c('BButton', {
    attrs: {
      "variant": "secondary",
      "size": "sm"
    },
    on: {
      "click": _vm.onCloseModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('HStack', {
    staticClass: "gap-3",
    attrs: {
      "center": ""
    }
  }, [_c('BFormCheckbox', {
    model: {
      value: _vm.isHidePrice,
      callback: function callback($$v) {
        _vm.isHidePrice = $$v;
      },
      expression: "isHidePrice"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.hidePrice')) + " ")]), _c('BButton', {
    staticClass: "px-3",
    attrs: {
      "variant": "success",
      "pill": "",
      "size": "sm"
    },
    on: {
      "click": _vm.onClickTakePhoto
    }
  }, [_c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "camera",
      "size": "13"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reservation.saveImage')) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }