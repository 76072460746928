import store from '@/store'

import useToast from '@useToast'

export default function useMessBoardHandle() {
  const { toastError } = useToast()
  const getAllPins = () => store
    .dispatch('app-documents/getAllPins') // Giả sử action này trả về dữ liệu các pins
    .then(response => response.data || [])
    .catch(() => {
      toastError({
        title: 'pinsList.error',
        content: 'document.pins.fetch.error',
      })
    })

  return {
    getAllPins,
  }
}
