<template lang="">
  <BCard
    id="incorrect-invoice-info-card"
    body-class="p-50"
  >
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="usersOutline"
        size="25"
        class="p-1 icon-card"
      />
      <!-- @dblclick.native.prevent="$emit('quick-input')" -->
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('invoice.incorrectInvoices.infoCardTitle') }}
      </span>
    </div>

    <b-row class="px-75">
      <!-- ANCHOR Loại thông báo -->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.notificationType')"
          rules="required"
          vid="notificationType"
        >
          <b-form-group
            label-for="notificationType"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.notificationType') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="notificationType"
              :value="incorrectInvoiceData?.notificationType"
              :name="$t('invoice.incorrectInvoices.columns.notificationType')"
              class="w-100 vue-select-class"
              style="font-size: 1rem"
              :options="INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS"
              label="label"
              :reduce="val => val.value"
              :disabled="!isEditing"
              :clearable="false"
              :placeholder="$t('placeholderSelect')"
              @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, notificationType: val })"
            >
              <template #option="data">
                <BBadge
                  :variant="data.variant"
                  rounded
                  class="px-75 text-wrap text-left"
                >
                  {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                </BBadge>
              </template>

              <template #selected-option="data">
                <BBadge
                  :variant="data.variant"
                  rounded
                  class="px-75"
                >
                  {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                </BBadge>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationType"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Số thông báo -->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.notificationNumber')"
          rules=""
        >
          <b-form-group label-for="notificationNumber">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.notificationNumber') }}
            </template>
            <b-form-input
              id="notificationNumber"
              :value="incorrectInvoiceData?.notificationNumber"
              :placeholder="$t('invoice.incorrectInvoices.ph.notificationNumber')"
              :formatter="onlyUpperCaseFormatter"
              :class="`${incorrectInvoiceData?.notificationNumber ? 'fw-800 text-16px' : ''}`"
              disabled
              :state="errors[0] ? false : null"
            />

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ngày lập thông báo-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.dateOfIssuance')"
          rules="required"
        >
          <b-form-group label-for="dateOfIssuance">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.dateOfIssuance') }}
              <span class="text-danger">(*)</span>
            </template>
            <!-- :state="errors[0] ? false : null" -->
            <flat-pickr
              id="dateOfIssuance"
              :value="incorrectInvoiceData?.dateOfIssuance"
              class="form-control"
              :disabled="!isCreate"
              :placeholder="`${$t('invoice.incorrectInvoices.columns.dateOfIssuance')}`"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: today,
                locale: $i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, dateOfIssuance: val })"
            />

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="dateOfIssuance"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Số thông báo rà soát của CQT-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber')"
          rules=""
        >
          <!-- rules="required" -->
          <b-form-group label-for="taxReviewNoticeNumber">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="taxReviewNoticeNumber"
              :value="incorrectInvoiceData?.taxReviewNoticeNumber"
              :placeholder="$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber')"
              :formatter="onlyUpperCaseFormatter"
              :disabled="true"
              :state="errors[0] ? false : null"
            />
            <!-- @input="(val) => $emit('update:incorrectInvoiceData', { ...invoiceData, taxReviewNoticeNumber: val })" -->
            <!-- :disabled="!isEditing" -->

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ngày thông báo của CQT-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')"
          rules=""
        >
          <!-- rules="required" -->
          <b-form-group label-for="dateOfTaxReviewNotice">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="dateOfTaxReviewNotice"
              :value="incorrectInvoiceData?.dateOfTaxReviewNotice"
              :placeholder="$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')"
              :formatter="onlyUpperCaseFormatter"
              :disabled="true"
              :state="errors[0] ? false : null"
            />
            <!-- @input="(val) => $emit('update:incorrectInvoiceData', { ...invoiceData, dateOfTaxReviewNotice: val })" -->
            <!-- :disabled="!isEditing" -->

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Nơi lập-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.placeOfIssuance')"
          rules="required"
        >
          <b-form-group label-for="placeOfIssuance">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.placeOfIssuance') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <b-form-input
              id="placeOfIssuance"
              :value="incorrectInvoiceData?.placeOfIssuance"
              :placeholder="$t('invoice.incorrectInvoices.columns.placeOfIssuance')"
              :formatter="onlyUpperCaseFormatter"
              :disabled="true"
              :state="errors[0] ? false : null"
            />
            <!-- @input="(val) => $emit('update:incorrectInvoiceData', { ...invoiceData, dateOfIssuance: val })" -->
            <!-- :disabled="!isEditing" -->

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </BCard>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BTooltip,
  BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { computed, ref, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

import { PAYMENT_METHOD_LIST, CUSTOMER_TYPE_OPTIONS, INVOICE_STATUS } from '@/constants/invoice'
import store from '@/store'
import useInvoiceCreateHandle from '@/views/invoices/create/useInvoiceCreateHandle'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import { apiCustomer } from '@/api'
import { getToday } from '@/@core/utils/filter'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

import { INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS } from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BBadge,
    BFormInput,
    BButton,
    vSelect: () => import('vue-select'),
    flatPickr,
    BSpinner,
    ValidationProvider,
    BTooltip,
  },
  props: {
    incorrectInvoiceData: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    // const { toastError, toastSuccess } = useToast()
    // const { getCompanyByTaxCode, loadingGetCompanyByTaxCode } = useInvoiceCreateHandle()
    // const {
    //   loadingTags, getTags, getTemplateId, ADJUSTMENT_TYPE_OPTIONS, DEFAULT_VAT_RATE, TAX_RATE_TYPES,
    // } = useInvoiceHandle()

    // // data agency logged in
    // const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    // const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    // // tìm theo MST
    // async function handleFindTaxCode(taxCode) {
    //   const res = await getCompanyByTaxCode(taxCode)
    //   if (res) {
    //     emit('update:incorrectInvoiceData', {
    //       ...props.invoiceData,
    //       buyerUnitName: res.name,
    //       buyerPhoneNumber: res.phonenumber,
    //       buyerEmail: res.mail,
    //       buyerAddress: res.address,
    //     })
    //   }
    // }

    // // thông tin bên bán
    // const companyOptions = computed(() => [
    //   props.isInvoiceRequest && isRoleF2.value
    //     ? agencyData.value?.parentAgency?.company || null // case F2 tạo F1 làm sau
    //     : agencyData.value?.company || null,
    // ].filter(Boolean))
    // let initCompany = false
    // watch(companyOptions, val => {
    //   if (val.length && !initCompany) {
    //     initCompany = true
    //     if (val[0]) {
    //       emit('update:incorrectInvoiceData', { ...props.invoiceData, company: val[0] })
    //     }
    //   } else if (agencyData.value && !val.length) {
    //     toastError('Công ty của đại lý chưa được cập nhật')
    //   }
    // }, { deep: true, immediate: true })

    // // mẫu hoá đơn (template)
    // const invoiceTemplateOptions = ref([])
    // async function getTemplateInvoice(id) {
    //   const res = await getTemplateId(id)
    //   return res?.data.filter(i => i?.active)
    // }

    // function handleSelectInvoiceTemplate(template) {
    //   if (!template) return
    //   // lấy các data theo template
    //   emit('update:incorrectInvoiceData', {
    //     ...props.invoiceData,
    //     template,
    //     templateNumber: template?.templateNumber,
    //     symbol: template?.symbol,
    //     discountType: template?.discountType,
    //     taxRateType: template?.taxRateType,
    //     valueAddedTax: template?.taxRateType === TAX_RATE_TYPES.SINGLE ? (template?.valueAddedTax ?? DEFAULT_VAT_RATE) : undefined,
    //     invoiceType: template?.invoiceType,
    //   })
    // }

    // watch(
    //   () => props.invoiceData.company,
    //   async (newValue, oldValue) => {
    //     if (newValue?.id !== oldValue?.id) {
    //       // FIXME duplicate call === detail
    //       invoiceTemplateOptions.value = await getTemplateInvoice(props.invoiceData.company.id)

    //       // Nếu có 1 inv template => auto fill
    //       if (invoiceTemplateOptions.value && invoiceTemplateOptions.value?.length === 1) {
    //         handleSelectInvoiceTemplate(invoiceTemplateOptions.value[0])
    //       }
    //     }
    //   },
    //   { deep: true, immediate: true },
    // )

    // // tags
    // const tagOptions = ref([])
    // const tagOptionsDefault = ref([])
    // async function onOpenTagHandle() {
    //   if (tagOptionsDefault.value.length) {
    //     tagOptions.value = cloneDeep(tagOptionsDefault.value)
    //     return
    //   }
    //   const res = await getTags()
    //   tagOptions.value = cloneDeep(res.tags)
    //   tagOptionsDefault.value = cloneDeep(res.tags)
    // }

    // // for save buyer info
    // async function handleSaveBuyerInfo() {
    //   root.$bvModal.show('modal-api-loading')
    //   const splitBuyerName = props.invoiceData.buyerName.split(' ')
    //   const lastName = splitBuyerName[0]
    //   const firstName = splitBuyerName.splice(1).join(' ')
    //   const payload = {
    //     firstName,
    //     lastName,
    //     phoneNumber: '',
    //     gender: '',
    //     emailAddress: props.invoiceData?.buyerEmail ?? '',
    //     company: {
    //       name: props.invoiceData?.buyerUnitName ?? '',
    //       address: props.invoiceData?.buyerAddress ?? '',
    //       taxCode: props.invoiceData?.buyerTaxCode ?? '',
    //       email: props.invoiceData?.buyerEmail ?? '',
    //     },
    //   }
    //   try {
    //     const res = await apiCustomer.addCustomer(payload)
    //     if (res) { toastSuccess('invoice.msg.saveCustomerSuccess') }
    //   } catch (error) {
    //     toastError('invoice.msg.saveCustomerError')
    //   } finally {
    //     root.$bvModal.hide('modal-api-loading')
    //   }
    // }

    // const validSaveBuyerInfo = computed(() => props.invoiceData.buyerName
    //   && props.invoiceData.buyerEmail
    //   && props.invoiceData.buyerUnitName
    //   && props.invoiceData.buyerAddress
    //   && props.invoiceData.buyerTaxCode)

    // //  for select buyer
    // const buyerOptions = ref([])

    // const sectionConfigs = ref({
    //   default: {
    //     onSelected: selected => {
    //       emit('update:incorrectInvoiceData', {
    //         ...props.invoiceData,
    //         buyerName: `${selected.item?.lastName ?? ''} ${selected.item?.firstName ?? ''}`,
    //         buyerUnitName: selected.item?.company?.name ?? '',
    //         buyerTaxCode: selected.item?.company?.taxCode ?? '',
    //         buyerEmail: selected.item?.company?.email ?? '',
    //         buyerAddress: selected.item?.company?.address ?? '',
    //         buyerUnitCode: selected.item?.code ?? null,
    //       })
    //       buyerOptions.value = []
    //     },
    //   },
    // })

    // function getSuggestionValue(suggestion) {
    //   const { lastName, firstName } = suggestion.item
    //   return `${lastName ?? ''} ${firstName ?? ''}`.trim()
    // }

    // const debounceSearchCustomer = debounce(async searchText => {
    //   const res = await apiCustomer.fetchCustomers({ searchText })
    //   if (res) {
    //     buyerOptions.value = [{ data: res.data.items }]
    //   }
    // }, 300)

    // function onInputBuyerNameHandle(value) {
    //   const val = value?.trim()
    //   emit('update:incorrectInvoiceData', { ...props.invoiceData, buyerName: val, buyerUnitCode: null })
    //   debounceSearchCustomer(val)
    // }

    return {
      onlyUpperCaseFormatter,
      Vietnamese,
      INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
      today: getToday('YYYY-MM-DD'),
      // PAYMENT_METHOD_LIST,
      // handleFindTaxCode,
      // loadingGetCompanyByTaxCode,
      // companyOptions,
      // invoiceTemplateOptions,
      // handleSelectInvoiceTemplate,
      // tagOptions,
      // CUSTOMER_TYPE_OPTIONS,
      // INVOICE_STATUS,
      // loadingTags,
      // onOpenTagHandle,
      // ADJUSTMENT_TYPE_OPTIONS,
      // handleSaveBuyerInfo,
      // validSaveBuyerInfo,
      // buyerOptions,
      // sectionConfigs,
      // getSuggestionValue,
      // onInputBuyerNameHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}
</style>
