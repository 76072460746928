var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/eticket-list.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.eticketList')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.hasEticket(_vm.reservationsData) ? _c('div', [_vm.hasOutEticket(_vm.reservationsData) ? _c('strong', {
    staticClass: "mb-75 font-medium-3"
  }, [_c('i', {
    staticClass: "text-warning"
  }, [_vm._v(_vm._s(_vm.$t('reservation.eticketList')) + ":")])]) : _vm._e(), _c('b-table-lite', {
    attrs: {
      "bordered": "",
      "centered": "",
      "responsive": "",
      "small": "",
      "thead-class": "text-body text-center text-nowrap",
      "tbody-class": "text-body align-middle px-25",
      "fields": _vm.tableColumns,
      "items": _vm.getTicketListByPaxLists(_vm.reservationsData)
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(index)",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(passenger)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.passengerName) + " ")])];
      }
    }, {
      key: "cell(eTicket)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-nowrap d-flex"
        }, [['TR', 'JQ'].includes(_vm.reservationsData.source) || _vm.isHideFee || _vm.isIssueLimited || _vm.disabledViewTicketDetailNDC || _vm.isBookingOutSystem ? _c('span', {
          staticClass: "align-text-top"
        }, [_vm._v(" " + _vm._s(item.number) + " ")]) : _c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          on: {
            "click": function click($event) {
              return _vm.openViewEticketModalHandle(item);
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top"
        }, [_vm._v(" " + _vm._s(item.number) + " ")])]), _c('b-button', {
          staticClass: "py-25 px-50 ml-75",
          attrs: {
            "variant": "outline-warning",
            "sm": "",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.handleCopyEticketNumber(item.number);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.copy')) + " ")])], 1)];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [!_vm.isEmpty(item.segments) ? _c('div', {
          staticClass: "text-left"
        }, [['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) && ['EMD'].includes(item.ticketType) && ['SVC'].includes(item.ticketSubType) ? [_c('div', [_vm._v(" " + _vm._s(_vm.getTripFromTicketDepartureVN1A(_vm.reservationsData.itineraries, item.segments)) + " ")])] : _vm._l(item.segments, function (segment, segmentIndex) {
          return _c('span', {
            key: segmentIndex,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(segment.departure && segment.arrival ? "".concat(segment.departure, "-").concat(segment.arrival).concat(segmentIndex !== item.segments.length - 1 ? ', ' : '') : '-') + " ")]);
        })], 2) : _c('div', {
          staticClass: "text-left"
        }, [_vm._v(" - ")])];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.ticketType) + " "), item.optionalServiceType ? _c('small', {
          staticClass: "text-airline font-italic text-truncate"
        }, [_vm._v(" (" + _vm._s(_vm.$t("reservation.".concat(item.optionalServiceType.toLowerCase()))) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref5) {
        var _item$status;
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s((_item$status = item.status) !== null && _item$status !== void 0 ? _item$status : '-') + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [['VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(_vm.reservationsData.source) ? _c('div', {
          staticClass: "text-right"
        }, [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.total >= 0 ? _vm.formatCurrency(item.total) : "-".concat(_vm.formatCurrency(item.total))) + " ")])]) : _c('div', [_vm._v(" - ")])];
      }
    }], null, true)
  }), _c('b-alert', {
    staticClass: "py-50 px-2 mt-1 mb-0",
    attrs: {
      "variant": "warning",
      "show": _vm.reservationsData.paxLists.some(function (pax) {
        return !!pax.eticket.find(function (item) {
          return item.status === 'VOIDED';
        });
      })
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('reservation.voidTicket.caution')))])])], 1) : _vm._e(), _vm.hasOutEticket(_vm.reservationsData) ? _c('div', {
    staticClass: "mt-2"
  }, [_c('strong', {
    staticClass: "mb-75 font-medium-3"
  }, [_c('i', {
    staticClass: "text-warning"
  }, [_vm._v("Danh sách số vé ngoài:")])]), _c('b-table-lite', {
    attrs: {
      "bordered": "",
      "centered": "",
      "responsive": "",
      "tbody-class": "text-dark align-middle px-25",
      "fields": ['index', 'passenger', 'eTicket', 'flight', 'ticketType', 'status'],
      "items": _vm.reservationsData.externalTickets
    },
    scopedSlots: _vm._u([_vm._l(['index', 'passenger', 'eTicket', 'flight', 'ticketType', 'status'], function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(index)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.id ? data.item.id : data.index + 1) + " ")])];
      }
    }, {
      key: "cell(passenger)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s("".concat(item.lastName, " / ").concat(item.firstName)) + " ")])];
      }
    }, {
      key: "cell(eTicket)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          on: {
            "click": function click($event) {
              return _vm.openViewEticketModalHandle(item);
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top"
        }, [_vm._v(" " + _vm._s(item.number) + " ")])])], 1)];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [!_vm.isEmpty(item.segments) ? _c('div', {
          staticClass: "text-left"
        }, _vm._l(item.segments, function (segment, segmentIndex) {
          return _c('span', {
            key: segmentIndex,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(segment.departure && segment.arrival ? "".concat(segment.departure, "-").concat(segment.arrival).concat(segmentIndex !== item.segments.length - 1 ? ', ' : '') : '-') + " ")]);
        }), 0) : _c('div', {
          staticClass: "text-left"
        }, [_vm._v(" - ")])];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.ticketType) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.status) + " ")];
      }
    }], null, true)
  })], 1) : _vm._e(), !_vm.hasEticket(_vm.reservationsData) && !_vm.hasOutEticket(_vm.reservationsData) ? _c('div', {
    staticClass: "font-weight-bolder text-warning ml-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.noTicketNum')) + " ")]) : _vm._e()])], 1), !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "d-flex-center justify-content-end gap-1 mb-50"
  }, [['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) && _vm.hasEticket(_vm.reservationsData) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.isLockedModify,
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowModalRefundTicketVN1A(_vm.reservationsData.paxLists);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CornerDownLeftIcon",
      "width": "20"
    }
  }), _c('span', {
    staticClass: "font-medium-1"
  }, [_vm._v(_vm._s(_vm.$t('reservation.refundTicket')))])], 1) : _vm._e(), ['VN1A_MT', 'VN1A', '1G', 'QH'].includes(_vm.reservationsData.source) && _vm.hasEticket(_vm.reservationsData) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.isLockedModify || ['CANCEL'].includes(_vm.reservationsData.status),
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openVoidTicketModalHandle(_vm.reservationsData, false);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/reload.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.voidTicket.title')) + " ")])], 1) : _vm._e(), _vm.hasOutEticket(_vm.reservationsData) || !_vm.hasOutEticket(_vm.reservationsData) && !_vm.hasEticket(_vm.reservationsData) ? _c('div', {
    staticClass: "d-flex-center justify-content-end gap-1"
  }, [['1S', '1S_CTRL'].includes(_vm.reservationsData.source) && _vm.hasOutEticket(_vm.reservationsData) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.isLockedModify,
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openVoidTicketModalHandle(_vm.reservationsData, true);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/reload.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.voidTicket.titleExternal')) + " ")])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.ticketData && !_vm.isHideFunctionButton ? _c('ModalViewEticket', {
    attrs: {
      "ticket-data": _vm.ticketData,
      "is-booking-out-system": _vm.isBookingOutSystem
    },
    on: {
      "update:ticketData": function updateTicketData($event) {
        _vm.ticketData = $event;
      },
      "update:ticket-data": function updateTicketData($event) {
        _vm.ticketData = $event;
      }
    }
  }) : _vm._e(), _c('ModalVoidTicket', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "ticket-list": _vm.ticketListToVoid,
      "is-external-tickets": _vm.isExternalTickets
    }
  }), _c('ModalDeleteTicket', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "ticket-list": _vm.getTicketListByPaxLists(_vm.reservationsData)
    }
  }), _c('ModalVoidTicketCancelAlert'), _vm.getTicketListByPaxLists(_vm.reservationsData) ? _c('ModalSearchEticket', {
    attrs: {
      "current-arr-ticket-length": _vm.getTicketListByPaxLists(_vm.reservationsData).length
    }
  }) : _vm._e(), !_vm.isEmpty(_vm.paxsData) ? _c('ModalRefundTicket', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "paxs-data": _vm.paxsData
    }
  }) : _vm._e(), !_vm.isEmpty(_vm.paxsData) ? _c('ModalRefundTicketVN1A', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "paxs-data": _vm.paxsData
    }
  }) : _vm._e(), !_vm.isEmpty(_vm.paxsData) ? _c('ModalExChangeTicket', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "paxs-data": _vm.paxsData
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }