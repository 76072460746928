<template>
  <IAmOverlay :loading="showLoading">
    <div
      v-for="(notification, notificationIndex) in messNotifications"
      :key="notificationIndex"
    >
      <b-card
        body-class="px-1 py-50"
        :bg-variant="readContent(notification)"
        :class="`mb-1 cursor-pointer border-${notification?.readStatus ? 'dark' : 'danger'} hover-card shadow-md`"
        @click="readContentMess(notificationIndex)"
      >
        <HStack>
          <IAmIcon
            v-if="!notification.readStatus"
            icon="circle"
            size="14"
            class="px-50"
          />
          <Stack class="gap-2 flex-1">
            <HStack between>
              <div class="pb-0">
                <h3 class="mb-0">
                  <span style="font-size: large; font-weight: 700;">{{ notification.subject }}</span>
                </h3>
              </div>
              <div>
                <span style="color: black; display: flex; gap: 3px;">
                  {{ dateTime(notification.createdAt) }}
                </span>
              </div>
            </HStack>

            <HStack between>
              <div class="text-dark">
                <div
                  v-if="notification.isShow"
                  key="expanded"
                >
                  <p class="mb-0 text-justify">
                    {{ notification.content }}
                  </p>
                </div>
                <div
                  v-else
                  key="truncated"
                >
                  <p class="mb-0 text-justify">
                    {{ notification.content }}
                  </p>
                </div>
              </div>
              <a
                v-if="notification?.data?.path"
                class="btn btn-flat-danger"
                :href="`/apps${notification?.data?.path}`"
                target="_blank"
              >
                {{ $t('messBoard.viewBooking') }}
              </a>
            </HStack>
          </Stack>
        </HStack>
      </b-card>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div
        v-if="!messNotifications.length"
        slot="spinner"
      />
      <div slot="no-more">
        {{ $t('messBoard.allNotiShown') }}
      </div>
    </infinite-loading>
  </IAmOverlay>
</template>
<script>
import {
  ref, onMounted, watch,
} from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'

import store from '@/store'
import { getUserData } from '@/api/auth/utils'

import { dateTime } from '@core/utils/filter'

export default {
  components: {
    BCard,
    InfiniteLoading,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    notiMessageBoard: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showLoading = ref(false)
    const messNotifications = ref([])
    const size = ref(10)
    const readContent = noti => (noti?.readStatus ? 'light' : 'light-danger')
    const readContentMess = async index => {
      const item = messNotifications.value[index]

      if (!item || item?.readStatus) return
      showLoading.value = true
      try {
        item.readStatus = true
        await store.dispatch('app-notification/readNoti', item.id)
      } catch (error) {
        console.error('Lỗi khi cập nhật trạng thái:', error)
      } finally {
        showLoading.value = false
      }
    }
    function infiniteHandler($state) {
      if (messNotifications.value.length === 0) {
        showLoading.value = true
      }

      store
        .dispatch('app-notification/getNoti', {
          lastId: messNotifications.value.length
            ? messNotifications.value[messNotifications.value.length - 1].id
            : null,
          recipientRefType: 'EMPLOYEE',
          channel: 'PUSH_NOTI',
          recipientRefId: getUserData().employeeData.id,
          size: size.value,
        })
        .then(response => {
          if (response.data.length > 0) {
            response.data.forEach(item => {
              messNotifications.value.push(item)
            })
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(error => {
          console.error('Lỗi khi tải thông báo:', error)
          $state.complete()
        })
        .finally(() => {
          showLoading.value = false
        })
    }
    const fetchNotifications = async () => {
      try {
        const res = await store
          .dispatch('app-notification/getNoti', {
            recipientRefType: 'EMPLOYEE',
            channel: 'PUSH_NOTI',
            recipientRefId: getUserData().employeeData.id,
          })
        messNotifications.value = res.data
        emit('fetch-noti', messNotifications.value)
      } catch (error) {
        console.error('Lỗi khi lấy thông báo:', error)
      }
    }

    const readAllNoti = async () => {
      try {
        const res = await store.dispatch('app-notification/readAllNoti', {
          recipientRefType: 'EMPLOYEE',
          recipientRefId: getUserData().employeeData.id.toString(),
        })
        if (res === 0) {
          messNotifications.value = messNotifications.value.map(noti => ({
            ...noti,
            readStatus: true,
          }))
        } else if (res && Array.isArray(res.data)) {
          messNotifications.value = res.data.map(item => ({
            ...item,
            readStatus: true,
          }))
        }
      } catch (error) {
        console.error('Lỗi khi lấy thông báo:', error)
      }
    }
    onMounted(() => {
      fetchNotifications()
    })

    watch(
      () => props.notiMessageBoard,
      newItems => {
        if (!newItems.length) return
        try {
          showLoading.value = true
          messNotifications.value = props.notiMessageBoard.map(e => ({ ...e, isShow: false }))
        } finally {
          showLoading.value = false
        }
      },
      { deep: true, immediate: true },
    )

    const toggleContent = index => {
      try {
        showLoading.value = true
        messNotifications.value[index] = !messNotifications.value[index]
      } finally {
        showLoading.value = false
      }
    }
    return {
      infiniteHandler, showLoading, readContent, messNotifications, dateTime, toggleContent, readContentMess, readAllNoti,
    }
  },
}
</script>

<style lang="scss" scoped>

.hover-card {
  &[class*="border-"]:not(:hover) {
    border-color: transparent !important;
  }
}
</style>
