<template>
  <!-- SECTION modal-modify-third-confirm-add-other -->
  <b-modal
    id="modal-modify-third-confirm-add-other"
    centered
    no-close-on-backdrop
    no-enforce-focus
    header-class="py-50"
    size="lg"
    @show="handleShowModal"
    @hide="handleHideModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div
          class="text-airline fw_700"
          :class="isMobileView ? 'font-medium-1' : 'font-medium-3'"
        >
          Thanh toán
        </div>
        <b-button
          variant="flat-secondary"
          class="p-50"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
        </b-button>
      </div>
    </template>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="px-1 px-md-2"
        pill
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="(['QH', 'TH', 'AK'].includes(bookingSource) || isPayNow) ? 'success' : 'warning'"
        class="fw-700"
        pill
        :disabled="!isConfirm"
        @click="handleConfirm"
      >
        {{ (['QH', 'TH', 'AK'].includes(bookingSource) || isPayNow) ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU' }}
      </b-button>
    </template>

    <div class="fw-700 mb-25">
      Xác nhận thông tin:
    </div>

    <b-card
      v-for="(segmentData, indexSegmentData) of dataShow"
      :key="indexSegmentData"
      header-bg-variant="light-warning"
      header-class="p-50"
      body-class="py-25 px-1"
      class="border-warning mb-50"
    >
      <template #header>
        <div class="text-airline fw_700">
          <span class="font-small-4">Hành trình: </span>
          <!-- <span>{{ ['VJ'].includes(bookingSource) ? segmentData.segment.TRIP_FLIGHT : `${segmentData.segment.departure.iataCode} - ${segmentData.segment.arrival.iataCode}` }} ({{ convertISODateTime(segmentData.segment.departure.at, segmentData.segment.departure.timeZone).date }})</span> -->
          {{ resolveHeaderTrip(segmentData) }}
        </div>
      </template>

      <CardNamePax
        :arr-pax="segmentData.arrPax"
        :name-type="'dịch vụ'"
      />

      <div class="mb-50">
        <span class="fw-700">Gói đã chọn:</span>
        <div
          v-for="(otherItem, itemOtherItem) of segmentData.newAddItems"
          :key="itemOtherItem"
          class="mb-25"
        >
          <div>{{ `- ${resolveSsrNameByCode(['TR', 'JQ'].includes(bookingSource) ? `${bookingSource}_${otherItem.code}` : otherItem.code)} x${otherItem.quantity}` }}</div>
        </div>
      </div>
    </b-card>

    <b-card
      header-class="p-1"
      no-body
    >
      <template #header>
        <b-row class="w-100 d-flex-between mx-1 mx-lg-3">
          <b-col
            cols="6"
            class="text-right font-medium-3 fw_700"
          >
            Tổng tiền:
          </b-col>
          <b-col
            cols="6"
            class="font-medium-3 text-warning fw_700 text-right"
          >
            {{ formatCurrency(totalPrice) }}
          </b-col>
        </b-row>
      </template>
    </b-card>

    <div
      v-if="getBookingData && ['VJ'].includes(bookingSource)"
      class="fw-700 mb-25"
    >
      Lựa chọn thanh toán:
    </div>

    <CardAncillaryPayOptions
      v-if="getBookingData && ['VJ'].includes(bookingSource)"
      :reservations-data="getBookingData"
      :is-pay-now.sync="isPayNow"
      :has-paid-trip="hasPaidTrip"
    />

    <IAmConfirm :is-confirm.sync="isConfirm" />
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BCard, BRow, BCol,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import {
  cloneDeep, isEmpty, flatten, sum,
} from 'lodash-es'
import Vue from 'vue'

import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'
import { resolveSsrNameByCode } from '@/constants/selectOptions'
import store from '@/store'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
    CardNamePax: () => import('../components/CardNamePax.vue'),
    CardAncillaryPayOptions: () => import(
      '@reservation/reservation-modify/components/detail/components/CardAncillaryPayOptions.vue'
    ),
  },
  props: {
    arrDataToConfirm: {
      type: [Array, null],
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { modifyAncillary, getBookingData } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
    } = useAncillaryServicesHandle()

    const isPayNow = ref(false)
    const hasPaidTrip = ref(false)
    const dataShow = ref(null)
    const isConfirm = ref(false)
    const totalPrice = ref(0)

    function checkTotalPrice(data) {
      const listPrice = data.map(segment => segment.newAddItems.map(i => (i.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType)).total * i.quantity) * segment.arrPax.length))
      totalPrice.value = sum(flatten(listPrice))
    }

    // FIXME thanh toán ngay
    function checkHasPaidTrip(data) {
      data.forEach(sgData => {
        if (isEmpty(sgData.arrPax[0].eticket)) {
          hasPaidTrip.value = false
          return
        }

        const listEticketOK = sgData.arrPax[0].eticket.filter(e => ['TKT'].includes(e.ticketType))

        const itemSegments = data.map(segData => ({
          departureDate: convertISODateTime(['VJ', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) ? segData.departure.at : segData.segment.departure.at, ['VJ', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) ? segData.departure.timeZone : segData.segment.departure.timeZone).date,
          departure: ['VJ', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) ? segData.departure.iataCode : segData.segment.departure.iataCode,
          arrival: ['VJ', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) ? segData.arrival.iataCode : segData.segment.arrival.iataCode,
        })).flat(2)

        const tktSegments = listEticketOK.map(tk => tk.segments.map(s => ({
          departureDate: convertISODateTime(s.departureDate).date,
          departure: s.departure,
          arrival: s.arrival,
        }))).flat()

        if (!isEmpty(listEticketOK)) {
          hasPaidTrip.value = itemSegments.every(bag => tktSegments.some(tkt => tkt.departureDate === bag.departureDate && tkt.departure === bag.departure && tkt.arrival === bag.arrival))
        } else {
          hasPaidTrip.value = false
        }
      })
    }

    watch(dataShow, val => {
      if (!val) return
      const cloneValue = cloneDeep(val)
      checkHasPaidTrip(cloneValue)
      checkTotalPrice(cloneValue)

      if (['VJ'].includes(bookingSource.value) && hasPaidTrip.value) {
        isPayNow.value = true
      }
    }, { deep: true })

    function handleShowModal() {
      dataShow.value = cloneDeep(props.arrDataToConfirm)
      isConfirm.value = false

      if (['AK', 'QH'].includes(bookingSource.value)
      || (['VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) && !['HOLD'].includes(getBookingData.value.status))
      ) {
        isPayNow.value = true
      } else {
        isPayNow.value = false
      }
    }

    function handleHideModal() {
      //
    }

    function getItineraries(data) {
      if (['VJ', 'VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value)) {
        return data.segment.map(segment => ({
          airline: segment.airline,
          departure: segment.departure.iataCode,
          arrival: segment.arrival.iataCode,
          departureDate: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
          arrivalDate: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
          flightNumber: segment.flightNumber,
          segmentId: segment.segmentId,
          fareBasisCode: segment?.fareBasisCode || '',
          bookingClass: segment.bookingClass || '',
          groupClass: segment.groupClass || '',
          bookingStatus: segment.bookingStatus,
          itineraryId: ['VN1A', 'VN1A_MT'].includes(bookingSource.value) ? segment.devItineraryIndex : segment.itineraryId,
        }))
      }

      return [{
        airline: data.segment.airline,
        departure: data.segment.departure.iataCode,
        arrival: data.segment.arrival.iataCode,
        departureDate: convertISODateTime(data.segment.departure.at, data.segment.departure.timeZone).ISOdatetime,
        arrivalDate: convertISODateTime(data.segment.arrival.at, data.segment.arrival.timeZone).ISOdatetime,
        flightNumber: data.segment.flightNumber,
        segmentId: data.segment.segmentId,
        fareBasisCode: data.segment?.fareBasisCode || '',
        bookingClass: data.segment.bookingClass || '',
        groupClass: data.segment.groupClass || '',
        bookingStatus: data.segment.bookingStatus,
        ...(['VJ', 'VZ', 'VU'].includes(data.segment.airline) && { itineraryId: data.segment.itineraryId }),
      }]
    }

    function getPayload(segmentData) {
      return segmentData.map(sData => sData.arrPax.map(pax => sData.newAddItems.map(bag => ({
        ancillaryCode: getBookingData.value.source === 'F1' ? bag.key : bag.code,
        ancillaryValue: getBookingData.value.source === 'F1' ? bag.code : bag.ssrValue || bag.code,
        ancillaryType: ['VN1A', 'VN1A_MT'].includes(bookingSource.value) ? 'OTHER' : undefined,
        paxInfo: {
          type: pax.paxType,
          paxId: pax.paxId,
          firstName: pax.firstName,
          lastName: pax.lastName,
          nameId: pax?.nameId || undefined,
        },
        itineraries: getItineraries(sData),
        quantity: 1,
        total: ['QH', 'VU', 'VJ', 'VZ'].includes(sData.segment.airline) ? bag.fares?.find(fare => fare.paxType === pax.paxType || ['ALL', ''].includes(fare.paxType))?.total : 1,
        operation: 'ADD',
        id: ['QH', 'VU'].includes(bookingSource.value) ? sData.segment.segmentId : '999',
      })))).flat(2)
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    async function handleConfirm() {
      const data = await getPayload(dataShow.value)

      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPayNow.value) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      const paymentData = {
        isPayNow: isPayNow.value,
        paymentPassword: paymentPassword?.value || '',
      }

      this.$bvModal.show('modal-api-loading')
      modifyAncillary(getBookingData.value, data, paymentData)
        .then((() => {
          const modalShow = Array.from(window.document.querySelectorAll('.modal.show')).map(el => el.id)
          modalShow.forEach(id => {
            root.$root.$emit('bv::hide::modal', id)
          })
        }))
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    function resolveHeaderTrip(segmentData) {
      return `${segmentData.flights} (${['VJ', 'VN1A', 'VN1A_MT'].includes(segmentData.source)
        ? convertISODateTime(segmentData.departure.at, segmentData.departure.timeZone).date
        : ['TR', 'JQ'].includes(bookingSource.value)
          ? segmentData.DEPARTURE_TIME
          : convertISODateTime(segmentData.segment.departure.at, segmentData.segment.departure.timeZone).date})`
    }

    return {
      fnAncillary,
      bookingSource,
      getBookingData,
      hasPaidTrip,
      isPayNow,
      dataShow,
      totalPrice,
      isConfirm,

      handleConfirm,
      handleShowModal,
      handleHideModal,
      formatCurrency,
      resolveSsrNameByCode,
      convertISODateTime,

      resolveHeaderTrip,
    }
  },
}
</script>
