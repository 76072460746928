<template>
  <AppCollapse type="border">
    <AppCollapseItem
      title
      :is-visible="false"
    >
      <template #header>
        <h4 class="card-title font-weight-bolder px-1 py-50">
          {{ $t('filters') }}
        </h4>
      </template>

      <b-card-body class="py-0">
        <b-row>
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('topup.startDate')"
            >
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: endDate ? endDate : today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('topup.endDate')"
            >
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: startDate,
                  maxDate: today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- ANCHOR AGENCY CODE -->
            <b-form-group
              label-class="h5 py-0"
              :label="$t('agency.agencyCode')"
            >
              <vue-autosuggest
                id="agency-code"
                v-model="agencyCode"
                :suggestions="agencySuggestions"
                :input-props="{
                  placeholder: `${$t('saleReport.phAgencyCode')}`,
                  class: 'form-control'
                }"
                :get-suggestion-value="getSuggestionValue"
                @input="inputHandle"
              >
                <template slot-scope="{ suggestion }">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="text-info">{{ suggestion.item.agencyCode }}</span> <small>({{ suggestion.item.agencyName }})</small>
                  </span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- ANCHOR BOOKING CODE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.bookingCode')"
            >
              <b-form-input
                v-model.trim="bookingCode"
                :placeholder="$t('saleReport.phBookingCode')"
                :formatter="trimUpperCaseInput"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <!-- ANCHOR FLIGHT TYPE -->
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.flightType')"
            >
              <v-select
                v-model="flightType"
                class="w-100"
                style="font-size: 1rem;"
                :options="flightTypeOptions"
                label="label"
                :clearable="false"
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                :disabled="Boolean(fixFlightType)"
              >
                <!-- @input="val => $emit('update:flightTypeFilter', val)" -->
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR NGÀY BAY -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.flightDate')"
            >
              <flat-pickr
                v-model="flightDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'd/m/Y',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR Source -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.source')"
            >
              <v-select
                v-model="source"
                class="w-100"
                style="font-size: 1rem;"
                :options="distributorsListWithTrain"
                label="label"
                multiple
                clearable
                :placeholder="$t('placeholderSelect')"
                :disabled="Boolean(fixFlightType)"
              >
                <template #option="data">
                  <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span>
                </template>

                <template #selected-option="data">
                  {{ $t(`flight.sourceName.${data.label}`) }}
                  <!-- <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span> -->
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR SaleReportTicketType -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.ticketType')"
            >
              <v-select
                v-model="saleReportTicketType"
                class="w-100"
                style="font-size: 1rem;"
                :options="saleReportTicketTypeOptions"
                label="label"
                clearable
                multiple
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Airline -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.airline')"
            >
              <b-form-input
                v-model.trim="airline"
                :placeholder="$t('saleReport.phAirline')"
                :formatter="trimUpperCaseInput"
                maxlength="2"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR DOB -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.shortTitle.dob')"
            >
              <HStack>
                <flat-pickr
                  v-model="dobFilter"
                  class="form-control flex-1"
                  :placeholder="$t('topup.placeholderSelectDate')"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    maxDate: endDate ? endDate : today,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                  }"
                />
                <BButton
                  v-b-tooltip.hover.top.window="$t('saleReport.clearDobFilter')"
                  variant="flat-danger"
                  class="p-75 rounded-circle"
                  size="sm"
                  @click="dobFilter = null"
                >
                  <IAmIcon icon="closeOutline" />
                </BButton>
              </HStack>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Search -->
          <b-button
            variant="warning"
            class="mr-1"
            @click="onUpdateFilters"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="onClearFilters"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-body>
      <!-- </b-card> -->
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { VueAutosuggest } from 'vue-autosuggest'
import debounce from 'lodash/debounce'

import { flightTypeOptions } from '@/constants/saleReport'
import { distributorsListWithTrain, saleReportTicketTypeOptions } from '@/constants/selectOptions'
import { apiAgencies } from '@/api'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'
import { convertISODateTime } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    VueAutosuggest,
  },
  props: {
    searchText: {
      type: String,
      default: '',
    },
    fixFlightType: {
      type: String,
      default: '',
    },
    initFilterIsInvoiced: {
      type: [Boolean, String],
      default: '',
    },
  },

  watch: {
    fixFlightType: {
      handler(val) {
        this.flightType = val
      },
      immediate: true,
    },
  },

  setup(props, { emit }) {
    const startDate = ref(convertISODateTime(new Date()).dateFilter)
    const endDate = ref(convertISODateTime(new Date()).dateFilter)
    const agencyCode = ref('')
    const flightType = ref('')
    const bookingCode = ref('')
    const flightDate = ref('')
    const source = ref([])
    const airline = ref('')
    const saleReportTicketType = ref([])
    const dobFilter = ref(null)

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDate.value ? endDate.value : today))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))

    const onClearFilters = () => {
      startDate.value = convertISODateTime(new Date()).dateFilter
      endDate.value = convertISODateTime(new Date()).dateFilter
      agencyCode.value = ''
      flightType.value = ''
      bookingCode.value = ''
      source.value = []
      airline.value = ''
      flightDate.value = ''
      saleReportTicketType.value = []
      dobFilter.value = null
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        agencyCode: agencyCode.value.trim() || undefined,
        flightType: flightType.value || undefined,
        bookingCode: bookingCode.value || undefined,
        flightDate: flightDate.value || undefined,
        sources: source.value.length ? source.value.join(',') : undefined,
        airline: airline.value || undefined,
        saleReportTicketTypes: saleReportTicketType.value.length ? saleReportTicketType.value.join(',') : undefined,
        dob: dobFilter.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    const agencySuggestions = ref([
      {
        data: [],
      },
    ])
    const inputHandle = debounce(input => {
      if (input === '' || input === undefined) {
        return
      }
      apiAgencies
        .fetchAgencies({ searchText: input })
        .then(response => {
          agencySuggestions.value = [
            {
              data: response.data.items,
            },
          ]
        })
    }, 200)

    function getSuggestionValue(suggestion) {
      agencyCode.value = suggestion.item.agencyCode
      return suggestion.item.agencyCode
    }

    return {
      flightTypeOptions,
      startDate,
      endDate,
      agencyCode,
      flightType,
      saleReportTicketType,
      bookingCode,
      flightDate,
      source,
      airline,
      dobFilter,
      Vietnamese,

      today,
      maxStartDate,
      minEndDate,
      distributorsListWithTrain,
      saleReportTicketTypeOptions,
      onClearFilters,
      onUpdateFilters,
      trimUpperCaseInput,

      inputHandle,
      agencySuggestions,
      getSuggestionValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
</style>
