<template>
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      is-visible
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="phone"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('tour.bookingDetail.contactInfo') }}
            </h6>
          </div>
        </div>
      </template>
      <div>
        <BRow v-if="isRoleF1">
          <BCol
            cols="12"
            md="6"
            class="my-25"
          >
            {{ $t('tour.field.agency') }}:
            <span class="font-weight-bolder text-uppercase">
              {{ bookingData.agencyCode }}
            </span>
          </BCol>
        </BRow>
        <BRow class="">
          <BCol
            cols="12"
            md="6"
            class="my-25"
          >
            {{ $t('tour.field.contactName') }}:
            <span class="font-weight-bolder">
              {{ bookingData.contactName }}
            </span>
          </BCol>
          <BCol
            cols="12"
            md="6"
            class="my-25"
          >
            {{ $t('tour.field.contactPhone') }}:
            <span class="font-weight-bolder">{{ bookingData.contactPhone }}</span>
          </BCol>
          <BCol
            cols="12"
            md="6"
            class="my-25"
          >
            {{ $t('tour.field.email') }}:
            <span class="font-weight-bolder">{{ bookingData.email }}</span>
          </BCol>
          <BCol
            cols="12"
            md="6"
            class="my-25"
          >
            {{ $t('tour.field.address') }}:
            <span class="font-weight-bolder">{{ bookingData.address }}</span>
          </BCol>
        </BRow>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { computed } from '@vue/composition-api'
import { BCol, BRow } from 'bootstrap-vue'

import store from '@/store'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BRow,
    BCol,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    return {
      isRoleF1,
    }
  },
}
</script>
<style lang="">

</style>
