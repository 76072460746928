<template lang="">
  <div>
    <BCard
      id="invoice-list"
      body-class="p-1"
    >
      <BAlert
        v-if="isLoadingCompanyId && !companyId"
        show
        variant="danger"
        class="py-1 px-2"
      >
        {{ $t('invoice.msg.companyNotFoundOrDeactivated') }}
      </BAlert>
      <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
        <IAmIcon
          icon="receipt"
          size="25"
          class="p-1 icon-card"
        />
        <span class="font-medium-3 m-0 fw-700">
          {{ $t('Incorrect Invoices List') }}
        </span>
      </div>

      <!-- ANCHOR Filters -->
      <IncorrectInvoiceFilter
        :sort-by.sync="sortBy"
        :is-sort-dir-desc.sync="isSortDirDesc"
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :notificationType.sync="notificationType"
        :notificationStatus.sync="notificationStatus"
        :statusOfTaxAuthority.sync="statusOfTaxAuthority"
        @refetch-data="refetchData"
        @clear-filters="
          clearFilter()
          refetchData()
        "
      />

      <!-- ANCHOR TOP BUTTON -->
      <HStack
        end
        class="w-100 gap-2 mb-50"
      >
        <BButton
          variant="info"
          size="sm"
          class="p-50"
          @click="onExportHandle"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <IAmIcon
              icon="download"
              size="16"
              color="white"
            />
            {{ $t('invoice.btn.export') }}
          </span>
        </BButton>

        <BButton
          variant="info"
          size="sm"
          class="p-50"
          :to="{ name: 'apps-incorrectInvoices-create' }"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <IAmIcon
              icon="increase"
              size="16"
              color="white"
            />
            {{ $t('Incorrect Invoice Create') }}
          </span>
        </BButton>

        <BButton
          variant="warning"
          size="sm"
          class="p-50"
          :disabled="!companyId"
          @click="onReleaseHandle"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <IAmIcon
              icon="splitPassenger"
              size="16"
              color="white"
            />
            {{ $t('invoice.btn.releaseInvoice') }}
          </span>
        </BButton>

        <!-- <BButton
          v-if="!onlyTotalAmountAdditional"
          variant="flat-primary"
          size="sm"
          class="p-50 shadow-lg"
          @click="onSettingHandle"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <IAmIcon
              icon="setting"
              size="16"
            />
          </span>
        </BButton> -->
      </HStack>

      <!-- ANCHOR Table List -->
      <b-table
        ref="refIncorrectInvoiceListTable"
        style="max-height: 70vh"
        :sticky-header="true"
        class="position-relative"
        :items="fetchIncorrectInvoices"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        bordered
        :busy="loadingList"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        :fields="tableColumnIncorrectInvoiceList"
        head-row-variant="primary"
        @row-contextmenu="handleContextMenu"
      >
        <template
          v-for="column in tableColumnIncorrectInvoiceList"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.key"
            class="text-nowrap"
          >
            <div v-if="column.key === 'checkbox'">
              <b-form-checkbox
                name="id-all-check-box"
                :checked="isAll"
                @change="selectAll"
              />
            </div>
            <div v-else>{{ $t(`invoice.incorrectInvoices.columns.${data.column}`) }}</div>
          </span>
        </template>

        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template #cell(checkbox)="row">
          <b-form-checkbox
            name="item-check-box"
            :checked="isChecked(row.item.id)"
            @change="chooseItem(row.item)"
          />
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(notificationNumber)="{ item }">
          <div class="d-flex-center">
            <b-link
              v-if="item.notificationNumber"
              class="nav-link text-primary hover fw-700"
              :to="{ name: 'apps-incorrectInvoices-detail', params: { id: item.id } }"
            >
              <span> {{ item.notificationNumber }}</span>
            </b-link>
            <span
              v-else
              class="text-muted font-italic"
            >
              {{ `<${$t('empty')}>` }}
            </span>
          </div>
        </template>

        <template #cell(notificationType)="{ item }">
          {{ $te(`invoice.incorrectInvoices.notificationType.${item.notificationType}`) ? $t(`invoice.incorrectInvoices.notificationType.${item.notificationType}`) : item.notificationType }}
        </template>

        <template #cell(createdAt)="{ item }">
          <div class="">
            {{ convertISODateTime(item.createdAt).date }}
          </div>
        </template>

        <template #cell(status)="{ item }">
          <BBadge
            :variant="resolveNotificationStatusVariant(item?.status)"
            pill
            class="px-75 fit-content"
          >
            {{ $t(`invoice.incorrectInvoices.notificationStatus.${item?.status}`) }}
          </BBadge>
        </template>

        <template #cell(statusOfTaxAuthority)="{ item }">
          <BBadge
            :variant="resolveNotificationStatusOfTaxAuthorityVariant(item?.statusOfTaxAuthority)"
            pill
            class="px-75 fit-content"
          >
            {{ $t(`invoice.incorrectInvoices.statusOfTaxAuthority.${item?.statusOfTaxAuthority}`) }}
          </BBadge>
        </template>

        <template #cell(taxReviewNoticeNumber)="{ item }">
          <span
            v-if="item.taxReviewNoticeNumber"
            class="fw-700"
          >
            {{ item.taxReviewNoticeNumber }}
          </span>
          <span
            v-else
            class="text-muted font-italic"
          >
            {{ `<${$t('empty')}>` }}
          </span>
        </template>

        <template #cell(dateOfTaxReviewNotice)="{ item }">
          <span
            v-if="item.dateOfTaxReviewNotice"
            class="fw-700"
          >
            {{ item.dateOfTaxReviewNotice }}
          </span>
          <span
            v-else
            class="text-muted font-italic"
          >
            {{ `<${$t('empty')}>` }}
          </span>
        </template>

        <template #cell(action)="{ item, index }">
          <div class="d-flex-center justify-content-start gap-1">
            <BButton
              v-b-tooltip.window.hover="$t('invoice.btn.detail')"
              variant="flat-dark"
              class="p-50 rounded-circle"
            >
              <!-- :to="{ name: 'apps-invoiceDetail', params: { id: item.id } }" -->
              <IAmIcon
                icon="editOutline"
                size="18"
                color="black"
              />
            </BButton>
            <!-- <BButton
              v-if="
                BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
                  item.releaseStatus
                ]?.VIEW_PDF
              "
              v-b-tooltip.window.hover="$t('invoice.btn.preview')"
              variant="flat-dark"
              class="p-50 rounded-circle"
              @click="onViewInvoiceHandle(item.id)"
            >
              <IAmIcon
                icon="showOutline"
                size="18"
                color="black"
              />
            </BButton> -->
            <BButton
              variant="flat-dark"
              class="p-50 rounded-circle"
            >
              <!-- @click="e => handleContextMenu(item, index, e)" -->
              <IAmIcon
                icon="dotsHorizontal"
                size="18"
                color="black"
              />
            </BButton>
          </div>
        </template>
      </b-table>

      <!-- ANCHOR: Table Footer -->
      <b-row>
        <b-col
          cols="6"
          md="4"
          class="d-flex align-items-center"
          order="1"
        >
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            append-to-body
            :calculate-position="withPopper"
          >
            <template #option="data">
              <span>
                {{ data.label }} / {{ $t('page') }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }} / {{ $t('page') }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="d-flex-center"
          order="3"
          order-md="2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalIncorrectInvoices"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>

        <b-col
          cols="6"
          md="4"
          order="2"
          order-md="3"
          class="d-flex-center justify-content-end"
        >
          <span class="text-muted">
            {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
            {{ dataMeta.of }} {{ $t('paginationText.items') }}
          </span>
        </b-col>
      </b-row>
    </BCard>

    <vue-context
      ref="MenuRefForInvoiceList"
      lazy
    >
      <li>
        <a
          v-for="(menu, menuIndex) in menuList"
          :key="menuIndex"
          class="d-flex gap-1"
          @click.prevent="menu.onClick(itemContext)"
        >
          <IAmIcon
            v-if="menu.icon"
            :icon="menu.icon"
            size="18"
          />
          {{ menu.name }}
        </a>
      </li>
    </vue-context>

    <InvoiceCancelModal
      :invoice-data="invoiceSelected"
      @refetch="refetchData"
    />

    <InvoiceSignModal
      :invoice-data="invoiceSelected"
      :companyId="companyId ?? null"
      :isBatch.sync="isReleaseBatch"
      :checkedItems="checkedItems"
      @refetch="refetchData"
    />

    <ModalCancelBatchInvoices :checkedItems="checkedItems" />
  </div>
</template>
<script>
import {
  BCard, BTable, BSpinner, BButton, BFormCheckbox, BBadge, BLink, BPagination, BCol, BRow, BAlert,
} from 'bootstrap-vue'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import uniqBy from 'lodash/uniqBy'

import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'
import { useRouter } from '@/@core/utils/utils'
import { sizePerPageOptions } from '@/constants/selectOptions'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'
import useIncorrectInvoiceHandle, { resolveNotificationTypeVariant, resolveNotificationStatusOfTaxAuthorityVariant, resolveNotificationStatusVariant } from '@/views/invoices/incorrect-invoices/useIncorrectInvoiceHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BButton,
    BFormCheckbox,
    BBadge,
    BLink,
    BPagination,
    BCol,
    BRow,
    BAlert,
    IncorrectInvoiceFilter: () => import('./IncorrectInvoiceFilter.vue'),
    VueContext: () => import('vue-context'),
    vSelect: () => import('vue-select'),
    InvoiceCancelModal: () => import('@/views/invoices/components/InvoiceCancelModal.vue'),
    InvoiceSignModal: () => import('@/views/invoices/components/InvoiceSignModal.vue'),
    ModalCancelBatchInvoices: () => import('@/views/invoices/components/ModalCancelBatchInvoices.vue'),
  },
  setup(_, { root }) {
    const { router } = useRouter()
    const { toastError, toastWarning } = useToast()
    const {
      INVOICE_STORE,
      refIncorrectInvoiceListTable,
      fetchIncorrectInvoices,
      loading: loadingList,
      tableColumnIncorrectInvoiceList,
      refetchData,
      sortBy,
      isSortDirDesc,
      startDate,
      endDate,
      notificationType,
      notificationStatus,
      statusOfTaxAuthority,
      clearFilter,
      totalIncorrectInvoices,
      dataMeta,
      sizePerPage,
      currentPage,
      // exportInvoices,
    } = useIncorrectInvoiceHandle()

    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
    })

    const isReleaseBatch = ref(false)
    const MenuRefForInvoiceList = ref()
    const itemContext = ref()
    const invoiceSelected = ref()
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const isLoadingCompanyId = computed(() => agencyData.value ?? false) // loading for company
    const companyId = computed(() => agencyData.value?.company?.id ?? null)

    function handleContextMenu(item, index, event) {
      event.preventDefault()
      itemContext.value = item
      MenuRefForInvoiceList.value.open(event)
    }

    function onViewInvoiceHandle(id) {
      if (!id) return
      const url = router.resolve({ name: 'apps-invoicePreview', params: { id } })
      window.open(url.href, '_blank')
    }

    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    // const checkInvoiceRequestAndViewF2 = invoice => invoice?.createdType === CREATE_TYPE.REQUEST && isRoleF2.value
    // for context menu
    // const menuFull = [
    //   {
    //     name: t('invoice.btn.edit'),
    //     onClick: item => {
    //       router.push({ name: 'apps-invoiceDetail', params: { id: item.id }, hash: '#edit' })
    //     },
    //     show: item => {
    //       if (!companyId.value) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.EDIT
    //     },
    //     icon: 'editOutline',
    //   },
    //   {
    //     name: t('invoice.btn.preview'),
    //     onClick: item => {
    //       onViewInvoiceHandle(item.id)
    //     },
    //     show: item => BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[item.releaseStatus]
    //       ?.VIEW_PDF,
    //     icon: 'file',
    //   },
    //   {
    //     name: t('invoice.btn.signAndReleaseInvoice'),
    //     onClick: item => {
    //       if (generateInvoiceNumberOnCreation.value !== !!item.invNumber) {
    //         toastError('invoice.msg.differenceGenerateInvoiceNumberOnCreationError')
    //         return
    //       }
    //       isReleaseBatch.value = false
    //       invoiceSelected.value = cloneDeep(item)
    //       root.$bvModal.show('modal-sign-invoice')
    //     },
    //     show: item => {
    //       if (!companyId.value || checkInvoiceRequestAndViewF2(item)) return false
    //       return (
    //         BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //           item.releaseStatus
    //         ]?.SIGN || item.signStatus === 'UNSIGNED'
    //       )
    //     },
    //     icon: 'pencil',
    //   },
    //   {
    //     name: t('invoice.btn.duplicateInvoice'),
    //     onClick: item => {
    //       if (!item?.id) return
    //       router.push({ name: 'apps-invoiceDuplicate', params: { id: item.id } })
    //     },
    //     show: item => {
    //       if (!companyId.value || checkInvoiceRequestAndViewF2(item)) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.DUPLICATE
    //     },
    //     icon: 'duplicate',
    //   },
    //   {
    //     name: t('invoice.btn.duplicateInvoiceRequest'),
    //     onClick: item => {
    //       if (!item?.id) return
    //       router.push({ name: 'apps-invoiceDuplicate', params: { id: item.id } })
    //     },
    //     show: item => {
    //       if (!checkInvoiceRequestAndViewF2(item)) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.DUPLICATE
    //     },
    //     icon: 'duplicate',
    //   },
    //   {
    //     name: t('invoice.btn.sendEmail'),
    //     onClick: async item => {
    //       root.$bvModal.show('modal-api-loading')
    //       const invoiceId = item?.id
    //       if (!invoiceId) return
    //       await sendMailInvoice(invoiceId)
    //       refetchData()
    //       root.$bvModal.hide('modal-api-loading')
    //     },
    //     show: item => {
    //       if (!companyId.value) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.SEND_EMAIL
    //     },
    //     icon: 'envelopeDownload',
    //   },
    //   {
    //     name: t('invoice.btn.cancelInv'),
    //     onClick: item => {
    //       invoiceSelected.value = cloneDeep(item)
    //       root.$bvModal.show('modal-cancel-invoice')
    //     },
    //     show: item => {
    //       if (!companyId.value || checkInvoiceRequestAndViewF2(item)) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.CANCEL
    //     },
    //     icon: 'trash',
    //   },
    //   {
    //     name: t('invoice.btn.adjustInvoice'),
    //     onClick: item => {
    //       if (!item?.id) return
    //       router.push({ name: 'apps-invoiceAdjust', params: { id: item.id } })
    //     },
    //     show: item => {
    //       if (!companyId.value || checkInvoiceRequestAndViewF2(item)) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.ADJUSTMENT
    //     },
    //     icon: 'fileDelete',
    //   },
    //   {
    //     name: t('invoice.btn.replaceInvoice'),
    //     onClick: item => {
    //       if (!item?.id) return
    //       router.push({ name: 'apps-invoiceReplace', params: { id: item.id } })
    //     },
    //     show: item => {
    //       if (!companyId.value || checkInvoiceRequestAndViewF2(item)) return false
    //       return BUTTON_ACTION_PERMISSIONS?.[item.invoiceStatus]?.[
    //         item.releaseStatus
    //       ]?.REPLACEMENT
    //     },
    //     icon: 'fileForm',
    //   },
    // ]

    // menuFull.filter(i => {
    //   if (!itemContext.value) return true
    //   return i.show(itemContext.value)
    // })
    const menuList = computed(() => ['Chưa có gì hết'])

    // for checkbox
    const checkedItems = ref([]) // object
    const isAll = computed(() => {
      if (!refIncorrectInvoiceListTable.value?.localItems?.length) return false
      const invoiceIdsInTable = refIncorrectInvoiceListTable.value?.localItems ?? []
      return invoiceIdsInTable.every(invItem => checkedItems.value.map(i => i.id).includes(invItem.id))
    })

    function isChecked(id) {
      return checkedItems.value.map(i => i.id).includes(id)
    }

    function setArray(array, item) {
      const index = array
        ? array.findIndex(element => element.id === item.id)
        : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(item)
      return array
    }

    function chooseItem(item) {
      checkedItems.value = setArray(checkedItems.value, item)
    }

    function selectAll(val) {
      const invoiceIdsInTable = refIncorrectInvoiceListTable.value?.localItems ?? []
      if (val) {
        checkedItems.value = uniqBy(checkedItems.value.concat(invoiceIdsInTable), 'id')
      } else {
        checkedItems.value = checkedItems.value.filter(
          i => !invoiceIdsInTable.map(i => i.id).includes(i.id),
        )
      }
    }

    async function onExportHandle() {
      toastWarning('featureInDevelopment')
      return
      // eslint-disable-next-line no-unreachable
      const titleMsg = () => this.$createElement('div', {
        domProps: {
          innerHTML: this.$t(
            `modal.confirmExport${
              checkedItems.value.length ? 'Choosen' : ''
            }`,
          ),
        },
      })

      const isConfirmed = await root.$bvModal.msgBoxConfirm(titleMsg(), {
        title: root.$t('modal.confirm'),
        size: 'sm',
        okVariant: 'info',
        okTitle: root.$t('modal.yes'),
        cancelTitle: root.$t('modal.no'),
        cancelVariant: 'outline-danger',
        hideHeaderClose: true,
        centered: true,
      })
      if (isConfirmed) {
        // exportInvoices(checkedItems.value)
      }
    }

    function onReleaseHandle() {
      if (!checkedItems.value?.length) {
        toastError('invoice.msg.noInvoiceSelected')
        return
      }
      isReleaseBatch.value = true
      invoiceSelected.value = null
      root.$bvModal.show('modal-sign-invoice')
    }
    function onSettingHandle() {
      root.$bvModal.show('modal-select-columns')
    }
    function onCancelInvoiceHandle() {
      if (!checkedItems.value?.length) {
        toastError('invoice.msg.noInvoiceSelected')
        return
      }
      root.$bvModal.show('modal-cancel-batch-invoices')
    }

    return {
      refetchData,
      fetchIncorrectInvoices,
      refIncorrectInvoiceListTable,
      loadingList,
      tableColumnIncorrectInvoiceList,
      sortBy,
      isSortDirDesc,
      startDate,
      endDate,
      notificationType,
      notificationStatus,
      statusOfTaxAuthority,
      clearFilter,
      resolveNotificationTypeVariant,
      resolveNotificationStatusVariant,
      resolveNotificationStatusOfTaxAuthorityVariant,
      convertISODateTime,
      handleContextMenu,
      MenuRefForInvoiceList,
      menuList,
      itemContext,
      onExportHandle,
      onReleaseHandle,
      onSettingHandle,
      totalIncorrectInvoices,
      dataMeta,
      sizePerPage,
      currentPage,
      sizePerPageOptions,
      onCancelInvoiceHandle,
      onViewInvoiceHandle,
      invoiceSelected,
      companyId,
      // for checkbox
      isAll,
      checkedItems,
      isChecked,
      chooseItem,
      selectAll,
      isLoadingCompanyId,
      isReleaseBatch,

      isRoleF2,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.maxWidth = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
  @import '@core/scss/vue/libs/vue-context.scss';

  #invoice-list ::v-deep {
    // .table-primary {
    //   background-color: $primary !important;
    //   border-color: $primary !important;
    //   color: white;
    // }

    .per-page-selector {
      width: 135px;
    }

    .position-left-45 {
      left: 44.5px !important;
    }

    .width-30 {
      width: 30px !important;
    }
  }

  #switch-additional-amount-status ::v-deep {
    .custom-control-input:checked ~ .custom-control-label::before,
    .custom-control-input:not(:disabled):active ~ .custom-control-label::before
    {
      background-color: $success !important;
      border-color: $success !important;
    }
  }
</style>

<style lang="scss">
  #invoice-list {
    .table {
      thead th {
        vertical-align: middle;
      }
    }
  }

</style>
