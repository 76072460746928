var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingDetail
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_vm.tourSelected ? _c('div', {
    staticClass: "d-flex flex-column gap-3 my-1"
  }, [_c('TourDetailBreadcrumbs', {
    attrs: {
      "paths": _vm.paths
    }
  }), _c('TourDetailScheduleAndPrice', {
    attrs: {
      "tourSelected": _vm.tourSelected,
      "showInfo": true,
      "title": 'Tóm tắt chuyến đi'
    }
  }), _c('TourBookContactInfo', {
    attrs: {
      "contactName": _vm.bookingTourData.contactName,
      "contactPhone": _vm.bookingTourData.contactPhone,
      "email": _vm.bookingTourData.email,
      "address": _vm.bookingTourData.address,
      "customerId": _vm.bookingTourData.customerId,
      "agencyCode": _vm.bookingTourData.agencyCode
    },
    on: {
      "update:contactName": function updateContactName($event) {
        return _vm.$set(_vm.bookingTourData, "contactName", $event);
      },
      "update:contact-name": function updateContactName($event) {
        return _vm.$set(_vm.bookingTourData, "contactName", $event);
      },
      "update:contactPhone": function updateContactPhone($event) {
        return _vm.$set(_vm.bookingTourData, "contactPhone", $event);
      },
      "update:contact-phone": function updateContactPhone($event) {
        return _vm.$set(_vm.bookingTourData, "contactPhone", $event);
      },
      "update:email": function updateEmail($event) {
        return _vm.$set(_vm.bookingTourData, "email", $event);
      },
      "update:address": function updateAddress($event) {
        return _vm.$set(_vm.bookingTourData, "address", $event);
      },
      "update:customerId": function updateCustomerId($event) {
        return _vm.$set(_vm.bookingTourData, "customerId", $event);
      },
      "update:customer-id": function updateCustomerId($event) {
        return _vm.$set(_vm.bookingTourData, "customerId", $event);
      },
      "update:agencyCode": function updateAgencyCode($event) {
        return _vm.$set(_vm.bookingTourData, "agencyCode", $event);
      },
      "update:agency-code": function updateAgencyCode($event) {
        return _vm.$set(_vm.bookingTourData, "agencyCode", $event);
      }
    }
  }), _c('TourBookPriceDetail', {
    attrs: {
      "tourSelected": _vm.tourSelected,
      "numberPassengers": {
        numberAdults: _vm.bookingTourData.numberAdults,
        numberChildren1: _vm.bookingTourData.numberChildren1,
        numberChildren2: _vm.bookingTourData.numberChildren2,
        numberInfant: _vm.bookingTourData.numberInfant
      }
    },
    on: {
      "updateNumberPassengers": _vm.updateNumberPassengers
    }
  }), _c('TourBookFooter', {
    on: {
      "bookTour": _vm.onBookTourHandle
    }
  }), _c('TourBookSuccessfulModal', {
    attrs: {
      "bookingCode": _vm.bookingCode
    }
  })], 1) : _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Không tìm thấy tour đã chọn. Vui lòng chọn lại tour! ")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }