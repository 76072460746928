<template>
  <li
    v-if="visibleForFloor(item) && visibleForRole(item) && visibleForRetail(item) && !((item.hideForProduction && isProduction) || item?.hideAll) && visibleByIsShowFunction"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled || !canViewVerticalNavMenuGroup(item),
      'sidebar-group-active': isActive || isActiveBg,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      :class="item.route ? 'p-0' : ''"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <!-- :class="item.route ? 'hover-bg bg-warning' : ''" -->
      <div
        class="d-flex-center rounded"
        :class="{
          'hover-bg': item.route,
        }"
        @click.stop.prevent="handleClickName(item)"
      >
        <IAmIcon
          v-if="item?.customIcon"
          :icon="item?.customIcon"
          class="mr-50"
          size="18"
        />
        <feather-icon
          v-else
          :icon="item.icon || 'CircleIcon'"
          class="mr-50"
        />
        <div class="flex-1 d-flex-between gap-2">
          <span
            v-if="!isVerticalMenuCollapsed || isMouseHovered || $store.state.verticalMenu.isShowText"
            class="menu-title text-truncate"
          >
            {{ t(item.title) }} {{ getRouteDescription(item) }}
          </span>
          <b-badge
            v-if="(item.tag || item.hideForProduction) && (!isVerticalMenuCollapsed || isMouseHovered)"
            pill
            :variant="item.tagVariant || (item.hideForProduction ? 'light-danger' : '') || 'primary'"
          >
            {{ item.tag || (item.hideForProduction ? 'DEV' : '') }}
          </b-badge>
        </div>
      </div>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'
import env from '@/libs/env'

import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'

import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      goToRoute,
      updateIsActive,
      isRouteActive,
      isVerticalMenuCollapsed,
      isMouseHovered,
    } = useVerticalNavMenuGroup(props.item)

    const isActiveBg = computed(c => {
      const cur = c.$route.name
      return isRouteActive(cur)
    })
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    const meData = computed(() => store.getters['userStore/getMeData'])

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const myFloor = computed(() => isRoleF3.value ? 'F3' : isRoleF2.value ? 'F2' : 'F1')
    const childFloor = computed(() => isRoleF1.value ? 'F2' : isRoleF2.value ? 'F3' : '')
    const parentFloor = computed(() => isRoleF3.value ? 'F2' : isRoleF2.value ? 'F1' : '')

    const visibleByIsShowFunction = computed(() => {
      if (props.item?.isShowFunction) {
        return props.item?.isShowFunction()
      }
      return true
    })

    const visibleForRole = route => {
      if (route.visibleRoles) {
        return route.visibleRoles.includes(meData.value?.type)
      }
      return true
    }

    const visibleForFloor = route => {
      if (route.visibleFloors) {
        return route.visibleFloors.includes(myFloor.value)
      }
      return true
    }

    const visibleForRetail = route => {
      if (route.title === 'Retail') {
        return meData?.value?.agency?.enableRetail
      }
      return true
    }

    const getRouteDescription = route => {
      if (route.route === 'apps-parent-workSchedules-page') {
        return parentFloor.value
      }
      if (route.route === 'apps-agencies-list') {
        return childFloor.value
      }
      return ''
    }

    function handleClickName(item) {
      if (item?.route) {
        goToRoute(item)
        if (item?.openChildren) {
          updateGroupOpen(true)
        }
      } else {
        updateGroupOpen(!isOpen.value)
      }
    }

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      goToRoute,
      handleClickName,
      isActiveBg,
      isVerticalMenuCollapsed,
      isMouseHovered,
      isProduction: env.isProduction,
      visibleByIsShowFunction,
      // ACL
      canViewVerticalNavMenuGroup,

      visibleForRole,
      visibleForFloor,
      visibleForRetail,
      getRouteDescription,

      // i18n
      t,

    }
  },
}
</script>

<style lang="scss">
.hover-bg{
  padding: 10px 15px;
  &:hover {
    background-color: #EFAD0255;
    border-radius: 6px;
  }
}
</style>
