<template lang="">
  <BModal
    id="modal-buy-package-config"
    size="lg"
    centered
    header-bg-variant="light"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :title="$t(`packageConfig.${titleHeader}.title`)"
    @show="showHandle"
  >
    <IAmOverlay :loading="loading || loadingPackage">
      <BRow
        v-if="buyItem"
        class="my-1"
      >
        <BCol
          cols="12"
          md="6"
        >
          <BCard
            class="border border-warning bg-light-warning mb-0 w-100"
            body-class="px-1 py-50"
          >
            <div class="d-flex-between font-weight-bolder font-large-1">
              <div>
                {{ buyItem.name }}
              </div>
              <div>
                <span>{{ formatCurrency(buyItem.price) }}
                  <small class="font-italic">VND</small>
                </span>
              </div>
            </div>

            <div
              v-for="(val, key) of buyItemValueToShowDetail"
              :key="key"
              class="text-dark py-50"
            >
              <div :class="`d-flex-between font-weight-bolder ${key === 'specialNote' ? 'text-success' : ''}`">
                <div>{{ $te(`packageConfig.columns.${key}`) ? $t(`packageConfig.columns.${key}`) : key }}:</div>
                <div v-if="typeof val?.value === 'number'">
                  <span class="font-medium-4">{{ formatCurrency(val.value) }}</span>
                  <small
                    v-if="val.unit"
                    class="font-italic ml-25"
                  >{{ val?.unit }}</small>
                </div>
                <div v-else-if="typeof val?.value === 'boolean'">
                  <feather-icon
                    v-if="val.value"
                    class="text-success"
                    icon="CheckIcon"
                    size="20"
                  />
                  <feather-icon
                    v-else
                    class="text-danger"
                    icon="XIcon"
                    size="20"
                  />
                </div>
                <div v-else>
                  <span class="font-medium-2">{{ val?.value }}</span>
                </div>
              </div>
            </div>
          </BCard>
        </BCol>
        <BCol
          v-if="!qrCodeUrl"
          cols="12"
          md="6"
          class="d-flex-center flex-column"
        >
          <BCard
            class="border w-100 mb-50 flex-grow-1"
            body-class="px-1 py-50 d-flex-center flex-column align-items-stretch"
          >
            <!-- <BFormCheckbox
              v-if="isBuy"
              :checked="checkCreateNewAgency"
              class="my-1"
              :disabled="isPayment"
              @change="checkCreateNewAgency = !checkCreateNewAgency, agencySelectedToBuy = null"
            >
              Áp dụng cho đại lý bán lẻ mới
            </BFormCheckbox> -->
            <h4
              v-if="isBuy && buyItem.type !== 'INVOICE'"
              class="my-1"
            >
              Áp dụng cho đại lý bán lẻ mới
            </h4>

            <b-form-group
              v-if="isGift"
              label-class="h5"
              label-for="agency"
              :label="$t('packageConfig.columns.packageConfig')"
            >
              <SelectPackageConfig
                :package-config-select.sync="buyItem"
                :clearable="false"
              />
            </b-form-group>

            <b-form-group
              v-if="!isBuy"
              label-class="h5"
              label-for="agency"
              :label="$t('packageConfig.columns.agency')"
            >
              <SelectChildAgency
                :id="`childAgency`"
                :disabled="(checkCreateNewAgency && isBuy) || isPayment || isIncrease || disabledAgencyItem"
                :agency-select.sync="agencySelectedToBuy"
                :placeholder="$t('saleReport.phAgencyCode')"
                :for-f1="true"
              />
            </b-form-group>

            <b-form-group
              v-if="buyItem.type !== 'INVOICE'"
              label-class="h5"
              label-for="monthsUse"
              :label="$t('packageConfig.columns.monthsUse')"
            >
              <b-form-input
                id="monthsUse"
                v-model="monthsUse"
                v-remove-non-numeric-chars.allNumber
                lazy-formatter
                :disabled="isPayment || isGift"
                :placeholder="$t('packageConfig.columns.monthsUse')"
              />
              <div
                v-if="!isGift"
                class="small text-info"
              >
                Mua 6 tháng sẽ được khuyến mại 1 tháng
              </div>
              <div
                v-if="monthsUse >= 6"
                class="small text-success"
              >
                Bạn sẽ được cộng thêm {{ Math.floor(monthsUse / 6) }} tháng miễn phí
              </div>
            </b-form-group>
            <b-form-group
              v-if="isBuy || isIncrease"
              label-class="h5"
              label-for="paymentMethod"
              :label="$t('packageConfig.columns.paymentMethod')"
            >
              <PaymentMethodSelect
                :payment-method.sync="paymentMethod"
                :disabled="isPayment"
              />
            </b-form-group>

            <b-form-group
              v-if="isBuy || isIncrease"
              label-class="h5"
              label-for="paymentMethod"
              :label="$t('packageConfig.columns.code')"
            >
              <b-input-group class="input-group-merge mb-50">
                <BFormInput
                  v-model="couponCode"
                  :placeholder="$t('packageConfig.columns.code')"
                  :formatter="removeAccentsUpperCaseFormatter"
                  maxlength="8"
                />
                <b-input-group-append is-text>
                  <b-spinner
                    v-if="loadingCheckCouponCode"
                    variant="primary"
                    small
                  />
                  <feather-icon
                    v-else-if="couponApply && !couponApply.isApplied && couponApply.enable"
                    class="text-success"
                    icon="CheckIcon"
                    size="16"
                  />
                  <feather-icon
                    v-else-if="couponApply === false || (couponApply && (couponApply?.isApplied || !couponApply?.enable))"
                    class="text-danger"
                    icon="XIcon"
                    size="16"
                  />
                </b-input-group-append>
              </b-input-group>
              <div v-if="couponApply">
                <em
                  v-if="couponApply.enable"
                  class="d-flex-center justify-content-end gap-1"
                  :class="`${couponApply.isApplied ? 'text-danger' : 'text-success'}`"
                >
                  Giảm giá
                  <span
                    v-if="couponApply.percentage"
                    class="d-flex gap-1"
                  >
                    <span class="font-weight-bolder">{{ couponApply.percentage }}%</span>
                    <span v-if="couponApply.amountMax">(tối đa {{ formatCurrency(couponApply.amountMax) }} VND)</span>
                  </span>
                  <span
                    v-else-if="couponApply.amountMax"
                    class="font-weight-bolder"
                  >
                    {{ formatCurrency(couponApply.amountMax) }} VND
                  </span>
                </em>
                <div
                  v-if="couponApply.isApplied"
                  class="d-flex-center justify-content-end text-danger gap-1"
                >
                  Mã giảm giá đã được sử dụng
                </div>
                <div
                  v-if="!couponApply.enable || couponApply === false"
                  class="d-flex-center justify-content-end text-danger gap-1"
                >
                  Mã giảm giá không tồn tại
                </div>
              </div>
              <div
                v-if="couponApply === false"
                class="d-flex-center justify-content-end text-danger gap-1"
              >
                Mã giảm giá không tồn tại
              </div>

              <div class="mt-1">
                Số tiền cần thanh toán:
                <b class="">{{ formatCurrency(totalAmountToBePaid) }}
                  <small>VND</small>
                </b>
              </div>
            </b-form-group>
          </BCard>

          <div class="d-flex-center gap-2">
            <BButton
              variant="flat-danger"
              @click="closeModal"
            >
              Huỷ
            </BButton>

            <BButton
              v-if="isGift"
              variant="warning"
              :disabled="disabledSubmit"
              @click="giftButtonHandle"
            >
              {{ $t('packageConfig.agencyPackageConfigsGift') }}
            </BButton>
            <BButton
              v-if="isIncrease"
              variant="warning"
              :disabled="disabledSubmit"
              @click="buyButtonHandle"
            >
              Gia hạn
            </BButton>
            <BButton
              v-if="isBuy"
              variant="primary"
              :disabled="disabledSubmit"
              @click="buyButtonHandle"
            >
              Mua
            </BButton>
          </div>
        </BCol>

        <BCol
          v-else
          cols="12"
          md="6"
        >
          <div class="d-flex-center flex-column">
            <em>Vui lòng quét để thanh toán !</em>
            <b-img
              :src="qrCodeUrl"
              style="width: 400px"
            />
          </div>
        </BCol>
      </BRow>
    </IAmOverlay>
    <ModalBuyConfirm @confirm-buy-package="submitBuyHandle" />
  </BModal>
</template>
<script>

import {
  BModal, BRow, BCol, BCard, BButton, BFormGroup, BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import {
  computed, nextTick, ref,
} from '@vue/composition-api'
import { debouncedWatch } from '@vueuse/core'
import cloneDeep from 'lodash/cloneDeep'

import useAgencyPackageConfig from '@/views/retail-package/agency-package-config/useAgencyPackageConfig'
import usePackgageConfig from '@/views/retail-package/package-config/usePackageConfig'
import usePackageCouponHandle from '@/views/retail-package/package-coupon/usePackageCouponHandle'
import store from '@/store'
import retailPackageStore from '@/views/retail-package/retailPackageStore'

import { formatCurrency } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    SelectChildAgency: () => import('@/views/apps/retail/components/SelectChildAgency.vue'),
    PaymentMethodSelect: () => import('@/views/retail-package/components/PaymentMethodSelect.vue'),
    SelectPackageConfig: () => import('@/views/retail-package/components/SelectPackageConfig.vue'),
    ModalBuyConfirm: () => import('./ModalBuyConfirm.vue'),
  },
  props: {
    buyItemProps: { // package config
      type: Object,
      default: null,
    },
    agencyItem: {
      type: Object,
      default: null,
    },
    disabledAgencyItem: {
      type: Boolean,
      default: false,
    },
    isGift: {
      type: Boolean,
      default: false,
    },
    isIncrease: {
      type: Boolean,
      default: false,
    },
    isPayment: {
      type: Boolean,
      default: false,
    },
    qrCode: {
      type: String,
      default: '',
    },
    paymentMethodProp: {
      type: String,
      default: '',
    },
    monthsUseProp: {
      type: [Number, String],
      default: 1,
    },
  },
  setup(props, { root, emit }) {
    if (!store.hasModule('app-package-config')) {
      store.registerModule('app-package-config', retailPackageStore)
    }

    const buyItem = ref(cloneDeep(props.buyItemProps))
    const { loading, buyPackage } = useAgencyPackageConfig()
    const { loading: loadingPackage, giftPackages, getMinPricePackageConfig } = usePackgageConfig()
    const { loadingCheckCouponCode, checkCouponCode } = usePackageCouponHandle()
    const buyItemValueToShowDetail = computed(() => {
      const {
        autoIssueTicket, searchLimit, bookingLimit, issueLimit, paxLimit, feeTransaction, enableDraft, descriptions,
      } = buyItem.value

      // Gói Web sẽ có config riêng theo descriptions, nếu type === 'WEB' sẽ là gói tính năng
      let results = descriptions.reduce((a, v) => ({ ...a, [v.description]: { value: v.value } }), {})
      if (buyItem.value.type === 'API') {
        results = {
          specialNote: {},
          searchLimit: { value: searchLimit, unit: 'lần' },
          bookingLimit: { value: bookingLimit, unit: 'lần' },
          issueLimit: { value: issueLimit, unit: 'lần' },
          paxLimit: { value: paxLimit, unit: 'khách' },
          enableDraft: { value: enableDraft },
          autoIssueTicket: { value: autoIssueTicket },
        }
        if (autoIssueTicket) {
          results.feeTransaction = { value: feeTransaction, unit: 'VND' }
        }
        const specialNote = descriptions.find(item => item.key === 'specialNote')
        if (specialNote) {
          results.specialNote = { value: specialNote.value, unit: 'lần' }
        } else {
          delete results.specialNote
        }
      }
      return results
    })

    const agencySelectedToBuy = ref()
    const checkCreateNewAgency = ref()
    const couponCode = ref()
    const monthsUse = ref(1)
    const paymentMethod = ref('CREDIT')
    const qrCodeUrl = ref('')

    function showHandle() {
      nextTick(async () => {
        if (!props.buyItemProps) {
          if (!getMinPricePackageConfig.value) {
            root.$bvModal.show('modal-api-loading')
            await store.dispatch('app-package-config/getPackageConfigs')
            root.$bvModal.hide('modal-api-loading')
          }
          buyItem.value = getMinPricePackageConfig.value
        } else {
          buyItem.value = cloneDeep(props.buyItemProps)
        }
        agencySelectedToBuy.value = props.agencyItem || null
        checkCreateNewAgency.value = Boolean(!props?.agencyItem)
        monthsUse.value = props?.monthsUseProp
        paymentMethod.value = props?.paymentMethodProp
        qrCodeUrl.value = props.qrCode
        couponCode.value = null
      })
    }

    function closeModal() {
      root.$bvModal.hide('modal-buy-package-config')
    }

    function giftButtonHandle() {
      const titleMsg = this.$createElement('div', {
        domProps: {
          innerHTML: `
          Bạn chắc chắn muốn tặng
            <span class="font-weight-bolder">
              ${buyItem.value.name}
              <span class="font-italic">
                (${formatCurrency(buyItem.value.price)} VND)
                </span>
              </span>
            áp dụng cho
            <span class="font-weight-bolder"> đại lý ${agencySelectedToBuy.value.agencyCode} (${agencySelectedToBuy.value.agencyName})</span> ?`,
        },
      })
      root.$bvModal.msgBoxConfirm([titleMsg], {
        title: this.$t('modal.confirm'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('modal.yes'),
        cancelTitle: this.$t('modal.no'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: true,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const payload = {
              packageConfigId: buyItem.value.id,
              agencyId: agencySelectedToBuy.value.id,
              monthsUse: Number(monthsUse.value),
            }
            await giftPackages(payload)

            closeModal()
          }
        })
    }

    const titleHeader = computed(() => props.isGift ? 'gift' : props.isIncrease ? 'increase' : props.isPayment ? 'payment' : 'buy')
    const isBuy = computed(() => !props.isGift && !props.isIncrease && !props.isPayment)

    const couponApply = ref(null)

    debouncedWatch(couponCode, async () => {
      if (couponCode.value?.length === 8) {
        couponApply.value = null
        const { data } = await checkCouponCode(couponCode.value)
        couponApply.value = data || false
      } else {
        couponApply.value = null
      }
    }, { debounce: 300 })

    const disabledSubmit = computed(() => {
      if ((couponApply.value && (couponApply.value.isApplied || !couponApply.value.enable)) || (!couponApply.value && couponCode.value?.length)) {
        return true
      }
      if (props.isGift) {
        return !agencySelectedToBuy.value || !Number(monthsUse.value)
      }
      return (!paymentMethod.value) || (!agencySelectedToBuy.value && !checkCreateNewAgency.value) || !Number(monthsUse.value)
    })

    const totalAmountToBePaid = computed(() => {
      const totalPrice = buyItem.value.price * monthsUse.value
      let totalDiscount = 0
      const coupon = couponApply.value
      if (coupon && !disabledSubmit.value) {
        if (coupon.percentage) {
          let discountAmount = totalPrice * (coupon.percentage / 100)
          if (coupon.amountMax > 0 && discountAmount > coupon.amountMax) {
            discountAmount = coupon.amountMax
          }
          totalDiscount = discountAmount
        } else {
          totalDiscount = coupon.amountMax
        }
      }
      if (totalDiscount > totalPrice) {
        totalDiscount = totalPrice
      }
      return totalPrice - totalDiscount
    })

    async function submitBuyHandle() {
      const payload = {
        packageConfigId: buyItem.value.id,
        agencyId: props.isIncrease ? agencySelectedToBuy.value.id : checkCreateNewAgency.value ? undefined : agencySelectedToBuy.value.id,
        couponCode: couponCode.value || undefined,
        ...(buyItem.value.type !== 'INVOICE' && { monthsUse: Number(monthsUse.value) }),
        paymentMethod: paymentMethod.value,
      }
      const res = await buyPackage(payload)
      emit('refetchData')
      if (paymentMethod.value === 'MONEY') {
        qrCodeUrl.value = res?.agencyPackageOrder?.qrCodeUrl
      } else {
        closeModal()
      }
    }

    function buyButtonHandle() {
      this.$bvModal.show('modal-buy-confirm')
    }

    return {
      showHandle,
      formatCurrency,
      buyItemValueToShowDetail,
      closeModal,
      buyButtonHandle,
      submitBuyHandle,
      checkCreateNewAgency,
      monthsUse,
      disabledSubmit,
      agencySelectedToBuy,
      paymentMethod,
      loading,
      qrCodeUrl,
      totalAmountToBePaid,
      titleHeader,
      isBuy,
      giftButtonHandle,
      loadingPackage,
      couponCode,
      removeAccentsUpperCaseFormatter,
      loadingCheckCouponCode,
      couponApply,
      buyItem,
    }
  },
}
</script>
