export default {
  vehiclesType: {
    CUSTOM: 'Custom',
    AIRPLANE: 'Airplane',
    CAR: 'Car',
    TRAIN: 'Train',
    HIGH_SPEED_TRAIN: 'High speed train',
  },
  tourType: {
    DOMESTIC: 'Domestic',
    OUTBOUND: 'Outbound',
  },
  msg: {
    selectedTourNotFound: 'Please select tour again!',
    agencyPlaceholder: 'Select agency for book tour',
  },
  field: {
    contactName: 'Fullname',
    contactPhone: 'Phone Number',
    email: 'Email',
    address: 'Address',
    type: 'Loại khách',
    unitPrice: 'Price/person',
    commission: 'Commission',
    totalCommission: 'Total Commission',
    number: 'Number',
    totalAmount: 'Total Amount',
    chooseAgency: 'Select agency',
    agency: 'Agency',
    totalSingleRoom: 'Total single room',
  },
  ph: {
    contactName: 'Enter fullname',
    contactPhone: 'Enter phone number',
    email: 'Enter email',
    address: 'Enter address',
  },
  passenger: {
    Adults: 'Adult',
    Children1: 'Children 1',
    Children2: 'Children 2',
    Infant: 'Infant',
    ageFrom: 'From {ageFrom} yo above',
    ageBetween: 'From {ageFrom}-{ageTo} yo',
    ageTo: 'Below {ageTo} yo',
  },
  totalPrice: 'Total price',
  displaySettings: 'Display',

  tableColumns: {
    no: 'No.', // No.
    bookingCode: 'Booking code', // Booking code
    status: 'Status', // Status
    customerName: 'Customer name', // Customer name
    tourName: 'Tour name', // Tour name
    startDate: 'Start date', // Start date
    totalPrice: 'Total price', // Total price
    totalCommission: 'Total commission', // Total commission
    createdAt: 'Created at', // Created at
    createdBy: 'Created by', // Created by
    note: 'Note', // Note
    actions: 'Actions', // Actions
    totalPriceToBePaid: 'Total price need to pay',
    totalPriceRefund: 'Total refund',
    depositPrice: 'Deposited / Paid',

    paxType: 'Pax type',
    tourPrice: 'Tour price',
    paxQuantity: 'Quantity',
    // totalPrice: 'Total price',
  },

  status: {
    ALL: 'ALL',
    RESERVED: 'Reserved', // Giữ chỗ
    DEPOSITED: 'Deposited', // Đã đặt cọc
    REQUEST_DEPOSIT: 'Request Deposit',
    REQUEST_PAYMENT: 'Request Payment',
    REQUEST_UPDATE: 'Request Update',
    PAID: 'Paid', // Đã thanh toán
    CANCELLATION_REQUESTED: 'Cancellation Requested', // Yêu cầu huỷ
    CANCELLED: 'Cancelled', // Đã huỷ
    EXPIRED: 'Expired',
  },
  showCommission: 'Show commission',
  hideCommission: 'Hide commission',
  bookingDetail: {
    tourInfo: 'Tour information',
    contactInfo: 'Contact information',
    paymentDeadline: 'Payment deadline',
    timeHold: 'Time hold',
  },
  historyActions: {
    CREATE: 'Book tour',
    CONFIRM_PAYMENT: 'Confirmed deposit/payment',
    CONFIRM_UPDATE: 'Confirmed update',
    REQUEST_CANCEL: 'Request cancel tour',
    REQUEST_SUPPORT: 'Confirm tour',
  },
  btn: {
    requestSupport: 'Confirm Tour',
    requestedSupport: 'Confirmed Tour',
  },
}
