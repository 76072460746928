var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-flightv2-show-price-group-booking",
      "title": "Tính giá, book đoàn lẻ",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg",
      "lazy": ""
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "d-flex gap-3"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _vm.isShowBookingGroupButton ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill btn-gradient",
          attrs: {
            "size": "md",
            "disabled": !_vm.quantityAdult
          },
          on: {
            "click": function click($event) {
              return _vm.handleGoToReservation();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")]) : _vm._e()], 1)];
      }
    }])
  }, [_c('AlertGroupBooking'), _c('div', {
    staticClass: "text-body fw-700"
  }, [_vm._v(" 1. Hành trình: ")]), _vm._l(_vm.listSelectedTrips, function (trip, indexTrip) {
    return _c('b-card', {
      key: indexTrip,
      staticClass: "mb-50 border rounded-lg text-center",
      attrs: {
        "body-class": "px-50 px-lg-1 py-75 d-flex-center flex-column flex-lg-row"
      }
    }, [_c('div', {
      staticClass: "mb-50 mb-lg-0"
    }, [_c('span', {
      staticClass: "mr-50 fw-700 text-airline"
    }, [_vm._v(" #" + _vm._s(indexTrip + 1) + ": ")]), _c('span', {
      staticClass: "mr-50 fw-700 text-airline"
    }, [_vm._v(" " + _vm._s(" ".concat(trip.source.includes('1S') ? 'VN' : trip.source).concat(trip.flightNumber, " ").concat(trip.departure.IATACode).concat(trip.arrival.IATACode, " ").concat(_vm.convertISODateTime(trip.departure.at, trip.departure.timezone).hourMin, " ").concat(_vm.convertISODateTime(trip.arrival.at, trip.arrival.timezone).hourMin, " | ").concat(trip.segments[0].airCraft, " ")) + " ")])]), _c('div', [_vm.resolveTripClasses(trip) ? _c('code', {
      class: "fw-700 ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2')
    }, [_vm._v(" " + _vm._s(_vm.resolveTripClasses(trip)) + " ")]) : _vm._e()])]);
  }), _c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('span', {
    staticClass: "text-body fw-700"
  }, [_vm._v("2. Số lượng khách:")])]), _c('b-card', {
    staticClass: "mb-50 border rounded-lg text-center",
    attrs: {
      "body-class": "py-75"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "px-50",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "quantity-adult"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1 text-nowrap fw-700"
        }, [_c('span', [_vm._v("Người lớn")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "font-medium-2 fw-600",
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "id": "quantity-adult",
      "max": "3",
      "size": "sm",
      "type": "number",
      "placeholder": "Nhập số lượng người lớn"
    },
    model: {
      value: _vm.quantityAdult,
      callback: function callback($$v) {
        _vm.quantityAdult = _vm._n($$v);
      },
      expression: "quantityAdult"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center px-50",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "quantity-child"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1 text-nowrap fw-700"
        }, [_c('span', [_vm._v("Trẻ em")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "font-medium-2 fw-600",
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "id": "quantity-adult",
      "max": "3",
      "size": "sm",
      "type": "number",
      "placeholder": "Nhập số lượng trẻ em"
    },
    model: {
      value: _vm.quantityChild,
      callback: function callback($$v) {
        _vm.quantityChild = _vm._n($$v);
      },
      expression: "quantityChild"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center px-50",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "quantity-infant"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1 text-nowrap fw-700"
        }, [_c('span', [_vm._v("Em bé")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "font-medium-2 fw-600",
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "id": "quantity-infant",
      "max": "3",
      "size": "sm",
      "type": "number",
      "placeholder": "Nhập số lượng em bé"
    },
    model: {
      value: _vm.quantityInfant,
      callback: function callback($$v) {
        _vm.quantityInfant = _vm._n($$v);
      },
      expression: "quantityInfant"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "py-75 fw-700",
    attrs: {
      "variant": "outline-warning",
      "pill": "",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.handleCalcPriceGroupBooking();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.groupBooking.calculate')) + " ")])], 1)], 1), _vm.isCalcPriceGroupBooking ? _c('div', [_c('IAmOverlay', {
    class: "py-1 ".concat(_vm.loading || _vm.isEmpty(_vm.arrTripsModify) ? 'mt-3 mb-1' : ''),
    attrs: {
      "loading": _vm.loading || _vm.isEmpty(_vm.arrTripsModify),
      "spinner-variant": "info"
    }
  }, [!_vm.isEmpty(_vm.listBookingCalculated) ? _c('b-card', {
    staticClass: "border",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "py-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', [_vm._v(" Chi tiết tính giá đoàn: ")])];
      },
      proxy: true
    }], null, false, 2921515296)
  }, [_c('div', {
    staticClass: "fw-700 text-body mt-50"
  }, [_vm._v(" Số hành khách nhập vào (Người lớn + trẻ em): "), _c('span', {
    staticClass: "ml-1 text-airline"
  }, [_vm._v(_vm._s(_vm.totalPassengerToCal))])]), _c('div', {
    staticClass: "fw-700 text-body mt-50 d-flex align-items-center"
  }, [_vm._v(" Tổng số chỗ có thể đặt: "), _c('span', {
    staticClass: "ml-1 text-success"
  }, [_vm._v(_vm._s(_vm.maxSeatsToBook))]), _c('feather-icon', {
    staticClass: "ml-50 text-info cursor-pointer",
    attrs: {
      "id": "seat-available-can-book",
      "icon": "InfoIcon",
      "size": "16"
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "seat-available-can-book",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v("Số chỗ còn trống trên hành trình và có thể đặt với hạng vé đã chọn")])])], 1), _vm.maxSeatsToBook < _vm.totalPassengerToCal ? _c('div', {
    staticClass: "fw-700 text-body mt-50 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" Số hành khách còn lại: ")]), _c('span', {
    staticClass: "ml-1 text-danger"
  }, [_vm._v(_vm._s(_vm.totalPassengerToCal - (Number(_vm.maxSeatsToBook) || 0)))]), _c('feather-icon', {
    staticClass: "ml-50 text-info cursor-pointer",
    attrs: {
      "id": "seat-unavailable-can-book",
      "icon": "InfoIcon",
      "size": "16"
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "seat-unavailable-can-book",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v("Số chỗ còn thiếu, hết chỗ")])])], 1) : _vm._e(), _c('b-alert', {
    staticClass: "mb-0 mt-1 px-1 py-50 font-weight-bolder",
    attrs: {
      "variant": "danger",
      "show": _vm.listBookingCalculated.length > 0
    }
  }, [_vm._v(" Thông tin giá chỉ mang tính chất tham khảo, chưa bao gồm phí dịch vụ. ")])], 1) : _vm._e(), _vm._l(_vm.listBookingCalculated, function (tripData, indexTrip) {
    return _c('b-card', {
      key: indexTrip,
      staticClass: "border",
      attrs: {
        "header-bg-variant": "light-info",
        "header-class": "py-75 mb-50"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "fw-700 text-airline"
          }, [_vm._v(" Hành trình: #" + _vm._s(indexTrip + 1) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card', {
      staticClass: "mb-0 ml-md-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', {
      staticClass: "text-body text-airline fw-700 my-50"
    }, [_vm._v(" " + _vm._s(" ".concat(_vm.resolveAirlineFlightNumber(tripData[0].trip.airline, tripData[0].trip.flightNumber), " ").concat(_vm.convertISODateTime(tripData[0].trip.departure.at, tripData[0].trip.departure.timezone).dayMonth, " ").concat(tripData[0].trip.departure.IATACode).concat(tripData[0].trip.arrival.IATACode, " ").concat(_vm.convertISODateTime(tripData[0].trip.departure.at, tripData[0].trip.departure.timezone).hourMin, " ").concat(_vm.convertISODateTime(tripData[0].trip.arrival.at, tripData[0].trip.arrival.timezone).hourMin, " ")) + " ")])]), _c('div', {
      staticClass: "fw-700 text-body my-50 mr-2 d-flex"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" Tổng số chỗ (người lớn, trẻ em): ")]), _c('span', {
      staticClass: "fw-700 text-airline mr-1"
    }, [_vm._v(" " + _vm._s(tripData.reduce(function (total, item) {
      return total + item.prices.reduce(function (tSeat, pax) {
        return tSeat + (['INFANT'].includes(pax.paxType) ? 0 : pax.quantityPax);
      }, 0);
    }, 0)) + " ")])]), _c('div', {
      staticClass: "fw-700 text-body mt-50"
    }, [_vm._v(" Thông tin giá: ")]), _c('b-table-lite', {
      staticClass: "mb-1 rounded",
      attrs: {
        "small": "",
        "bordered": "",
        "responsive": "",
        "show-empty": "",
        "no-border-collapse": "",
        "thead-class": "text-body font-weight-bolder text-nowrap",
        "tbody-class": "text-body font-weight-bolder text-nowrap",
        "empty-text": _vm.$t('noMatchingResult'),
        "items": tripData.flatMap(function (c) {
          return c.prices;
        }),
        "fields": ['paxType', 'bookingClass', 'net', 'tax', 'fare', 'quantity', 'totalPrice']
      },
      scopedSlots: _vm._u([_vm._l(['paxType', 'bookingClass', 'net', 'tax', 'fare', 'quantity', 'totalPrice'], function (column) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: column,
              staticClass: "text-body"
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
          }
        };
      }), {
        key: "cell(pax_type)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_vm._v(" " + _vm._s(_vm.$t("".concat(_vm.capitalize(item.paxType)))) + " ")];
        }
      }, {
        key: "cell(bookingClass)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('span', {
            staticClass: "fw-700 text-danger mr-25"
          }, [_vm._v(_vm._s(item.code))]), _vm._v(" (" + _vm._s(item.fareBasisCode) + ") ")];
        }
      }, {
        key: "cell(net)",
        fn: function fn(_ref4) {
          var item = _ref4.item;
          return [_c('div', {
            staticClass: "text-right fw-600"
          }, [_vm._v(" " + _vm._s(item.net ? _vm.formatCurrency(item.net) : '-') + " ")])];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_c('div', {
            staticClass: "text-right fw-600"
          }, [_vm._v(" " + _vm._s(item.tax ? _vm.formatCurrency(item.tax) : '-') + " ")])];
        }
      }, {
        key: "cell(fare)",
        fn: function fn(_ref6) {
          var item = _ref6.item;
          return [_c('div', {
            staticClass: "text-right fw-600"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.fare)) + " ")])];
        }
      }, {
        key: "cell(quantity)",
        fn: function fn(_ref7) {
          var item = _ref7.item;
          return [_c('div', {
            staticClass: "text-center fw-600"
          }, [_vm._v(" " + _vm._s(item.quantityPax) + " ")])];
        }
      }, {
        key: "cell(totalPrice)",
        fn: function fn(_ref8) {
          var item = _ref8.item;
          return [_c('div', {
            staticClass: "text-airline text-right fw-700"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(item.fare) * item.quantityPax)) + " ")])];
        }
      }], null, true)
    })], 1);
  })], 2)], 1) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }