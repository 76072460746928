var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Stack', {
    staticClass: "gap-2"
  }, [_c('div', {
    class: "text-tour fw-700 ".concat(_vm.isMobileView ? 'text-14px' : 'text-18px', " text-uppercase")
  }, [_vm._v(" Gợi ý Tour Du lịch ")]), _vm.tourSuggestions.length ? _c(_vm.isMobileView ? 'Stack' : 'HStack', {
    tag: "component",
    staticClass: "gap-2"
  }, _vm._l(_vm.tourSuggestions, function (tourSuggestion, tourIndex) {
    var _tourSuggestion$image;
    return _c('div', {
      key: tourIndex,
      staticClass: "bg-light-info rounded-8px p-50 hover-div flex-1"
    }, [_c('HStack', {
      staticClass: "flex-nowrap text-dark gap-2"
    }, [_c('BImgLazy', {
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "src": "".concat((tourSuggestion === null || tourSuggestion === void 0 ? void 0 : (_tourSuggestion$image = tourSuggestion.image) === null || _tourSuggestion$image === void 0 ? void 0 : _tourSuggestion$image.path) || _vm.backgroundTourUrl),
        "rounded": ""
      }
    }), _c('Stack', {
      staticClass: "justify-content-center"
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.window",
        value: tourSuggestion === null || tourSuggestion === void 0 ? void 0 : tourSuggestion.name,
        expression: "tourSuggestion?.name",
        modifiers: {
          "hover": true,
          "window": true
        }
      }],
      staticClass: "fw-700 clamped-content"
    }, [_vm._v(" " + _vm._s(tourSuggestion.name) + " ")]), _c('HStack', [_c('div', [_vm._v("Thời gian: ")]), _c('div', {
      staticClass: "text-info fw-700"
    }, [_vm._v(" " + _vm._s(tourSuggestion.totalDay) + " Ngày " + _vm._s(tourSuggestion.totalNight) + " Đêm ")])]), _c('HStack', [_c('div', {
      class: "text-danger fw-800 ".concat(_vm.isMobileView ? 'text-14px' : 'text-18px')
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(tourSuggestion.priceDefault))) + " VND ")]), _c('BLink', {
      class: "btn btn-flat-success py-25 fw-700 ".concat(_vm.isMobileView ? 'text-12px px-50' : 'text-16px'),
      attrs: {
        "to": {
          name: 'apps-toursList',
          query: {
            type: tourSuggestion.type,
            category: tourSuggestion.categoryId
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" Xem Chi tiết ")])], 1)], 1)], 1)], 1);
  }), 0) : _c('Stack', {
    staticClass: "fw-700 gap-2 font-italic"
  }, [_vm.loadingTourSuggestion ? _c('HStack', [_c('BSpinner', {
    attrs: {
      "small": ""
    }
  }), _c('div', [_vm._v(" Chúng tôi đang tìm kiếm chương trình tour cho Quý khách. ")])], 1) : _c('div', [_vm._v(" Không có gợi ý Tour du lịch cho đặt chỗ này. Tham khảo các Tour du lịch khác tại "), _c('BLink', {
    class: "text-success fw-700",
    attrs: {
      "to": {
        name: 'apps-toursList'
      },
      "target": "_blank"
    }
  }, [_vm._v(" đây ")]), _vm._v(". ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }