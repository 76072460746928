import { getOsEnv } from './utils'

const isProduction = getOsEnv('VUE_APP_ENV') === 'production'
const isStaging = getOsEnv('VUE_APP_ENV') === 'staging'
const isDevelopment = getOsEnv('VUE_APP_ENV') === 'development'

export const env = {
  isProduction,
  isStaging,
  isDevelopment,

  // BANNER
  bannerFull: getOsEnv('VUE_APP_BANNER_FULL_URL'),
  bannerMobile: getOsEnv('VUE_APP_BANNER_MOBILE_URL'),
  logoNoti: getOsEnv('VUE_APP_LOGO_NOTI_URL'),
  logoFavicon: getOsEnv('VUE_APP_LOGO_FAVICON_URL'),
  logoFull: getOsEnv('VUE_APP_LOGO_FULL_URL'),
  backgroundFlightSearchUrl: getOsEnv('VUE_APP_BACKGROUND_FLIGHT_SEARCH_URL'),
  backgroundTrainSearchUrl: getOsEnv('VUE_APP_BACKGROUND_TRAIN_SEARCH_URL'),
  backgroundTourUrl: getOsEnv('VUE_APP_BACKGROUND_TOUR_URL'),

  // GENERAL
  appName: getOsEnv('VUE_APP_NAME') || 'Airdata',
  mamaShortName: getOsEnv('VUE_APP_MAMA_SHORT_NAME') || getOsEnv('VUE_APP_NAME') || 'ADT',
  mamaFullName: getOsEnv('VUE_APP_MAMA_FULL_NAME') || getOsEnv('VUE_APP_NAME'),
  mamaId: Number(getOsEnv('VUE_APP_MAMA_AGENCY_ID') || '1000000'),
  airlineLogoUrl: getOsEnv('VUE_APP_LOGO_AIRLINE_URL') || 'https://airdata-cms.sgp1.cdn.digitaloceanspaces.com/airlines',
  airlineLogoSubUrl: 'https://airdata-cms-dev.sgp1.cdn.digitaloceanspaces.com/airlines',
  urlInvoicePortal: getOsEnv('VUE_APP_INVOICE_PORTAL_URL'),

  checkInVietnamAirlinesUrl: getOsEnv('VUE_APP_CHECK_IN_VIETNAM_AIRLINES_URL') || 'https://booking.vietnamairlines.com/ssci/identification?lang=vi-VN',
  checkInCancellationVietnamAirlinesUrl: getOsEnv('VUE_APP_CHECK_IN_CANCELLATION_VIETNAM_AIRLINES_URL') || 'https://www.vietnamairlines.com/vn/vi/Home?type=managebooking',
  checkInVietjetAirUrl: getOsEnv('VUE_APP_CHECK_IN_VIETJET_AIR_URL') || 'https://www.vietjetair.com/vi/checkin',
  checkInBambooAirwaysUrl: getOsEnv('VUE_APP_CHECK_IN_BAMBOO_AIRWAYS_URL') || 'https://digital.bambooairways.com/ssci/identification?lang=vi',
  checkInVietravelAirlinesUrl: getOsEnv('VUE_APP_CHECK_IN_VIETRAVEL_AIRLINES_URL') || 'https://booking.vietravelairlines.com/vi/checkin',

  // search
  airportSelectColorVNA: getOsEnv('VUE_APP_FUNC_AIRPORT_SELECT_COLOR_VNA'),
  isHideGroupBooking: getOsEnv('VUE_APP_FUNC_HIDE_GROUP_BOOKING'), // ẩn book đoàn
  isBoldTextAppCollapseFlight: getOsEnv('VUE_APP_FUNC_BOLD_TEXT_APP_COLLAPSE_FLIGHT'),
  showArrivalTimeInPriceReport: getOsEnv('VUE_APP_FUNC_SHOW_ARRIVAL_TIME_IN_PRICE_REPORT'),
  highOrderFilters: getOsEnv('VUE_APP_VARIABLE_HIGH_ORDER_FILTERS', 'array'),
  defaultVisibleFilters: getOsEnv('VUE_APP_VARIABLE_DEFAULT_VISIBLE_FILTERS', 'array'),
  isCacheCustomServiceFee: getOsEnv('VUE_APP_FUNC_CACHE_CUSTOM_SERVICE_FEE'), // cache customServiceFee vào sessionStorage (https://discord.com/channels/1054696448110903327/1296041113697648690/1296041118110056480)
  ignoreCacheFlightSearchData: getOsEnv('VUE_APP_FUNC_IGNORE_CACHE_FLIGHT_SEARCH_DATA'), // bỏ cache flight search vào localStorage (https://discord.com/channels/1054696448110903327/1314083546813431888)

  // book
  isCombineFullName: getOsEnv('VUE_APP_FUNC_COMBINE_FULL_NAME'), // Gộp field họ tên
  isDobInputOutsideDetailBox: getOsEnv('VUE_APP_FUNC_DOB_INPUT_OUTSIDE_DETAIL_BOX'), // Cho DOB input ra ngoài block chi tiết
  isShowPaymentCardLast: getOsEnv('VUE_APP_FUNC_SHOW_PAYMENT_CARD_LAST'), // 2. Di chuyển block chi tiết giá xuống phía dưới dịch vụ bổ sung
  isShowYearOfFlightDateInBookingTripDetail: getOsEnv('VUE_APP_FUNC_SHOW_YEAR_FLIGHT_DATE_IN_BOOKING_TRIP_DETAIL'), // 41. Bổ sung năm tại phần thông tin chuyến bay tại màn hình book vé
  isAutoCheckboxInContactsInfo: getOsEnv('VUE_APP_FUNC_AUTO_CHECKBOX_IN_CONTACTS_INFO'), // Mặc định gửi thông tin liên hệ lên hãng nếu nhập (https://discord.com/channels/1054696448110903327/1302884441789693992/1302884448886460479)
  isHideTakePhotoItineraries: getOsEnv('VUE_APP_FUNC_HIDE_TAKE_PHOTO_ITINERARIES') || false, // Chụp ảnh hành trình book

  // modify
  isCancelItineraryTextButton: getOsEnv('VUE_APP_FUNC_CANCEL_ITINERARY_TEXT_BUTTON'), // true: huỷ hành trình/ false: huỷ chuyến bay
  removeSourcesInFindPnrOutSystem: getOsEnv('VUE_APP_VARIABLE_REMOVE_SOURCE_IN_FIND_PNR_OUT_SYSTEM', 'array'), // 20. Bỏ Vietnam Airlines Miền Trung tại phần Tìm PNR ngoài hệ thống
  isSmsModalForTpv: getOsEnv('VUE_APP_FUNC_SMS_BOOKING_FOR_TPV'),
  sourcesCanAutoIssue: isProduction ? ['VJ', 'QH', 'VU'] : ['QH', 'VN1A', 'VJ', 'VU'],
  sourcesCanLowFareTracking: ['VU', 'VN1A', 'VN1A_MT'],
  isHideLowFareTracking: getOsEnv('VUE_APP_FUNC_HIDE_LOW_FARE_TRACKING') || false, // ẩn canh vé rẻ
  canHideFareBooking: getOsEnv('VUE_APP_FUNC_CAN_HIDE_FARE_BOOKING') || false, // Ẩn giá booking F2, F3 (TPV) (https://discord.com/channels/1054696448110903327/1311271037370896396)
  isWarningVariantBadgeHoldBooking: getOsEnv('VUE_APP_FUNC_IS_WARNING_VARIANT_BADGE_HOLD_BOOKING') || false, // Booking Hold badge màu vàng (TPV)
  canBuyBaggageAddOnSourceTH: getOsEnv('VUE_APP_FUNC_CAN_BUY_BAGGAGE_ADD_ON_SOURCE_TH') || false, // Có thể mua hành lý cho TH (TPV) - BGT: không
  isOpenNewTabInBookingList: getOsEnv('VUE_APP_FUNC_BOOKING_LIST_OPEN_NEW_TAB') || false, // Mở tab mới khi ấn vào chi tiết booking ngoài màn list (A công yc)
  isDisableChangeFlightBookingHold: getOsEnv('VUE_APP_FUNC_DISABLE_CHANGE_FLIGHT_HOLD') || false, // Mở đổi chuyến bay với booking GIỮ CHỖ (VN1A)

  // train
  isHideTrain: getOsEnv('VUE_APP_FUNC_HIDE_TRAIN') || false,

  // tour
  isHideTour: getOsEnv('VUE_APP_FUNC_HIDE_TOUR') || false, // ẩn tour

  // retail package
  isHideCreditMethod: getOsEnv('VUE_APP_FUNC_HIDE_CREDIT_METHOD') || false,

  // invoice
  isHideInvoice: getOsEnv('VUE_APP_FUNC_HIDE_INVOICE') || false,

  // other
  isHideResell: getOsEnv('VUE_APP_FUNC_HIDE_RESELL'),
  isHideSepayManager: getOsEnv('VUE_APP_FUNC_HIDE_SEPAY_MANAGER'),
  isInfoPortalTitleReplaceDocuments: getOsEnv('VUE_APP_FUNC_REPLACE_DOCUMENTS_TITLE_BY_INFO_PORTAL'), // 18. Thay đổi tiêu đề : Tài liệu bằng Cổng thông tin
  navBarBackgroundColor: getOsEnv('VUE_APP_VARIABLE_NAVBAR_BACKGROUND_COLOR'),
  isHideTerminal: getOsEnv('VUE_APP_FUNC_HIDE_TERMINAL'),
  isHideSupportRequest: getOsEnv('VUE_APP_FUNC_HIDE_SUPPORT_REQUEST'),
  isOpenIdBookerForSale: getOsEnv('VUE_APP_FUNC_OPEN_ID_BOOKER_FOR_SALE'),
  isLimitF3BalanceAccordingF2Balance: getOsEnv('VUE_APP_FUNC_LIMIT_F3_BALANCE_ACCORDING_F2_BALANCE'), // Giới hạn số dư khi điều chỉnh F3 theo F2, BGT có, TPV không (https://discord.com/channels/1054696448110903327/1302638323461783654/1302879619724218428)
  isHideFlightCalendar: getOsEnv('VUE_APP_FUNC_HIDE_FLIGHT_CALENDAR'),
  isHideMessageBoard: getOsEnv('VUE_APP_FUNC_HIDE_MESSAGE_BOARD'),
  combinationTabName: getOsEnv('VUE_APP_VARIABLE_COMBINATION_TAB_NAME') || '', // Thêm tên source vào tab giá kết hợp
  tourRolesAccess: getOsEnv('VUE_APP_VARIABLE_TOUR_ROLE_ACCESS'), // Role được access tour
}

export default env
