var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-reservation-modify-seat-update",
      "header-bg-variant": "light-info",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "title": _vm.$t('reservation.selectSeat'),
      "body-class": "px-25",
      "footer-class": "p-50 sticky_footer_seat_modal",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "centered": "",
      "size": "xl"
    },
    on: {
      "hide": _vm.handleHideModal,
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": "",
            "size": _vm.isMobileView ? 'sm' : 'md'
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25",
          attrs: {
            "pill": "",
            "size": _vm.isMobileView ? 'sm' : 'md',
            "disabled": !_vm.dataToSelectSeat.some(function (item) {
              return item.hasAddedSeat;
            })
          },
          on: {
            "click": _vm.openSelectPayOptionModalHandle
          }
        }, [_c('span', {
          staticClass: "align-middle px-2"
        }, [_vm._v(_vm._s(_vm.$t('reservation.continue')))])])];
      }
    }])
  }, [_vm.segmentData && _vm.segmentData.segment ? _c('BRow', {
    staticClass: "justify-content-center fw-700 pt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": " + _vm._s(['TR', 'JQ'].includes(_vm.bookingSource) ? _vm.segmentData.segment.flightName : "".concat(_vm.segmentData.segment.departure.iataCode, "-").concat(_vm.segmentData.segment.arrival.iataCode)) + " "), _c('small', {
    staticClass: "text-info font-weight-bolder ml-50"
  }, [_vm._v(" (" + _vm._s(_vm.convertISODateTime(_vm.segmentData.segment.departure.at, _vm.segmentData.segment.departure.timeZone).date) + ") ")])]) : _vm._e(), _vm.seatsData ? _c('BRow', {
    staticClass: "justify-content-around mb-lg-2 mr-1"
  }, [_c('BCol', {
    staticClass: "text-center font-weight-bolder font-medium-3 mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(_vm.seatsData.seatsMap.aircraft) || _vm.seatsData.seatsMap.aircraft) + " ")]), _c('BCol', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "text-info",
    staticStyle: {
      "width": "30px",
      "height": "30px"
    },
    attrs: {
      "src": require("@icons/seat-available.svg")
    }
  }), _c('p', {
    staticClass: "mb-0 mt-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.Available')) + " ")])]), _c('BCol', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "text-secondary",
    staticStyle: {
      "width": "30px",
      "height": "30px"
    },
    attrs: {
      "src": require("@icons/seat-unavailable.svg")
    }
  }), _c('p', {
    staticClass: "mb-0 mt-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.Unavailable')) + " ")])])], 1) : _vm._e(), _c('BRow', {
    staticClass: "mt-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('ColSelectSeat', {
    attrs: {
      "data-to-select-seat": _vm.dataToSelectSeat,
      "get-is-empty-seat-map-data": _vm.getIsEmptySeatMapData,
      "booking-source": _vm.bookingSource,
      "seats-data": _vm.seatsData,
      "segment-data": _vm.segmentData
    },
    on: {
      "handleDeleteSeat": _vm.handleDeleteSeatInTable
    }
  }), _c('BCol', [_c('BAlert', {
    staticClass: "p-1 mx-1",
    attrs: {
      "show": _vm.isEmptySeat,
      "variant": "warning"
    }
  }, [_vm._v(" Không tìm thấy dữ liệu sơ đồ ghế cho hành trình này. ")]), !_vm.isEmpty(_vm.seatsOption) ? _vm._l(_vm.seatsOption, function (seatsOptionItem, seatsOptionIndex) {
    return _c('BRow', {
      key: seatsOptionIndex,
      class: "flex-nowrap mx-0 px-2 px-md-0 py-1 overflow-auto ".concat(_vm.seatsOption.length > 1 ? 'mb-2' : ''),
      staticStyle: {
        "justify-content": "safe center"
      },
      attrs: {
        "no-gutters": ""
      }
    }, _vm._l(seatsOptionItem.dataSeats, function (groupColumnSeat, groupColumnSeatIndex) {
      return _c('div', {
        key: groupColumnSeatIndex,
        staticClass: "d-flex flex-column align-items-center",
        style: ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) && groupColumnSeat.length === 1 ? "width:18px;" : ''
      }, [_vm._v(" " + _vm._s(groupColumnSeat && groupColumnSeat.length >= 1 ? "".concat(groupColumnSeat[0].col !== 'AISLE_LOCATION' ? groupColumnSeat[0].col : '') : '') + " "), _vm._l(groupColumnSeat, function (seat, seatsRowIndex) {
        return _c('div', {
          key: seatsRowIndex,
          staticClass: "text-center",
          style: ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? "width:30px; height:35px" : "height:45px; width:45px;"
        }, [_c('ButtonSeat', {
          attrs: {
            "seat": seat,
            "seat-already-chosen-arr": _vm.seatAlreadyChosenArr,
            "data-to-select-seat": _vm.dataToSelectSeat,
            "source": _vm.bookingSource
          },
          on: {
            "handleClickSeat": _vm.handleSelectSeat
          }
        })], 1);
      })], 2);
    }), 0);
  }) : _vm.getIsEmptySeatMapData === 'loading' ? _c('BRow', {
    staticClass: "justify-content-center align-items-center flex-column height-150 w-100 mx-0"
  }, [_c('BSpinner', {
    attrs: {
      "variant": "info"
    }
  }), _c('i', {
    staticClass: "text-info"
  }, [_vm._v("Đang lấy dữ liệu sơ đồ chỗ ngồi ...")])], 1) : _vm.getIsEmptySeatMapData === 'empty' ? _c('BRow', {
    staticClass: "mx-0 d-flex-center"
  }, [_c('BAlert', {
    staticClass: "py-1 px-2 mt-2",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', [_vm._v(" Không tìm thấy dữ liệu sơ đồ ghế cho hành trình này.")]), _c('p', [_vm._v("Vui lòng kiểm tra hạng vé, tải lại trang hoặc liên hệ với Booker!")])])], 1) : _vm._e()], 2)], 1), _vm.bookingData ? _c('ModalAncillaryPayOptions', {
    attrs: {
      "reservations-data": _vm.bookingData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip,
      "place": _vm.bookingSource
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "submit": _vm.handleConfirmModifySeat
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }