var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticStyle: {
      "background": "#fff"
    },
    attrs: {
      "id": "import-modal",
      "visible": _vm.showImportModalDetail,
      "shadow": "",
      "size": "lg",
      "backdrop": "",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.closeModalContact
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', [_c('span', {
          staticStyle: {
            "font-size": "large",
            "font-weight": "800"
          }
        }, [_vm._v(" " + _vm._s(_vm.mode === 'edit' ? _vm.readonly ? _vm.$t('customer.detailsContact') : _vm.$t('customer.updateContact') : _vm.$t('customer.addContactCustomer')) + " ")])]), _c('b-button', {
          staticStyle: {
            "background": "none !important",
            "border": "none !important"
          },
          on: {
            "click": _vm.closeModalContact
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "black"
          },
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('CardContactDetail', {
          attrs: {
            "dataContactEdit": _vm.dataToEdit,
            "readonlys": _vm.readonly,
            "mode": _vm.mode
          },
          on: {
            "add-contact-data-modal": function addContactDataModal(data) {
              return _vm.handleAddDataContact(data);
            }
          }
        }), _vm.readonlyModalDetail === false ? _c('div', [_c('div', {
          staticClass: "d-flex justify-content-end gap-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.mode === 'edit' ? _vm.toggleReadonly() : _vm.$emit('back');
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RotateCcwIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.mode === 'edit' ? _vm.readonly ? _vm.$t('customer.edit') : _vm.$t('customer.details') : _vm.$t('customer.Back')) + " ")], 1), _c('b-button', {
          attrs: {
            "variant": "warning",
            "disabled": _vm.readonly || invalid
          },
          on: {
            "click": function click($event) {
              return _vm.handleSave();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RefreshCwIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.mode === 'edit' ? _vm.$t('customer.save') : _vm.$t('customer.moreDropdown.add')) + " ")], 1)], 1)]) : _c('div', [_c('div', {
          staticClass: "d-flex justify-content-end gap-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.mode === 'edit' ? _vm.toggleReadonly() : _vm.$emit('back');
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RotateCcwIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.mode === 'edit' ? _vm.readonly ? _vm.$t('customer.edit') : _vm.$t('customer.details') : _vm.$t('customer.Back')) + " ")], 1), _c('b-button', {
          attrs: {
            "disabled": _vm.readonly || invalid,
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.handleSave();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RefreshCwIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.mode === 'edit' ? _vm.$t('customer.save') : _vm.$t('customer.moreDropdown.add')) + " ")], 1)], 1)])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }