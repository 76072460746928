var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-void-ticket-cancel-alert",
      "title": _vm.title || _vm.$t('reservation.voidTicket.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")])];
      }
    }])
  }, [_c('b-alert', {
    staticClass: "p-1 py-50",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.$t('reservation.voidTicket.caution')))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }