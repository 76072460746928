import isEmpty from 'lodash/isEmpty'
import { Ability } from '@casl/ability'

import { getUserData } from '@/api/auth/utils'
import api from '@/api/'
import store from '@/store'
import env from '@/libs/env'

export default {
  namespaced: true,
  state: {
    ability: null,
    grantedScopes: [],
    functionScopes: [],
    // TODO: handle role Mama or Agency for invoice module
    roleMama: getUserData().employeeData?.agency?.id === 1000000,
    roleF2: getUserData().employeeData?.agency?.parentAgency?.id === 1000000,
    roleCTV:
      getUserData().employeeData?.agency?.id !== 1000000
      && getUserData().employeeData?.agency?.parentAgency?.id !== 1000000,
    meData: null,
    // dùng cho mua gói bán lẻ. có cho phép thanh toán QR hay ko
    allowPaymentQR: false,
    // banks
    listBank: [],
  },
  getters: {
    getGrantedScopes: state => state.grantedScopes,
    hasGrantedScopes: state => !isEmpty(state.grantedScopes),
    ability(state) {
      const subjectName = () => 'all'
      return state.ability ? state.ability : new Ability([], { subjectName })
    },
    hasFunctionScopes: state => !isEmpty(state.functionScopes),
    getFunctionScopes: state => state.functionScopes,

    getFunctionScopesbyFunctionName: state => functionName => {
      const funcScope = state.functionScopes.find(
        item => item.functionName === functionName,
      )
      return funcScope
    },
    getRoleMama: state => state.roleMama,
    getRoleF2: state => state.roleF2,
    getRoleCTV: state => state.roleCTV,
    getMeData: state => state.meData,
    getAgencyData: state => state.meData?.agency,
    getAgencyEnableInvoice: state => state.roleMama || state.meData?.agency?.enableInvoice || false,
    getAgencyEnableTour: state => (state.roleMama && (env.tourRolesAccess.includes(state.meData?.type)))
      || (state.roleF2 && ['ADM', 'OPE', 'SE'].includes(state.meData?.type) && state.meData?.agency?.enableTour)
      || false,
    getAgencyEnableTrain: state => state.roleMama || state.meData?.agency?.enableTrain || false,
    getAgencyCompany: state => state.meData?.agency?.company && state.meData?.agency?.company?.active ? state.meData?.agency?.company : null,
    getAgencyDataId: state => state.meData?.agency?.id,
    getMeDataId: state => state.meData?.id,
    getMeDataType: state => state.meData?.type,
    getEmployeeConfig: state => state.meData?.employeeConfig || null,
    getMeEnablePaymentPassword: state => state.meData?.enablePaymentPassword,
    hasMeData: state => !isEmpty(state.meData),
    getServiceFee: state => (isDomestic, airline) => {
      const ticketType = isDomestic ? 'INLAND' : 'INTERNATIONAL'
      return state.meData.serviceFeeConfigs?.find(
        item => item.ticketType === ticketType && item.airline === airline,
      )
    },
    getAgencyFee: state => (isDomestic, airline) => {
      if (state.roleMama) return 0
      const ticketType = isDomestic ? 'INLAND' : 'INTERNATIONAL'
      const agencyFeeConfigFound = state.meData?.agency?.agencyFeeConfig?.find(
        item => item.ticketType === ticketType && item.airline === airline,
      )
      if (state.roleF2 && agencyFeeConfigFound) {
        const agencyFeeByTicketSources = store.getters['globalConfig/getConfig']('agencyFeeByTicketSources')
        const agencyFeeByTicketFound = agencyFeeByTicketSources?.find(item => item.key === agencyFeeConfigFound.airline)
        if (agencyFeeByTicketFound) {
          agencyFeeConfigFound.feeType = 'FLIGHT_ITINERARY'
        }
      }
      return agencyFeeConfigFound
    },
    getHideFee: state => state?.meData?.agency?.hideFee && state?.roleCTV,
    canIssueLimit: state => source => state.meData.agency.issueLimit.find(item => source.includes(item.airlineSource))?.allowance || false,
    canBookingGroup: state => state.roleMama || state.meData.agency?.enableBookingGroup,
    getAllowPaymentQR: state => state.allowPaymentQR,

    // config agency feature
    getEnableQuickName: state => state.roleCTV
      ? state.meData?.agency?.parentAgency?.enableQuickName
      : state.meData?.agency?.enableQuickName,
    getLimitPaxBooking: state => {
      const limit = state.roleCTV
        ? state.meData?.agency?.parentAgency?.limitPaxBooking
        : state.meData?.agency?.limitPaxBooking
      return limit?.enable ? limit?.paxLength : 9
    },
    getEnableRebook: state => state.roleCTV
      ? state.meData?.agency?.parentAgency?.enableRebook
      : state.meData?.agency?.enableRebook,
    getListBank: state => state.listBank || [],
    hasListBank: state => !!(state.listBank?.length),
    getBankById: state => id => { // id hoac code hoac name
      const getBankById = state.listBank.find(item => item.id === id || item.code === id)
      if (getBankById) return getBankById
      const getBankByText = state.listBank.find(item => [item?.name, item?.shortName].filter(Boolean).includes(id))
      return getBankByText
    },
  },
  mutations: {
    SET_GRANTED_SCOPES(state, newGrantedScopes) {
      state.grantedScopes = newGrantedScopes
    },
    SET_ABILITY(state, newAbility) {
      state.ability = newAbility
    },
    CLEAR_ABILITY(state) {
      state.ability = null
      state.grantedScopes = []
    },
    SET_FUNCTION_SCOPES(state, newFunctionScopes) {
      state.functionScopes = newFunctionScopes
    },
    SET_ROLE_MAMA(state, value) {
      state.roleMama = value
    },
    SET_ROLE_F2(state, value) {
      state.roleF2 = value
    },
    SET_ROLE_CTV(state, value) {
      state.roleCTV = value
    },
    SET_ME_DATA(state, value) {
      state.meData = value
    },
    SET_ALLOW_PAYMENT_QR(state, value) {
      state.allowPaymentQR = value
    },
    SET_LIST_BANK(state, value) {
      state.listBank = value
    },
  },
  actions: {
    setGrantedScopes({ commit }, newGrantedScopes) {
      commit('SET_GRANTED_SCOPES', newGrantedScopes)
    },

    fetchGrantedScopes({ dispatch }) {
      const id = getUserData().userData?.id
      if (!id) return null
      return api.role
        .getGrantedScopes(id)
        .then(res => {
          dispatch('setGrantedScopes', res.data.items)
        })
        .catch(error => console.error({ error }))
    },

    setAbility({ commit }, newAbility) {
      commit('SET_ABILITY', newAbility)
    },

    clearAbility({ commit }) {
      commit('CLEAR_ABILITY')
    },

    setFunctionScopes({ commit }, newFunctionScopes) {
      commit('SET_FUNCTION_SCOPES', newFunctionScopes)
    },

    fetchFunctionScopes({ dispatch }) {
      return api.role.getFunctionScopes().then(res => {
        dispatch('setFunctionScopes', res.data.items)
      })
    },

    setRoleMama({ commit }, value) {
      commit('SET_ROLE_MAMA', value)
    },

    setRoleF2({ commit }, value) {
      commit('SET_ROLE_F2', value)
    },

    setRoleCTV({ commit }, value) {
      commit('SET_ROLE_CTV', value)
    },

    setMeData({ commit, dispatch }, value) {
      commit('SET_ME_DATA', value)
      dispatch('setRoleMama', value.agency.id === 1000000)
      dispatch('setRoleF2', value.agency?.parentAgency?.id === 1000000)
      dispatch(
        'setRoleCTV',
        !!value.agency?.parentAgency?.id
          && value.agency?.parentAgency?.id !== 1000000,
      )
      // eslint-disable-next-line no-unused-expressions
      getUserData().storage?.setItem('employeeData', JSON.stringify(value))
    },

    fetchMeData({ dispatch }) {
      return new Promise((resolve, reject) => {
        api.employee
          .getMe()
          .then(res => {
            dispatch('setMeData', res.data)
            resolve(res.data)
          })
          .catch(error => {
            localStorage.clear()
            reject(error)
          })
      })
    },
    setAllowPaymentQR({ commit }, value) {
      commit('SET_ALLOW_PAYMENT_QR', value)
    },

    fetchListBank({ dispatch }) {
      return new Promise((resolve, reject) => {
        api.bankAccount
          .getBanks()
          .then(res => {
            dispatch('setListBank', res.data)
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    setListBank({ commit }, value) {
      commit('SET_LIST_BANK', value)
    },
  },
}
