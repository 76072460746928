export default {
  title: 'Sale report',
  columns: {
    // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
    notePrivate: 'Note private',
    no: 'No.',
    created: 'Created',
    updated: 'Updated',
    airline: 'Airline',
    source: 'Source',
    bookingCode: 'Booking code',
    paxName: 'Pax name',
    paxId: 'Pax ID',
    paxType: 'Pax type',
    ticketNumber: 'Ticket number',
    paymentCode: 'Payment code',
    ticketType: 'Ticket type',
    flightType: 'Flight type',
    trips: 'Trips',
    flightDates: 'Flight Dates',
    bookingClass: 'Booking class',
    fareBasisCode: 'Fare basis code',
    booker: 'Booker',
    issuer: 'Issuer',
    issueDate: 'Issue date',
    bookingDate: 'Book date',
    agency: 'Agency',
    signBook: 'Sign book',
    currency: 'Currency',
    priceTicket: 'Price ticket',
    vat: 'Vat',
    otherTax: 'Other tax',
    tax: 'Collection fee',
    fee: 'Fee',
    feeIn: 'Fee in',
    feeOut: 'Fee out',
    feeService: 'Service fee',
    commissionPrepaid: 'Prepaid commision',
    commissionPrepaidIn: 'Commission Prepaid In',
    commissionPrepaidOut: 'Commission Prepaid Out',
    commission: 'Commission',
    unpaidCommission: 'Unpaid Commision',
    unpaidCommissionIn: 'Unpaid Commision In',
    unpaidCommissionOut: 'Unpaid Commision Out',
    totalPayment: 'Total payment',
    tradeCreditors: 'Accrued debt',
    receivables: 'Accrue credit',
    accountsPayable: 'Accounts payable',
    profit: 'Profit',
    balanceAdjustment: 'Balance Adjustment',
    dob: 'Issue ticket with DOB',

    // for CHOOSE_TICKET_TABLE_COLUMNS
    checkbox: '',
    ticketNo: 'Ticket number',
    invoiceAirline: 'Airline',
    invoiceIssueDate: 'Issue date',
    unitPrice: 'Unit price',
    invoiceVAT: 'VAT',
    invoiceCollectionFee: 'Collection fee',
    invoiceTotalPayment: 'Total payment',
    customer: 'AG/CTV/Customer',
    refundDate: 'Refund date',
    actions: 'Actions',
    // ANCHOR short title
    shortTitle: {
      // for SALE_REPORT_TABLE_COLUMNS & CHOOSE_TICKET_TABLE_COLUMNS
      no: 'No',
      created: 'Created',
      updated: 'Updated',
      airline: 'Airline',
      source: 'Source',
      bookingCode: 'Booking code',
      paxName: 'Pax Name',
      paxId: 'Pax Id',
      paxType: 'Pax Type',
      ticketNumber: 'Ticket No.',
      paymentCode: 'Payment Code',
      ticketType: 'Ticket Type',
      flightType: 'Flight Type',
      trips: 'Trips',
      flightDates: 'Flight Dates',
      bookingClass: 'Booking Class',
      fareBasisCode: 'Fare Basis Code',
      booker: 'Booker',
      issuer: 'Issuer',
      issueDate: 'Issue Date',
      bookingDate: 'Book Date',
      agency: 'Agency',
      signBook: 'Sign book',
      currency: 'Currency',
      priceTicket: 'NET Price',
      vat: 'VAT',
      otherTax: 'Other Tax',
      tax: 'Tax',
      fee: 'Fee',
      feeIn: 'Fee F1',
      feeOut: 'Fee F2',
      feeService: 'Service Fee',
      commissionPrepaid: 'Com Prepaid',
      commissionPrepaidIn: 'Com Prepaid In',
      commissionPrepaidOut: 'Com Prepaid Out',
      commission: 'Com',
      unpaidCommission: 'Unpaid Com',
      unpaidCommissionIn: 'Unpaid Com In',
      unpaidCommissionOut: 'Unpaid Com Out',
      totalPayment: 'Total payment',
      tradeCreditors: 'Trade Creditors',
      receivables: 'Receivables',
      balanceAdjustment: 'Balance Adjustment',
      accountsPayable: 'Debt',
      profit: 'Profit',

      // for CHOOSE_TICKET_TABLE_COLUMNS
      checkbox: '',
      ticketNo: 'Ticket No',
      invoiceAirline: 'Airline',
      invoiceIssueDate: 'Issue date',
      unitPrice: 'Unit price',
      invoiceVAT: 'Vat',
      invoiceCollectionFee: 'Collection Fee',
      invoiceTotalPayment: 'Total Payment',
      customer: 'AG/Customer',
      refundDate: 'Refund date',
      actions: 'Actions',
      dob: 'DOB',
    },
  },
  domestic: 'Domestic',
  inbound: 'International',
  domesticAndInbound: 'Domestic and International',
  Domestic: 'Domestic',
  Inbound: 'International',
  DomesticAndInbound: 'Domestic and International',
  attachmentStatus: 'Status for attachment in invoice',
  attachedInInvoice: 'Being attached in invoice',
  notAttachedInInvoice: 'Not attached in invoice yet',

  // 'ph'acronym of 'placeholder'
  phAgencyCode: 'Please select an agency',
  phBookingCode: 'Please enter a booking code',
  phTicketNumber: 'Please enter a ticket number',
  phSearch: 'Search text ...',
  phAirline: 'Enter airline',
  clearDobFilter: 'Clear DOB filter',

  createManually: {
    title: 'Create sale report manually',
    subTitle: 'Enter sale report information',

    // field
    no: 'No.',
    agency: 'Agency',
    customer: 'Customer',
    ticketNumber: 'Ticket number',
    airline: 'Airline',
    source: 'Source',
    bookingCode: 'Booking code',
    paxName: 'Pax name',
    paxType: 'Pax type',
    paxId: 'Pax ID',
    bookingDate: 'Booking date',
    issueDate: 'Issue date',
    signBook: 'Sign book',
    signIss: 'Sign Issuer',
    booker: 'Booker',
    issuer: 'Issuer',
    ticketType: 'Ticket type',
    currency: 'Currency',
    priceTicket: 'Price ticket',
    tax: 'Tax',
    otherTax: 'Other tax',
    fee: 'Fee',
    collectionFee: 'Collection fee',
    feeService: 'Fee Service',
    commissionPrepaid: 'Commission Prepaid',
    unpaidCommission: 'Unpaid Commission',
    fFlyer: 'FFlyer Number',
    trips: 'Trips',
    bookingClass: 'Booking Class',
    fareBasisCode: 'Fare basis code',
    flightType: 'Flight type',
    flownFare: 'Flown fare',
    flownDate: 'Flown date',
    flightDates: 'Flight dates',
    commission: 'Commision',
    originalTk: 'Original ticket',
    ticketSubType: 'Ticket sub type',
    placeholder: {
      agency: 'Select agency',
      customer: 'Select customer',
      ticketNumber: 'Enter ticket number',
      airline: 'Enter airline',
      source: 'Enter source',
      bookingCode: 'Enter booking code',
      paxName: 'Enter pax name',
      paxType: 'Select pax type',
      paxId: 'Enter pax ID',
      bookingDate: 'Enter Booking date',
      issueDate: 'Enter Issue date',
      signBook: 'Enter Sign book',
      signIss: 'Enter Sign Issuer',
      booker: 'Enter Booker',
      issuer: 'Enter Issuer',
      ticketType: 'Enter ticket type',
      currency: 'Enter Currency',
      priceTicket: 'Enter Price ticket',
      tax: 'Enter Tax',
      otherTax: 'Enter other tax',
      fee: 'Enter Fee',
      collectionFee: 'Enter Collection fee',
      feeService: 'Enter Fee Service',
      commissionPrepaid: 'Enter Commission Prepaid',
      unpaidCommission: 'Enter Unpaid Commission',
      fFlyer: 'Enter FFlyer Number',
      trips: 'Enter trips',
      bookingClass: 'Enter booking class',
      fareBasisCode: 'Enter Fare basis code',
      flightType: 'Select flight type',
      flownFare: 'Enter Flown fare',
      flownDate: 'Enter Flown date',
      flightDates: 'Enter Flight dates',
      commission: 'Enter Commision',
      originalTk: 'Enter original ticket',
      ticketSubType: 'Enter ticket sub type',
    },
  },
  detail: {
    title: 'Detail sale report',
    updateTitle: 'Update sale report',
    update: 'Update',
  },
  confirmDelete: 'Are you sure want to delete this sale report?',
  flightDate: 'Flight date',

  f2: {
    title: 'Sale report F2',
  },

  sum: 'Sum',

  RECEIPTS_NOTE: 'Receipts note',
  SPENDING_NOTE: 'Spending note',
  TOPUP: 'Topup',
  TKT: 'TKT',
  EMD: 'EMD',
  RFD: 'RFD',
  VOID: 'VOID',
  INSURANCE: 'Insurance',
  PACKAGE_ORDER: 'Retail package',
  TOUR_REFUND: 'Tour refund',
  TOUR_DEPOSIT: 'Tour deposit',
  TOUR_PAYMENT: 'Tour payment',
}
