<template>
  <div id="message-board">
    <IAmOverlay :loading="showLoading">
      <b-tabs
        v-model="tabIndex"
        @input="updateHash"
      >
        <template #tabs-end>
          <div
            v-if="tabIndex === 0"
            class="d-flex flex-1 justify-content-end gap-2"
          >
            <!-- FIXME responsive -->
            <span class="d-flex-center text-warning">{{ $t(`messBoard.sort.${sortOrder}`) }}</span>
            <b-button
              pill
              class="p-50"
              variant="flat-warning"
              @click="sortMessages"
            >
              <IAmIcon
                icon="sortOutline"
                size="22"
              />
            </b-button>
            <HStack
              center
              class="flex-wrap"
            >
              <b-button
                v-for="(filterValue, filterValueIndex) in filterValues"
                :key="filterValueIndex"
                pill
                size="sm"
                :variant="selectedFilter === filterValue.value ? 'warning' : 'outline-dark'"
                class="min-width-120"
                @click="selectedFilter = filterValue.value"
              >
                {{ $t(`messBoard.priorityPin.${filterValue.label}`) }}
              </b-button>
            </HStack>
          </div>
          <div
            v-else
            class="d-flex-center justify-content-end gap-1 flex-1"
          >
            <b-badge
              v-if="unReadCount"
              pill
              variant="light-warning"
              class="my-25 h-100 pl-75 mr-1 d-flex-center text-airline-warning"
            >
              <span class="text-airline-warning">
                {{ unReadCount }} {{ $t('messBoard.unRead') }}
              </span>
            </b-badge>
            <b-button
              rounded
              class="px-1 text-nowrap"
              pill
              variant="warning"
              :disabled="!unReadCount || showLoading"
              @click="handleReadAllNoti"
            >
              {{ $t('messBoard.readAllMessages') }}
            </b-button>
            <b-form-input
              v-model="searchContentNoti"
              style="min-width: 400px !important;"
              type="search"
              :placeholder="$t('messBoard.searchNameNoti')"
              :reduce="val => val.value"
              class=" fit-content"
              debounce="300"
              trim
            />
          </div>
        </template>
        <!-- Tab content sections -->
        <b-tab :class="['tab-pane', { 'active': $route.hash === '#note' }]">
          <template #title>
            <div class="d-flex-center gap-1">
              <IAmIcon
                icon="speaker"
                size="18"
              />
              <span class="fw-700">
                {{ $t('messBoard.note') }}
              </span>
              <div class="position-relative">
                <span
                  class="position-absolute font-small-3"
                  style="top: -4px"
                >
                  ({{ boardsMessage.length }})
                </span>
              </div>
            </div>
          </template>
          <TabsNoteMessage :messageBoard="filterMessage" />
        </b-tab>
        <b-tab
          lazy
          :class="['tab-pane', { 'active': $route.hash === '#notification' }]"
        >
          <template #title>
            <div class="d-flex-center gap-1">
              <IAmIcon
                icon="bellOutline"
                size="18"
              />
              <span class="fw-700">
                {{ $t('notification.title') }}
              </span>

              <div
                v-if="unReadCount"
                class="position-relative"
              >
                <span
                  class="position-absolute font-small-3"
                  style="top: -4px"
                >
                  ({{ unReadCount }})
                </span>
              </div>
            </div>
          </template>
          <TabsNotificationMessage
            :notiMessageBoard="filterContentNoti"
            @fetch-noti="handleFetchNotication"
          />
        </b-tab>
      </b-tabs>
    </IAmOverlay>
  </div>
</template>
<script>
import {
  BTabs, BTab, BButton, BFormInput, BBadge,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'

import router from '@/router'
import { apiNoti } from '@/api/'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { getUserData } from '@/api/auth/utils'
import IAmOverlay from '@/components/IAmOverlay.vue'

import useMessBoardHandle from './useMessBoardHandle'
import TabsNoteMessage from './TabsNoteMessage.vue'
import TabsNotificationMessage from './TabsNotificationMessage.vue'

export default {
  components: {
    BFormInput,
    TabsNoteMessage,
    BButton,
    BTabs,
    BTab,
    TabsNotificationMessage,
    BBadge,
    IAmOverlay,
  },
  setup() {
    const isChecked = ref(false)
    const showLoading = ref(false)
    const { route } = useRouter()
    const selectedFilter = ref('ALL')
    const filterValues = ref([
      {
        label: 'ALL', value: 'ALL',
      },
      {
        label: 'HIGH', value: 'HIGH',
      },
      {
        label: 'MEDIUM', value: 'MEDIUM',
      },
      {
        label: 'LOW', value: 'LOW',
      },
    ])

    const unReadCount = ref(0)
    const searchContentNoti = ref('')
    const notiLength = ref(0)
    const tabIndex = ref(0)
    const notifications = ref([])
    const boardsMessage = ref([])
    const sortOrder = ref('original')
    const originalBoards = ref([])

    const handleFetchNotication = messNotifications => {
      notifications.value = messNotifications
    }

    const sortMessages = () => {
      const priorityMap = { HIGH: 3, MEDIUM: 2, LOW: 1 }
      if (sortOrder.value === 'original') {
        sortOrder.value = 'asc'
        boardsMessage.value.sort((a, b) => (priorityMap[a.priorityPin] || 0) - (priorityMap[b.priorityPin] || 0))
      } else if (sortOrder.value === 'asc') {
        sortOrder.value = 'desc'
        boardsMessage.value.sort((a, b) => (priorityMap[b.priorityPin] || 0) - (priorityMap[a.priorityPin] || 0))
      } else {
        sortOrder.value = 'original'
        boardsMessage.value = [...originalBoards.value]
      }
    }

    const getUnRead = async () => {
      try {
        const res = await apiNoti.unReadCountNoti({
          recipientRefType: 'EMPLOYEE',
          recipientRefId: getUserData().employeeData.id,
        })
        unReadCount.value = res.data.count
      } catch (error) {
        console.error('Error fetching unread notifications:', error)
      }
    }
    const filterMessage = computed(() => {
      if (selectedFilter.value === 'ALL') {
        return boardsMessage.value
      }
      return boardsMessage.value.filter(message => message.priorityPin === selectedFilter.value)
    })
    const readAllNoti = async () => {
      try {
        const res = await store.dispatch('app-notification/readAllNoti', {
          recipientRefType: 'EMPLOYEE',
          recipientRefId: getUserData().employeeData.id.toString(),
        })

        if (res === 0) {
          notifications.value = notifications.value.map(noti => ({
            ...noti,
            readStatus: true,
          }))
        } else if (res && Array.isArray(res.data)) {
          notifications.value = res.data.map(item => ({
            ...item,
            readStatus: true,
          }))
        }
      } catch (error) {
        console.error('Lỗi khi lấy thông báo:', error)
      }
    }
    const {
      getAllPins,
    } = useMessBoardHandle()
    const handleReadAllNoti = () => {
      showLoading.value = true

      readAllNoti().finally(() => {
        showLoading.value = false
      })
    }
    const resetTabToFirst = () => {
      if (tabIndex.value !== 0) {
        tabIndex.value = 0
      }
    }

    const updateHash = index => {
      const hash = index === 1 ? '#notification' : '#note'
      router.push({ hash })
    }
    onMounted(async () => {
      showLoading.value = true
      updateHash()
      getUnRead()
      const resPins = await getAllPins()
      const list = resPins.items.map(item => ({
        ...item,
        isRead: false,
      }))
      boardsMessage.value = list
      originalBoards.value = list
      showLoading.value = false
    })
    watch(() => route.value.params.id || route.hash, newId => {
      showLoading.value = true
      resetTabToFirst()
      if (!newId) return
      const targetElement = document.getElementById(`message-${newId}`)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      setTimeout(() => {
        showLoading.value = false
        window.scrollBy(0, -50)
      }, 500)
    }, { immediate: true })

    const filterContentNoti = computed(() => searchContentNoti.value.trim()
      ? notifications.value.filter(noti => noti.subject?.toLowerCase().includes(searchContentNoti.value?.toLowerCase()) || noti.content?.toLowerCase().includes(searchContentNoti.value?.toLowerCase()))
      : notifications.value)

    return {
      notiLength,
      handleReadAllNoti,
      isChecked,
      filterContentNoti,
      searchContentNoti,
      tabIndex,
      handleFetchNotication,
      notifications,
      showLoading,
      filterValues,
      selectedFilter,
      unReadCount,
      filterMessage,
      sortMessages,
      sortOrder,
      boardsMessage,
      getUnRead,
      resetTabToFirst,
      updateHash,
    }
  },
}
</script>

<style lang="scss" scoped>
  #message-board ::v-deep {
    .nav-tabs .nav-link {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
</style>
