var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex gap-2"
  }, [_c('span', {
    staticClass: "bg-grey px-1 rounded-pill gap-2 d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "28px",
      "height": "28px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickMonthButtonHandle('prev');
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronLeftOutline",
      "size": "18"
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "100px"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    attrs: {
      "id": "selectMonth",
      "options": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      "reduce": function reduce(data) {
        return data;
      },
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-center mx-auto"
        }, [_vm._v(" " + _vm._s(_vm.getMonthText(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.getMonthText(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.month,
      callback: function callback($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "28px",
      "height": "28px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickMonthButtonHandle('next');
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronRightOutline",
      "size": "18"
    }
  })], 1)], 1), _c('span', {
    staticClass: "bg-grey px-1 rounded-pill gap-2 d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "28px",
      "height": "28px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": false
    },
    on: {
      "click": function click($event) {
        _vm.year--;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronLeftOutline",
      "size": "18"
    }
  })], 1), _c('v-select', {
    staticStyle: {
      "min-width": "70px"
    },
    attrs: {
      "id": "selectYear",
      "options": _vm.yearOptions,
      "reduce": function reduce(data) {
        return data;
      },
      "getOptionLabel": function getOptionLabel(data) {
        return data;
      },
      "no-options": _vm.$t('noOptions'),
      "clearable": false
    },
    model: {
      value: _vm.year,
      callback: function callback($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  }), _c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "28px",
      "height": "28px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": false
    },
    on: {
      "click": function click($event) {
        _vm.year++;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronRightOutline",
      "size": "18"
    }
  })], 1)], 1)]), _c('FullCalendar', {
    ref: "refCalendar",
    attrs: {
      "id": "refCalendar",
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function fn(arg) {
        return [_c('Stack', [arg.event.extendedProps.flights.length > 0 ? _c('div', {
          staticClass: "d-flex p-0"
        }, [_c('HStack', {
          staticClass: "text-danger bg-light-danger w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25",
          staticStyle: {
            "overflow-x": "hidden"
          }
        }, [_c('div', {
          staticClass: "rounded-circle d-flex-center bg-danger",
          staticStyle: {
            "width": "25px",
            "height": "25px"
          }
        }, [_c('strong', {
          staticClass: "text-white"
        }, [_vm._v(" " + _vm._s(arg.event.extendedProps.flights.length) + " ")])]), _c('IAmIcon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "sentOutline",
            "size": "18"
          }
        }), _c('div', {
          staticClass: "d-none d-xl-block"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flight')) + " ")])], 1)], 1) : _vm._e(), arg.event.extendedProps.trains.length > 0 ? _c('div', {
          staticClass: "d-flex p-0"
        }, [_c('HStack', {
          staticClass: "text-success bg-light-success w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25",
          staticStyle: {
            "overflow-x": "hidden"
          }
        }, [_c('div', {
          staticClass: "rounded-circle d-flex-center bg-success",
          staticStyle: {
            "width": "25px",
            "height": "25px"
          }
        }, [_c('strong', {
          staticClass: "text-white"
        }, [_vm._v(" " + _vm._s(arg.event.extendedProps.trains.length) + " ")])]), _c('IAmIcon', {
          staticClass: "text-success",
          attrs: {
            "icon": "trainIcon",
            "size": "18"
          }
        }), _c('div', {
          staticClass: "d-none d-xl-block"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.title')) + " ")])], 1)], 1) : _vm._e(), arg.event.extendedProps.tours.length > 0 ? _c('div', {
          staticClass: "d-flex p-0"
        }, [_c('HStack', {
          staticClass: "text-info bg-light-info w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25",
          staticStyle: {
            "overflow-x": "hidden"
          }
        }, [_c('div', {
          staticClass: "rounded-circle d-flex-center bg-info",
          staticStyle: {
            "width": "25px",
            "height": "25px"
          }
        }, [_c('strong', {
          staticClass: "text-white"
        }, [_vm._v(" " + _vm._s(arg.event.extendedProps.tours.length) + " ")])]), _c('IAmIcon', {
          staticClass: "text-info",
          attrs: {
            "icon": "tour",
            "size": "18"
          }
        }), _c('div', {
          staticClass: "d-none d-xl-block"
        }, [_vm._v(" Tour ")])], 1)], 1) : _vm._e()])];
      }
    }])
  })], 1), _vm.itemsByFilter.length > 0 ? _c('b-card', _vm._l(_vm.itemsByFilter, function (item) {
    var _item$trains, _item$tours;
    return _c('div', {
      key: item.date
    }, [_c('div', {
      staticClass: "font-weight-bolder",
      staticStyle: {
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.date, _vm.getCurrentTimezone(), _vm.locale).fullDateStr) + " ")]), _c('div', {
      staticClass: "text-danger mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.noteCalendar')) + " ")]), item.flights.length ? _vm._l(item.flights, function (flight, index) {
      return _c('b-link', {
        key: "flight-".concat(index),
        staticClass: "d-flex flex-wrap cursor-pointer flight",
        on: {
          "click": function click($event) {
            return _vm.openBookingDetail('apps-reservations-modify', flight.id);
          }
        }
      }, _vm._l(flight.itineraries, function (itinerary, itiIndex) {
        var _vm$first, _vm$last, _vm$last$arrival;
        return _c('div', {
          key: "itinerary-".concat(itiIndex),
          staticClass: "d-flex cursor-pointer flight p-0"
        }, [_c('span', {
          staticClass: "mr-1 pr-1 font-weight-bolder border-right-danger border-2 text-danger"
        }, [_vm._v(_vm._s(_vm.convertISODateTime((_vm$first = _vm.first(itinerary)) === null || _vm$first === void 0 ? void 0 : _vm$first.departure.at).time))]), _c('div', {
          staticClass: "d-flex gap-1 flex-column"
        }, [_c('div', {
          staticClass: "text-danger font-weight-bolder d-flex gap-1 align-items-center flex-wrap"
        }, [_c('div', {
          staticClass: "bg-danger rounded-circle d-flex-center",
          staticStyle: {
            "width": "28px",
            "height": "28px"
          }
        }, [_c('IAmIcon', {
          staticClass: "text-white",
          attrs: {
            "icon": "sentOutline",
            "size": "18"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t('reservation.flightTo')) + " " + _vm._s((_vm$last = _vm.last(itinerary)) === null || _vm$last === void 0 ? void 0 : (_vm$last$arrival = _vm$last.arrival) === null || _vm$last$arrival === void 0 ? void 0 : _vm$last$arrival.city) + " "), _vm._l(itinerary, function (ff, ind) {
          return _c('span', {
            key: "flight-number-".concat(ind)
          }, [_vm._v(" (" + _vm._s(ff.airline) + _vm._s(ff.flightNumber) + ") ")]);
        })], 2), _vm._l(itinerary, function (cc, ind) {
          return _c('div', {
            key: "info-".concat(ind),
            staticClass: "d-flex flex-column flex-md-row gap-2 text-dark"
          }, [_c('div', {
            staticClass: "d-flex text-dark gap-2"
          }, [_c('strong', [_vm._v(_vm._s(flight.bookingCode))]), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(cc.departure.at, cc.departure.timeZone).time) + "-" + _vm._s(_vm.convertISODateTime(cc.arrival.at, cc.arrival.timeZone).time) + " " + _vm._s(_vm.convertISODateTime(cc.departure.at, cc.departure.timeZone).dayMonth))]), _c('span', [_vm._v(_vm._s("".concat(cc.departure.iataCode).concat(cc.arrival.iataCode)))])]), _c('span', [_vm._v(_vm._s("".concat(flight.paxLists[0].lastName, " ").concat(flight.paxLists[0].firstName)) + " " + _vm._s("".concat(flight.paxLists.length > 1 ? "+".concat(flight.paxLists.length - 1) : ' ')))])]);
        })], 2)]);
      }), 0);
    }) : _vm._e(), item !== null && item !== void 0 && (_item$trains = item.trains) !== null && _item$trains !== void 0 && _item$trains.length ? _vm._l(item.trains, function (train, trainIndex) {
      var _train$listTrip, _train$listTrip2, _train$listTrip3, _train$listTrip4, _train$listTrip5, _train$listTrip6, _train$listTrip7, _train$listTrip8, _train$listTrip9, _train$listPassenger, _train$listPassenger2, _train$listPassenger3;
      return _c('b-link', {
        key: "train-".concat(trainIndex),
        staticClass: "d-flex flex-wrap cursor-pointer flight",
        on: {
          "click": function click($event) {
            return _vm.openBookingDetail('apps-train-ticketDetail', train.id);
          }
        }
      }, [_c('span', {
        staticClass: "mr-1 pr-1 font-weight-bolder border-right-success border-2 text-success"
      }, [_vm._v(_vm._s((_train$listTrip = train.listTrip) === null || _train$listTrip === void 0 ? void 0 : _train$listTrip[0].departureHour))]), _c('div', {
        staticClass: "d-flex gap-1 flex-column"
      }, [_c('div', {
        staticClass: "text-success font-weight-bolder d-flex gap-1 align-items-center flex-wrap"
      }, [_c('div', {
        staticClass: "bg-success rounded-circle d-flex-center",
        staticStyle: {
          "width": "28px",
          "height": "28px"
        }
      }, [_c('IAmIcon', {
        staticClass: "text-white",
        attrs: {
          "icon": "trainIcon",
          "size": "18"
        }
      })], 1), _vm._v(" " + _vm._s(_vm.$t('train.trainTo')) + " " + _vm._s((_train$listTrip2 = train.listTrip) === null || _train$listTrip2 === void 0 ? void 0 : _train$listTrip2[0].arrivalName) + " (" + _vm._s((_train$listTrip3 = train.listTrip) === null || _train$listTrip3 === void 0 ? void 0 : _train$listTrip3[0].trainCode) + ") ")]), _c('div', {
        staticClass: "d-flex flex-column flex-md-row gap-2 text-dark"
      }, [_c('div', {
        staticClass: "d-flex text-dark gap-2"
      }, [_c('strong', [_vm._v(_vm._s(train.bookingCode))]), _c('HStack', [_vm._v(" " + _vm._s((_train$listTrip4 = train.listTrip) === null || _train$listTrip4 === void 0 ? void 0 : _train$listTrip4[0].departureHour) + " (" + _vm._s(_vm.convertISODateTime((_train$listTrip5 = train.listTrip) === null || _train$listTrip5 === void 0 ? void 0 : _train$listTrip5[0].departureTime).dayAndMonth) + ") - " + _vm._s((_train$listTrip6 = train.listTrip) === null || _train$listTrip6 === void 0 ? void 0 : _train$listTrip6[0].arrivalHour) + " (" + _vm._s(_vm.convertISODateTime((_train$listTrip7 = train.listTrip) === null || _train$listTrip7 === void 0 ? void 0 : _train$listTrip7[0].arrivalTime).dayAndMonth) + ") ")]), _c('span', [_vm._v(_vm._s("".concat((_train$listTrip8 = train.listTrip) === null || _train$listTrip8 === void 0 ? void 0 : _train$listTrip8[0].departure).concat((_train$listTrip9 = train.listTrip) === null || _train$listTrip9 === void 0 ? void 0 : _train$listTrip9[0].arrival)))])], 1), _c('span', [_vm._v(_vm._s("".concat((_train$listPassenger = train.listPassenger) === null || _train$listPassenger === void 0 ? void 0 : _train$listPassenger[0].fullName)) + " " + _vm._s("".concat(((_train$listPassenger2 = train.listPassenger) === null || _train$listPassenger2 === void 0 ? void 0 : _train$listPassenger2.length) > 1 ? "+".concat(((_train$listPassenger3 = train.listPassenger) === null || _train$listPassenger3 === void 0 ? void 0 : _train$listPassenger3.length) - 1) : ' ')))])])])]);
    }) : _vm._e(), item !== null && item !== void 0 && (_item$tours = item.tours) !== null && _item$tours !== void 0 && _item$tours.length ? _vm._l(item.tours, function (tour, tourIndex) {
      var _tour$departureHour, _tour$tour, _tour$tour2, _tour$tour3;
      return _c('b-link', {
        key: "tour-".concat(tourIndex),
        staticClass: "d-flex flex-wrap cursor-pointer flight",
        on: {
          "click": function click($event) {
            return _vm.openBookingDetail('apps-toursBooking-detail', tour.bookingCode);
          }
        }
      }, [_c('span', {
        staticClass: "mr-1 pr-1 font-weight-bolder border-right-info border-2 text-info"
      }, [_vm._v(_vm._s((_tour$departureHour = tour === null || tour === void 0 ? void 0 : tour.departureHour) !== null && _tour$departureHour !== void 0 ? _tour$departureHour : '-'))]), _c('div', {
        staticClass: "d-flex gap-1 flex-column"
      }, [_c('div', {
        staticClass: "text-info font-weight-bolder d-flex gap-1 align-items-center flex-wrap"
      }, [_c('div', {
        staticClass: "bg-info rounded-circle d-flex-center",
        staticStyle: {
          "width": "28px",
          "height": "28px"
        }
      }, [_c('IAmIcon', {
        staticClass: "text-white",
        attrs: {
          "icon": "tour",
          "size": "18"
        }
      })], 1), _vm._v(" " + _vm._s(_vm.$t('Tour List')) + " " + _vm._s(tour === null || tour === void 0 ? void 0 : (_tour$tour = tour.tour) === null || _tour$tour === void 0 ? void 0 : _tour$tour.tourName) + " ")]), _c('HStack', {
        staticClass: "text-dark gap-2"
      }, [_c('strong', [_vm._v(_vm._s(tour.bookingCode))]), _c('span', [_vm._v(_vm._s(tour === null || tour === void 0 ? void 0 : (_tour$tour2 = tour.tour) === null || _tour$tour2 === void 0 ? void 0 : _tour$tour2.tourTotalDay) + "N" + _vm._s(tour === null || tour === void 0 ? void 0 : (_tour$tour3 = tour.tour) === null || _tour$tour3 === void 0 ? void 0 : _tour$tour3.tourTotalNight) + "Đ ")]), tour !== null && tour !== void 0 && tour.numberAdults ? _c('span', [_c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(tour === null || tour === void 0 ? void 0 : tour.numberAdults) + " ")]), _vm._v(" "), _c('span', {
        staticClass: "font-italic"
      }, [_vm._v(" Người lớn ")])]) : _vm._e(), tour !== null && tour !== void 0 && tour.numberChildren1 ? _c('span', [_c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(tour === null || tour === void 0 ? void 0 : tour.numberChildren1) + " ")]), _vm._v(" "), _c('span', {
        staticClass: "font-italic"
      }, [_vm._v(" Trẻ em 1 ")])]) : _vm._e(), tour !== null && tour !== void 0 && tour.numberChildren2 ? _c('span', [_c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(tour === null || tour === void 0 ? void 0 : tour.numberChildren2) + " ")]), _vm._v(" "), _c('span', {
        staticClass: "font-italic"
      }, [_vm._v(" Trẻ em 2 ")])]) : _vm._e(), tour !== null && tour !== void 0 && tour.numberInfant ? _c('span', [_c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(tour === null || tour === void 0 ? void 0 : tour.numberInfant) + " ")]), _vm._v(" "), _c('span', {
        staticClass: "font-italic"
      }, [_vm._v(" Em bé ")])]) : _vm._e()])], 1)]);
    }) : _vm._e()], 2);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }