<template lang="">
  <BCard
    class="border-hover-success mb-0"
    body-class="p-0"
    style="border-radius: 16px; box-shadow: 0px 2px 4px -2px #0000000D; box-shadow: 4px 4px 8px -1px #18274B14;"
  >
    <BRow :class="`px-1 py-50 ${isMobileView ? 'gap-2' : ''}`">
      <BCol
        cols="12"
        md="4"
        class="d-flex-center"
      >
        <BImgLazy
          :src="`${tourData?.image?.path || tourData?.block?.image?.path || backgroundTourUrl}`"
          rounded
          fluid-grow
        />
      </BCol>
      <BCol
        cols="12"
        md="8"
      >
        <Stack>
          <div :class="`fw-700 fit ${isMobileView ? 'text-heading-5' : 'text-heading-3'} text-truncate`">
            {{ tourData?.tourName }}
          </div>
          <div :class="`d-flex-between ${isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row'}`">
            <HStack v-if="tourData?.tourCode">
              <IAmIcon
                icon="ticketLine"
                size="20"
                color="green"
              />
              Mã chương trình:
              <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ tourData?.tourCode }}</span>
            </HStack>
            <HStack
              v-if="tourData?.tourCode"
              class="gap-1"
            >
              <IAmIcon
                icon="locationOutline"
                size="20"
                color="green"
              />
              Khởi hành:
              <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ defaultDepartPoint }}</span>
            </HStack>
          </div>

          <HStack v-if="tourData?.levelNam">
            <IAmIcon
              icon="hotelLine"
              size="20"
              color="green"
            />
            Khách sạn:
            <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ tourData?.levelNam }}</span>
          </HStack>

          <div :class="`d-flex-between ${isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row'}`">
            <HStack>
              <IAmIcon
                icon="clockOutline"
                size="20"
                color="green"
              />
              Thời gian:
              <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ tourData?.totalDay }}N{{ tourData?.totalNight }}D</span>
            </HStack>
            <HStack class="gap-1">
              <IAmIcon
                icon="plane"
                size="20"
                color="green"
              />
              Phương tiện:
              <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ $t(`tour.vehiclesType.${tourData?.vehiclesGo}`) }}
                <span v-if="tourData?.vehiclesBack !== tourData?.vehiclesGo"> - {{ $t(`tour.vehiclesType.${tourData?.vehiclesBack}`) }}</span>
              </span>
            </HStack>
          </div>

          <div :class="`d-flex-between ${isMobileView ? 'flex-column align-items-start gap-1' : 'flex-row'}`">
            <HStack>
              <IAmIcon
                icon="softSeatOutline"
                size="20"
                color="green"
              />
              Số chỗ:
              <span :class="`${tourData?.numberTicketsAvailable ? 'text-info' : 'text-danger'} fw-700`">{{ tourData?.numberTicketsAvailable || 'Hết chỗ' }}</span>
            </HStack>
            <HStack v-if="tourData?.deadlineVisa">
              <IAmIcon
                icon="clockOutline"
                size="20"
                color="green"
              />
              Hạn nộp hồ sơ:
              <span :class="`text-info fw-800 ${isMobileView ? 'text-14px' : 'text-16px'}`">{{ tourData?.deadlineVisa }}</span>
            </HStack>
          </div>
        </Stack>

        <div class="top-border" />
        <Stack
          gap="1"
          class="fit-content"
        >
          <div
            v-for="(itinerary, itineraryIndex) in tourData?.itineraries"
            :key="itineraryIndex"
          >
            <TourShortenFlightTrip
              v-for="(segment, segmentIndex) in itinerary"
              :key="segmentIndex"
              :segment="segment"
            />
          </div>
        </Stack>

        <div class="d-flex align-items-end justify-content-between">
          <div class="flex-1">
            <div class="d-flex gap-2">
              Giá chỉ từ
              <div v-if="tourData?.adultsPriceSales < tourData?.adultsPrice">
                <del class="fw-700">{{ formatCurrency(tourData?.adultsPrice) }} VND</del>
              </div>
            </div>
            <div :class="`text-danger fw-900 ${isMobileView ? 'text-16px' : 'text-24px'}`">
              {{ formatCurrency(tourData?.adultsPriceSales) }} VND
            </div>
          </div>

          <div class="flex-1 d-flex align-items-end justify-content-between flex-row-reverse">
            <BButton
              variant="success"
              :to="{ name: 'apps-toursDetail', params: { id: tourData?.priceId } }"
              :class="`fw-700 ${isMobileView ? 'text-12px px-50' : 'text-16px'}`"
            >
              Xem chi tiết
            </BButton>

            <div v-if="isShowCommission">
              <div>
                Hoa hồng
              </div>
              <div class="text-success fw-700">
                {{ tourData?.adultsAgencyPromotion ? `${formatCurrency(tourData?.adultsAgencyPromotion ?? 0)} VND` : 'Liên hệ' }}
              </div>
            </div>
          </div>
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
import {
  BButton, BCard, BCol, BImgLazy, BRow,
} from 'bootstrap-vue'

import { formatCurrency } from '@/@core/utils/filter'
import env from '@/libs/env'

import { defaultDepartPoint } from '../useTourHandle'

export default {
  components: {
    BCard,
    BImgLazy,
    BRow,
    BCol,
    BButton,
    TourShortenFlightTrip: () => import('../components/TourShortenFlightTrip.vue'),
  },
  props: {
    tourData: {
      type: Object,
      default: null,
    },
    isShowCommission: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      formatCurrency,
      defaultDepartPoint,
      backgroundTourUrl: env.backgroundTourUrl,
    }
  },
}
</script>
<style lang="scss" scoped>
.top-border {
  margin: 2px auto;
  border-top: 2px solid #e6e6e6;
}
.border-hover-success {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid #28A745;
  }
}

</style>
