<template>
  <b-modal
    id="import-modal"
    :visible="showImportModalDetail"
    shadow
    style="background: #fff;"
    size="lg"
    backdrop
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    @hide="closeModalContact"
  >
    <template #modal-header>
      <div>
        <span style="font-size: large; font-weight: 800;">
          {{ mode === 'edit' ? (readonly ? $t('customer.detailsContact') : $t('customer.updateContact')) : $t('customer.addContactCustomer') }}
        </span>
      </div>

      <b-button
        style="background:none !important; border: none !important;"
        @click="closeModalContact"
      >
        <feather-icon
          icon="XIcon"
          style="color: black;"
        />
      </b-button>
    </template>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <CardContactDetail
        :dataContactEdit="dataToEdit"
        :readonlys="readonly"
        :mode="mode"
        @add-contact-data-modal="(data)=>handleAddDataContact(data)"
      />
      <div v-if="readonlyModalDetail === false">
        <div class="d-flex justify-content-end gap-2">
          <b-button
            variant="warning"

            @click="mode === 'edit' ? toggleReadonly(): $emit('back')"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            {{ mode === 'edit' ? (readonly ? $t('customer.edit') : $t('customer.details')) : $t('customer.Back') }}
          </b-button>
          <b-button
            variant="warning"
            :disabled="readonly || invalid "

            @click="handleSave()"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />

            {{ mode === 'edit' ? $t('customer.save') : $t('customer.moreDropdown.add') }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-end gap-2">
          <b-button
            variant="warning"
            @click="mode === 'edit' ? toggleReadonly(): $emit('back')"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            {{ mode === 'edit' ? (readonly ? $t('customer.edit') : $t('customer.details')) : $t('customer.Back') }}
          </b-button>
          <b-button
            :disabled="readonly || invalid "
            variant="warning"
            @click="handleSave()"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            {{ mode === 'edit' ? $t('customer.save') : $t('customer.moreDropdown.add') }}
          </b-button>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api'
import { BButton, BModal } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'

import { apiCustomer } from '@/api'

import { getDifference } from '@core/utils/utils'

import useToast from '@useToast'

import CardContactDetail from './CardContactDetail.vue'

export default {
  components: {
    CardContactDetail,
    BButton,
    BModal,
    ValidationObserver,
  },
  props: {
    showImportModalDetail: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    readonlyModalDetail: {
      type: Boolean,
      default: false,
    },
    editContactCustomer: {
      type: Object,
      default: () => ({}),
    },
    customerId: {
      type: Number,
      required: true,

    },
  },
  setup(props, { emit }) {
    const readonly = ref(props.readonlyModalDetail)
    const dataToEdit = ref(cloneDeep(props.editContactCustomer))
    const dataToAdd = ref({})

    // watchEffect(() => {
    //   console.log({ props })
    // })

    const { toastSuccess, toastError } = useToast()
    watch(
      () => props.editContactCustomer,
      newValue => {
        dataToEdit.value = cloneDeep(newValue)
      },
    )

    const handleAddDataContact = data => {
      dataToAdd.value = data
    }
    const toggleOkDetailContact = () => {
      emit('ok-detail-contact')
    }
    const closeModalContact = () => {
      emit('close-modal-detail')
    }
    const toggleReadonly = () => {
      readonly.value = !readonly.value
    }
    const handleSave = async () => {
      try {
        const payload = dataToAdd.value
        const { birthDay } = payload
        const today = new Date()
        const birthDate = new Date(birthDay)
        const age = today.getFullYear() - birthDate.getFullYear()
        if (age > 12) {
          payload.type = 'ADULT'
        } else if (age < 2) {
          payload.type = 'INFANT'
        } else {
          payload.type = 'CHILD'
        }
        if (payload?.passportConfig?.nationality?.longCode) {
          payload.passportConfig.nationality = payload.passportConfig.nationality.longCode
        }
        if (payload?.passportConfig?.placeOfIssue?.longCode) {
          payload.passportConfig.placeOfIssue = payload.passportConfig.placeOfIssue.longCode
        }

        if (props.mode === 'edit') {
          const dataToUpdate = getDifference(payload, props.editContactCustomer)
          const res = await apiCustomer.updateContact(
            props.customerId,
            dataToEdit.value.id,
            dataToUpdate,
          )
          toastSuccess({ title: 'Success', content: 'Customer updated successfully!' })
          emit('refetch-data', res)
        } else {
          const res = await apiCustomer.addContact(props.customerId, payload)
          toastSuccess({ title: 'Success', content: 'New contact added successfully!' })
          emit('refetch-data', res)
        }
        emit('update:showImportModalDetail', false)
      } catch (error) {
        console.error('Error:', error)
        toastError({ title: 'Error', content: 'Operation failed!' })
      }
    }

    return {
      dataToEdit,
      readonly,
      toggleReadonly,
      handleSave,
      closeModalContact,
      toggleOkDetailContact,
      handleAddDataContact,
    }
  },
}
</script>
