<template lang="">
  <BCard class="rounded-8px mb-0 h-100">
    <div :class="`d-flex-center text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
      Thông tin liên lạc
    </div>

    <BRow v-if="isRoleF1">
      <!--ANCHOR agencyCode -->
      <BCol
        cols="12"
        md="6"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t(`tour.field.chooseAgency`)"
          vid="chooseAgency"
          rules="required"
        >
          <BFormGroup>
            <div class="d-flex gap-1 font-weight-bolder">
              <label for="chooseAgency">{{ $t(`tour.field.chooseAgency`) }}</label>
              <span class="text-danger">(*)</span>
            </div>

            <v-select
              id="chooseAgency"
              v-model="agencySelected"
              :options="agencyOptions"
              searchable
              label="agencyCode"
              clearable
              :filterable="false"
              :placeholder="$t('tour.msg.agencyPlaceholder')"
              :reduce="val => val"
              :loading="agencyLoading"
              @open="handleOpenAgency"
              @search="handleSearchAgency"
              @input="onInputAgencyHandle"
            >
              <template #selected-option="option">
                <HStack>
                  <span class="fw-700 text-uppercase">{{ option.agencyCode }}</span>
                  <small class="d-block font-weight-bold text-truncate">
                    ({{ option.agencyName }})
                  </small>
                </HStack>
              </template>
              <template #option="option">
                <HStack>
                  <span class="fw-700 text-uppercase">{{ option.agencyCode }}</span>
                  <span class="d-block font-weight-bold text-truncate">
                    ({{ option.agencyName }})
                  </span>
                </HStack>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>

            <BFormInvalidFeedback
              v-if="errors[0]"
              class="d-block text-danger"
            >
              {{ errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </BCol>
    </BRow>
    <BRow>
      <!--ANCHOR contactName -->
      <BCol
        cols="12"
        md="6"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t(`tour.field.contactName`)"
          vid="contactName"
          rules="required"
        >
          <BFormGroup>
            <div class="d-flex gap-1 font-weight-bolder">
              <label for="contactName">{{ $t(`tour.field.contactName`) }}</label>
              <span class="text-danger">(*)</span>
            </div>
            <vue-autosuggest
              id="contactName"
              :value="contactName"
              :suggestions="customerSuggestions"
              :input-props="{
                id:'contactName-input',
                placeholder: $t('tour.ph.contactName'),
                name: $t('tour.field.contactName'),
                state: `${errors.length > 0 ? false : null}`,
                class:`form-control text-uppercase ${errors.length > 0 ? 'is-invalid' : null}`
              }"
              class="placeholder-text"
              :section-configs="sectionConfigs"
              :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'contactName')"
              @input="val => inputHandle('contactName', val?.toUpperCase())"
            >
              <template slot-scope="{ suggestion }">
                <div class="cursor-pointer">
                  <Stack>
                    <strong class="text-uppercase">{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                    <div>{{ suggestion.item.phoneNumber }}</div>
                    <div>{{ suggestion.item.emailAddress }}</div>
                  </Stack>
                </div>
              </template>
            </vue-autosuggest>
            <BFormInvalidFeedback
              v-if="errors[0]"
              class="d-block text-danger"
            >
              {{ errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </BCol>

      <!--ANCHOR contactPhone -->
      <BCol
        cols="12"
        md="6"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t(`tour.field.contactPhone`)"
          vid="contactPhone"
          rules="required"
        >
          <BFormGroup>
            <div class="d-flex gap-1 font-weight-bolder">
              <label for="contactPhone">{{ $t(`tour.field.contactPhone`) }}</label>
              <span class="text-danger">(*)</span>
            </div>
            <vue-autosuggest
              id="contactPhone"
              v-remove-non-numeric-chars.allNumber
              :value="contactPhone"
              :suggestions="customerSuggestions"
              :input-props="{
                id:'contactPhone-input',
                placeholder: $t('tour.ph.contactPhone'),
                name: $t('tour.field.contactPhone'),
                state: `${errors.length > 0 ? false : null}`,
                class:`form-control ${errors.length > 0 ? 'is-invalid' : null}`
              }"
              :section-configs="sectionConfigs"
              :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'contactPhone')"
              @input="val => inputHandle('contactPhone', val)"
            >
              <template slot-scope="{ suggestion }">
                <div class="cursor-pointer">
                  <Stack>
                    <strong class="text-uppercase">{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                    <div>{{ suggestion.item.phoneNumber }}</div>
                    <div>{{ suggestion.item.emailAddress }}</div>
                  </Stack>
                </div>
              </template>
            </vue-autosuggest>
            <BFormInvalidFeedback
              v-if="errors[0]"
              class="d-block text-danger"
            >
              {{ errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </BCol>
      <!--ANCHOR email -->
      <BCol
        cols="12"
        md="6"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t(`tour.field.email`)"
          vid="email"
          rules="required|email"
        >
          <BFormGroup>
            <div class="d-flex gap-1 font-weight-bolder">
              <label for="email">{{ $t(`tour.field.email`) }}</label>
              <span class="text-danger">(*)</span>
            </div>
            <vue-autosuggest
              id="email"
              :value="email"
              :suggestions="customerSuggestions"
              :input-props="{
                id:'email-input',
                placeholder: $t('tour.ph.email'),
                name: $t('tour.field.email'),
                state: `${errors.length > 0 ? false : null}`,
                class:`form-control ${errors.length > 0 ? 'is-invalid' : null}`
              }"
              :section-configs="sectionConfigs"
              :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'email')"
              @input="val => inputHandle('email', val)"
            >
              <template slot-scope="{ suggestion }">
                <div class="cursor-pointer">
                  <Stack>
                    <strong class="text-uppercase">{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                    <div>{{ suggestion.item.phoneNumber }}</div>
                    <div>{{ suggestion.item.emailAddress }}</div>
                  </Stack>
                </div>
              </template>
            </vue-autosuggest>
            <BFormInvalidFeedback
              v-if="errors[0]"
              class="d-block text-danger"
            >
              {{ errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </BCol>
      <!--ANCHOR address -->
      <BCol
        cols="12"
        md="6"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t(`tour.field.address`)"
          vid="address"
          rules=""
        >
          <BFormGroup>
            <div class="d-flex gap-1 font-weight-bolder">
              <label for="address">{{ $t(`tour.field.address`) }}</label>
            </div>
            <BFormInput
              id="address"
              :value="address"
              :state="errors.length > 0 ? false : null"
              :name="$t(`tour.field.address`)"
              :placeholder="$t(`tour.ph.address`)"
              debounce="200"
              @input="val => $emit('update:address', val)"
            />
            <BFormInvalidFeedback class="text-danger">
              {{ errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </BCol>
    </BRow>

    <HStack
      v-if="selectedCustomer"
      class="align-items-center"
    >
      <div>Khách hàng đã chọn:</div>
      <div class="fw-700">
        {{ selectedCustomer.contactName }}
      </div>

      <BButton
        v-b-tooltip.hover.v-danger="$t('flight.removeCustomerSelected')"
        variant="flat-danger"
        class="rounded-circle p-50"
        @click="onRemoveSelectedCustomer"
      >
        <IAmIcon
          icon="closeOutline"
          size="18"
        />
      </BButton>
    </HStack>
    <!-- <div>
      <BFormCheckbox
        :checked="sendMailCustomer"
        @change="val => $emit('update:sendMailCustomer', val)"
      >
        Gửi email cho khách hàng
      </BFormCheckbox>
    </div> -->
  </BCard>
</template>
<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'
import { debounce } from 'lodash-es'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'

import { upperAndSpaceInput } from '@/@core/comp-functions/forms/formatter-input'
import { apiAgencies, apiCustomer } from '@/api'
import store from '@/store'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    VueAutosuggest,
    vSelect,
  },
  props: {
    contactName: { type: String, default: '' },
    contactPhone: { type: String, default: '' },
    email: { type: String, default: '' },
    address: { type: String, default: '' },
    customerId: { type: [String, Number, null], default: null },
    agencyCode: { type: [String, null], default: null },
  },
  setup(props, { emit }) {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const selectedCustomer = ref()
    const customerSuggestions = ref([])

    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          selectedCustomer.value = {
            id: selected.item.id,
            contactName: `${selected.item?.lastName?.toUpperCase()} ${selected.item?.firstName?.toUpperCase()}`,
            contactPhone: selected.item.phoneNumber,
            email: selected.item.emailAddress,
            address: [
              selected.item.addressStreet,
              selected.item.addressWard,
              selected.item.addressDistrict,
              selected.item.addressCity,
              selected.item.addressCountry,
            ]
              .filter(Boolean)
              .join(', '),
          }

          emit('update:contactName', selectedCustomer.value?.contactName)
          emit('update:contactPhone', selectedCustomer.value?.contactPhone)
          emit('update:email', selectedCustomer.value?.email)
          emit('update:address', selectedCustomer.value?.address)
          emit('update:customerId', selectedCustomer.value?.id)
        },
      },
    })

    const inputHandle = debounce((field, input) => {
      if (isRoleF1.value) return
      apiCustomer.fetchCustomers({ searchText: input })
        .then(res => {
          customerSuggestions.value = [{ data: res.data.items }]
        })

      emit(`update:${field}`, input)
    }, 300)

    function getSuggestionValue(suggestion, val) {
      return suggestion.item[val]
    }

    function onRemoveSelectedCustomer() {
      selectedCustomer.value = null
      emit('update:customerId', null)
    }

    // ANCHOR fetch Agency
    const agencyOptionsDefault = ref([])
    const agencyOptions = ref([])
    const agencySelected = ref()

    const { toastError } = useToast()
    const agencyLoading = ref(false)
    const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
      agencyLoading.value = true
      apiAgencies
        .fetchAgencies({ searchText })
        .then(response => { resolve(response.data.items) })
        .catch(e => {
          reject(e)
          toastError({
            title: 'messagesList.error',
            content: `${e.message || e}`,
          })
        })
        .finally(() => {
          agencyLoading.value = false
        })
    })

    function handleOpenAgency() {
      if (agencyOptionsDefault.value.length === 0) {
        fetchAgencies().then(res => {
          agencyOptions.value = res
          agencyOptionsDefault.value = res
        })
      } else {
        agencyOptions.value = agencyOptionsDefault.value
      }
    }

    const handleSearchAgency = debounce(search => {
      if (search) {
        fetchAgencies(search).then(res => {
          agencyOptions.value = res
        })
      }
    }, 300)

    function onInputAgencyHandle(selectedAgency) {
      emit('update:agencyCode', selectedAgency.agencyCode)
      emit('update:contactName', selectedAgency?.agencyName)
      emit('update:contactPhone', selectedAgency?.agencyOwner?.phoneNumber)
      emit('update:email', selectedAgency?.emailAddress)
      emit('update:address', selectedAgency?.agencyAddress)
      emit('update:customerId', null)
    }

    return {
      upperAndSpaceInput,
      sectionConfigs,
      selectedCustomer,
      customerSuggestions,
      inputHandle,
      getSuggestionValue,
      onRemoveSelectedCustomer,
      isRoleF1,
      handleOpenAgency,
      handleSearchAgency,
      agencyOptions,
      agencyLoading,
      onInputAgencyHandle,
      agencySelected,
    }
  },
}
</script>
<style lang="scss" scoped>
.placeholder-text ::v-deep {
  input::placeholder {
    text-transform: none !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
