export function getOsEnv(key, type = 'string') {
  if (typeof process.env[key] === 'undefined') {
    // throw new Error(`Environment variable ${key} is not set.`)
    console.error(`Environment variable ${key} is not set.`)
    return ''
  }
  const isTurnOnAllFunctionEnv = localStorage.getItem('isTurnOnAllFunctionEnv') === 'true'
  if (key.startsWith('VUE_APP_FUNC_') && isTurnOnAllFunctionEnv && type === 'string' && key !== 'VUE_APP_FUNC_HIDE_TOUR') {
    return !process.env[key] // isTurnOnAllFunctionEnv
  }
  if (type === 'array') {
    return (process.env[key].includes(',') ? process.env[key].split(',') : [process.env[key]]).filter(Boolean)
  }

  return process.env[key]
}

export default {
  getOsEnv,
}
