var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-ancillary-seat-details",
      "header-bg-variant": "light-info",
      "title": "Chi tiết chỗ ngồi",
      "title-class": "text-airline font-weight-bolder font-medium-2",
      "header-class": "p-75",
      "footer-class": "p-50",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "centered": "",
      "size": "lg"
    },
    on: {
      "show": _vm.openModalSeatDetails
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticStyle: {
      "min-height": "200px"
    }
  }, _vm._l(_vm.resolveSegmentsAddSeat(_vm.reservationsData.itineraries.flat()), function (segment, index) {
    return _c('b-card', {
      key: index,
      staticClass: "border shadow",
      attrs: {
        "header-bg-variant": "light-info",
        "header-class": "py-1 mb-0 justify-content-center",
        "body-class": "pb-0 px-0 mb-1"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body fw-700"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": " + _vm._s(['TR', 'JQ'].includes(_vm.bookingSource) ? segment.flightName : "".concat(segment.departure.iataCode, " -").concat(segment.arrival.iataCode)) + " "), _c('small', {
            staticClass: "text-info fw-700"
          }, [_vm._v(" (" + _vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).date) + ") ")])])];
        },
        proxy: true
      }], null, true)
    }, [segment.status === 'CKIN' ? _c('BAlert', {
      staticClass: "text-danger px-2 py-50 fw-700 my-50",
      attrs: {
        "show": "",
        "variant": "danger"
      }
    }, [_vm._v(" Vé đã check-in không thể thêm chỗ ngồi ")]) : _vm._e(), _c('b-table', {
      staticClass: "mb-0 mt-25 px-25",
      attrs: {
        "centered": "",
        "hover": "",
        "responsive": "",
        "bordered": "",
        "small": "",
        "no-border-collapse": "",
        "thead-class": "text-nowrap text-center",
        "tbody-class": "text-nowrap",
        "fields": _vm.seatDetailsField(_vm.bookingSource),
        "items": _vm.resolveSsrData(segment, _vm.reservationsData)
      },
      scopedSlots: _vm._u([_vm._l(_vm.seatDetailsField(_vm.bookingSource), function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: indexColumn
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
          }
        };
      }), {
        key: "table-colgroup",
        fn: function fn(scope) {
          return _vm._l(scope.fields, function (field) {
            return _c('col', {
              key: field.key,
              style: {
                width: field.key === 'seat' ? '120px' : '180px',
                'max-width': field.key === 'seat' ? '120px' : '400px'
              }
            });
          });
        }
      }, {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('small', {
            staticClass: "font-weight-bolder text-warning"
          }, [_vm._v(" " + _vm._s(['1S', '1S_CTRL'].includes(_vm.bookingSource) ? data.item.passenger.paxId : data.index + 1) + " ")]), _c('span', [_vm._v(" " + _vm._s(data.item.passenger.lastName) + " " + _vm._s(data.item.passenger.firstName) + " " + _vm._s(data.item.passenger.title || '') + " ")])];
        }
      }, {
        key: "cell(seatNum)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_vm.isEmpty(item.ancillaryServices) ? _c('div', {
            staticClass: "text-center"
          }, [_vm._v(" - ")]) : _c('div', {
            staticClass: "d-flex-center"
          }, [_c('b-button', {
            staticClass: "px-50 text-nowrap fw-700",
            attrs: {
              "variant": "success",
              "size": "sm",
              "disabled": ""
            }
          }, [_vm._v(" " + _vm._s(item.ancillaryServices[0].pdcSeat) + " ")])], 1)];
        }
      }, {
        key: "cell(status)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_vm.isEmpty(item.ancillaryServices) ? _c('div', {
            staticClass: "text-center"
          }, [_vm._v(" - ")]) : _c('div', {
            staticClass: "font-weight-bolder font-small-4 text-center",
            class: item.ancillaryServices[0].status === 'HI' ? 'text-success' : item.ancillaryServices[0].status === 'HD' ? 'text-warning' : 'text-dark'
          }, [_vm._v(" " + _vm._s(_vm.STATUS_NAME_ANCILLARY[item.ancillaryServices[0].status] || item.ancillaryServices[0].status) + " ")])];
        }
      }, {
        key: "cell(total)",
        fn: function fn(_ref4) {
          var item = _ref4.item;
          return [_vm.isEmpty(item.ancillaryServices) ? _c('div', {
            staticClass: "text-right py-50"
          }, [_vm._v(" 0 ")]) : _c('div', {
            staticClass: "font-weight-bold text-right"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.ancillaryServices[0].totalPrice)) + " ")])];
        }
      }, {
        key: "cell(action)",
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_vm.fnAncillary.SEAT.delete && !_vm.isEmpty(item.ancillaryServices) && item.ancillaryServices[0].status !== 'HI' ? _c('div', [_c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(234, 84, 85, 0.15)',
              expression: "'rgba(234, 84, 85, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "btn-icon rounded-pill d-flex justify-content-center align-items-center",
            attrs: {
              "variant": "flat-danger",
              "size": "sm"
            },
            on: {
              "click": function click(event) {
                return _vm.handleRemoveSeat(_vm.reservationsData, item.ancillaryServices[0], event);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "mr-25",
            attrs: {
              "icon": "Trash2Icon"
            }
          }), _c('span', {
            staticStyle: {
              "padding-top": "1px"
            }
          }, [_vm._v(" Huỷ chỗ ngồi ")])], 1)], 1) : _vm._e()];
        }
      }], null, true)
    }), _c('div', {
      staticClass: "w-100 text-right mt-50"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(0, 207, 232, 0.15)',
        expression: "'rgba(0, 207, 232, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-icon my-50 mr-50 p-50",
      attrs: {
        "variant": "outline-info",
        "pill": "",
        "disabled": segment.status === 'CKIN'
      },
      on: {
        "click": function click($event) {
          return _vm.showEditSeat(segment);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-center px-1"
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "PlusIcon",
        "size": "20"
      }
    }), _c('span', [_vm._v("Chọn chỗ ngồi")])], 1)])], 1)], 1);
  }), 1), _c('ModalSeatUpdate', {
    attrs: {
      "segment-data": _vm.segmentData,
      "seats-map-data": _vm.getSeatMapData,
      "is-open-modal-seat": _vm.isOpenModalSeat
    },
    on: {
      "update:isOpenModalSeat": function updateIsOpenModalSeat($event) {
        _vm.isOpenModalSeat = $event;
      },
      "update:is-open-modal-seat": function updateIsOpenModalSeat($event) {
        _vm.isOpenModalSeat = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }