var render = function () {
  var _vm$segmentList;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-cancel-flight-vn1a",
      "title": _vm.$t('reservation.cancelFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "footer-class": "px-50 m-0",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [(_vm$segmentList = _vm.segmentList) !== null && _vm$segmentList !== void 0 && _vm$segmentList.length ? [_c('div', [_c('BAlert', {
    staticClass: "px-1 py-75 m-0",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có). ")]), _c('div', {
    staticClass: "text-medium-4 d-flex-between font-weight-bolder"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('reservation.selectItineraryToCancel')))]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-dark",
      "title": "Tải lại hành trình"
    },
    on: {
      "click": _vm.getSegment
    }
  }, [_c('feather-icon', {
    class: "".concat(_vm.loading ? 'spinner-1s' : ''),
    attrs: {
      "icon": "RotateCwIcon",
      "size": "16"
    }
  })], 1)], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          staticClass: "custom-checkbox-label mb-50",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.selectAll')) + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "ml-1 ml-sm-2",
          attrs: {
            "id": "checkbox-group-cancel-flight-vn1a",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.selectedFlightToCancel,
            callback: function callback($$v) {
              _vm.selectedFlightToCancel = $$v;
            },
            expression: "selectedFlightToCancel"
          }
        }, _vm._l(_vm.segmentList, function (segment) {
          return _c('div', {
            key: segment.index,
            staticClass: "d-flex "
          }, [_c('b-form-checkbox', {
            staticClass: "custom-checkbox-label mb-50",
            attrs: {
              "value": segment
            }
          }, [_c('span', {
            staticClass: "font-weight-bolder font-medium-3 text-dark"
          }, [_vm._v(_vm._s(segment.segmentText))])])], 1);
        }), 0)];
      }
    }], null, false, 1817434466)
  })], 1), _c('div', [_c('b-form-checkbox', {
    attrs: {
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isRecalculate,
      callback: function callback($$v) {
        _vm.isRecalculate = $$v;
      },
      expression: "isRecalculate"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" Tính lại giá ")])]), _vm.isRecalculate ? _c('small', {
    staticClass: "text-warning"
  }, [_vm._v(" (*) " + _vm._s(_vm.$t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']')) + " ! ")]) : _vm._e()], 1), _vm.canPriceBargainFinder && _vm.isRecalculate ? _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-12px justify-content-around"
  }, [_c('b-form-checkbox', {
    staticClass: "mx-1",
    attrs: {
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])]), _c('b-form-checkbox', {
    staticClass: "mx-1",
    attrs: {
      "name": "keepGroup-checkbox"
    },
    model: {
      value: _vm.keepGroup,
      callback: function callback($$v) {
        _vm.keepGroup = $$v;
      },
      expression: "keepGroup"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.keepGroup')) + " ")])])], 1)]) : _vm._e()] : _vm.responseText ? [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "70vh",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.responseText.booking.join('\n'),
      "rows": "16",
      "max-rows": "40"
    }
  })] : _vm._e(), _c('div', {
    staticClass: "d-flex-center gap-2 my-50"
  }, [_c('b-button', {
    staticClass: "center rounded-pill px-1",
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.closeHandle();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), !_vm.responseText ? _c('b-button', {
    staticClass: "border-0 px-1",
    attrs: {
      "variant": "outline-danger",
      "disabled": !_vm.selectedFlightToCancelLength,
      "pill": ""
    },
    on: {
      "click": _vm.submitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.cancelFlight')) + " ")]) : _vm._e()], 1)], 2)], 1), _c('BModal', {
    attrs: {
      "id": "modal-cancel-recalculate-show-price-VN1A",
      "title": _vm.$t('reservation.showCalculatePrice.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "hide-header-close": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "rounded-pill px-2",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCloseModalShowPriceNew(close);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.handleSavePrice
          }
        }, [_c('span', {}, [_vm._v("LƯU GIÁ MỚI")])])];
      }
    }])
  }, [!_vm.isEmpty(_vm.newPriceItinerary) ? _c('div', {
    staticClass: "position-relative"
  }, [_vm.tripDetailsItinerary ? _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightInfo')) + " ")]), _c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.tripDetailsItinerary,
      "rows": "2"
    }
  })], 1) : _vm._e(), !_vm.isEmpty(_vm.newPriceItinerary) ? _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "striped": "",
      "body-class": "p-0",
      "thead-class": "text-center font-weight-bolder text-nowrap",
      "tbody-class": "text-center text-nowrap",
      "fields": ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'],
      "items": _vm.newPriceItinerary
    },
    scopedSlots: _vm._u([_vm._l(['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))])])];
        }
      };
    }), {
      key: "cell(passengerType)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.paxType) + " ")];
      }
    }, {
      key: "cell(net)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.netFare)) + " ")];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tax)) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.totalPrice)))])];
      }
    }, {
      key: "cell(quantity)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.paxNum ? _vm.formatCurrency(item.paxNum) : 1) + " ")];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "fw-800"
        }, [_vm._v(_vm._s(item.paxNum ? _vm.formatCurrency(item.totalPrice * item.paxNum) : _vm.formatCurrency(item.totalPrice)))])];
      }
    }], null, true)
  }) : _vm._e(), _c('p', {
    class: "text-right text-body text-nowrap mr-md-2 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" Tổng tiền: "), _c('span', {
    class: "text-airline fw-800 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-3')
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.newPriceItinerary.reduce(function (total, item) {
    return total + (item.paxNum ? item.totalPrice * item.paxNum : item.totalPrice);
  }, 0))) + " VND")])]), _c('BAlert', {
    staticClass: "py-75 mt-1 text-center px-75",
    attrs: {
      "show": _vm.isWarningItinerary,
      "variant": "warning"
    }
  }, [_c('span', {
    class: "fw-700 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!! ")])])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }