import service from './service'

export default {
  fetchBookings(payload = {}) {
    return service.get('/bookings', payload)
  },

  fetchPastDayBookings(payload = {}) {
    return service.get('/past-day-booking', payload)
  },

  fetchBookingById(id, payload = {}) {
    return service.get(`/bookings/${id}`, payload)
  },

  fetchPastDayBookingById(id) {
    return service.get(`/past-day-booking/${id}`)
  },

  updateNote(id = '', payload = {}) {
    return service.patch(`/bookings/${id}/note`, payload)
  },

  updateDiscount(id = '', payload = {}) {
    return service.patch(`/bookings/${id}/discount-info`, payload)
  },

  updateAutoIssueTime(id = '', payload = {}) {
    return service.post(`/bookings/${id}/set-auto-issue`, payload)
  },

  updateUnsetIssueTime(id = '', payload = {}) {
    return service.post(`/bookings/${id}/unset-auto-issue`, payload)
  },

  activeReservation(id = '', payload = {}) {
    return service.patch(`/bookings/${id}/active`, payload)
  },

  banBooking(id = '', payload = {}) {
    return service.post(`/bookings/${id}/ban-book`, payload)
  },

  unbanBooking(id = '') {
    return service.post(`/bookings/${id}/unban-book`)
  },

  submitBooking(id = '', payload = {}) {
    return service.post(`/bookings/${id}/submit-draft`, payload)
  },

  sendSubmitBookingMail(id = '') {
    return service.post(`/bookings/${id}/send-submit-booking-mail`)
  },

  deActiveReservation(id = '', payload = {}) {
    return service.patch(`/bookings/${id}/deactive`, payload)
  },

  reBookReservation(id = '', params = {}) {
    return service.post(`/bookings/${id}/rebook`, params)
  },

  exportBooking(payload = {}, fileName) {
    return service.excel('/bookings/export', payload, fileName)
  },

  // hieuht define
  flightPayment(payload = {}) {
    return service.post('/flights/payment', payload)
  },

  retrieveBooking(payload = {}) {
    return service.post('flights/retrieve-booking', payload)
  },

  ancillaryPayments(payload = {}) {
    return service.post('flights/book/ancillary-payments', payload)
  },

  modifyPaxInfo(payload = {}) {
    return service.post('/flights/modify/pax-info', payload)
  },

  // xoá/thêm em bé, sửa tên+paxType cho VN1A
  commandModifyPaxInfo(payload = {}) {
    return service.post('/commands/modify-pax-info', payload)
  },

  paxInfoPrice(payload = {}) {
    return service.post('/flights/modify/pax-info-price', payload)
  },

  fetchListAncillary(payload = {}) {
    return service.post('/flights/list-ancillary', payload)
  },

  modifyAncillary(payload = {}) {
    return service.post('/flights/modify/ancillary', payload)
  },

  fetchSeatMap(payload = {}) {
    return service.post('/flights/seat-map', payload)
  },

  modifyReserveSeat(payload = {}) {
    return service.post('/flights/modify/reserve-seat', payload)
  },
  modifyCancelSeat(payload = {}) {
    return service.post('/flights/modify/cancel-seat', payload)
  },

  cancelFlightPrice(payload = {}) {
    return service.post('/flights/modify/cancel-flights-price', payload)
  },

  cancelFlight(payload = {}) {
    return service.post('/flights/modify/cancel-flights', payload)
  },
  cancelFlight1G(payload = {}) {
    return service.post('/flights/booking/cancel', payload)
  },

  splitPnr(payload = {}) {
    return service.post('/flights/modify/split-pnr', payload)
  },

  calculatePriceBooking(payload = {}) {
    return service.post('/flights/book/calculate-price', payload)
  },

  cleanAirPricingConversion(payload = {}) {
    return service.post('flights/book/calculate-price/clean-conversion', payload)
  },

  addFlights(payload = {}) {
    return service.post('/flights/modify/add-flights', payload)
  },

  searchForModify(payload = {}) {
    return service.post('/flights/search-for-modify', payload)
  },

  changeFlights(payload = {}) {
    return service.post('/flights/modify/change-flights', payload)
  },

  sendEmail(payload = {}) {
    return service.post('/flights/send-email', payload)
  },

  sendEmailCommand(payload = {}) {
    return service.post('/commands/send-mail-command', payload)
  },

  sendOutSideEmail(payload = {}) {
    return service.post('/commands/outside-email', payload)
  },

  addFlightsPrice(payload = {}) {
    return service.post('/flights/modify/add-flights-price', payload)
  },
  changeFlightsPrice(payload = {}) {
    return service.post('/flights/modify/change-flights-price', payload)
  },

  modifyDocuments(payload = {}) {
    return service.post('/flights/modify-documents', payload)
  },

  fetchEticketByNumber(payload = {}) {
    return service.post('/flights/documents/get-tkt', payload)
  },

  voidTicket(payload = {}) {
    return service.post('/flights/void-tickets', payload)
  },

  voidTicketV2(payload = {}) {
    return service.post('v2/flights/ticketing/void-tickets', payload)
  },

  eTicketUpdates(payload = {}) {
    return service.post('/flights/modify/ticket-details', payload)
  },

  refundTicketAmount(payload = {}) {
    return service.post('/flights/refund-ticket-amounts', payload)
  },

  refundTickets(payload = {}) {
    return service.post('/flights/refund-tickets', payload)
  },

  exchangeTicketAmount(payload = {}) {
    return service.post('/flights/exchange-ticket-amounts', payload)
  },

  exchangeTickets(payload = {}) {
    return service.post('/flights/exchange-tickets', payload)
  },
  getCommissions(params = {}) {
    // for 1G
    return service.get('/commissions', params)
  },
  checkTicketing(id) { // for 1G
    return service.get(`/flights/ticketing-helpers/${id}`)
  },
  ticketing(payload = {}) {
    // for VN
    return service.post('/flights/book/ticketing', payload)
  },
  shortenBooking(payload = {}) {
    return service.post('/commands/shorten-booking', payload)
  },
  shortenEticket(payload = {}) {
    return service.post('/commands/retrieve-ticket-document', payload)
  },
  confirmSegments(payload = {}) {
    return service.post('/commands/confirm-segments', payload)
  },
  // retrieveTicket(payload = {}) {
  //   return service.post('/flights/retrieve-ticket', payload)
  // },
  confirmFlightsSegments(payload = {}) {
    return service.post('/flights/confirm-segments', payload)
  },
  modifyRemarks(payload = {}) {
    //   return service.post('/flights/modify/remarks', payload)
    return service.post('commands/modify-remarks', payload)
  },
  osiFlight(payload = {}) {
    return service.post('flights/modify/osi', payload)
  },
  sendQueue(payload = {}) {
    return service.post('/commands/queue', payload)
  },
  bookSpecialServices(payload = {}) {
    return service.post('/flights/modify/special-services', payload)
  },

  modifyFrequentFlyer(payload = {}) {
    return service.post('/flights/modify/fftv', payload)
  },

  getCountriesName(payload = {}) {
    return service.get('/metadata/country_name', payload)
  },

  setLowFareTracking({ id, payload }) {
    return service.post(`bookings/${id}/set-low-fare-tracking`, payload)
  },

  cancelLowFareTracking({ id, payload }) {
    return service.post(`bookings/${id}/cancel-low-fare-tracking`, payload)
  },

  customServiceFee({ id, payload }) {
    return service.post(`bookings/${id}/custom-fee-service`, payload)
  },

  stopPaymentReminder({ id }) {
    return service.patch(`bookings/${id}/stop-payment-reminder`)
  },

  searchReservations(payload) { // For find booking VJ
    return service.post('flights/search-reservations', payload)
  },

  bookingStatistic(params) {
    return service.get('bookings/statistic', params)
  },

  lowFareTrackingStatistic(params) {
    return service.get('bookings/low-fare-tracking', params)
  },

  getListSpecialServices(payload) {
    return service.post('flights/special-service/list-service', payload)
  },
  createSpecialServices(payload) {
    return service.post('flights/special-service/create-service', payload)
  },

  getInsurancePlans(payload) {
    return service.post('flights/insurance/plans', payload)
  },
  bulkCreateInsuranceDraft(payload) {
    return service.post('flights/insurance/bulk-create-draft', payload)
  },
  updateInsuranceDraft(payload) {
    return service.post('flights/insurance/update-draft', payload)
  },
  deleteInsuranceDraft(payload) {
    return service.post('flights/insurance/delete-draft', payload)
  },
  createInsuranceCoverNote(payload) {
    return service.post('flights/insurance/create-cover-note', payload)
  },
  editInsuranceCoverNote(payload) {
    return service.post('flights/insurance/edit-cover-note', payload)
  },
  createInsurancePolicy(payload) {
    return service.post('flights/insurance/create-policy', payload)
  },
  cancelInsurance(payload) {
    return service.post('flights/insurance/cancel', payload)
  },
  // enable edit outside
  enableEditOutside(id) {
    return service.patch(`bookings/${id}/outside/enable-edit`)
  },
  assignBooking({ id, payload }) {
    return service.post(`bookings/${id}/assign-booking`, payload)
  },
  getAuditLogsFromSource(payload) {
    return service.post('flights/booking/audit', payload)
  },
  outSideTicket(payload) {
    return service.post('/commands/outside-ticket', payload)
  },
  outSideShortenBooking(payload) {
    return service.post('/commands/outside-shorten-booking', payload)
  },
  addMultiFftv(payload) {
    return service.post('/commands/add-multi-fftv', payload)
  },

  // for cancel flight VN1A
  commandsGetBooking(payload) {
    return service.post('/commands/get-booking', payload)
  },
  commandsCancelSegment(payload) {
    return service.post('/commands/cancel-segment', payload)
  },

  // command for VN1A
  commandAddQueues(payload) {
    return service.post('/commands/add-queues', payload)
  },
  commandAddOsi(payload) {
    return service.post('/commands/add-osi', payload)
  },
  commandAddSsrs(payload) {
    return service.post('/commands/add-ssrs', payload)
  },
  commandAddDocuments(payload) {
    return service.post('/commands/add-documents', payload)
  },
  getBookingRaws(payload) {
    return service.get('/bookings/raws', payload)
  },
  getBookingRawsByCode(code) {
    return service.get(`/bookings/raws/${code}`)
  },

  // Ẩn giá booking F2, F3 (TPV) (https://discord.com/channels/1054696448110903327/1311271037370896396)
  hideFareBooking(id) {
    return service.patch(`bookings/${id}/hide-fare`)
  },
}
