export default {
  title: 'Danh sách request tìm kiếm của F2',
  columns: {
    no: 'STT',
    agentCode: 'Đại lý',
    flightDates: 'Ngày bay',
    // request: 'Request',
    requestType: 'Loại request',
    routes: 'Hành trình',
    trips: 'Chi tiết',
    createdAt: 'Thời gian',
    source: 'HT đặt vé',
  },
  requestType: {
    SEARCH: 'Tìm kiếm',
    PAYMENT: 'Thanh toán',
    CREATE: 'Đặt chỗ',
  },
  filters: {
    startDate: 'Từ ngày',
    endDate: 'Đến ngày',
    agencyCode: 'Đại lý',
    requestType: 'Loại request',
    searchText: 'Tìm kiếm',
    ph: {
      searchText: 'Nhập và nhấn ↵ để tìm kiếm',
    },
  },
}
