<template>
  <div>
    <BAlert
      :show="show && alertList && alertList.length > 0"
      variant="info"
      style="font-size: 16px; text-align: justify; color: #00558F !important;"
      class="px-1 py-25 mb-25"
    >
      <div class="d-flex-center justify-content-between">
        <strong
          class="text-info fw-700 d-flex-center text-uppercase"
          style="font-size: 18px; text-align: justify; color: #00558F !important;"
        >
          <feather-icon
            size="22"
            class="mr-50"
            icon="AlertCircleIcon"
          />
          {{ $t('important') }}
        </strong>
        <div class="d-flex-center gap-1">
          <b-button
            variant="flat-info"
            class="p-50"
            @click="toggleCollapse"
          >
            <feather-icon
              v-if="isOpen"
              class="cursor-pointer"
              icon="ChevronDownIcon"
              size="20"
            />
            <feather-icon
              v-else
              class="cursor-pointer"
              icon="ChevronUpIcon"
              size="20"
            />
          </b-button>
          <b-button
            variant="flat-danger"
            class="p-50"
            @click="hide"
          >
            <feather-icon
              class="cursor-pointer"
              icon="XIcon"
              size="20"
            />
          </b-button>
        </div>
      </div>

      <div :class="'alert-container ' + (isOpen ? 'show' : 'hide')">
        <hr class="my-25">
        <div class="d-flex justify-content-between align-items-stretch gap-1">
          <div>
            <strong
              v-for="(alertItem, alertIndex) in (isMoreNotify ? alertListFull : alertList)"
              :key="alertIndex"
              style="line-height: 1.5"
              class="d-flex-center justify-content-start gap-1"
            >
              <span v-if="alertItem.id">⚠️</span>
              <component
                :is="alertItem.id ? 'b-link' : 'div'"
                :id="alertItem.id ? `alert-${alertItem.id}` : undefined"
                :to="{
                  name: isHideMessageBoard ? 'apps-documents-edit' : 'apps-messageBoard-id',
                  params: { id: alertItem.id },
                }"
                :class="{
                  'hover-underline': !!alertItem.id,
                  'text-danger': alertItem.priority === 'HIGH'
                }"
                class="text-break"
                style="font-size: 16px; text-align: justify; color: #00558F;"
              >
                <span v-html="`${alertItem.key} ${alertItem.value[0]}`" />
              </component>
              <div
                v-for="(valueItem, valueIndex) in alertItem.value.slice(1)"
                :key="valueIndex"
                class="ml-1 text-justify"
              >
                - <span v-html="valueItem" />
              </div>
            </strong>
          </div>

          <div
            v-if="haveMoreNotify"
            class="d-flex align-items-end"
          >
            <b-button
              variant="flat-info"
              class="p-50 cursor-pointer"
              @click="toggleMoreNotify"
            >
              <feather-icon
                v-if="isMoreNotify"
                icon="ChevronDownIcon"
                size="20"
              />
              <feather-icon
                v-else
                icon="ChevronUpIcon"
                size="20"
              />
            </b-button>
          </div>
        </div>
      </div>
    </BAlert>

    <!-- Cảnh báo AG hết hạn gói bán lẻ -->
    <BAlert
      :show="expiredPackageList.length > 0 && isOpenExpiredPackage"
      variant="warning"
      style="font-size: 16px; text-align: justify;"
      class="px-1 py-25 mb-25 d-flex-between"
    >
      <div>
        <div
          v-for="expiredPackage in expiredPackageList"
          :key="expiredPackage.id"
          :class="`${expiredPackage.countDayBeforeExpired < 0 ? 'text-danger' : ''} my-25`"
        >
          Gói bán lẻ {{ expiredPackage.packageConfig.name }} hết hạn vào {{ convertISODateTime(expiredPackage.expiredAt).date }},
          <span v-if="expiredPackage.countDayBeforeExpired >= 0">
            còn {{ expiredPackage.countDayBeforeExpired }} ngày
          </span>
          <span v-else> đã hết hạn {{ -expiredPackage.countDayBeforeExpired }} ngày trước </span>
        </div>
      </div>
      <div class="d-flex gap-2">
        <b-button
          variant="outline-warning"
          class="p-50"
          @click="$router.push({ name: 'apps-agencyPackageConfigs-list' })"
        >
          Xem ngay
        </b-button>
        <b-button
          variant="flat-danger"
          class="p-50"
          @click="() => {isOpenExpiredPackage = false}"
        >
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
          />
        </b-button>
      </div>
    </BAlert>
  </div>
</template>

<script>
import {
  BAlert, BButton, BLink,
} from 'bootstrap-vue'
import {
  ref, computed, onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'
import env from '@/libs/env'

export default {
  components: {
    BAlert, BButton, BLink,
  },
  setup() {
    store.dispatch('globalConfig/getAllPins')
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    if (isRoleF2.value) {
      store.dispatch('globalConfig/fetchExpiredPackage')
    }

    onUnmounted(() => {
      store.dispatch('globalConfig/clearExpiredPackage')
    })

    const expiredPackageList = computed(() => store.getters['globalConfig/getExpiredPackage'] || [])
    const alertListFull = computed(() => store.getters['globalConfig/getAllAlert'] || [])
    const alertList = computed(() => alertListFull.value.filter(item => item.id === undefined || item.priority === 'HIGH' || item.priority === 'MEDIUM'))
    const show = ref(true)
    function hide() {
      show.value = false
    }

    const isOpen = ref(true)
    const toggleCollapse = () => {
      isOpen.value = !isOpen.value
    }

    const isMoreNotify = ref(false)
    const toggleMoreNotify = () => {
      isMoreNotify.value = !isMoreNotify.value
    }

    const haveMoreNotify = computed(() => alertListFull.value.length > alertList.value.length)

    const isOpenExpiredPackage = ref(true)
    return {
      alertList,
      alertListFull,
      show,
      hide,
      isOpen,
      toggleCollapse,
      isMoreNotify,
      toggleMoreNotify,
      haveMoreNotify,
      expiredPackageList,
      convertISODateTime,
      isOpenExpiredPackage,
      isHideMessageBoard: env.isHideMessageBoard,
    }
  },
}
</script>

<style lang="scss" scoped>
.alert-container {
  transition: 300ms ease all;
  &.hide {
    max-height: 0px;
    overflow: hidden;
    border: none !important;
  }
  &.show {
    max-height: 2000px;
  }
}
</style>
