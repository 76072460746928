import { ref, computed, watch } from '@vue/composition-api'

import {
  INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE, INCORRECT_INVOICES_LIST_COLUMNS,
} from '@/constants/invoice'
import store from '@/store'
import { arrayToObject } from '@/@core/utils/utils'

import useToast from '@useToast'

export default function useIncorrectInvoiceHandle() {
  const { toastError } = useToast()

  const refIncorrectInvoiceListTable = ref(null)
  const loading = ref(false)

  const tableColumnIncorrectInvoiceList = ref(INCORRECT_INVOICES_LIST_COLUMNS)

  // filter
  const startDate = ref('') // từ ngày
  const endDate = ref('') // đến ngày
  const notificationType = ref('') // Loại thông báo
  const notificationStatus = ref('') // trạng thái thông báo
  const statusOfTaxAuthority = ref('') // trạng thái phát hành CQT

  const sizePerPage = ref(20)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalIncorrectInvoices = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refIncorrectInvoiceListTable.value ? refIncorrectInvoiceListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalIncorrectInvoices.value,
    }
  })

  const clearFilter = () => {
    startDate.value = ''
    endDate.value = ''
    notificationType.value = ''
    notificationStatus.value = ''
    statusOfTaxAuthority.value = ''
  }

  const refetchData = () => {
    refIncorrectInvoiceListTable.value.refresh()
  }

  const fetchIncorrectInvoices = (_ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-invoice/fetchIncorrectInvoices', {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        notificationType: notificationType.value || undefined,
        status: notificationStatus.value || undefined,
        statusOfTaxAuthority: statusOfTaxAuthority.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(res => {
        totalIncorrectInvoices.value = res.count
        const mappingRes = res.items
        // .map(item => ({
        //   ...item,
        //   invoiceItems: (item?.invoiceItems || []).filter(item => item?.itemType === GOODS_SERVICE_TYPES.GOODS_SERVICE),
        // }))
        callback(mappingRes)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách hoá đơn sai sót')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  // const exportInvoices = (invoiceIds = []) => new Promise((resolve, reject) => {
  //   vm.$bvModal.show('modal-api-loading')
  //   const payload = invoiceIds.length
  //     ? { invoiceIds }
  //     : {
  //       startDate: startDate.value || undefined,
  //       endDate: endDate.value || undefined,
  //       invoiceStatus: invoiceStatus.value || undefined,
  //       releaseStatus: releaseStatus.value || undefined,
  //       sendMailStatus: sendMailStatus.value || undefined,
  //       tags: tagsFilter.value.length ? tagsFilter.value : undefined,
  //       signStatus: signStatus.value || undefined,
  //       invNumber: invNumber.value || undefined,
  //       referenceCode: referenceCode.value || undefined,
  //       buyerName: buyerName.value || undefined,
  //       agencyReceiveCode: agencyCode.value || undefined,
  //       sortBy: sortBy.value ? sortBy.value : 'createdAt',
  //       sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
  //       size: sizePerPage.value,
  //       page: currentPage.value,
  //     }
  //   const fileName = `${onlyTotalAmountAdditional.value ? 'CongNoXuatChenhHoaDon' : isInvoiceRequest.value ? 'DanhSachYeuCauXuatHoaDon' : 'DanhSachHoaDon'}_${convertISODateTime(new Date()).date.replaceAll('/', '')}`
  //   store
  //     .dispatch('app-invoice/exportInvoices', { payload, fileName })
  //     .then(res => {
  //       resolve(res)
  //       toastSuccess({
  //         title: 'messagesList.success',
  //         content: 'invoice.msg.exportSuccess',
  //       })
  //     })
  //     .catch(e => {
  //       toastError({
  //         title: 'messagesList.error',
  //         content: 'invoice.msg.exportError',
  //       })
  //       reject(e)
  //     })
  //     .finally(() => {
  //       vm.$bvModal.hide('modal-api-loading')
  //     })
  // })

  return {
    // searchText,
    refIncorrectInvoiceListTable,
    fetchIncorrectInvoices,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalIncorrectInvoices,

    // Filter
    isSortDirDesc,
    sortBy,
    startDate,
    endDate,
    notificationType,
    notificationStatus,
    statusOfTaxAuthority,

    // constants
    INVOICE_STORE,
    tableColumnIncorrectInvoiceList,
    // exportInvoices,
  }
}

const getVariant = (array, value) => array.find(item => item.value === value)?.variant ?? 'secondary'

export const INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS = [
  { label: 'TYPE_1', value: 'TYPE_1', variant: 'light-success' }, // Thông báo của NNT
  { label: 'TYPE_2', value: 'TYPE_2', variant: 'light-info' }, // Thông báo của NNT theo thông báo rà soát của CQT
]
export const INCORRECT_INVOICE_NOTIFICATION_TYPE = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS)

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS = [
  { label: 'NEW', value: 'NEW', variant: 'light-success' }, // Mới tạo
  { label: 'SIGNED', value: 'SIGNED', variant: 'light-warning' }, // Đã ký
]

export const INCORRECT_INVOICE_NOTIFICATION_STATUS = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS)

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS = [
  { label: 'NOT_YET_SENT', value: 'NOT_YET_SENT', variant: 'secondary' }, // Chưa gửi CQT
  { label: 'SENT', value: 'SENT', variant: 'light-success' }, // Đã gửi CQT
  { label: 'RECEIVED', value: 'RECEIVED', variant: 'success' }, // RECEIVED
  { label: 'NOT_RECEIVED', value: 'NOT_RECEIVED', variant: 'light-danger' }, // NOT_RECEIVED
  { label: 'ACCEPTED', value: 'ACCEPTED', variant: 'success' }, // Chấp nhận
  { label: 'NOT_ACCEPTED', value: 'NOT_ACCEPTED', variant: 'light-danger' }, // Không chấp nhận
  { label: 'INVOICE_VALID', value: 'INVOICE_VALID', variant: 'light-danger' }, // INVOICE_VALID
  { label: 'INVOICE_INVALID', value: 'INVOICE_INVALID', variant: 'light-danger' }, // INVOICE_INVALID
  { label: 'INVOICE_ISSUE_CODE', value: 'INVOICE_ISSUE_CODE', variant: 'light-danger' }, // INVOICE_ISSUE_CODE
  { label: 'INVOICE_TABLE_VALID', value: 'INVOICE_TABLE_VALID', variant: 'light-danger' }, // INVOICE_TABLE_VALID
  { label: 'INVOICE_TABLE_INVALID', value: 'INVOICE_TABLE_INVALID', variant: 'light-danger' }, // INVOICE_TABLE_INVALID
  { label: 'INCORRECT_INVOICE_NOTIFICATION_INVALID', value: 'INCORRECT_INVOICE_NOTIFICATION_INVALID', variant: 'light-danger' }, // INCORRECT_INVOICE_NOTIFICATION_INVALID
  { label: 'INCORRECT_INVOICE_NOTIFICATION_ACCEPTED_NOT_ACCEPTED', value: 'INCORRECT_INVOICE_NOTIFICATION_ACCEPTED_NOT_ACCEPTED', variant: 'light-danger' }, // INCORRECT_INVOICE_NOTIFICATION_ACCEPTED_NOT_ACCEPTED
  { label: 'INVALID', value: 'INVALID', variant: 'light-danger' }, // Không hợp lệ
]
// ADJUSTMENT: 'light-info',
// ADJUSTED: 'light-warning',
// REPLACEMENT: 'light-pink',
// REPLACED: 'light-danger',
// CANCELLED: 'danger',
// ERROR: 'outline-danger',

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS)

export const resolveNotificationTypeVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS, value)
export const resolveNotificationStatusVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS, value)
export const resolveNotificationStatusOfTaxAuthorityVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS, value)

export function useIncorrectInvoiceDetailHandle() {
  const { toastError } = useToast()

  const loadingDetail = ref(false)

  const getIncorrectInvoiceById = id => new Promise((resolve, reject) => {
    loadingDetail.value = true
    store
      .dispatch('app-invoice/getIncorrectInvoiceById', id)
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingDetail.value = false
      })
  })
  return {
    getIncorrectInvoiceById,
    loadingDetail,
  }
}

export function useIncorrectInvoiceCreateHandle() {
  const { toastError, toastSuccess } = useToast()

  const loadingCreate = ref(false)

  const createIncorrectInvoice = payload => {
    loadingCreate.value = true
    store
      .dispatch('app-invoice/createIncorrectInvoice', payload)
      .then(res => {
        console.log({ res })
        toastSuccess('invoice.msg.createSuccess')
        // if (res?.id) {
        //   router.push({ name: res?.createdType === CREATE_TYPE.REQUEST ? 'apps-invoiceRequest-list' : 'apps-invoiceList' })
        // }
      })
      .catch(error => {
        console.error({ error })
        toastError('invoice.msg.createError')
      })
      .finally(() => {
        loadingCreate.value = false
      })
  }
  return {
    createIncorrectInvoice,
    loadingCreate,
  }
}
