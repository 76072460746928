<template lang="">
  <Stack class="gap-2">
    <div :class="`text-tour fw-700 ${isMobileView ? 'text-14px' : 'text-18px'} text-uppercase`">
      Gợi ý Tour Du lịch
    </div>
    <component
      :is="isMobileView ? 'Stack' : 'HStack'"
      v-if="tourSuggestions.length"
      class="gap-2"
    >
      <div
        v-for="(tourSuggestion, tourIndex) in tourSuggestions"
        :key="tourIndex"
        class="bg-light-info rounded-8px p-50 hover-div flex-1"
      >
        <HStack class="flex-nowrap text-dark gap-2">
          <BImgLazy
            :src="`${tourSuggestion?.image?.path || backgroundTourUrl}`"
            rounded
            style="width: 120px"
          />
          <Stack class="justify-content-center">
            <div
              v-b-tooltip.hover.window="tourSuggestion?.name"
              class="fw-700 clamped-content"
            >
              {{ tourSuggestion.name }}
            </div>

            <HStack>
              <div>Thời gian: </div>
              <div class="text-info fw-700">
                {{ tourSuggestion.totalDay }} Ngày {{ tourSuggestion.totalNight }} Đêm
              </div>
            </HStack>

            <HStack>
              <div :class="`text-danger fw-800 ${isMobileView ? 'text-14px' : 'text-18px'}`">
                {{ formatCurrency(Number(tourSuggestion.priceDefault)) }} VND
              </div>

              <BLink
                :class="`btn btn-flat-success py-25 fw-700 ${isMobileView ? 'text-12px px-50' : 'text-16px'}`"
                :to="{ name: 'apps-toursList', query: {
                  type: tourSuggestion.type,
                  category: tourSuggestion.categoryId
                } }"
                target="_blank"
              >
                Xem Chi tiết
              </BLink>
            </HStack>
          </Stack>
        </HStack>
      </div>
    </component>
    <Stack
      v-else
      class="fw-700 gap-2 font-italic"
    >
      <HStack v-if="loadingTourSuggestion">
        <BSpinner small />
        <div>
          Chúng tôi đang tìm kiếm chương trình tour cho Quý khách.
        </div>
      </HStack>
      <div v-else>
        Không có gợi ý Tour du lịch cho đặt chỗ này. Tham khảo các Tour du lịch khác tại <BLink
          :class="`text-success fw-700`"
          :to="{ name: 'apps-toursList'}"
          target="_blank"
        >
          đây
        </BLink>.
      </div>
    </Stack>
  </Stack>
</template>
<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { BImgLazy, BLink, BSpinner } from 'bootstrap-vue'

import store from '@/store'
import { apiTour } from '@/api'
import { formatCurrency } from '@/@core/utils/filter'
import env from '@/libs/env'

export default {
  components: {
    BImgLazy, BLink, BSpinner,
  },
  props: {
    points: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const suggestionTourByIATACode = computed(() => store.getters['globalConfig/getConfig']('suggestionTourByIATACode'))
    const hasTourCategories = computed(() => store.getters['globalConfig/hasTourCategories'])
    const tourCategories = computed(() => store.getters['globalConfig/getTourCategories'])

    onMounted(async () => {
      if (!hasTourCategories.value) {
        await store.dispatch('globalConfig/fetchTourCategory')
      }
    })

    const tourSuggestions = ref([])
    const loadingTourSuggestion = ref(true)
    watch([suggestionTourByIATACode, hasTourCategories], () => {
      if (!suggestionTourByIATACode.value || !props.points.length || !hasTourCategories.value) return
      loadingTourSuggestion.value = true
      props.points.forEach(point => {
        const categoryNameFound = suggestionTourByIATACode.value.find(item => item.key === point)
        if (!categoryNameFound) return
        categoryNameFound.value.forEach(async category => {
          const categoryFound = tourCategories.value.find(tourCat => tourCat.name === category)
          if (!categoryFound) return
          const resTourBlock = await apiTour.getTourBlock({
            categoryId: categoryFound?.id,
            type: categoryFound?.type,
          })
          if (resTourBlock?.data?.items?.length) {
            tourSuggestions.value.push(...resTourBlock?.data?.items.map(i => ({ ...i, type: categoryFound?.type })))
          }
          if (tourSuggestions.value.length > 3) {
            tourSuggestions.value = tourSuggestions.value.slice(0, 3)
          }
        })
      })
      loadingTourSuggestion.value = false
    }, { deep: true, immediate: true })

    return {
      suggestionTourByIATACode,
      tourSuggestions,
      formatCurrency,
      backgroundTourUrl: env.backgroundTourUrl,
      loadingTourSuggestion,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/variables/_variables';
.clamped-content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  max-height: 100%;
}
.hover-div {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid $info;
  }
}
</style>
