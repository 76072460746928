var render = function () {
  var _vm$topupData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "m-0 py-75 pr-25 pr-sm-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('topup.tabInfo')) + " ")]), _vm.canConfirmManual(_vm.topupData) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-75 px-md-2 py-50 py-md-1",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.onOpenPopupConfirmManual(_vm.topupData.paymentCode, _vm.topupData.paymentAmount, _vm.topupData.type);
            }
          }
        }, [!_vm.isMobileView ? _c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckCircleIcon"
          }
        }) : _vm._e(), _c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.isMobileView ? 'Xác nhận thủ công' : _vm.$t('topup.confirmManual')) + " ")])], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-0"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.tableColumns.paymentCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.paymentCode,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.paymentAmount')
    }
  }, [_c('b-input-group', {
    attrs: {
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-input-group-text', [_vm._v(" " + _vm._s(_vm.$t('agency.currency')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.formatCurrency(_vm.topupData.paymentAmount),
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.paymentStatus')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.$t(_vm.resolveTopupStatus(_vm.topupData.paymentStatus)),
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.bankAccountName')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.bankAccountName,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.bankAccountNo')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.bankAccountNo,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.bankCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.bankCode,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.senderName')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "".concat(_vm.topupData.senderName).concat((_vm$topupData = _vm.topupData) !== null && _vm$topupData !== void 0 && _vm$topupData.senderCode ? " (".concat(_vm.topupData.senderCode, ")") : ''),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.senderId')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.senderId,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.senderEntity')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.senderEntity,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.recipientId')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.recipientId,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.recipientEntity')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.topupData.recipientEntity,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.note')
    }
  }, [_c('b-form-textarea', {
    staticClass: "overflow-auto",
    attrs: {
      "value": _vm.topupData.note,
      "disabled": "",
      "no-resize": "",
      "max-rows": "4",
      "rows": ['xs', 'sm'].includes(_vm.$store.getters['app/currentBreakPoint']) ? 1 : 2
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.evidenceUrl')
    }
  }, [_c('b-form-textarea', {
    staticClass: "overflow-auto",
    attrs: {
      "value": _vm.topupData.evidenceUrl,
      "disabled": "",
      "no-resize": "",
      "max-rows": "4",
      "rows": ['xs', 'sm'].includes(_vm.$store.getters['app/currentBreakPoint']) ? 1 : 2
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.tableColumns.created')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.dateTime(_vm.topupData.createdAt),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold",
    attrs: {
      "label": _vm.$t('topup.tableColumns.updated')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.dateTime(_vm.topupData.updatedAt),
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticStyle: {
      "max-width": "100vw"
    },
    attrs: {
      "src": _vm.topupData.qrCodeUrl
    }
  })], 1)])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger",
      "to": {
        name: 'apps-topupSender-list'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCcwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('back')))])], 1)], 1), _c('TopupPopupConfirmManual', {
    attrs: {
      "pay-code": _vm.paymentCode,
      "pay-amount": _vm.paymentAmount,
      "pay-type": _vm.paymentType
    },
    on: {
      "hidden": _vm.resetPaymentData,
      "confirm-manual-success": function confirmManualSuccess($event) {
        return _vm.$emit('confirm-manual-success');
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }