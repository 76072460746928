var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "m-0",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('b-col', {
    staticClass: "justify-content-center mb-1 pl-0",
    attrs: {
      "sm": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0 form-group-custom  "
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "id": "nested-city",
      "reduce": function reduce(val) {
        return val.id;
      },
      "placeholder": _vm.$t('customer.placeholderCity'),
      "options": _vm.optionsIdCity,
      "label": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var name = _ref.name;
        return [_c('span', [_vm._v(" " + _vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref2) {
        var name = _ref2.name;
        return [_c('span', [_vm._v(" " + _vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedIdCity,
      callback: function callback($$v) {
        _vm.selectedIdCity = $$v;
      },
      expression: "selectedIdCity"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "justify-content-center mb-1 ",
    attrs: {
      "sm": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0 form-group-custom "
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "id": "nested-state",
      "reduce": function reduce(val) {
        return val.id;
      },
      "label": "name",
      "options": _vm.optionsIdDistrict,
      "placeholder": _vm.$t('customer.placeholderDistrict')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref3) {
        var name = _ref3.name;
        return [_c('span', [_vm._v(" " + _vm._s(name) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref4) {
        var name = _ref4.name;
        return [_c('span', [_vm._v(" " + _vm._s(name) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedIdDistrict,
      callback: function callback($$v) {
        _vm.selectedIdDistrict = $$v;
      },
      expression: "selectedIdDistrict"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "justify-content-center mb-1 ",
    attrs: {
      "sm": "12",
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0 flex-grow-1"
  }, [_c('b-input-group', [_c('b-form-input', {
    staticClass: "search-input",
    attrs: {
      "id": "nested-country",
      "type": "search",
      "placeholder": _vm.$t('customer.placeholderSearch'),
      "value": _vm.searchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    model: {
      value: _vm.searchTextFilter,
      callback: function callback($$v) {
        _vm.searchTextFilter = $$v;
      },
      expression: "searchTextFilter"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "justify-content-center mb-1 ",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    attrs: {
      "placeholder": _vm.$t('customer.balanceStartDate'),
      "config": {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        locale: _vm.i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex-center mb-1 pr-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-button', {
    staticClass: "px-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": _vm.toggleAddCustomer
    }
  }, [_c('feather-icon', {
    staticStyle: {
      "color": "white !important",
      "font-weight": "600",
      "align-items": "center",
      "margin-right": "10px"
    },
    attrs: {
      "icon": "UserPlusIcon",
      "size": "18"
    }
  }), _c('i', {
    staticClass: "pi pi-plus-circle"
  }), _vm._v(_vm._s(_vm.$t('customer.addCustomer')) + " ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }