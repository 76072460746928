<template>
  <b-modal
    id="modal-void-ticket-cancel-alert"
    :title="title || $t('reservation.voidTicket.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    no-close-on-backdrop
  >
    <b-alert
      variant="warning"
      show
      class="p-1 py-50"
    >
      <span class="fw-700">{{ $t('reservation.voidTicket.caution') }}</span>
    </b-alert>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BButton, BAlert,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
