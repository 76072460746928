<template>
  <div>
    <b-modal
      id="modal-cancel-flight-vn1a"
      :title="$t('reservation.cancelFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      footer-class="px-50 m-0"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
      @show="showHandle"
    >
      <IAmOverlay :loading="loading">
        <template v-if="segmentList?.length">
          <!-- ANCHOR - Select segment -->
          <div>
            <BAlert
              show
              variant="danger"
              class="px-1 py-75 m-0"
            >
              Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có).
            </BAlert>
            <div class="text-medium-4 d-flex-between font-weight-bolder">
              <div>{{ $t('reservation.selectItineraryToCancel') }}</div>
              <BButton
                v-b-tooltip.hover
                variant="flat-dark"
                class="rounded-circle p-50"
                title="Tải lại hành trình"
                @click="getSegment"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="16"
                  :class="`${loading ? 'spinner-1s' : ''}`"
                />
              </BButton>
            </div>
            <b-form-group>
              <template #label>
                <b-form-checkbox
                  v-model="allSelected"
                  aria-describedby="flights"
                  aria-controls="flights"
                  class="custom-checkbox-label mb-50"
                  @change="toggleAll"
                >
                  <div class="text-body-2">
                    {{ $t('reservation.selectAll') }}
                  </div>
                </b-form-checkbox>
              </template>
              <template #default="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-cancel-flight-vn1a"
                  v-model="selectedFlightToCancel"
                  :aria-describedby="ariaDescribedby"
                  stacked
                  class="ml-1 ml-sm-2"
                  name="flights"
                >
                  <div
                    v-for="(segment) in segmentList"
                    :key="segment.index"
                    class="d-flex "
                  >
                    <b-form-checkbox
                      :value="segment"
                      class="custom-checkbox-label mb-50"
                    >
                      <span class="font-weight-bolder font-medium-3 text-dark">{{ segment.segmentText }}</span>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </div>

          <!-- ANCHOR - Recalculate price -->
          <div>
            <b-form-checkbox
              v-model="isRecalculate"
              name="retain-checkbox"
            >
              <div class="text-body fw-600 font-medium-1">
                Tính lại giá
              </div>
            </b-form-checkbox>
            <small
              v-if="isRecalculate"
              class="text-warning"
            > (*) {{ $t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']') }} ! </small>
          </div>
          <!-- ANCHOR - Retain and BargainFinder -->
          <b-card
            v-if="canPriceBargainFinder && isRecalculate"
            body-class="py-1"
          >
            <div class="d-flex flex-wrap gap-12px justify-content-around">
              <b-form-checkbox
                v-model="isBargainFinder"
                name="retain-checkbox"
                class="mx-1"
              >
                <div class="text-body fw-600 font-medium-1">
                  {{ $t('reservation.bargainFinder') }}
                </div>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="keepGroup"
                class="mx-1"
                name="keepGroup-checkbox"
              >
                <div class="text-body fw-600 font-medium-1">
                  {{ $t('reservation.keepGroup') }}
                </div>
              </b-form-checkbox>
            </div>
          </b-card>
        </template>

        <template v-else-if="responseText">
          <b-form-textarea
            :value="responseText.booking.join('\n')"
            class="font-weight-bolder text-uppercase"
            rows="16"
            max-rows="40"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 70vh; overflow: auto;"
          />
        </template>

        <div class="d-flex-center gap-2 my-50">
          <b-button
            variant="outline-secondary"
            class="center rounded-pill px-1"
            @click="closeHandle()"
          >
            {{ $t('reservation.close') }}
          </b-button>

          <b-button
            v-if="!responseText"
            class="border-0 px-1"
            variant="outline-danger"
            :disabled="!selectedFlightToCancelLength"
            pill
            @click="submitHandle"
          >
            {{ $t('reservation.cancelFlight') }}
          </b-button>
        </div>
      </IAmOverlay>
    </b-modal>

    <!-- ======================================== Confirm new price: MODAL SHOW PRICE =================================== -->
    <BModal
      id="modal-cancel-recalculate-show-price-VN1A"
      :title="$t('reservation.showCalculatePrice.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-header-close
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="rounded-pill px-2"
          @click="handleCloseModalShowPriceNew(close)"
        >
          {{ $t('reservation.close') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient"
          pill
          @click="handleSavePrice"
        >
          <span class="">LƯU GIÁ MỚI</span>
        </b-button>
      </template>

      <div
        v-if="!isEmpty(newPriceItinerary)"
        class="position-relative"
      >
        <div
          v-if="tripDetailsItinerary"
          class="mb-1"
        >
          <div class="fw-700 font-medium-2">
            {{ $t('flight.flightInfo') }}
          </div>
          <b-form-textarea
            :value="tripDetailsItinerary"
            class="font-weight-bolder text-uppercase"
            rows="2"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;  overflow: auto;"
          />
        </div>

        <BTableLite
          v-if="!isEmpty(newPriceItinerary)"
          bordered
          responsive
          small
          hover
          striped
          body-class="p-0"
          class="mb-1 rounded"
          thead-class="text-center font-weight-bolder text-nowrap"
          tbody-class="text-center text-nowrap"
          :fields="['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
          :items="newPriceItinerary"
        >
          <template
            v-for="(column, indexColumn) in ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
            #[`head(${column})`]="data"
          >
            <div :key="indexColumn">
              <span>{{ $t(`reservation.${data.column}`) }}</span>
            </div>
          </template>

          <template #cell(passengerType)="{ item }">
            {{ item.paxType }}
          </template>
          <template #cell(net)="{ item }">
            {{ formatCurrency(item.netFare) }}
          </template>
          <template #cell(tax)="{ item }">
            {{ formatCurrency(item.tax) }}
          </template>
          <template #cell(total)="{ item }">
            <span class="fw-600">{{ formatCurrency(item.totalPrice) }}</span>
          </template>
          <template #cell(quantity)="{ item }">
            {{ item.paxNum ? formatCurrency(item.paxNum) : 1 }}
          </template>
          <template #cell(totalAmount)="{ item }">
            <span class="fw-800">{{ item.paxNum ? formatCurrency(item.totalPrice * item.paxNum) : formatCurrency(item.totalPrice) }}</span>
          </template>
        </BTableLite>

        <p :class="`text-right text-body text-nowrap mr-md-2 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
          Tổng tiền: <span :class="`text-airline fw-800 ${isMobileView ? 'font-medium-1' : 'font-medium-3'}`">{{ formatCurrency(newPriceItinerary.reduce((total, item) => total + (item.paxNum ? (item.totalPrice * item.paxNum) : item.totalPrice), 0)) }} VND</span>
        </p>

        <BAlert
          :show="isWarningItinerary"
          variant="warning"
          class="py-75 mt-1 text-center px-75"
        >
          <span :class="`fw-700 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
            GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!!
          </span>
        </BAlert>
      </div>
      <!-- </IAmOverlay> -->
    </BModal>
  </div>
</template>
<script>
import {
  BCard, BTableLite,
  BModal, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BButton, VBTooltip, BFormTextarea, BAlert,
} from 'bootstrap-vue'
import {
  ref, watch, computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
import { formatCurrency } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BButton,
    BFormTextarea,
    BAlert,
    BCard,
    BTableLite,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },

  directives: { 'b-tooltip': VBTooltip },
  props: {
    source: {
      type: String,
      default: '',
    },
    pnrNumber: {
      type: String,
      default: '',
    },
    paxCode: {
      type: String,
      default: '',
    },
    agencyCode: {
      type: String,
      default: '',
    },
  },
  setup(props, { root }) {
    const { toastWarning, toastSuccess, toastError } = useToast()
    const {
      delay, loading,
      commandsGetBooking,
      commandsCancelSegment,
      recalculatePriceItinerary,
      refetchBookingData,
    } = useReservationHandle()

    const segmentList = ref([])
    const selectedFlightToCancel = ref()
    const allSelected = ref(false)
    const responseText = ref()

    const isRecalculate = ref(false)
    const isBargainFinder = ref(false)
    const keepGroup = ref(false)
    const isRetain = ref(false)
    const segmentRecalculateList = computed(() => segmentList.value.filter(segment => !selectedFlightToCancel.value?.some(it => it?.index === segment?.index)))

    const tripDetailsItinerary = ref(null)
    const newPriceItinerary = ref(null)
    const isWarningItinerary = ref(false)

    const prePayload = ref(null)

    async function getSegment() {
      const payload = {
        source: props.source,
        pnrNumber: props.pnrNumber,
      }
      const res = await commandsGetBooking(payload)
      segmentList.value = res.segmentList
      if (res.segmentList?.length > 1) isRecalculate.value = true
    }

    watch(() => isRecalculate.value, val => {
      if (!val) {
        keepGroup.value = false
        isRetain.value = false
      }
    })

    async function showHandle() {
      selectedFlightToCancel.value = []
      segmentList.value = []
      allSelected.value = false
      responseText.value = ''

      isBargainFinder.value = false
      keepGroup.value = false
      isRetain.value = false

      tripDetailsItinerary.value = null
      newPriceItinerary.value = []
      isWarningItinerary.value = false

      prePayload.value = null

      await getSegment()
    }

    // F2, F3 tự bật isPriceBargainFinder trong employeeConfig mới được chọn tính giá rẻ nhất
    const canPriceBargainFinder = computed(() => {
      const employeeConfig = store.getters['userStore/getEmployeeConfig']
      const isRoleF1 = store.getters['userStore/getRoleMama']
      const { isPriceBargainFinder } = employeeConfig
      return !!(!isRoleF1 && isPriceBargainFinder) || isRoleF1
    })

    watch(selectedFlightToCancel, newValue => {
      if (newValue?.length === 0) {
        allSelected.value = false
      } else if (newValue?.length === segmentList.value.length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    }, { deep: true, immediate: true })

    function toggleAll() {
      selectedFlightToCancel.value = allSelected.value ? segmentList.value.map(item => item) : []
    }

    function closeHandle() {
      root.$bvModal.hide('modal-cancel-flight-vn1a')
    }

    function submitHandle() {
      if (isRecalculate.value && isEmpty(segmentRecalculateList.value)) {
        toastWarning({ title: 'Không thể tính lại giá nếu chọn huỷ tất cả hành trình !' })
        return
      }
      const contentMsg = () => this.$createElement('div', {
        domProps: {
          innerHTML: `<div class="font-weight-bolder">Bạn có chắc chắn muốn huỷ chuyến bay?</div>
            ${selectedFlightToCancel.value.map(item => `<div class="font-weight-bolder font-medium-3">${item.segmentText}</div>`).join('')}`,
        },
      })
      const titleMsg = () => this.$createElement('h4', { domProps: { innerHTML: root.$t('reservation.cancelFlight') } })
      root.$bvModal
        .msgBoxConfirm(contentMsg(), {
          title: titleMsg(),
          size: 'md',
          okVariant: 'danger',
          okTitle: root.$t('reservation.confirm'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'flat-dark',
          centered: true,
        })
        .then(async value => {
          if (value) {
            const payload = {
              segmentList: selectedFlightToCancel.value,
              source: props.source,
              agencyCode: props.agencyCode,
              paxCode: props.paxCode,
              pnrNumber: props.pnrNumber,
              ...(isRecalculate.value && {
                rePrice: true,
                bargainFinder: isBargainFinder.value,
                retain: isRetain.value,
                keepGroup: keepGroup.value,
              }),
            }

            prePayload.value = payload
            const reload = !isRecalculate.value

            const res = await commandsCancelSegment(payload, reload)

            if (isRecalculate.value) {
              tripDetailsItinerary.value = res?.trips
              newPriceItinerary.value = res?.paxPrice
              isWarningItinerary.value = res?.isWarning
              await delay(200)

              this.$bvModal.show('modal-cancel-recalculate-show-price-VN1A')
            } else {
              responseText.value = res
              segmentList.value = []
            }
          }
        })
    }

    function handleSavePrice() {
      if (prePayload.value) {
        // showCalculatePriceLoading.value = true
        this.$bvModal.show('modal-api-loading')

        recalculatePriceItinerary({
          rePrice: prePayload.value.rePrice,
          bargainFinder: prePayload.value.bargainFinder,
          keepGroup: prePayload.value.keepGroup,
          retain: true,
          source: props.source,
          pnrNumber: props.pnrNumber,
        })
          .then(() => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.saveRecalculatePriceSuccess',
            })
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.saveRecalculatePriceError',
            })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')

            this.$bvModal.hide('modal-cancel-flight-vn1a')
            this.$bvModal.hide('modal-cancel-recalculate-show-price-VN1A')
          })
      }
    }

    function handleCloseModalShowPriceNew(close) {
      this.$bvModal.hide('modal-cancel-flight-vn1a')
      this.$bvModal.hide('modal-cancel-recalculate-show-price-VN1A')
      close()
      refetchBookingData()
    }

    const selectedFlightToCancelLength = computed(() => selectedFlightToCancel.value?.length)

    return {
      showHandle,
      segmentList,
      selectedFlightToCancel,
      loading,
      getSegment,
      allSelected,
      submitHandle,
      toggleAll,
      closeHandle,
      selectedFlightToCancelLength,
      responseText,
      isEmpty,
      formatCurrency,

      handleSavePrice,
      isRetain,
      keepGroup,
      isRecalculate,
      isBargainFinder,
      canPriceBargainFinder,

      tripDetailsItinerary,
      newPriceItinerary,
      isWarningItinerary,

      handleCloseModalShowPriceNew,
    }
  },
}
</script>
<style lang="">

</style>
