var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "id": "incorrect-invoice-info-card",
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "usersOutline",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.infoCardTitle')) + " ")])], 1), _c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.notificationType'),
      "rules": "required",
      "vid": "notificationType"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$incorrectInvoiceD;
        var errors = _ref.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "notificationType"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.notificationType')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "w-100 vue-select-class",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "notificationType",
            "value": (_vm$incorrectInvoiceD = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD === void 0 ? void 0 : _vm$incorrectInvoiceD.notificationType,
            "name": _vm.$t('invoice.incorrectInvoices.columns.notificationType'),
            "options": _vm.INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
            "label": "label",
            "reduce": function reduce(val) {
              return val.value;
            },
            "disabled": !_vm.isEditing,
            "clearable": false,
            "placeholder": _vm.$t('placeholderSelect')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:incorrectInvoiceData', Object.assign({}, _vm.incorrectInvoiceData, {
                notificationType: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('BBadge', {
                staticClass: "px-75 text-wrap text-left",
                attrs: {
                  "variant": data.variant,
                  "rounded": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationType.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('BBadge', {
                staticClass: "px-75",
                attrs: {
                  "variant": data.variant,
                  "rounded": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationType.".concat(data.label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "notificationType",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.notificationNumber'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$incorrectInvoiceD2, _vm$incorrectInvoiceD3;
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "notificationNumber"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.notificationNumber')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          class: "".concat((_vm$incorrectInvoiceD2 = _vm.incorrectInvoiceData) !== null && _vm$incorrectInvoiceD2 !== void 0 && _vm$incorrectInvoiceD2.notificationNumber ? 'fw-800 text-16px' : ''),
          attrs: {
            "id": "notificationNumber",
            "value": (_vm$incorrectInvoiceD3 = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD3 === void 0 ? void 0 : _vm$incorrectInvoiceD3.notificationNumber,
            "placeholder": _vm.$t('invoice.incorrectInvoices.ph.notificationNumber'),
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": "",
            "state": errors[0] ? false : null
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "notificationNumber",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.dateOfIssuance'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _vm$incorrectInvoiceD4;
        var errors = _ref3.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "dateOfIssuance"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.dateOfIssuance')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "dateOfIssuance",
            "value": (_vm$incorrectInvoiceD4 = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD4 === void 0 ? void 0 : _vm$incorrectInvoiceD4.dateOfIssuance,
            "disabled": !_vm.isCreate,
            "placeholder": "".concat(_vm.$t('invoice.incorrectInvoices.columns.dateOfIssuance')),
            "config": {
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              maxDate: _vm.today,
              locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
              disableMobile: true
            }
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:incorrectInvoiceData', Object.assign({}, _vm.incorrectInvoiceData, {
                dateOfIssuance: val
              }));
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "dateOfIssuance",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$incorrectInvoiceD5;
        var errors = _ref4.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "taxReviewNoticeNumber"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "taxReviewNoticeNumber",
            "value": (_vm$incorrectInvoiceD5 = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD5 === void 0 ? void 0 : _vm$incorrectInvoiceD5.taxReviewNoticeNumber,
            "placeholder": _vm.$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber'),
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": true,
            "state": errors[0] ? false : null
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "notificationNumber",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$incorrectInvoiceD6;
        var errors = _ref5.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "dateOfTaxReviewNotice"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "dateOfTaxReviewNotice",
            "value": (_vm$incorrectInvoiceD6 = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD6 === void 0 ? void 0 : _vm$incorrectInvoiceD6.dateOfTaxReviewNotice,
            "placeholder": _vm.$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice'),
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": true,
            "state": errors[0] ? false : null
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "notificationNumber",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.incorrectInvoices.columns.placeOfIssuance'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var _vm$incorrectInvoiceD7;
        var errors = _ref6.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "placeOfIssuance"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.columns.placeOfIssuance')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "placeOfIssuance",
            "value": (_vm$incorrectInvoiceD7 = _vm.incorrectInvoiceData) === null || _vm$incorrectInvoiceD7 === void 0 ? void 0 : _vm$incorrectInvoiceD7.placeOfIssuance,
            "placeholder": _vm.$t('invoice.incorrectInvoices.columns.placeOfIssuance'),
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": true,
            "state": errors[0] ? false : null
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "notificationNumber",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }