import common from './common'
import sidebar from './sidebar'
import selectOptions from './selectOptions'
import messagesList from './messagesList'
import reservation from './reservation'
import customer from './customer'
import employee from './employee'
import agency from './agency'
import document from './document'
import login from './login'
import modal from './modal'
import flight from './flight'
import vnAirports from './vnAirports'
import topup from './topup'
import invoice from './invoice'
import auditLog from './auditLog'
import saleReport from './saleReport'
import errorNoti from './errorNoti'
import accountsPayable from './accountsPayable'
import msg from './msg'
import promotionAg from './promotionAg'
import promotionAirline from './promotionAirline'
import promotionUser from './promotionUser'
import ticket from './ticket'
import fee from './fee'
import myAccount from './myAccount'
import schedule from './schedule'
import scheduleCalendar from './scheduleCalendar'
import scheduleTag from './scheduleTag'
import flightPreparation from './flightPreparation'
import notification from './notification'
import retail from './retail'
import utils from './utils'
import packageConfig from './packageConfig'
import bankAccount from './bankAccount'
import resellTicket from './resellTicket'
import sepay from './sepay'
import supportReq from './supportReq'
import bank from './bank'
import train from './train'
import messBoard from './messBoard'
import tour from './tour'
import agentRequests from './agentRequests'

export default {
  ...common,
  ...sidebar,
  ...selectOptions,
  msg, // include common messages MSG_xxx BUT NOT FOR VALIDATION
  messBoard,
  modal,
  messagesList,
  login,
  reservation,
  customer,
  employee,
  agency,
  document,
  flight,
  vnAirports,
  topup,
  invoice,
  auditLog,
  saleReport,
  accountsPayable,
  errorNoti,
  promotionAg,
  promotionUser,
  promotionAirline,
  ticket,
  fee,
  myAccount,
  schedule,
  scheduleCalendar,
  scheduleTag,
  flightPreparation,
  notification,
  retail,
  utils,
  packageConfig,
  bankAccount,
  resellTicket,
  sepay,
  supportReq,
  bank,
  train,
  tour,
  agentRequests,
}
