export const COLORS = {
  YELLOW: '#EFAD02', // #FAC907: màu vàng cũ
  RED: '#E22326',
  GREEN: '#45B143',
  BLUE: '#0088FF',
  LIGHT_BLUE: '#40B0C9',
  DEEP_OCEAN_BLUE: '#1F6987',
  BLACK: '#343434',
  PINK: '#CD2A7A',
  INDIGO: '#6517F6',
  PURPLE: '#6E43C4',
  ORANGE: '#F47D00',
  TEAL: '#48C995',
  GRAY: '#78808A',
}

export const BACKGROUND_COLORS = {
  YELLOW: '#f5ef8a',
  RED: '#F9D3D4',
  GREEN: '#DAEFD9',
  BLUE: '#DDF3FF',
  LIGHT_BLUE: '#D9EFF4',
  DEEP_OCEAN_BLUE: '#D9EFF4',
  BLACK: '#D6D6D6',
  PINK: '#F5D4E4',
  INDIGO: '#E0D1FD',
  PURPLE: '#E2D9F3',
  ORANGE: '#FDE5CC',
  TEAL: '#DAF4EA',
  GRAY: '#E4E6E8',
}

/*
export const THEMES = {
  '#EFAD02': 'yellow',
  '#E22326': 'red',
  '#45B143': 'green',
  '#0088FF': 'blue',
  '#40B0C9': 'light_blue',
  '#343434': 'black',
  '#CD2A7A': 'pink',
  '#6517F6': 'indigo',
  '#6E43C4': 'purple',
  '#F47D00': 'orange',
  '#48C995': 'teal',
  '#78808A': 'gray',
}

shortean code by use Object.entries & reduce as following:
*/
// Những Trạng thái của số vé sẽ hiển thị số vé trên mặt vé
export const STATUS_TICKET_SHOW_TICKET_NUMBER_IN_ETICKET = ['OK', 'OPEN', 'CKIN', 'CTRL', 'CTRL | OK']

export const THEMES = Object.entries(COLORS).reduce((themes, [key, value]) => {
  themes[value.toLowerCase()] = key.toLowerCase()
  return themes
}, {})

export const BACKGROUND_COLOR_THEMES = Object.entries(BACKGROUND_COLORS).reduce((themes, [key, value]) => ({ ...themes, [key.toLowerCase()]: value.toLowerCase() }), {})

export const TICKET_COLOR_OPTIONS = [
  {
    value: COLORS.YELLOW,
    label: 'ticket.colors.yellow',
  },
  {
    value: COLORS.RED,
    label: 'ticket.colors.red',
  },
  {
    value: COLORS.GREEN,
    label: 'ticket.colors.green',
  },
  {
    value: COLORS.BLUE,
    label: 'ticket.colors.blue',
  },
  {
    value: COLORS.LIGHT_BLUE,
    label: 'ticket.colors.lightBlue',
  },
  {
    value: COLORS.DEEP_OCEAN_BLUE,
    label: 'ticket.colors.deepOceanBlue',
  },
  {
    value: COLORS.BLACK,
    label: 'ticket.colors.black',
  },
  {
    value: COLORS.PINK,
    label: 'ticket.colors.pink',
  },
  {
    value: COLORS.INDIGO,
    label: 'ticket.colors.indigo',
  },
  {
    value: COLORS.PURPLE,
    label: 'ticket.colors.purple',
  },
  {
    value: COLORS.ORANGE,
    label: 'ticket.colors.orange',
  },
  {
    value: COLORS.TEAL,
    label: 'ticket.colors.teal',
  },
  {
    value: COLORS.GRAY,
    label: 'ticket.colors.gray',
  },
]

export const TICKET_LANGUAGE_OPTIONS = [
  {
    value: 'vi',
    label: 'vietnam',
  },
  {
    value: 'en',
    label: 'english',
  },
]

export const ETICKET_TEMPLATE_OPTIONS = [
  { label: 'modern', value: 'MODERN' },
  { label: 'basic', value: 'BASIC' },
]
export const DEFAULT_CONFIG = {
  showAgency: true,
  showPrice: false,
  showTimeHold: true,
  showPayment: false,
  showStatus: false,
  showBookingClass: false,
  showPriceNetFare: false,
  showBackground: false,
  showBackgroundColor: false,
  showAgencyLogo: true,
  useQuillEditor: false,
  status: 'HOLD', // 'HOLD' || 'PAID'
  color: '#0088FF',
}

export const defaultNoteVn = `
<h4>
  <b style="color: red;">Quý khách xin lưu ý:</b>
</h4>
<p>- Quý khách vui lòng tới sân bay trước 90 phút (chuyến bay nội địa) & trước 180 phút (chuyến bay quốc tế) trước giờ khởi hành để làm thủ tục check-in.</p>
<p>- Giấy tờ tùy thân:</p>
<p>• Thẻ căn cước công dân (CCCD); Hộ chiếu (passport); Giấy phép lái xe; Thẻ Đảng viên.....</p>
<p>• Giấy xác nhận nhân thân: Do công an cấp xã nơi thường trú, có dán ảnh, đóng dấu giáp lai và có giá trị trong vòng 30 ngày kể từ ngày xác nhận.</p>
<p>• Ứng dụng VNeID: Hành khách có thể sử dụng tài khoản định danh điện tử mức độ 2 trên ứng dụng VNeID.</p>
<p>• Trẻ em dưới 14 tuổi: CCCD, Giấy khai sinh Bản gốc hoặc bản sao y trích lục hợp lệ.</p>
<p>• Trẻ em từ 14 tuổi trở lên (chưa có CCCD): Giấy xác nhận nhân thân, có giá trị trong vòng 30 ngày kể từ ngày xác nhận.</p>
<p>• Trẻ sơ sinh: Giấy chứng sinh có giá trị trong vòng 30 ngày, nếu chưa có giấy khai sinh.</p>
<p>• Hành khách là phụ nữ mang thai: Vui lòng liên hệ với đại lý hoặc đơn vị cung cấp dịch vụ để được tư vấn chi tiết.</p>
<h4 class="ql-align-center">
  <b>Xin cảm ơn và chúc Quý khách có một chuyến bay tốt đẹp!</b>
</h4>
`

export const defaultNoteEn = `
<h4>
  <b style="color: red;">Important note</b>
</h4>
<p>- Passengers are kindly requested to arrive at the airport at least 90 minutes prior to departure for domestic flights and 180 minutes for international flights to complete the check-in process.</p>
<p>- Identification Documents:</p>
<p>• National ID card (CCCD); Passport; Driver's license; Party member card, etc.</p>
<p>• Personal Identity Confirmation Document: Issued by the local police station where you reside, with a photo, an official stamp, and valid for 30 days from the date of issuance.</p>
<p>• VNeID Application: Passengers may use a level-2 electronic identification account on the VNeID app.</p>
<p>• Children under 14 years old: Original or certified copy of the birth certificate or a valid extract.</p>
<p>• Children aged 14 and above (without a National ID): Personal Identity Confirmation Document valid for 30 days from the date of issuance.</p>
<p>• Infants: Birth certificate is required if available, or a birth record valid for 30 days if the birth certificate has not been issued.</p>
<p>• Pregnant passengers: Please contact the airline or service provider for detailed advice.</p>
<h4 class="ql-align-center">
  <b>Thank you, and wish you a pleasant flight!</b>
</h4>
`

export const FLIGHTS_INFO_SAMPLE = [
  {
    bookingCode: 'NYQWRN',
    trip: 'HAN-SGN',
    segments: [
      {
        from: 'HAN',
        to: 'DAD',
        departTime: '07:45',
        arrivalTime: '09:00',
        departDate: '22/09/2023',
        arrivalDate: '22/09/2023',
        departWeekday: 'T6',
        arrivalWeekday: 'T6',
        airline: 'VN',
        operating: 'BL',
        flight: 'VN267',
        airCraft: '320',
        mileage: 390,
        durationHM: '1hr(s)15min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'E',
        transitTime: '00:45',
      },
      {
        from: 'DAD',
        to: 'SGN',
        departTime: '09:45',
        arrivalTime: '11:45',
        departDate: '22/09/2023',
        arrivalDate: '22/09/2023',
        departWeekday: 'T6',
        arrivalWeekday: 'T6',
        airline: 'VN',
        operating: '',
        flight: 'VN480',
        airCraft: '320',
        mileage: 373,
        durationHM: '2hr(s)00min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'E',
        transitTime: null,
      },
    ],
  },
  {
    bookingCode: 'D49BX3',
    trip: 'SGN-HAN',
    segments: [
      {
        from: 'SGN',
        to: 'HAN',
        departTime: '12:00',
        arrivalTime: '15:15',
        departDate: '24/09/2023',
        arrivalDate: '24/09/2023',
        departWeekday: 'CN',
        arrivalWeekday: 'CN',
        airline: 'QH',
        operating: '',
        flight: 'QH242',
        airCraft: '321',
        mileage: 718,
        durationHM: '3hr(s)15min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'Y',
        transitTime: null,
      },
    ],
  },
]

export const PAXS_SAMPLE = [
  {
    passenger: 'ticket.paxTable.pax1',
    bags: 'ticket.paxTable.bagsTicket1',
  },
  {
    passenger: 'ticket.paxTable.pax2',
    bags: 'ticket.paxTable.bagsTicket2',
  },
  {
    passenger: 'ticket.paxTable.pax3',
    bags: 'ticket.paxTable.bagsTicket3',
  },
]

export const PRICE_SAMPLE = {
  vatRateValue: 0.1,
  totalAmountBeforeTax: 1000000,
  currency: 'VND',
}

export const FARE_BOOKING_DATA_ARRAY_SAMPLE = [
  {
    trip: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
    departureDate: '2024-03-21T09:45:00+07:00',
    paxFare: [
      {
        paxType: 'ADULT',
        flight: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 2,
        fareBasisCode: 'TPXVNF9, RPXVNF9',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 3276000,
        tax: 2540000,
        total: 5816000,
      },
      {
        paxType: 'CHILD',
        flight: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 1,
        fareBasisCode: 'TPXVNF9/CH10, RPXVNF9/CH10',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 1476000,
        tax: 1138000,
        total: 2614000,
      },
    ],
    total: 8430000,
  },
  {
    trip: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
    departureDate: '2024-03-21T09:45:00+07:00',
    paxFare: [
      {
        paxType: 'ADULT',
        flight: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 2,
        fareBasisCode: 'TPXVNF9, RPXVNF9',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 3076000,
        tax: 2340000,
        total: 5416000,
      },
      {
        paxType: 'CHILD',
        flight: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 1,
        fareBasisCode: 'TPXVNF9/CH10, RPXVNF9/CH10',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 1476000,
        tax: 1138000,
        total: 2614000,
      },
    ],
    total: 8030000,
  },
]

export const DEFAULT_TRAIN_CONFIG = {
  // status: 'HOLD', // 'HOLD' || 'PAID'

  showAgency: true,
  showAgencyLogo: true,
  agencyInfo: '',
  agencyInfoEn: '',
  showStatus: true,
  locale: 'vi',
  useQuillEditor: false,
  note: '',
  noteEn: '',
  showPayment: false,
  showPrice: true,
  showPriceNetFare: true,
  showPriceDetail: true,
  color: '#0088FF',
  showBackground: true,
  showBackgroundColor: true,
}

export const defaultTrainNoteVn = `
<h4>
  <b style="color: red;">Quý khách xin lưu ý:</b>
</h4>
<ul>
  <li>
    Quý khách vui lòng có mặt tại ga từ 45-60 phút trước giờ tàu chạy, đặc biệt vào mùa lễ Tết nên đến sớm hơn 1h để có đủ thời gian hoàn thành các thủ tục (nếu có) như nhận vé, check in, tìm đường đến đúng ga và phòng hờ cho các trường hợp không mong muốn.
  </li>
  <li>
  Trẻ em từ 6 tuổi đến 10 tuổi: Có bản chính thức hoặc bản sao giấy khai sinh hoặc hộ chiếu ( trường hợp không có giấy tờ xác định được tuổi thì phải có chiều cao dưới 132 cm).
  </li>
  <li>
  Trẻ em dưới 6 tuổi (có người lớn đi kèm): Có bản chính thức hoặc bản sao giấy khai sinh hoặc hộ chiếu (trường hợp không có giấy tờ xác định được độ tuổi thì phải có chiều cao dưới 112 cm).
  </li>
  <li>
  Các đối tượng CSXH, các đối tượng giảm giá khác mang theo đầy đủ giấy tờ như khi đi mua vé để xuất trình cho nhân viên đường sắt kiểm tra khi có yêu cầu.
  </li>
  <li>
     Trường hợp giấy tờ tùy thân có thông tin không đúng với thông tin ghi vé giảm giá thì vé giảm giá đó coi như không hợp lệ.
  </li>
</ul>
<h4 class="ql-align-center">
  <b>Xin cảm ơn và chúc quý khách có một hành trình tốt đẹp!</b>
</h4>
`

export const defaultTrainNoteEn = `
<h4>
  <b style="color: red;">Important note:</b>
</h4>
<ul>
  <li>
    Please arrive at the station 45-60 minutes before the train departs. During the holiday season, we recommend arriving 1 hour earlier to allow enough time for any procedures (if necessary), such as ticket collection, check-in, finding the correct platform, and allowing extra time for any unexpected situations.
  </li>
  <li>
    Children aged 6 to 10: Please bring either an original or a copy of their birth certificate or passport (if there is no document to verify their age, the child must be shorter than 132 cm).
  </li>
  <li>
    Children under 6 years old (accompanied by an adult): Please bring either an original or a copy of their birth certificate or passport (if there is no document to verify their age, the child must be shorter than 112 cm).
  </li>
  <li>
    Social policy beneficiaries and other discount-eligible individuals should bring all necessary documents for verification by railway staff if requested.
  </li>
  <li>
    In case the personal identification information does not match the discounted ticket information, the discounted ticket will be considered invalid.
  </li>
</ul>
<h4 class="ql-align-center">
  <b>Thank you and wish you a pleasant journey!</b>
</h4>
`

export const TRAIN_LIST_TRIPS_EXAMPLE = [
  {
    departure: 'HNO',
    arrival: 'SGO',
    dayOfStart: '2024-11-23T17:00:00Z',
    departureName: 'Hà Nội',
    arrivalName: 'Sài Gòn',
    departureTime: '2024-11-23T17:00:00Z',
    departureHour: '21:10',
    arrivalTime: '2024-11-25T17:00:00Z',
    arrivalHour: '08:25',
    trainCode: 'SE1',
    tripInfos: [
      {
        id: 8622154,
        pdfUrl: null,
        status: {
          id: 4,
          description: 'Đang chờ thanh toán trả sau',
        },
        arrival: 'SGO',
        fullName: 'TRAN VAN NAM',
        ticketId: 116070469,
        coachCode: 'A64L',
        coachName: '1',
        departure: 'HNO',
        issueDate: '2024-11-22T21:57:11Z',
        paymentId: 4306818,
        trainCode: 'SE1',
        dayOfStart: '2024-11-23T17:00:00Z',
        seatNumber: 1,
        statusText: 'Chờ thanh toán',
        totalPrice: 1253000,
        arrivalHour: '08:25',
        arrivalName: 'Sài Gòn',
        arrivalTime: '2024-11-25T17:00:00Z',
        bookingCode: 'YQ5PKN',
        companyCode: null,
        seatTypeCode: 'NML',
        seatTypeName: 'Ngồi mềm điều hòa',
        departureHour: '21:10',
        departureName: 'Hà Nội',
        departureTime: '2024-11-23T17:00:00Z',
        paymentMethod: 1,
        identifyNumber: '09876543212',
        finishPaymentDate: '2024-11-22T22:12:12Z',
        passengerTargetCode: 1,
        fee: 10000,
        parentFee: 0,
        feeService: 20000,
        totalPricePaid: 1263000,
        totalPriceTicket: 1273000,
      },
      {
        id: 8622155,
        pdfUrl: null,
        status: {
          id: 4,
          description: 'Đang chờ thanh toán trả sau',
        },
        arrival: 'SGO',
        fullName: 'HOANG THI THUY',
        ticketId: 116076794,
        coachCode: 'An28LV',
        coachName: '9',
        departure: 'HNO',
        issueDate: '2024-11-22T21:57:11Z',
        paymentId: 4306818,
        trainCode: 'SE1',
        dayOfStart: '2024-11-23T17:00:00Z',
        seatNumber: 13,
        statusText: 'Chờ thanh toán',
        totalPrice: 1029000,
        arrivalHour: '08:25',
        arrivalName: 'Sài Gòn',
        arrivalTime: '2024-11-25T17:00:00Z',
        bookingCode: 'YQ5PKN',
        companyCode: null,
        seatTypeCode: 'AnLT1v',
        seatTypeName: 'Nằm khoang 4 điều hòa',
        departureHour: '21:10',
        departureName: 'Hà Nội',
        departureTime: '2024-11-23T17:00:00Z',
        paymentMethod: 1,
        identifyNumber: '0987654320099',
        finishPaymentDate: '2024-11-22T22:12:12Z',
        passengerTargetCode: 1,
        fee: 10000,
        parentFee: 0,
        feeService: 20000,
        totalPricePaid: 1039000,
        totalPriceTicket: 1049000,
      },
    ],
  },
  {
    departure: 'SGO',
    arrival: 'HNO',
    dayOfStart: '2024-11-28T17:00:00Z',
    departureName: 'Sài Gòn',
    arrivalName: 'Hà Nội',
    departureTime: '2024-11-28T17:00:00Z',
    departureHour: '19:00',
    arrivalTime: '2024-11-30T17:00:00Z',
    arrivalHour: '05:30',
    trainCode: 'SE4',
    tripInfos: [
      {
        id: 8622156,
        pdfUrl: null,
        status: {
          id: 4,
          description: 'Đang chờ thanh toán trả sau',
        },
        arrival: 'HNO',
        fullName: 'TRAN VAN NAM',
        ticketId: 116988671,
        coachCode: 'A64L',
        coachName: '1',
        departure: 'SGO',
        issueDate: '2024-11-22T21:57:11Z',
        paymentId: 4306818,
        trainCode: 'SE4',
        dayOfStart: '2024-11-28T17:00:00Z',
        seatNumber: 5,
        statusText: 'Chờ thanh toán',
        totalPrice: 817000,
        arrivalHour: '05:30',
        arrivalName: 'Hà Nội',
        arrivalTime: '2024-11-30T17:00:00Z',
        bookingCode: 'YQ5PKN',
        companyCode: null,
        seatTypeCode: 'NML',
        seatTypeName: 'Ngồi mềm điều hòa',
        departureHour: '19:00',
        departureName: 'Sài Gòn',
        departureTime: '2024-11-28T17:00:00Z',
        paymentMethod: 1,
        identifyNumber: '09876543212',
        finishPaymentDate: '2024-11-22T22:12:12Z',
        passengerTargetCode: 1,
        fee: 10000,
        parentFee: 0,
        feeService: 20000,
        totalPricePaid: 827000,
        totalPriceTicket: 837000,
      },
    ],
  },
]
