var render = function () {
  var _vm$reservationsData2, _vm$fnAncillary, _vm$fnAncillary$BAGGA, _vm$fnAncillary2, _vm$fnAncillary2$SEAT, _vm$fnAncillary3, _vm$fnAncillary3$OTHE, _vm$reservationsData3, _vm$reservationsData4, _vm$reservationsData$, _vm$reservationsData$2, _vm$reservationsData5, _vm$reservationsData6, _vm$reservationsData7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        var _vm$reservationsData;
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/passengers.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.passengerDetails')) + " ")])], 1), _c('div', {
          staticStyle: {
            "margin-right": "2.5rem"
          }
        }, [((_vm$reservationsData = _vm.reservationsData) === null || _vm$reservationsData === void 0 ? void 0 : _vm$reservationsData.paxLists.length) > 0 ? _c('BButton', {
          staticClass: "p-50 rounded-circle m-0",
          attrs: {
            "variant": "flat-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.handleCopyPassengers($event);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "text-airline",
          attrs: {
            "icon": "CopyIcon",
            "size": "18",
            "title": "".concat(_vm.$t('copy'), " ").concat(_vm.$t('reservation.passenger'))
          }
        })], 1) : _vm._e()], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-row', _vm._l(_vm.reservationsData.paxLists, function (pax, paxIndex) {
    return _c('b-col', {
      key: paxIndex,
      staticClass: "my-50",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('PaxDetail', {
      attrs: {
        "pax": pax,
        "pax-index": paxIndex,
        "reservations-data": _vm.reservationsData,
        "is-hide-function-button": _vm.isHideFunctionButton,
        "hide-feature": _vm.hideFeature,
        "is-booking-out-system": _vm.isBookingOutSystem
      },
      on: {
        "openEditTravelFlyPassengerModalHandle": function openEditTravelFlyPassengerModalHandle($event) {
          return _vm.openEditTravelFlyPassengerModalHandle(pax);
        },
        "handleDeleteInfant": function handleDeleteInfant($event) {
          return _vm.handleDeleteInfant(_vm.reservationsData, pax);
        },
        "deleteBSVHandle": function deleteBSVHandle($event) {
          return _vm.deleteBSVHandle(_vm.reservationsData, pax);
        }
      }
    })], 1);
  }), 1), !_vm.isEmpty((_vm$reservationsData2 = _vm.reservationsData) === null || _vm$reservationsData2 === void 0 ? void 0 : _vm$reservationsData2.paxListsCancelled) ? _c('b-card', {
    staticClass: "border mt-1",
    attrs: {
      "header-bg-variant": "light-danger",
      "header-class": "p-1 py-50",
      "body-class": "p-1 py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-medium-1 fw-800 text-danger"
        }, [_vm._v(" Hành khách đã huỷ ")])];
      },
      proxy: true
    }], null, false, 1333919751)
  }, _vm._l(_vm.reservationsData.paxListsCancelled, function (p, pIndex) {
    return _c('div', {
      key: pIndex,
      staticClass: "my-50",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-start mr-50"
    }, [_c('div', {
      staticClass: "font-medium-3 text-danger"
    }, [_c('span', [_vm._v(_vm._s("".concat(pIndex + 1, ". ")))]), _c('code', {
      staticClass: "text-danger font-italic bg-transparent pl-0 pr-25"
    }, [_vm._v(" " + _vm._s("".concat(p.paxType ? "(".concat(_vm.$t("reservation.".concat(p.paxType)), ")") : '')) + " ")]), _c('b', [_vm._v(_vm._s("".concat(p === null || p === void 0 ? void 0 : p.lastName, " / ").concat(p === null || p === void 0 ? void 0 : p.firstName, " ").concat((p === null || p === void 0 ? void 0 : p.title) || '', " ")))])])])]);
  }), 0) : _vm._e()], 1)], 1), !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "d-flex-center justify-content-end gap-1 my-50 flex-wrap"
  }, [(_vm$fnAncillary = _vm.fnAncillary) !== null && _vm$fnAncillary !== void 0 && (_vm$fnAncillary$BAGGA = _vm$fnAncillary.BAGGAGE) !== null && _vm$fnAncillary$BAGGA !== void 0 && _vm$fnAncillary$BAGGA.available || _vm.enableDevelopEnv('baggage', _vm.bookingSource) ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.canAccess('booking.addBag') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL',
      "size": "sm"
    },
    on: {
      "click": _vm.showEditBaggage
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/extra-luggage.svg"),
      "width": "17"
    }
  }), _c('span', {
    staticClass: "font-small-4",
    staticStyle: {
      "padding-bottom": "1px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addBaggage')) + " ")])], 1) : _vm._e(), (_vm$fnAncillary2 = _vm.fnAncillary) !== null && _vm$fnAncillary2 !== void 0 && (_vm$fnAncillary2$SEAT = _vm$fnAncillary2.SEAT) !== null && _vm$fnAncillary2$SEAT !== void 0 && _vm$fnAncillary2$SEAT.available || _vm.enableDevelopEnv('seat', _vm.bookingSource) ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.canAccess('booking.addSeat') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL',
      "size": "sm"
    },
    on: {
      "click": _vm.showEditSeat
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/chair-blue.svg"),
      "width": "17"
    }
  }), _c('span', {
    staticClass: "font-small-4",
    staticStyle: {
      "padding-bottom": "1px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addSeat')) + " ")])], 1) : _vm._e(), (_vm$fnAncillary3 = _vm.fnAncillary) !== null && _vm$fnAncillary3 !== void 0 && (_vm$fnAncillary3$OTHE = _vm$fnAncillary3.OTHER) !== null && _vm$fnAncillary3$OTHE !== void 0 && _vm$fnAncillary3$OTHE.available || _vm.enableDevelopEnv('other', _vm.bookingSource) ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.canAccess('booking.addSeat') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL',
      "size": "sm"
    },
    on: {
      "click": _vm.showEditOther
    }
  }, [_c('b-img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/dots-vertical.svg"),
      "width": "17"
    }
  }), _c('span', {
    staticClass: "font-small-4",
    staticStyle: {
      "padding-bottom": "1px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addServices')) + " ")])], 1) : _vm._e(), ['VJ', 'VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit || ['CANCEL'].includes(_vm.reservationsData.status) || !((_vm$reservationsData3 = _vm.reservationsData) !== null && _vm$reservationsData3 !== void 0 && (_vm$reservationsData4 = _vm$reservationsData3.itineraries) !== null && _vm$reservationsData4 !== void 0 && _vm$reservationsData4.length),
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openModifyChangePassengersInfo(_vm.reservationsData.source);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "pencil",
      "size": "15"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.modifyPaxsInfo')))])], 1) : _vm._e(), _vm.SOURCES_CAN.ADD_INFANT.includes(_vm.reservationsData.source) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    style: ['VU'].includes(_vm.reservationsData.source) ? "max-width: 340px;" : '',
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL'
    },
    on: {
      "click": function click($event) {
        return _vm.openAddInfantModalHandle(_vm.reservationsData.paxLists, _vm.reservationsData);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/increase.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.addInfant')))])], 1) : _vm._e(), ['1G', 'VJ'].includes(_vm.reservationsData.source) || ['1A'].includes(_vm.reservationsData.source) && !_vm.NDC_1A_BOOKING ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem || _vm.reservationsData.status === 'CANCEL',
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openModifyDocumentsModalHandle(_vm.reservationsData);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "pencil",
      "size": "15"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(['VJ'].includes(_vm.reservationsData.source) ? _vm.$t('reservation.modifyDocumentsVJ') : _vm.$t('reservation.modifyDocuments')))])], 1) : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem
    },
    on: {
      "click": _vm.openAddMultiFftvModal
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CreditCardIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.addBSV')))])], 1) : _vm._e(), ['1G'].includes(_vm.reservationsData.source) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem
    },
    on: {
      "click": _vm.openEditCtcmCtceFor1gModal
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InboxIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.editCtcmCtceFor1g.title')))])], 1) : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "disabled": _vm.isBookingOutSystem,
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAddDocumentsModal
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "addReceipt",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.addDocuments.title')))])], 1) : _vm._e(), _vm.canSplitPnr(_vm.reservationsData) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": !_vm.canAccess('booking.diveBooking') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.reservationsData.status === 'CANCEL'
    },
    on: {
      "click": _vm.openSeparateBookingModalHandle
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/split-pax.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.separateBooking')))])], 1) : _vm._e()], 1) : _vm._e(), _c('ModalSeparateBooking'), _c('ModalAddInfant', {
    attrs: {
      "adult-option": _vm.adultOptions,
      "booking-data": _vm.reservationsData
    }
  }), _vm.paxListsData ? _c('ModalModifyDocuments', {
    attrs: {
      "pax-lists": _vm.paxListsData,
      "reservations-data": _vm.reservationsData
    }
  }) : _vm._e(), ['VJ'].includes(_vm.reservationsData.source) && _vm.paxListsData ? _c('ModalModifyDocumentsVJ', {
    attrs: {
      "pax-lists": _vm.paxListsData,
      "reservations-data": _vm.reservationsData
    }
  }) : _vm._e(), !_vm.isEmpty(_vm.passengerData) ? _c('ModalEditTravelFlyPassenger', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "passenger-data": _vm.passengerData
    }
  }) : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('ModalAddMultiFftv', {
    attrs: {
      "booking-code": _vm.reservationsData.bookingCode,
      "source": _vm.reservationsData.source,
      "pax-lists": _vm.reservationsData.paxLists,
      "default-phone-number": (_vm$reservationsData$ = _vm.reservationsData.contact.paxInfo.phone[0]) === null || _vm$reservationsData$ === void 0 ? void 0 : _vm$reservationsData$.address,
      "default-email-address": (_vm$reservationsData$2 = _vm.reservationsData.contact.paxInfo.email[0]) === null || _vm$reservationsData$2 === void 0 ? void 0 : _vm$reservationsData$2.address
    }
  }) : _vm._e(), ['1G'].includes(_vm.reservationsData.source) ? _c('ModalEditCtcmCtceFor1g', {
    attrs: {
      "booking-code": _vm.reservationsData.bookingCode,
      "pax-lists": _vm.reservationsData.paxLists,
      "source": _vm.reservationsData.source,
      "agency-code": (_vm$reservationsData5 = _vm.reservationsData) === null || _vm$reservationsData5 === void 0 ? void 0 : (_vm$reservationsData6 = _vm$reservationsData5.createdBy) === null || _vm$reservationsData6 === void 0 ? void 0 : (_vm$reservationsData7 = _vm$reservationsData6.agency) === null || _vm$reservationsData7 === void 0 ? void 0 : _vm$reservationsData7.agencyCode,
      "pax-code": _vm.reservationsData.paxContact.code,
      "ssr-lists": _vm.reservationsData.ssr
    }
  }) : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('ModalAddDocumentsVN1A', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }) : _vm._e(), !['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('ModalEditInfoPassenger') : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('ModalEditInfoPassengerVN1A') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }