<template>
  <IAmOverlay :loading="loading">
    <!-- ANCHOR b-card calendar -->
    <b-card>
      <div class="d-flex gap-2">
        <span class="bg-grey px-1 rounded-pill gap-2 d-flex align-items-center">
          <b-button
            variant="flat-dark"
            class="rounded-circle border border-dark"
            size="sm"
            style="width: 28px; height: 28px; padding: 0;"
            @click="onClickMonthButtonHandle('prev')"
          >
            <IAmIcon
              icon="chevronLeftOutline"
              size="18"
            />
          </b-button>
          <div style="width: 100px;">
            <v-select
              id="selectMonth"
              v-model="month"
              :options="[1,2,3,4,5,6,7,8,9,10,11,12]"
              :reduce="data => data"
              :clearable="false"
              class="w-100"
            >
              <template #option="data">
                <span class="text-center mx-auto">
                  {{ getMonthText(data.label) }}
                </span>
              </template>
              <template #selected-option="data">
                <span>
                  {{ getMonthText(data.label) }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </div>

          <b-button
            variant="flat-dark"
            class="rounded-circle border border-dark"
            size="sm"
            style="width: 28px; height: 28px; padding: 0;"
            @click="onClickMonthButtonHandle('next')"
          >
            <IAmIcon
              icon="chevronRightOutline"
              size="18"
            />
          </b-button>
        </span>
        <span class="bg-grey px-1 rounded-pill gap-2 d-flex align-items-center">
          <b-button
            variant="flat-dark"
            class="rounded-circle border border-dark"
            size="sm"
            :disabled="false"
            style="width: 28px; height: 28px; padding: 0;"
            @click="year--"
          >
            <IAmIcon
              icon="chevronLeftOutline"
              size="18"
            />
          </b-button>

          <v-select
            id="selectYear"
            v-model="year"
            :options="yearOptions"
            :reduce="data => data"
            :getOptionLabel="data => data"
            :no-options="$t('noOptions')"
            :clearable="false"
            style="min-width: 70px;"
          />
          <b-button
            variant="flat-dark"
            class="rounded-circle border border-dark"
            size="sm"
            style="width: 28px; height: 28px; padding: 0;"
            :disabled="false"
            @click="year++"
          >
            <IAmIcon
              icon="chevronRightOutline"
              size="18"
            />
          </b-button>
        </span>
      </div>
      <!-- content -->
      <FullCalendar
        id="refCalendar"
        ref="refCalendar"
        :options="calendarOptions"
      >
        <template #eventContent="arg">
          <Stack>
            <div
              v-if="arg.event.extendedProps.flights.length > 0"
              class="d-flex p-0"
            >
              <HStack
                class="text-danger bg-light-danger w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25"
                style="overflow-x: hidden;"
              >
                <div
                  class="rounded-circle d-flex-center bg-danger"
                  style="width: 25px;height: 25px;"
                >
                  <strong class="text-white">
                    {{ arg.event.extendedProps.flights.length }}
                  </strong>
                </div>
                <IAmIcon
                  icon="sentOutline"
                  size="18"
                  class="text-danger"
                />
                <div class="d-none d-xl-block">
                  {{ $t('reservation.flight') }}
                </div>
              </HStack>
            </div>

            <div
              v-if="arg.event.extendedProps.trains.length > 0"
              class="d-flex p-0"
            >
              <HStack
                class="text-success bg-light-success w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25"
                style="overflow-x: hidden;"
              >
                <div
                  class="rounded-circle d-flex-center bg-success"
                  style="width: 25px;height: 25px;"
                >
                  <strong class="text-white">
                    {{ arg.event.extendedProps.trains.length }}
                  </strong>
                </div>
                <IAmIcon
                  icon="trainIcon"
                  size="18"
                  class="text-success"
                />
                <div class="d-none d-xl-block">
                  {{ $t('train.title') }}
                </div>
              </HStack>
            </div>
            <div
              v-if="arg.event.extendedProps.tours.length > 0"
              class="d-flex p-0"
            >
              <HStack
                class="text-info bg-light-info w-100 font-weight-bolder align-items-center rounded-lg px-50 py-25"
                style="overflow-x: hidden;"
              >
                <div
                  class="rounded-circle d-flex-center bg-info"
                  style="width: 25px;height: 25px;"
                >
                  <strong class="text-white">
                    {{ arg.event.extendedProps.tours.length }}
                  </strong>
                </div>
                <IAmIcon
                  icon="tour"
                  size="18"
                  class="text-info"
                />
                <div class="d-none d-xl-block">
                  Tour
                </div>
              </HStack>
            </div>
          </Stack>
        </template>
      </FullCalendar>
    </b-card>
    <!-- ANCHOR b-card detail -->
    <b-card v-if="itemsByFilter.length > 0">
      <!-- content -->
      <div
        v-for="item in itemsByFilter"
        :key="item.date"
      >
        <div
          class="font-weight-bolder"
          style="font-size: 20px;"
        >
          {{ convertISODateTime(item.date, getCurrentTimezone(), locale).fullDateStr }}
        </div>
        <div class="text-danger mb-1">
          {{ $t('flight.noteCalendar') }}
        </div>
        <template v-if="item.flights.length">
          <b-link
            v-for="(flight, index) in item.flights"
            :key="`flight-${index}`"
            class="d-flex flex-wrap cursor-pointer flight"
            @click="openBookingDetail('apps-reservations-modify', flight.id)"
          >
            <div
              v-for="(itinerary, itiIndex) in flight.itineraries"
              :key="`itinerary-${itiIndex}`"
              class="d-flex cursor-pointer flight p-0"
            >
              <span class="mr-1 pr-1 font-weight-bolder border-right-danger border-2 text-danger">{{ convertISODateTime(first(itinerary)?.departure.at).time }}</span>
              <div class="d-flex gap-1 flex-column">
                <div class="text-danger font-weight-bolder d-flex gap-1 align-items-center flex-wrap">
                  <div
                    class="bg-danger rounded-circle d-flex-center"
                    style="width: 28px; height: 28px;"
                  >
                    <IAmIcon
                      icon="sentOutline"
                      size="18"
                      class="text-white"
                    />
                  </div>
                  {{ $t('reservation.flightTo') }} {{ last(itinerary)?.arrival?.city }}
                  <span
                    v-for="(ff, ind) in itinerary"
                    :key="`flight-number-${ind}`"
                  >
                    ({{ ff.airline }}{{ ff.flightNumber }})
                  </span>
                </div>
                <div
                  v-for="(cc,ind) in itinerary"
                  :key="`info-${ind}`"
                  class="d-flex flex-column flex-md-row gap-2 text-dark"
                >
                  <div class="d-flex text-dark gap-2">
                    <strong>{{ flight.bookingCode }}</strong>
                    <span>{{ convertISODateTime(cc.departure.at,cc.departure.timeZone).time }}-{{ convertISODateTime(cc.arrival.at,cc.arrival.timeZone).time }}  {{ convertISODateTime(cc.departure.at,cc.departure.timeZone).dayMonth }}</span>
                    <span>{{ `${cc.departure.iataCode}${cc.arrival.iataCode}` }}</span>
                  </div>
                  <span>{{ `${flight.paxLists[0].lastName} ${flight.paxLists[0].firstName}` }} {{ `${flight.paxLists.length >1 ? `+${flight.paxLists.length -1}` : ' '}` }}</span>
                </div>
              </div>
            </div>
          </b-link>
        </template>

        <template v-if="item?.trains?.length">
          <b-link
            v-for="(train, trainIndex) in item.trains"
            :key="`train-${trainIndex}`"
            class="d-flex flex-wrap cursor-pointer flight"
            @click="openBookingDetail('apps-train-ticketDetail', train.id)"
          >
            <span class="mr-1 pr-1 font-weight-bolder border-right-success border-2 text-success">{{ train.listTrip?.[0].departureHour }}</span>
            <div class="d-flex gap-1 flex-column">
              <div class="text-success font-weight-bolder d-flex gap-1 align-items-center flex-wrap">
                <div
                  class="bg-success rounded-circle d-flex-center"
                  style="width: 28px; height: 28px;"
                >
                  <IAmIcon
                    icon="trainIcon"
                    size="18"
                    class="text-white"
                  />
                </div>
                {{ $t('train.trainTo') }} {{ train.listTrip?.[0].arrivalName }} ({{ train.listTrip?.[0].trainCode }})
              </div>
              <div class="d-flex flex-column flex-md-row gap-2 text-dark">
                <div class="d-flex text-dark gap-2">
                  <strong>{{ train.bookingCode }}</strong>
                  <HStack>
                    {{ train.listTrip?.[0].departureHour }} ({{ convertISODateTime(train.listTrip?.[0].departureTime).dayAndMonth }})
                    -
                    {{ train.listTrip?.[0].arrivalHour }} ({{ convertISODateTime(train.listTrip?.[0].arrivalTime).dayAndMonth }})
                  </HStack>
                  <span>{{ `${train.listTrip?.[0].departure}${train.listTrip?.[0].arrival}` }}</span>
                </div>
                <span>{{ `${train.listPassenger?.[0].fullName}` }} {{ `${train.listPassenger?.length > 1 ? `+${train.listPassenger?.length - 1}` : ' '}` }}</span>
              </div>
            </div>
          </b-link>
        </template>

        <template v-if="item?.tours?.length">
          <b-link
            v-for="(tour, tourIndex) in item.tours"
            :key="`tour-${tourIndex}`"
            class="d-flex flex-wrap cursor-pointer flight"
            @click="openBookingDetail('apps-toursBooking-detail', tour.bookingCode)"
          >
            <span class="mr-1 pr-1 font-weight-bolder border-right-info border-2 text-info">{{ tour?.departureHour ?? '-' }}</span>
            <div class="d-flex gap-1 flex-column">
              <div class="text-info font-weight-bolder d-flex gap-1 align-items-center flex-wrap">
                <div
                  class="bg-info rounded-circle d-flex-center"
                  style="width: 28px; height: 28px;"
                >
                  <IAmIcon
                    icon="tour"
                    size="18"
                    class="text-white"
                  />
                </div>
                {{ $t('Tour List') }} {{ tour?.tour?.tourName }}
              </div>
              <HStack class="text-dark gap-2">
                <strong>{{ tour.bookingCode }}</strong>
                <span>{{ tour?.tour?.tourTotalDay }}N{{ tour?.tour?.tourTotalNight }}Đ </span>
                <span v-if="tour?.numberAdults">
                  <span class="fw-700">
                    {{ tour?.numberAdults }}
                  </span> <span class="font-italic">
                    Người lớn
                  </span>
                </span>
                <span v-if="tour?.numberChildren1">
                  <span class="fw-700">
                    {{ tour?.numberChildren1 }}
                  </span> <span class="font-italic">
                    Trẻ em 1
                  </span>
                </span>
                <span v-if="tour?.numberChildren2">
                  <span class="fw-700">
                    {{ tour?.numberChildren2 }}
                  </span> <span class="font-italic">
                    Trẻ em 2
                  </span>
                </span>
                <span v-if="tour?.numberInfant">
                  <span class="fw-700">
                    {{ tour?.numberInfant }}
                  </span> <span class="font-italic">
                    Em bé
                  </span>
                </span>
              </HStack>
            </div>
          </b-link>
        </template>
      </div>
    </b-card>
  </IAmOverlay>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { BButton, BCard, BLink } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import { LunarDate } from 'vietnamese-lunar-calendar'
import last from 'lodash/last'
import first from 'lodash/first'

import { convertISODateTime, getCurrentTimezone, getToday } from '@/@core/utils/filter'
import i18n from '@/libs/i18n'
import IAmIcon from '@/components/IAmIcon.vue'
import { specialDayOfVietnam } from '@/constants/selectOptions'
import { useRouter } from '@/@core/utils/utils'

import useListHandle from './useListHandle'

export default {
  components: {
    FullCalendar,
    BCard,
    BLink,
    BButton,
    IAmIcon,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const refCalendar = ref(null)
    const month = ref(moment().month() + 1)
    const year = ref(moment().year())
    const itemsByFilter = ref([])
    const {
      fetchItem, items, loading, filter,
    } = useListHandle()
    const locale = computed(() => i18n.locale)
    onMounted(() => {
      moment.locale(i18n.locale)
      fetchItem()
    })
    const currentYear = new Date().getFullYear()

    const yearOptions = computed(() => {
      const range = 5
      const options = []
      // eslint-disable-next-line no-plusplus
      for (let i = currentYear; i <= currentYear + range; i++) {
        options.push(i)
      }
      return options
    })

    watch(locale, val => {
      moment.locale(val)
    })

    watch(month, val => {
      month.value = val < 1 ? 1 : val > 12 ? 12 : val
      filter.value = {
        startDate: moment().year(year.value).month(month.value - 1).startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment().year(year.value).month(month.value - 1).endOf('month')
          .format('YYYY-MM-DD'),
      }
      const date = moment().year(year.value).month(month.value - 1).startOf('month')
        .format('YYYY-MM-DD')
      refCalendar.value.getApi().gotoDate(date)
    })

    watch(year, () => {
      filter.value = {
        startDate: moment().year(year.value).month(month.value - 1).startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment().year(year.value).month(month.value - 1).endOf('month')
          .format('YYYY-MM-DD'),
      }
      const date = moment().year(year.value).month(month.value - 1).startOf('month')
        .format('YYYY-MM-DD')
      refCalendar.value.getApi().gotoDate(date)
    })

    function getMonthText(month) {
      moment.locale(i18n.locale)
      const temp = moment().month(month - 1).format('MMMM')
      return temp.charAt(0).toUpperCase() + temp.slice(1).toLowerCase()
    }

    function getLunarDateElement(date) {
      moment.locale(i18n.locale)
      const mm = moment(date)
      const lunarDate = new LunarDate(mm.year(), mm.month() + 1, mm.date())
      const isSpecialDay = specialDayOfVietnam.find(item => {
        if (item.type === 'solar') {
          return item.date === mm.date() && item.month === mm.month()
        }
        if (item.type === 'lunar') {
          return item.date === lunarDate?.date && item.month === lunarDate?.month
        }
        return false
      })

      const lunarDiv = document.createElement('a')
      lunarDiv.className = 'fc-daygrid-luna-day-number'
      lunarDiv.style.color = ` ${lunarDate.date === 1 || (mm.date() === 1 && mm.month() === 1) || isSpecialDay ? '#C91B23' : ''}`
      lunarDiv.textContent = `${lunarDate.date}${lunarDate.date === 1 || (mm.date() === 1 && mm.month() === 1) || isSpecialDay ? `/${lunarDate.month}` : ''}`

      return lunarDiv
    }

    function select(params) {
      const {
        startStr,
        endStr,
      } = params
      refCalendar.value.getApi().updateSize()
      if (moment(startStr).month() + 1 !== month.value) return
      const temp = items.value.filter(e => {
        const itemDate = moment(e.date)
        return itemDate.isSameOrAfter(moment(startStr)) && itemDate.isBefore(moment(endStr))
      })
      itemsByFilter.value = temp
    }

    function eventClick(params) {
      refCalendar.value.getApi().select({
        start: params.event.startStr,
        allDay: params.event.allDay,
      })
    }

    const calendarOptions = ref({
      locale,
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ],
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      initialView: 'dayGridMonth',
      editable: false,
      selectable: true,
      showNonCurrentDates: false,
      selectMirror: true,
      dayMaxEvents: true,
      select,
      eventClick,
      selectOverlap: true,
      events: items,
      dayHeaderContent(arg) {
        if (locale.value === 'vi') {
          arg.text = arg.text.replace(/\bTh\b/g, 'Thứ')
        }
      },
      dayCellDidMount(arg) {
        const { date, el } = arg
        const dayTopElement = el.querySelector('.fc-daygrid-day-top')
        if (dayTopElement) {
          dayTopElement.prepend(getLunarDateElement(date))
        }
      },
    })

    watch([refCalendar, items], () => {
      if (refCalendar.value && items.value.length) {
        const today = getToday('YYYY-MM-DD')
        refCalendar.value.getApi().select({
          start: today,
          // end: tomorrow,
          allDay: true,
        })
      }
    })

    const { router } = useRouter()
    function openBookingDetail(name, id) {
      if (!name || !id) return
      const route = router.resolve({
        name,
        params: { id },
      })
      window.open(route.href)
    }

    function onClickMonthButtonHandle(type) {
      const delta = type === 'next' ? 1 : -1 // type = next or prev
      month.value += delta

      if (month.value > 12) {
        month.value = 1
        year.value += 1
      } else if (month.value < 1) {
        month.value = 12
        year.value -= 1
      }
    }
    return {
      refCalendar,
      calendarOptions,
      convertISODateTime,
      itemsByFilter,
      locale,
      month,
      year,
      moment,
      loading,
      yearOptions,
      getMonthText,
      last,
      first,
      getCurrentTimezone,
      openBookingDetail,
      onClickMonthButtonHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
.flight {
  transition: transform 0.3s ease;
  padding: 7px;
  border-radius: 7px;

  &:hover {
    background-color: #FFF7EA;
  }
}

.bg-grey {
  background-color: #E6E6E6;
}

#refCalendar {
  max-height: 80vh;
  width: 100%;
}

::v-deep .fc-day-other {
  visibility: hidden;
}
/* Tùy chỉnh Vue Select */
::v-deep .fc-col-header-cell {
  background-color: #EAF6FC;
  padding:5px;
}
/* Tùy chỉnh Vue Select */
::v-deep .vs__dropdown-toggle {
  border: none !important;
  box-shadow: none !important;
}

::v-deep .vs__actions {
  display: none;
}

/* Tùy chỉnh FullCalendar */
::v-deep .fc-daygrid-day-top {
  text-align: start;
  justify-content: start !important;
  font-weight: 700 !important;
}

::v-deep .fc-daygrid-day-number {
  color: black !important;
  padding: 5px 10px;
  font-size: 20px;
}

::v-deep .fc-daygrid-luna-day-number {
  font-weight: normal;
  font-size: 12px;
  margin-top: 16px;
}

::v-deep .text-danger, .text-success, .text-info {
  font-size: 16px;
  font-weight: bold;
}
::v-deep .fc-daygrid-more-link {
  display: none !important;
}

/* Responsive styles */
@media (max-width: 767px) { /* Small screens (sm) */

#refCalendar {
  min-height: 60vh;
}
  ::v-deep .fc-daygrid-day-number {
    font-size: 16px;
    padding: 4px 8px;
  }

  ::v-deep .fc-daygrid-luna-day-number {
    font-size: 10px;
    margin-top: 10px;
  }

  ::v-deep .text-danger, .text-success, .text-info {
    font-size: 16px;
  }
  ::v-deep .card-body {
    padding: 0.5rem;
  }
  ::v-deep div.fc-daygrid-day-events > div > a > div > div {
    padding: 0 !important;
    div > div{
      font-size: 14px;
      padding: 10px;
      width: 20px !important;
      height: 20px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) { /* Medium screens (md) */
  ::v-deep .fc-daygrid-day-number {
    font-size: 18px;
    padding: 5px 10px;
  }

  ::v-deep .fc-daygrid-luna-day-number {
    font-size: 11px;
    margin-top: 12px;
  }

  ::v-deep .text-danger, .text-success, .text-info {
    font-size: 18px;
  }
}

@media (min-width: 992px) { /* Large screens (lg) */
  ::v-deep .fc-daygrid-day-number {
    font-size: 20px;
    padding: 6px 12px;
  }

  ::v-deep .fc-daygrid-luna-day-number {
    font-size: 12px;
    margin-top: 16px;
  }

  ::v-deep .text-danger, .text-success, .text-info {
    font-size: 16px;
  }
}
::v-deep .fc-daygrid-event-harness{
  visibility: unset !important;
  position: unset !important;
}
::v-deep .fc-event-main{
  position: unset !important;
  z-index:unset !important;
}
::v-deep .fc-daygrid-day-events{
  position: unset !important;
  display: block !important;
}

::v-deep .fc .fc-daygrid-day.fc-day-today {
  background: transparent
}

::v-deep .fc-highlight {
  background: #ffdc2826
}

::v-deep .fc .fc-daygrid-day {
  &:hover {
    cursor: pointer;
  }
}
</style>
