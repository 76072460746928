<template lang="">
  <div>
    <ValidationObserver ref="refFormObserver">
      <IncorrectInvoiceInfoCard
        :incorrectInvoiceData.sync="incorrectInvoiceToCreate"
        isCreate
      />
      <IncorrectInvoiceSelectCard />
      <IncorrectInvoiceDetailCard />
    </ValidationObserver>

    <div>
      <BButton
        variant="info"
        @click="onCreateIncorrectInvoice"
      >
        {{ $t('invoice.incorrectInvoices.btn.create') }}
      </BButton>
    </div>
    <pre>{{ incorrectInvoiceToCreate }}</pre>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { BButton } from 'bootstrap-vue'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { getToday } from '@/@core/utils/filter'
import store from '@/store'
import router from '@/router'

import IncorrectInvoiceDetailCard from './components/IncorrectInvoiceDetailCard.vue'
import IncorrectInvoiceInfoCard from './components/IncorrectInvoiceInfoCard.vue'
import IncorrectInvoiceSelectCard from './components/IncorrectInvoiceSelectCard.vue'
import useIncorrectInvoiceHandle, { INCORRECT_INVOICE_NOTIFICATION_TYPE, useIncorrectInvoiceCreateHandle } from './useIncorrectInvoiceHandle'
import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    IncorrectInvoiceDetailCard,
    IncorrectInvoiceSelectCard,
    IncorrectInvoiceInfoCard,
    ValidationObserver,
    BButton,
  },
  setup() {
    const { refFormObserver } = formValidation()
    const companyId = computed(() => store.getters['userStore/getAgencyCompany']?.id)

    const { INVOICE_STORE } = useIncorrectInvoiceHandle()
    const { createIncorrectInvoice } = useIncorrectInvoiceCreateHandle()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
    })

    const blankIncorrectInvoice = {
      // companyId: companyId.value,
      notificationType: INCORRECT_INVOICE_NOTIFICATION_TYPE.TYPE_1,
      taxReviewNoticeNumber: '',
      dateOfTaxReviewNotice: '',
      placeOfIssuance: 'Ha Noi',
      dateOfIssuance: getToday('YYYY-MM-DD'),
      incorrectItems: [
        {
          id: 'dd7e4e8e-3022-477a-a748-38d9d3b1a726',
          typeOfInvoiceApplication: 'TYPE_1',
          taxAuthorityCode: 'taxAuthorityCode',
          templateNumberAndSymbol: 'templateNumberAndSymbol',
          invoiceNumber: '00000020',
          invoiceDate: `${getToday('YYYY-MM-DD')}T09:00:00Z`,
          action: 'CANCEL',
          reason: 'reason',
          amount: 0,
          isInternal: true,
          incorrectInvoiceNotificationId: 'string',
          invoiceEmail: 'lethaiduc11@gmail.com',
          invoiceBuyerName: 'Đặng Hải Bằng',
          invoiceTotalAmount: 2134000,
          invoiceBuyerUnitName: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ AIRDATA',
        },
      ],
    }

    const incorrectInvoiceToCreate = ref()
    const initIncorrectInvoiceToCreate = () => {
      incorrectInvoiceToCreate.value = cloneDeep(blankIncorrectInvoice)
      // store.dispatch('app-invoice/clearSelectedTickets')
    }
    initIncorrectInvoiceToCreate()

    function onCreateIncorrectInvoice() {
      // const isValid = refFormObserver.value.validate()
      // if (!isValid) return
      const payload = {
        ...incorrectInvoiceToCreate.value,
        companyId: companyId.value,
        dateOfIssuance: `${incorrectInvoiceToCreate.value.dateOfIssuance}T09:00:00Z`,
      }
      createIncorrectInvoice(payload)
    }
    // const selectedTickets = computed(() => store.getters['app-invoice/getSelectedTickets'])
    // const getCollectionFee = computed(() => store.getters['app-invoice/getCollectionFee'])
    // const getNeedToPayAmount = computed(() => store.getters['app-invoice/getNeedToPayAmount'])
    // const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])

    // const isInvalidInvoiceItems = ref(false)
    // watch(selectedTickets, (newValue, oldValue) => {
    //   if (selectedTickets.value.length && isInvalidInvoiceItems.value) {
    //     isInvalidInvoiceItems.value = false
    //   }
    //   if (!newValue.length && oldValue.length) {
    //     store.dispatch('app-invoice/clearSelectedTickets')
    //   }
    // })

    // async function createInvoiceHandle() {
    //   if (!selectedTickets.value.length) {
    //     isInvalidInvoiceItems.value = true
    //   }
    //   const isValid = await refFormObserver.value.validate()
    //   if (!isValid || isInvalidInvoiceItems.value) return
    //   const payload = {
    //     ...invoiceToCreate.value,
    //     invoiceItems: selectedTickets.value.map(item => ({
    //       itemType: item.itemType,
    //       name: `${item.name}`,
    //       ticketNumber: `${item.ticketNumber ?? ''}`,
    //       unit: item.unit,
    //       quantity: item.quantity ?? 0,
    //       unitPrice: numberToFixed(item.unitPrice ?? 0),
    //       valueAddedTax: item.valueAddedTax,
    //       discountRate: 0,
    //     })),
    //     templateId: invoiceToCreate.value?.template?.id,
    //     companyId: invoiceToCreate.value?.company?.id,
    //     agencyIssueId: invoiceToCreate.value?.agencyIssue?.id,
    //     agencyReceiveId: invoiceToCreate.value?.agencyReceive?.id,
    //     collectionFee: getCollectionFee.value,
    //     totalAmountAdditional: getNeedToPayAmount.value ?? 0,
    //     // xoá khỏi payload
    //     template: undefined, // xoá template khỏi payload
    //     company: undefined, // xoá company khỏi payload
    //     createdAt: undefined,
    //     invoiceType: undefined,
    //     agencyIssue: undefined,
    //     agencyReceive: undefined,
    //     invoiceStatus: undefined,
    //     createdType: isInvoiceRequest.value ? CREATE_TYPE.REQUEST : undefined,
    //   }
    //   createInvoice(payload)
    // }

    // const collapseSelectedTicket = ref(false)

    function exitHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmCancelInput'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            router.push({ name: 'app-incorrectInvoice-list' })
          }
        })
    }

    // const resetInvoice = ref({})

    // const updateInvoiceByField = debounce(({ key, value }) => {
    //   invoiceToCreate.value[key] = value
    // }, 300)

    return {
      refFormObserver,
      blankIncorrectInvoice,
      incorrectInvoiceToCreate,
      onCreateIncorrectInvoice,
      exitHandle,
      // invoiceToCreate,
      // createInvoiceHandle,
      // loading,
      // collapseSelectedTicket,
      // exitHandle,
      // resetInvoice,
      // isInvalidInvoiceItems,
      // isCompanyActive,
      // updateInvoiceByField,
      // isInvoiceRequest,
    }
  },
}
</script>
<style lang="">

</style>
