<template>
  <div id="header-tab-flight-id">
    <swiper
      class="swiper"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(trip, tripIndex) in searchFlightArray"
        :key="tripIndex"
        class="slide fit-content"
      >
        <BButton
          variant="vna-color"
          class="p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column"
          :style="tabIndex === tripIndex ? `background: ${headerColor}; color: white` : `color: ${headerColor}; background: white; border: 1px solid ${headerColor};`"
          @click="handleClickTab(tripIndex)"
        >
          <span
            id="draftSelectedTrip"
            class="fw-700 text-16px"
          >
            #{{ tripIndex + 1 }}
            {{ `${trip.startPoint}-${trip.endPoint} ${convertISODateTime(trip.departDate).date}` }}
          </span>
          <span class="text-14px font-weight-light">
            <span v-if="draftSelectedTrip[tripIndex]">
              {{ `${resolveAirlineFlightNumber(draftSelectedTrip[tripIndex].airline, draftSelectedTrip[tripIndex].flightNumber)}` }}
              {{ convertISODateTime(draftSelectedTrip[tripIndex].departure.at, draftSelectedTrip[tripIndex].departure.timezone).time }} - {{ convertISODateTime(draftSelectedTrip[tripIndex].arrival.at, draftSelectedTrip[tripIndex].arrival.timezone).time }}
            </span>
            <span
              v-else
              class="text-warning"
            >
              {{ $t('flight.notSelectedFlights') }}
            </span>
          </span>
        </BButton>
      </swiper-slide>
      <swiper-slide
        v-if="getGroupedItineraryResponse && notDomesticFlight"
        class="slide fit-content"
      >
        <BButton
          variant="vna-color"
          class="p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column"
          :style="tabIndex === searchFlightArray.length ? `background: ${headerColor}; color: white` : `color: ${headerColor}; background: white; border: 1px solid ${headerColor};`"
          @click="handleClickTab(searchFlightArray.length)"
        >
          <div class="d-flex align-items-center gap-1">
            <span class="fw-700 text-16px text-warning d-flex-center flex-column">
              <div>GKH</div>
              <div v-if="getSearchFlight?.sources?.includes('1G')">1G</div>
              <div v-else-if="combinationTabName">{{ combinationTabName }}</div>
            </span>
            <div class="d-flex-center flex-column gap-1">
              <span
                v-for="(trip, tripIndex) in searchFlightArray"
                :key="tripIndex"
              >{{ `${trip.startPoint}-${trip.endPoint} ${convertISODateTime(trip.departDate).date}` }}</span>
            </div>
          </div>
        </BButton>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { computed } from '@vue/composition-api'

import store from '@/store'
import env from '@/libs/env'
import { isDomesticFlight } from '@/constants/selectOptions'

import { convertISODateTime, resolveAirlineFlightNumber } from '@core/utils/filter'

export default {
  components: {
    BButton,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperOptions = computed(() => ({
      freeMode: true,
      spaceBetween: 5,
    }))
    const searchFlightArray = computed(() => store.getters['app-flight-v2/getSearchFlightArray']())
    const draftSelectedTrip = computed(() => store.getters['app-flight-v2/getDraftSelectedTrip'])
    const getGroupedItineraryResponse = computed(() => store.getters['app-flight-v2/getGroupedItineraryResponse'])
    const notDomesticFlight = computed(() => searchFlightArray.value?.some(it => !isDomesticFlight(it.startPoint, it.endPoint)))

    const getSearchFlight = computed(() => store.getters['app-flight-v2/getSearchFlight'])

    function handleClickTab(index) {
      store.dispatch('app-flight-v2/setTabIndex', index)
    }

    const defaultColor = '#166987'

    const headerColor = env?.navBarBackgroundColor || defaultColor
    return {
      swiperOptions,
      convertISODateTime,
      handleClickTab,
      getSearchFlight,
      searchFlightArray,
      draftSelectedTrip,
      getGroupedItineraryResponse,
      resolveAirlineFlightNumber,

      headerColor,
      notDomesticFlight,
      combinationTabName: env?.combinationTabName,
    }
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-flight-v2/getTabIndex'] },
      set(newValue) { this.$store.dispatch('app-flight-v2/setTabIndex', newValue) },
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

#header-tab-flight-id {
  position: sticky;
  top: 50px;
  z-index: 10;
}
</style>
