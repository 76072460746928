var render = function () {
  var _vm$fareRuleAirlineBy, _vm$fareRuleAirlineBy2, _vm$fareRuleAirlineBy3, _vm$fareRuleAirlineBy4, _vm$fareRuleAirlineBy5, _vm$fareRuleAirlineBy6, _vm$baggageInBookingD, _vm$flightInfo, _vm$baggageInBookingD2, _vm$baggageInBookingD3, _vm$flightInfo2, _vm$baggageInBookingD4, _vm$baggageInBookingD5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex text-truncate py-75 px-1 px-md-2 rounded-8px w-100 gap-2",
    class: {
      'bg-gray ': _vm.showInfoFlight
    }
  }, [_vm.showInfoFlight ? _c('div', {
    staticClass: "flex-fill"
  }, [_vm.flightInfo.status ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.status')) + ": ")]), _c('div', {
    staticClass: "d-flex gap-2"
  }, [_c('span', {
    staticClass: "fw-700",
    class: _vm.flightInfo.status === 'HK' ? 'text-success' : ['HL', 'SC', 'WK'].includes(_vm.flightInfo.status) ? 'text-danger' : _vm.flightInfo.status === 'KL' ? 'text-warning' : ''
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.status) + " ")]), ['UC', 'HL', 'DS', 'NO', 'HX', 'AK', 'GK'].includes(_vm.flightInfo.status) ? _c('div', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(" Lưu ý: Chưa có chỗ ")]) : _vm._e()])]) : _vm._e(), _vm.flightInfo.bookingClass || _vm.flightInfo.groupClass ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.bookingClass')) + ": ")]), _vm.flightInfo.fareBasisCode ? _c('div', [_vm._v(" ("), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm.reservationsData.source === 'VU' && _vm.flightInfo.fareType ? _c('span', [_vm._v(" " + _vm._s(_vm.flightInfo.fareType) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.flightInfo.groupClass || ((_vm$fareRuleAirlineBy = _vm.fareRuleAirlineByBookingClass) === null || _vm$fareRuleAirlineBy === void 0 ? void 0 : _vm$fareRuleAirlineBy.cabinName)))])]), _vm.flightInfo.bookingClass && (_vm.flightInfo.groupClass || (_vm$fareRuleAirlineBy2 = _vm.fareRuleAirlineByBookingClass) !== null && _vm$fareRuleAirlineBy2 !== void 0 && _vm$fareRuleAirlineBy2.cabinName) ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.bookingClass) + " ")]), _c('span', {
    staticClass: "fw-600 text-danger ml-25"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.fareBasisCode) + " ")]), _vm._v(" ) ")]) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.groupClass || ((_vm$fareRuleAirlineBy3 = _vm.fareRuleAirlineByBookingClass) === null || _vm$fareRuleAirlineBy3 === void 0 ? void 0 : _vm$fareRuleAirlineBy3.cabinName)) + " ")]), _vm.flightInfo.bookingClass && (_vm.flightInfo.groupClass || (_vm$fareRuleAirlineBy4 = _vm.fareRuleAirlineByBookingClass) !== null && _vm$fareRuleAirlineBy4 !== void 0 && _vm$fareRuleAirlineBy4.cabinName) ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.flightInfo.bookingClass) + " ")])])]) : _vm._e(), _vm.reservationsData.supplierPNRs && _vm.reservationsData.supplierPNRs.length ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.airPnr')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.resolveAirPnr(_vm.reservationsData.supplierPNRs, _vm.flightInfo.airline, {
    showInEticket: false
  })) + " ")])]) : _vm._e(), _vm.reservationsData.isDomestic && (_vm.flightInfo.bookingClass || _vm.flightInfo.groupClass) && _vm.reservationsData.source !== 'QH' || _vm.reservationsData.isDomestic && _vm.flightInfo.groupClass && _vm.reservationsData.source === 'QH' || ['VU'].includes(_vm.reservationsData.source) ? _c('div', {
    staticClass: "pb-25 d-flex-between",
    attrs: {
      "id": "rule-refund-".concat(_vm.index)
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.$t("reservation.".concat(_vm.reservationsData.source === 'VJ' ? 'refundTicketVJ' : 'refundTicket'))) + ": ")]), _c('BBadge', {
    staticClass: "font-weight-bold text-wrap",
    attrs: {
      "variant": ['', 'NONE', false].includes((_vm$fareRuleAirlineBy5 = _vm.fareRuleAirlineByBookingClass) === null || _vm$fareRuleAirlineBy5 === void 0 ? void 0 : _vm$fareRuleAirlineBy5.refund) ? 'danger' : 'info'
    }
  }, [_vm._v(" " + _vm._s(['', 'NONE', false].includes((_vm$fareRuleAirlineBy6 = _vm.fareRuleAirlineByBookingClass) === null || _vm$fareRuleAirlineBy6 === void 0 ? void 0 : _vm$fareRuleAirlineBy6.refund) ? _vm.$t('reservation.disallowRefund') : _vm.$t('reservation.allowRefund')) + " " + _vm._s("".concat(_vm.reservationsData.source === 'VJ' ? _vm.$t('reservation.identification') : '')) + " ")])], 1) : _vm._e(), _vm.flightInfo.mileage ? _c('div', {
    staticClass: "pb-25 d-flex-between"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.flightsDetails.distance')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(Number(_vm.flightInfo.mileage)))])]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-wrap pb-25 d-flex-between",
    attrs: {
      "title": (_vm$baggageInBookingD = _vm.baggageInBookingDetail) === null || _vm$baggageInBookingD === void 0 ? void 0 : _vm$baggageInBookingD.carryBag
    }
  }, [_c('small', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.handBaggage')) + ": ")]), (_vm.reservationsData.source !== 'QH' || _vm.reservationsData.source === 'QH' && (_vm$flightInfo = _vm.flightInfo) !== null && _vm$flightInfo !== void 0 && _vm$flightInfo.groupClass) && (_vm$baggageInBookingD2 = _vm.baggageInBookingDetail) !== null && _vm$baggageInBookingD2 !== void 0 && _vm$baggageInBookingD2.carryBag ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm$baggageInBookingD3 = _vm.baggageInBookingDetail) === null || _vm$baggageInBookingD3 === void 0 ? void 0 : _vm$baggageInBookingD3.carryBag) + " ")]) : _c('small', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      value: _vm.$t('reservation.contactBookerBaggage'),
      expression: "$t('reservation.contactBookerBaggage')",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "font-weight-bold text-danger text-truncate width-200"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.contactBookerBaggage')) + " ")])]), _vm.reservationsData.source !== 'QH' || _vm.reservationsData.source === 'QH' && (_vm$flightInfo2 = _vm.flightInfo) !== null && _vm$flightInfo2 !== void 0 && _vm$flightInfo2.groupClass ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-wrap d-flex-between",
    attrs: {
      "title": (_vm$baggageInBookingD4 = _vm.baggageInBookingDetail) === null || _vm$baggageInBookingD4 === void 0 ? void 0 : _vm$baggageInBookingD4.checkinBag
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.freeBaggage')) + ": ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm$baggageInBookingD5 = _vm.baggageInBookingDetail) === null || _vm$baggageInBookingD5 === void 0 ? void 0 : _vm$baggageInBookingD5.checkinBag) + " ")])]) : _vm._e()]) : _c('div', {
    staticClass: "flex-fill"
  }), _c('div', [!_vm.hideFeature ? _c('BButton', {
    staticClass: "p-25 rounded-circle",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showInfoFlight = !_vm.showInfoFlight;
      }
    }
  }, [_vm.showInfoFlight ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }