var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.showLoading
    }
  }, [_vm.messageBoard.length ? _c('div', _vm._l(_vm.messageBoard, function (messageItem, messageIndex) {
    return _c('div', {
      key: messageIndex,
      attrs: {
        "id": "message-".concat(messageItem.id)
      }
    }, [_c('b-card', {
      class: "shadow-sm border-".concat(_vm.getBgColor(messageItem.priorityPin), " hover-card"),
      attrs: {
        "bg-variant": _vm.getVariantColor(messageItem),
        "body-class": "p-1"
      }
    }, [_c('HStack', [!messageItem.isRead ? _c('IAmIcon', {
      staticClass: "px-50",
      attrs: {
        "icon": "circle",
        "size": "14"
      }
    }) : _vm._e(), _c('Stack', {
      staticClass: "flex-1 gap-2"
    }, [_c('HStack', {
      attrs: {
        "between": ""
      }
    }, [_c('div', {
      staticClass: "text-18px fw-700"
    }, [_c('span', {
      class: "text-".concat(_vm.getBgColor(messageItem.priorityPin))
    }, [_vm._v(" (" + _vm._s(_vm.$t("messBoard.priorityPin.".concat(messageItem.priorityPin))) + ") ")]), _c('span', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(messageItem.name) + " ")])]), _c('div', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(_vm.dateTime(messageItem.updatedAt)) + " ")])]), _c('div', {
      staticClass: "text-dark"
    }, [_c('div', {
      style: "".concat(!_vm.expandedItems[messageIndex] ? 'max-height: 10rem; overflow: hidden; text-overflow: ellipsis;' : ''),
      domProps: {
        "innerHTML": _vm._s(_vm.resolveDescription(messageItem.description, _vm.expandedItems[messageIndex] ? 'expanded' : null))
      }
    })]), _c('div', {
      staticClass: "d-flex justify-content-between fw-600"
    }, [_c('BLink', {
      class: "text-".concat(_vm.getBgColor(messageItem.priorityPin)),
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleContent(messageIndex);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("messBoard.".concat(_vm.expandedItems[messageIndex] ? 'collapse' : 'viewMore'))) + " ")]), _vm.expandedItems[messageIndex] ? _c('BLink', {
      staticClass: "ml-auto",
      class: "text-".concat(_vm.getBgColor(messageItem.priorityPin)),
      attrs: {
        "to": {
          name: 'apps-documents-edit',
          params: {
            id: messageItem.id
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.detail')) + " ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1);
  }), 0) : _c('div', [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.noInformation')) + " ")])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }