/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchCustomers(_payload = {}) {
    return service.get('/customers', _payload)
  },

  getCustomerById(id, payload = {}) {
    return service.get(`/customers/${id}`, payload)
  },

  deleteCustomer(id = '') {
    return service.delete(`/customers/${id}`)
  },

  addCustomer(payload = {}) {
    return service.post('/customers', payload)
  },

  updateCustomer(id = '', payload = {}) {
    return service.patch(`/customers/${id}`, payload)
  },

  bulkDeleteCustomers(ids = {}) {
    return service.post('/customers/bulk-delete', ids)
  },

  fetchContacts(id, _payload = {}) {
    return service.get(`/customers/${id}/contacts`, _payload)
  },

  getContactById(id, contactId, payload = {}) {
    return service.get(`/customers/${id}/contacts/${contactId}`, payload)
  },

  addContact(id, payload = {}) {
    return service.post(`/customers/${id}/contacts`, payload)
  },

  updateContact(id = '', contactId = '', payload = {}) {
    return service.patch(`/customers/${id}/contacts/${contactId}`, payload)
  },

  deleteContact(customerId = '', contactId) {
    return service.delete(`/customers/${customerId}/contact/${contactId}`)
  },

  validateEmail(payload = {}) {
    return service.post('/customers/validate-email', payload)
  },

  validatePhonenumber(payload = {}) {
    return service.post('/customers/validate-phone', payload)
  },

  exportCustomers(payload = {}, fileName) {
    return service.excel('/customers/export', payload, fileName)
  },

  exportCustomersTemplate(fileName) {
    return service.excel('/customers/export-template', {}, fileName)
  },

  bulkInsert(payload = {}) {
    return service.post('/customers/bulk-insert', payload)
  },

  getCountries(payload = {}) {
    return service.get('/metadata/countries', payload)
  },

  getAdministrativeUnits(payload = {}) {
    return service.get('/metadata/administrative-units', payload)
  },
  changeSocialNetwork(id, payload) {
    return service.put(`/customers/${id}/social-accounts`, payload)
  },
  getAllCustomer(_payload = {}) {
    return service.get('/customers/search/balance', _payload)
  },

  getCustomersByAgency(agencyId, payload) {
    return service.get(`/customers/by-agency/${agencyId}`, payload)
  },
}
