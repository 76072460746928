var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    attrs: {
      "type": "border"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "is-visible": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title font-weight-bolder px-1 py-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "py-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.startDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDate ? _vm.endDate : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.endDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDate,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('vue-autosuggest', {
    attrs: {
      "id": "agency-code",
      "suggestions": _vm.agencySuggestions,
      "input-props": {
        placeholder: "".concat(_vm.$t('saleReport.phAgencyCode')),
        class: 'form-control'
      },
      "get-suggestion-value": _vm.getSuggestionValue
    },
    on: {
      "input": _vm.inputHandle
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var suggestion = _ref.suggestion;
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(_vm._s(suggestion.item.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(suggestion.item.agencyName) + ")")])])];
      }
    }]),
    model: {
      value: _vm.agencyCode,
      callback: function callback($$v) {
        _vm.agencyCode = $$v;
      },
      expression: "agencyCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phBookingCode'),
      "formatter": _vm.trimUpperCaseInput
    },
    model: {
      value: _vm.bookingCode,
      callback: function callback($$v) {
        _vm.bookingCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "bookingCode"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.flightType')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.flightTypeOptions,
      "label": "label",
      "clearable": false,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "disabled": Boolean(_vm.fixFlightType)
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.flightType,
      callback: function callback($$v) {
        _vm.flightType = $$v;
      },
      expression: "flightType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.flightDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'd/m/Y',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.flightDate,
      callback: function callback($$v) {
        _vm.flightDate = $$v;
      },
      expression: "flightDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.source')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.distributorsListWithTrain,
      "label": "label",
      "multiple": "",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "disabled": Boolean(_vm.fixFlightType)
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(_vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("flight.sourceName.".concat(data.label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.source,
      callback: function callback($$v) {
        _vm.source = $$v;
      },
      expression: "source"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.ticketType')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.saleReportTicketTypeOptions,
      "label": "label",
      "clearable": "",
      "multiple": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.saleReportTicketType,
      callback: function callback($$v) {
        _vm.saleReportTicketType = $$v;
      },
      expression: "saleReportTicketType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.airline')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phAirline'),
      "formatter": _vm.trimUpperCaseInput,
      "maxlength": "2"
    },
    model: {
      value: _vm.airline,
      callback: function callback($$v) {
        _vm.airline = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "airline"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.shortTitle.dob')
    }
  }, [_c('HStack', [_c('flat-pickr', {
    staticClass: "form-control flex-1",
    attrs: {
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDate ? _vm.endDate : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.dobFilter,
      callback: function callback($$v) {
        _vm.dobFilter = $$v;
      },
      expression: "dobFilter"
    }
  }), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top.window",
      value: _vm.$t('saleReport.clearDobFilter'),
      expression: "$t('saleReport.clearDobFilter')",
      modifiers: {
        "hover": true,
        "top": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-circle",
    attrs: {
      "variant": "flat-danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.dobFilter = null;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "closeOutline"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": _vm.onUpdateFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }