var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-payment-ancillary-services",
      "title": _vm.$t('reservation.paymentAncillary'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.handleHideModal,
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25",
          attrs: {
            "disabled": !_vm.selectedItems.length,
            "pill": ""
          },
          on: {
            "click": _vm.handleConfirmPaymentAncillary
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      }
    }])
  }, [_c('div', {
    staticClass: "my-75 font-weight-bold"
  }, [_vm._v(" Chọn gói dịch vụ thanh toán: ")]), _c('b-card', {
    staticClass: "border",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "id": "table-ancillary-services",
      "centered": "",
      "responsive": "",
      "hover": "",
      "small": "",
      "thead-class": "text-dark text-center text-nowrap",
      "tbody-class": "text-dark text-center text-nowrap",
      "fields": _vm.ancillaryServicesColumns,
      "items": _vm.ancillaryServicesList,
      "show-empty": "",
      "empty-text": 'Không tìm thấy những dịch vụ thêm chưa thanh toán!'
    },
    on: {
      "row-clicked": _vm.onRowClicked
    },
    scopedSlots: _vm._u([_vm._l(_vm.ancillaryServicesColumns, function (column, index) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: index,
            staticClass: "text-dark"
          }, [data.column === 'checkbox' ? _c('span', [_c('b-form-checkbox', {
            staticClass: "mr-1 pl-0",
            attrs: {
              "disabled": true,
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handlePaxsSelectAll(_vm.allPaxsSelected);
              }
            },
            model: {
              value: _vm.allPaxsSelected,
              callback: function callback($$v) {
                _vm.allPaxsSelected = $$v;
              },
              expression: "allPaxsSelected"
            }
          })], 1) : _c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          staticClass: "p-0",
          attrs: {
            "name": "check-box",
            "inline": "",
            "disabled": true,
            "checked": _vm.isChecked(item)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(item);
            }
          }
        })];
      }
    }, {
      key: "cell(full_name)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('small', {
          staticClass: "text-warning font-weight-bold"
        }, [_vm._v(_vm._s(item.passenger.paxId))]), _vm._v(" " + _vm._s("".concat(item.passenger.lastName, " / ").concat(item.passenger.firstName)) + " ")])];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-center text-truncate"
        }, [!_vm.isEmpty(item.segments) && item.segments.length < 2 ? _c('div', [_vm._v(" " + _vm._s(item.segments[0].departure.iataCode) + " - " + _vm._s(item.segments[0].arrival.iataCode) + " ")]) : _vm._e(), !_vm.isEmpty(item.segments) && item.segments.length > 1 ? _c('div', _vm._l(item.segments, function (segment, indexSegment) {
          return _c('span', {
            key: indexSegment
          }, [_vm._v(" " + _vm._s(indexSegment === 0 ? "".concat(segment.departure.iataCode) : '') + " " + _vm._s(indexSegment !== 0 && indexSegment !== item.segments.length - 1 ? " - ".concat(segment.departure.iataCode) : " - ".concat(segment.arrival.iataCode)) + " ")]);
        }), 0) : _vm._e(), _c('b-badge', {
          staticClass: "ml-1 font-weight-bold",
          attrs: {
            "variant": item.eticket ? 'success' : 'warning'
          }
        }, [_vm._v(" " + _vm._s(item.eticket ? 'Đã xuất vé' : 'Chưa xuất vé') + " ")])], 1)];
      }
    }, {
      key: "cell(services)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.serviceType) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-center text-truncate"
        }, [_vm._v(" " + _vm._s(item.status) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.serviceType === 'SEAT' ? _c('span', [_vm._v(_vm._s(item.pdcSeat))]) : _vm._e(), item.serviceType === 'BAGGAGE' ? _c('div', [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(item.serviceSubCode)) + " x" + _vm._s(item.numberOfItems) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-center text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " ")])];
      }
    }], null, true)
  })], 1), !_vm.isEmpty(_vm.ancillaryServicesList) && _vm.ancillaryServicesList.some(function (item) {
    return !item.eticket;
  }) ? _c('b-card', {
    staticClass: "border-warning text-center my-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-alert', {
    staticClass: "fw-700 text-airline mb-0 px-1 py-50",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v(" Chỉ thanh toán các dịch vụ trên hành trình đã xuất vé. Vui lòng xuất vé trước khi thực hiện! ")])], 1) : _vm._e(), _c('b-card', {
    attrs: {
      "body-class": "p-1"
    }
  }, [_c('div', {
    staticClass: "mr-2 text-right"
  }, [_c('span', {
    staticClass: "mr-2 font-weight-bolder"
  }, [_vm._v(" Tổng tiền thanh toán: ")]), _c('span', {
    staticClass: "font-weight-bolder text-warning font-medium-5"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalPriceSelected)))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }