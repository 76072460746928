<template>
  <IAmOverlay :loading="showLoading">
    <div v-if="messageBoard.length">
      <div
        v-for="(messageItem, messageIndex) in messageBoard"
        :id="`message-${messageItem.id}`"
        :key="messageIndex"
      >
        <b-card
          :bg-variant="getVariantColor(messageItem)"
          :class="`shadow-sm border-${getBgColor(messageItem.priorityPin)} hover-card`"
          body-class="p-1"
        >
          <HStack>
            <IAmIcon
              v-if="!messageItem.isRead"
              icon="circle"
              size="14"
              class="px-50"
            />
            <Stack class="flex-1 gap-2">
              <!-- HEADER -->
              <HStack between>
                <div class="text-18px fw-700">
                  <span :class="`text-${getBgColor(messageItem.priorityPin)}`">
                    ({{ $t(`messBoard.priorityPin.${messageItem.priorityPin}`) }})
                  </span>
                  <span class="text-dark">
                    {{ messageItem.name }}
                  </span>
                </div>

                <div class="text-dark">
                  {{ dateTime(messageItem.updatedAt) }}
                </div>
              </HStack>
              <!-- BODY -->
              <div class="text-dark">
                <div
                  :style="`${!expandedItems[messageIndex] ? 'max-height: 10rem; overflow: hidden; text-overflow: ellipsis;' : ''}`"
                  v-html="resolveDescription(messageItem.description, expandedItems[messageIndex] ? 'expanded' : null)"
                />
              </div>
              <div class="d-flex justify-content-between fw-600">
                <BLink
                  :class="`text-${getBgColor(messageItem.priorityPin)}`"
                  @click.prevent="toggleContent(messageIndex)"
                >
                  {{ $t(`messBoard.${expandedItems[messageIndex] ?'collapse' : 'viewMore'}`) }}
                </BLink>
                <BLink
                  v-if="expandedItems[messageIndex] "
                  :class="`text-${getBgColor(messageItem.priorityPin)}`"
                  class="ml-auto"
                  :to="{ name: 'apps-documents-edit', params: { id: messageItem.id } }"
                  target="_blank"
                >
                  {{ $t('messBoard.detail') }}
                </BLink>
              </div>
            </Stack>
          </HStack>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-alert
        variant="warning"
        show
      >
        <h4 class="alert-heading text-center">
          {{ $t('messBoard.noInformation') }}
        </h4>
      </b-alert>
    </div>
  </IAmOverlay>
</template>
<script>
import {
  BAlert, BCard, BLink,
} from 'bootstrap-vue'
import {
  ref, watch, set, onUnmounted,
} from '@vue/composition-api'

import store from '@/store'

import { dateTime } from '@core/utils/filter'

import documentStoreModule from '../documents/documentStoreModule'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    messageBoard: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const showLoading = ref(false)
    const expandedItems = ref([])
    const boards = ref([])

    const MESS_BOARD_STORE_MODULE_NAME = 'app-documents'
    if (!store.hasModule(MESS_BOARD_STORE_MODULE_NAME)) {
      store.registerModule(MESS_BOARD_STORE_MODULE_NAME, documentStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(MESS_BOARD_STORE_MODULE_NAME)) {
        store.unregisterModule(MESS_BOARD_STORE_MODULE_NAME)
      }
    })

    const getBgColor = priorityPin => {
      switch (priorityPin) {
        case 'HIGH':
          return 'danger'
        case 'MEDIUM':
          return 'warning'
        case 'LOW':
          return 'secondary'
        default:
          return 'white'
      }
    }
    const getVariantColor = noti => {
      if (noti.priorityPin === 'HIGH' && !noti.isRead) {
        return 'light-danger'
      } if (noti.priorityPin === 'MEDIUM' && !noti.isRead) {
        return 'light-warning'
      } if (noti.priorityPin === 'LOW' && !noti.isRead) {
        return 'light-secondary'
      }
      return 'light'
    }

    watch(() => props.messageBoard, newItems => {
      if (!newItems.length) return
      expandedItems.value = newItems.map(item => item.isExpand || false)
    },
    { deep: true, immediate: true })

    const toggleContent = messageIndex => {
      const item = props.messageBoard[messageIndex]
      if (!item) return
      set(item, 'isExpand', !item.isExpand)
      if (!item.isRead) {
        set(item, 'isRead', true)
      }
    }

    const resolveDescription = (description, expanded = false) => {
      if (!description) return description
      return description.replaceAll('<img ', `<img style="max-width: 100%; ${expanded ? '' : 'max-height: 60vh;'}"`)
    }
    return {
      showLoading,
      getVariantColor,
      getBgColor,
      dateTime,
      toggleContent,
      expandedItems,
      boards,
      resolveDescription,
    }
  },

}
</script>

<style lang="scss" scoped>
.hover-card {
  &[class*="border-"]:not(:hover) {
    border-color: transparent !important;
  }
}
</style>
